<ng-container *ngIf="merchantService.merchant">

<div style="position: absolute; width: 100%;">
  
  <app-navbar [menuShown]="false" 
              [locationShown]="false" 
              [logoShown]="false" 
              [frontColor]="'black'"
              [highlightLanguageSelection]="true"
              [fol_bg]="false"></app-navbar>
  
</div>

<div class="d-flex flex-column background justify-content-end bg-white"
     [style.background]="'url(' + this.merchantService.merchant.header_image.large_url + ')'"
     [style.background-size]="'cover'"
     [style.background-position]="'center'"
     *ngIf="!fol && merchantService.merchant.header_image">
     <div class="d-flex flex-row">
       
       <div style="position:absolute;top:15px;left:15px">
          <img class="logo" src="{{ merchantService.merchant.image?.medium_url }}" style="border-radius: 5px;">
       </div>
        
        <!--
        <div class="d-none d-lg-block clickable" style="transform:scale(0.6);transform-origin: bottom;margin: 0 -10px -15px auto;text-decoration:none;color:black">
          <ng-container *ngIf="user_loyalty_point">
            <loyalty-card [flipped]="false" [user_loyalty_point]="user_loyalty_point" [large_screen]="true"></loyalty-card>
          </ng-container>
        </div>
        -->

     </div>
     <ng-container *ngTemplateOutlet="titlebar"></ng-container>
</div>

<div class="d-flex flex-column background justify-content-end"
     [ngClass]="{ 'fol-header': fol }"
     [ngStyle]="{'background-color': !fol && 'rgb(240,240,240)' }"
     *ngIf="fol || !merchantService.merchant.header_image">
     
     <ng-container *ngTemplateOutlet="titlebar"></ng-container>
</div>

<ng-template #titlebar>
  <div class="venuebar container-fluid py-2">
       <div class="d-flex justify-content-between align-items-center">

                <div *ngIf="merchantService.kiosk && back_shown; else backArrow">
                  <button mat-flat-button 
                          color="primary" 
                          (click)="backClicked()">
                            <ng-container *ngIf="role !== 'checkout'">
                              <fa-icon  
                                  class="clickable" 
                                  [icon]="faCancel">
                              </fa-icon>
                            {{ 'kiosk.cancel_order' | translate }}</ng-container>
                            <ng-container *ngIf="role === 'checkout'">
                              <fa-icon  
                                  class="clickable" 
                                  [icon]="faEdit">
                              </fa-icon>
                              {{ 'kiosk.modify_order' | translate }}
                            </ng-container>
                  </button>
                </div>
        
                <ng-template #backArrow>
                  <div style="z-index: 9999;">
                    <fa-icon  
                        *ngIf="back_shown"
                        class="clickable" 
                        (click)="backClicked()"
                        [icon]="faArrowLeft">
                    </fa-icon>
                  </div>
                </ng-template>
        
        
        <div  *ngIf="!merchantService.kiosk" 
              class="merchant_name"
              [ngClass]="{ 'fol-merchant-name': fol }">
          <ng-container *ngIf="this.userService.local_location && this.userService.local_location.typecode == 'table'; else merchantName"> {{ this.userService.local_location.name }}</ng-container>
          <ng-template #merchantName>
          
            {{ merchantService.merchant.name }}

            <ng-container
             *ngIf="extra_info">

              <div class="pe-1 z-9999"
                *ngIf="userService.user && !this.userService.user.is_anonymous_user && !userService.user.virtual_user" 
                style="display: inline;">
              <a (click)="toggleFav(merchantService.merchant,$event)">
                
                <fa-icon  
                      *ngIf="!merchantService.merchant.favorite"
                      class="clickable" 
                      [icon]="faHeartCircle">
                </fa-icon>

                <fa-icon  
                      *ngIf="merchantService.merchant.favorite"
                      class="clickable" 
                      [icon]="faHeartCircleSolid">
                </fa-icon>

              </a>
            </div>

            <fa-icon  class="clickable pe-1 z-9999" 
                      (click)="goToInfo();"
                      [icon]="faInfoCircle">
            </fa-icon>

            <div class="favorite pe-1 z-9999" 
                style="display: inline;">
                
                <fa-icon  
                      *ngIf="!userService.user"
                      class="clickable" 
                      [icon]="faUserCircle"
                      [routerLink]="['/login']">
                </fa-icon>

                <fa-icon  
                      *ngIf="userService.user && !userService.user.virtual_user"
                      class="clickable" 
                      [icon]="faUserCircleSolid"
                      [routerLink]="['/profile']"
                      >
                </fa-icon>

            </div>

          </ng-container>

          </ng-template>
        </div>

        <div class="text-right">
           <div *ngIf="user_loyalty_point?.credit > 0">
                <fa-icon
                    class="pe-1"
                    [icon]="faWallet">
                </fa-icon>
                {{ user_loyalty_point?.credit | currency:'EUR' }}
            </div>
        </div>

       </div>
     </div>
  </ng-template>

</ng-container>