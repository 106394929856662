import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { UserService} from '../../services/user.service';
import { GroupService} from '../../services/group.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {

  public groupMembers;
  public group;

  groupMember = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email])
  });

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    public dialogRef: MatDialogRef<MembersComponent>,
    private util: UtilityService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.getGroup();
    this.getGroupMembers();
  }

  getGroup() {
    this.groupService.getGroup(this.data.group_id)
    .subscribe(
      group => {
        console.log(group);
        this.group = group;
      },
      error => {
        console.log(error);
      }
    );
  }

  getGroupMembers() {
    this.userService.getGroupMembers(this.data.group_id)
    .subscribe(
      groupMembers => {
        console.log('GROUP MEMBERS =>');
        console.log(groupMembers);
        this.groupMembers = groupMembers;
      },
      error => {
        console.log(error);
      }
    );
  }

  onGroupMemberFormSubmit() {
    this.groupMember.value.email =  this.groupMember.value.email.toLowerCase();
    this.userService.createGroupMember(this.data.group_id, this.groupMember.value).subscribe(
      groupMember => {
        this.util.openSnackBar(this.translate.instant('group_member.invitation_sent'), this.translate.instant('ok'));
        this.groupMembers.unshift(groupMember);
        this.groupMember.setValue({
          email: ''
        });
      },
      error => {
        this.util.openSnackBar('Group member is already added', this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  deleteGroupMember(groupMemberId) {
    this.userService.deleteGroupMember(this.data.group_id, groupMemberId)
    .subscribe(
      groupMember => {
        this.groupMembers = this.groupMembers.filter(function( groupMember ) {
          return groupMember.id !== groupMemberId;
        });
        this.util.openSnackBar(this.translate.instant('group_member.removed'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar('Group member cannot be removed', this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  setUser(email) {
    this.groupService.setOwner(this.group.id, email)
    .subscribe(
      group => {
        console.log("THE OWNER HAS BEEN SET!!!!!! =>");
        console.log(group);
        this.group = group;
      },
      error => {
        console.log(error);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
