  <div class="subtitle">
    {{ 'input_loyalty_card.subtitle' | translate }}
  </div>
  
  <ngx-spinner type="ball-pulse"></ngx-spinner>
  
  <ng-container *ngIf="!userService.basket.order.loyalty_card_qr_code">
    <div>
       <div style="flex-grow:1">
          <mat-form-field style="width:100%">
             <mat-label>{{ 'input_loyalty_card.placeholder' | translate }}</mat-label>
            <input matInput [(ngModel)]="loyalty_card_qr_code" (ngModelChange)="checkIfSix()" type="text">
          </mat-form-field>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="userService.basket.order.loyalty_card_qr_code">
    <div class="row" style="width:100%">
      <div class="col-12 col-md-3 align-self-center" style="font-weight: bold;" *ngIf="userService.basket.order.loyalty_card_qr_code">{{ userService.basket.order.loyalty_card_qr_code }}</div>
      <div class="col-11 col-md-8 align-self-center"></div>
      <div class="col-1">
        <button mat-icon-button (click)="removeLoyaltyCardNumber()" style="text-align:right;">
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </div>
    </div>

    <div class="py-3" *ngIf="loyalty_points">
      <div class="loyalty-card w-100" style="height:250px;">
        <div class="front loyalty-card-background-2"
          [style.background]="'url(' + background_url + ')'"
          [style.background-position]="'center'"
          [style.background-size]="'cover'">
          <div class="card-body loyalty-card-background d-flex flex-column justify-content-between" style="border-radius:10px;height: 250px;">
            <div class="d-flex flew-row justify-content-between" style="height: 100px;">
              <div><img width="60px;" src='{{ logo }}'></div>
              <div><img width="100px;" src="{{ merchantService.merchant.image?.medium_url }}"></div>
              <div style="width: 60px; text-align: right;">
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between p-3">
              <div  class="plus-points"
                    [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#ad151c'}">
                {{ loyalty_points }}
              </div>
              <div class="ps-3">
                <ng-container *ngIf="loyalty_points > 1; else earn_one">{{ 'loyalty_points.earn_multiple' | translate:{ loyalty_points: loyalty_points } }}</ng-container>
                <ng-template #earn_one>
                  {{ 'loyalty_points.earn_one' | translate:{ loyalty_points: loyalty_points } }}
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
