<ngx-spinner name="orderDetails" type="ball-pulse"></ngx-spinner>

<div class="fixed" *ngIf="!loading">
	<venue-header [extra_info]="true"></venue-header>	
	<div class="titlebar px-3">{{ 'order_detail.title' | translate }}</div>
</div>

<div class="main pt-3" *ngIf="!loading">

	<data-request 
				  [needsUserDetails]="needsUserDetails"
				  [needsOrderDetails]="true"
				  (completed)="onDataRequestCompleted($event)"
	></data-request>

</div>