<div class="d-flex flex-column center-screen" *ngIf="payconiq">

	<ng-container *ngIf="!isMobile">
		<div class="payconiq-frame" >
			<img src='{{ payconiq._links.qrcode.href }}'>
		</div>
		<ng-container *ngIf="!isKiosk">
			<div class="mt-3"
					style="font-size: 1.4em; border-top: 1px solid; width: 75%;">
				<a class="payconiq-link" href="{{ payconiq._links.deeplink.href.toLowerCase() }}">
				<strong>Betaal je via mobiel toestel?</strong>
				Klik hier om de Payconiq app te openen en de betaling uit te voeren
			</a>
			</div>
		</ng-container>
	</ng-container>

	<div class="payconiq-mobile" *ngIf="isMobile && payment">
		<a class="payconiq-link" href="{{ payconiq._links.deeplink.href.toLowerCase() }}">
			<img width='200' src='assets/payment_methods/PAYCONIQ.png'><br>
			Klik hier om de Payconiq app te openen en de betaling uit te voeren
		</a>
		<div 	class="mt-3" 
				style="font-size: 1.4em; border-top: 1px solid;">
			<a class="payconiq-link" (click)="toggleDevice()">
			<strong>Wens je de QR code te scannen?</strong>
			Klik hier om de QR code te tonen zodat je deze kan scannen
		</a>
		</div>
	</div>

	<div class="payconiq-status" *ngIf="payment">
		{{ payment.creditor.name }}<br>
		{{ payment.amount/100 | currency: payment.currency }}<br>
	</div>

	<div class="cancel">
		<a (click)="goBack()">betaling annuleren</a>
	</div>

	<div *ngIf="!isMobile">
		<img class="payconiq-info" src='/assets/payment_methods/payconiq_info.png'>
	</div>

</div>
