import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MerchantService} from '../services/merchant.service';
import { UserService} from '../services/user.service';
import { PubnubService } from '../services/pubnub.service';
import { UtilityService } from '../services/utility.service';
import { environment } from '../../environments/environment';

import { User } from '../models/user';

import { Router, ActivatedRoute } from '@angular/router';

import { AppHelper } from '../helpers/app-helper';

import { Location } from '@angular/common';

import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  private merchant_slug;
  private merchant_domain;
  private paramKiosk;
  public kiosks; 
  public background_image: string;
  public background_repeat = environment.background_repeat;
  public background_size = environment.background_size;




  constructor(
    public merchantService: MerchantService,
  	private userService: UserService,
    private pubnubService: PubnubService,
    private util: UtilityService,
    private router: Router,
    private route:ActivatedRoute,
    private translate: TranslateService,
    private appHelper: AppHelper) {

    this.route.queryParams
      .subscribe(params => {
        this.paramKiosk = params.kiosk;
      });

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        this.merchant_domain = params['merchant_slug'];
        if (this.merchant_domain.indexOf('one2three') == -1) { this.merchant_domain += '.one2three.be'; }
        this.getKiosks();
      });

  }

  ngOnInit() {

    this.background_image = 'assets/backgrounds/' + environment.whitelabel + '.jpg';
    
  }

  disableKiosk() {
    this.merchantService.kiosk = null;
    this.userService.toggleDarkMode(false);
    if (localStorage) localStorage.setItem("kiosk", null);
    this.userService.logoutUser();
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  getKiosks() {

    this.merchantService.getKiosksByDomain(this.merchant_slug).subscribe(
        response => {
          console.log(response.kiosks);
           this.kiosks = response.kiosks;
           if (this.paramKiosk) { 
             let kiosk  = this.kiosks[this.paramKiosk-1];
             this.setKiosk(kiosk); 
           };
        }
    );

  }

  setKiosk(kiosk) {

    kiosk.merchant_slug = this.merchant_slug;

    this.merchantService.kiosk = kiosk;
    this.userService.toggleDarkMode(true);
    
    if (this.merchantService.kiosk) {
      this.translate.use(this.merchantService.kiosk.locale);
      //this.register();
      if (localStorage && kiosk) {
        localStorage.setItem("kiosk", JSON.stringify(this.merchantService.kiosk));
      }
      
      console.log("DOMAIN=>");
      console.log(this.merchantService.merchant);
      //this.appHelper.setVenue(this.merchantService.merchant.domain);
      this.router.navigate(['../'], { relativeTo: this.route });
    } else {
      console.log("LOG OUT");
    }
  }

}
