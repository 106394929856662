<div class="d-flex flex-column">
  <button *ngIf="data"
          mat-icon-button type="button"
          (click)="onCancel()"
          class="align-self-end">
    <mat-icon>close</mat-icon>
  </button>

  <h4 class="mx-2 my-4 bold text-center">
    <fa-icon [icon]="faFilter"
             class="me-1"></fa-icon>
    <ng-container>{{ 'filters' | translate | capitalizeFirst }}</ng-container>
    <!--
    <ng-container *ngIf="!data && total_entries">{{ total_entries }} handelaars</ng-container>
  -->
  </h4>
  
<!--
  <div class="d-flex justify-content-begin">
    <div class="mx-3 my-2"
        [matMenuTriggerFor]="types"
        style="position:relative;width:100%;">
      <button mat-stroked-button [ngStyle]="{'background-color': selected_kitchen_type ? 'rgba(173,21,28,0.5)' : 'white' }" style="width:100%;">
        <ng-container *ngIf="!selected_kitchen_type">{{ 'filter_on.kitchen_type' | translate | capitalizeFirst }}</ng-container>
        <ng-container *ngIf="selected_kitchen_type">
          <fa-icon
            class="pe-1"
            [icon]="faCheck">
          </fa-icon>
          {{ 'kitchen_type.' + selected_kitchen_type | translate | capitalizeFirst }}
        </ng-container>
        <fa-icon
          class="ps-1"
          [icon]="faChevronDown">
        </fa-icon>
      </button>
      <mat-menu #types="matMenu">
        <div class="container-full">
          <div class="row g-0">
            <div  *ngFor="let kitchen_type_l of kitchen_types"
                class="col-6">
              <button (click)="filterKitchenType(kitchen_type_l)"
                      mat-menu-item>
                <ng-container *ngIf="kitchen_type_l !== selected_kitchen_type">{{ 'kitchen_type.' + kitchen_type_l | translate | capitalizeFirst }}</ng-container>
                <ng-container *ngIf="kitchen_type_l == selected_kitchen_type">
                  <fa-icon
                    class="pe-1"
                    [icon]="faCheck">
                  </fa-icon>
                  {{ 'kitchen_type.' + kitchen_type_l | translate | capitalizeFirst }}
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="d-flex justify-content-begin">
    <div class="mx-3 my-2"
        [matMenuTriggerFor]="dishTypes"
        style="position:relative;width:100%">
      <button mat-stroked-button [ngStyle]="{'background-color': selected_dish_type ? 'rgba(173,21,28,0.5)' : 'white' }" style="width:100%;">
        <ng-container *ngIf="!selected_dish_type">{{ 'filter_on.dish_type' | translate | capitalizeFirst }}</ng-container>
        <ng-container *ngIf="selected_dish_type">
          <fa-icon
            class="pe-1"
            [icon]="faCheck">
          </fa-icon>
          {{ 'dish_type.' + selected_dish_type | translate | capitalizeFirst }}
        </ng-container>
        <fa-icon
          class="ps-1"
          [icon]="faChevronDown">
        </fa-icon>
      </button>
      <mat-menu #dishTypes="matMenu">
        <div class="container-full">
          <div class="row g-0">
            <div *ngFor="let dish_type_l of dish_types"
                class="col-6">
              <button (click)="filterDishType(dish_type_l)"
                      mat-menu-item>
                <ng-container *ngIf="dish_type_l !== selected_dish_type">{{ 'dish_type.' + dish_type_l | translate | capitalizeFirst }}</ng-container>
                <ng-container *ngIf="dish_type_l == selected_dish_type">
                  <fa-icon
                    class="pe-1"
                    [icon]="faCheck">
                  </fa-icon>
                  {{ 'dish_type.' + dish_type_l | translate | capitalizeFirst }}
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
-->

  <div class="d-flex justify-content-between align-items-top mx-2"> 
    <div>
      <h5><strong>{{ 'filter_on.loyalty_points' | translate | capitalizeFirst }}</strong></h5>
    </div>
    <div>
       <mat-slide-toggle  [(ngModel)]="filter_on_loyalty_points" 
                          [color]="'primary'"
                          (change)="onFilterChange()">
        </mat-slide-toggle>
      
      <!--
      <div class="form-check form-switch form-switch-md">
        <input  class="form-check-input" 
                [(ngModel)]="filter_on_loyalty_points"
                (ngModelChange)="onFilterChange()"
              type="checkbox" 
              id="flexSwitchCheckDefault">
      </div>
    -->
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-top mx-2"> 
      <div class="d-flex flex-row">
        <h5 class="me-2"><strong>{{ 'filter_on.order_and_reward' | translate | capitalizeFirst }}</strong></h5>
        <fa-icon [icon]="faCircleInfo" [matTooltip]="translate.instant('filter_on.order_and_reward_info')" [matTooltipPosition]="'above'"></fa-icon>
      </div>
      <div>
         <mat-slide-toggle  [(ngModel)]="filter_on_order_and_reward" 
                            [color]="'primary'"
                            (change)="onFilterChange()">
        </mat-slide-toggle>
        <!--
        <div class="form-check form-switch form-switch-md">
          <input  class="form-check-input" 
                  [(ngModel)]="filter_on_order_and_reward"
                  (ngModelChange)="onFilterChange()"
                type="checkbox" 
                id="flexSwitchCheckDefault">
        </div>
        -->
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-top mx-2"> 
    <div>
      <h5><strong>{{ 'filter_on.open_today' | translate | capitalizeFirst }}</strong></h5>
    </div>
    <div>
       <mat-slide-toggle  [(ngModel)]="filter_on_open_today" 
                          [color]="'primary'"
                          (change)="onFilterChange()">
        </mat-slide-toggle>
      <!--
      <div class="form-check form-switch form-switch-md">
        <input  class="form-check-input" 
                [(ngModel)]="filter_on_open_today"
                (ngModelChange)="onFilterChange()"
              type="checkbox" 
              id="flexSwitchCheckDefault">
      </div>
    -->
    </div>
  </div>

  <ng-container *ngIf="user_logged_in">
    <div class="d-flex justify-content-between align-items-top mx-2">
      <div>
        <h5><strong>{{ 'filter_on.favourite_merchants' | translate | capitalizeFirst }}</strong></h5>
      </div>
      <div>
         <mat-slide-toggle  [(ngModel)]="filter_on_favourite_merchants" 
                            [color]="'primary'"
                            (change)="onFilterChange()">
        </mat-slide-toggle>
        <!--
        <div class="form-check form-switch form-switch-md">
          <input  class="form-check-input"
                  [(ngModel)]="filter_on_favourite_merchants"
                  (ngModelChange)="onFilterChange()"
                type="checkbox"
                id="flexSwitchCheckDefault">
        </div>
      -->
      </div>
    </div>
  </ng-container>

  <button mat-stroked-button class="mx-3 mt-3" (click)="resetFilters()" [disabled]="filter_count == 0" (ngModelChange)="resetFilters()">
    {{ 'filter_on.reset' | translate | capitalizeFirst }}
  </button>

  <button mat-raised-button
          *ngIf="data"
          class="m-3 align-self-center justify-content-end"
          color="primary"
          style="color:white;position: absolute;bottom:20px;"
          (click)="onConfirm()">
    {{ 'confirm' | translate | capitalizeFirst}}
  </button>
</div>