/* src/app/settings/settings.component.scss */
.settings_kiosk {
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 100vw;
  align-items: center;
  justify-content: space-around;
}
.settings_kiosk > a {
  font-size: 1.3rem;
  background-color: rgb(141, 123, 123);
  border-radius: 10px;
  padding: 5px 20px;
  color: white;
}
.set-kiosks {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  justify-content: space-around;
  margin-top: 10vh;
}
div.instructions {
  font-size: 1.7rem;
  text-align: center;
  border-radius: 10px;
  padding: 20px 20px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 60vw;
}
.background {
  width: 100vw;
  height: 100vh;
}
.buttons-set-kiosks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 4vh 0 7vh 0;
  width: 100vw;
}
.buttons-set-kiosks > * > button {
  width: 150px;
  height: 200px;
  border-radius: 5px;
  border: 3px solid black;
}
/*# sourceMappingURL=settings.component.css.map */
