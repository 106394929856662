<div class="d-flex align-items-center justify-content-end pt-3 pe-3"
    style="text-align:right;width:100%;"
    *ngIf="merchantService.kiosk && domain && domain.indexOf('kuehnenagel') > -1">
    <app-language-selection></app-language-selection>
</div>

<div class="{{ template }} main p-3 d-flex flex-column justify-content-center align-items-center">

  <div  class="d-flex justify-content-between align-items-center p-3 py-1 my-3 first-part" 
        style="width:100vw;position:absolute;top:0;left:0"
        *ngIf="template == 'template_default' && !merchantService.kiosk">
    <div style="width:100px;">
      <mat-icon class="clickable" (click)="goBack()">arrow_back</mat-icon>
    </div>
    <div style="text-align: center; flex-grow:1;">
      <img class="logo" src="{{ logo }}">
    </div>
    <div class="d-flex align-items-center justify-content-end me-2"
          style="text-align:right;width:100px;">
      <app-language-selection  *ngIf="domain && domain.indexOf('kuehnenagel') > -1"></app-language-selection>
    </div>
  </div>

  <!--
  <div style="margin-bottom: 10px;">
    <img class="logo-big" src="{{ merchantService.merchant?.image?.medium_url }}">
  </div>
  -->

  <div  *ngIf="unauthorized"
        class="main-card mb-1">

    <mat-card class="p-3">
      {{ 'private_shop' | translate }}
    </mat-card>

  </div>

  <div  *ngIf="!userService.user"
        class="main-card">
    <mat-card class="p-3 authentication-card">
	   <app-authentication (authenticated)="doAuthenticated($event)" [domain]="domain" [scenario]="scenario" [module]="module"></app-authentication>
    </mat-card>
  </div>

</div>

<div class="overflow-hidden white-background">
</div>

<div class="overflow-hidden background"
     *ngIf="template == 'template_default'"
   [style.background]="'url(' + background_image + ')'"
   [style.background-repeat]="background_repeat"
  [style.background-size]="background_size"
   [style.background-position]="'center'">
</div>

<!--
  [style.background]="'url(https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80)'"
-->

<div class="overflow-hidden background"
     *ngIf="template == 'template_pos'"
      [style.background]="'url(' + background_image + ')'"
      [style.background]="'url(assets/backgrounds/pos_background.png)'"
   [style.background-repeat]="background_repeat"
  [style.background-size]="background_size"
   [style.background-position]="'center'">
</div>