/* src/app/order-status/order-status.component.css */
.joyn_text {
  font-size: 10px;
  padding-top: 20px;
  padding-right: 10px;
}
.joyn_text_kiosk {
  font-size: 20px;
  padding: 20px;
}
.reward {
  max-width: 800px;
  margin: auto;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 20px;
}
.joyn {
  max-width: 800px;
  margin: auto;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 4px;
}
.joyn_kiosk {
  width: 800px;
  margin: auto;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 4px;
}
.joyn-button-div {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.joyn-button {
  font-size: 12px !important;
  color: white;
  background-color: #2164B0;
  width: 100%;
}
.joyn_logo {
  float: right;
  width: 80px;
  position: relative;
  top: -43px;
  margin-bottom: -48px;
}
.xjoyn_logo {
  position: relative;
  top: -30px;
  left: 10px;
  width: 80px;
  margin-bottom: -30px;
}
.clickable {
  font-weight: bolder;
  text-decoration: underline !important;
}
/*# sourceMappingURL=order-status.component.css.map */
