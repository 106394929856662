/* src/app/suggestions/suggestions.component.scss */
.product_name {
  font-weight: bolder;
  font-size: 12px;
  padding-bottom: 6px;
}
.product_description {
  font-size: 11px;
  padding-bottom: 6px;
}
.product_price {
  font-weight: bolder;
}
.product_unavailable {
  font-size: 10px;
}
.unavailable {
  background-color: #F0F0F0;
}
.suggestion-card {
  width: 35vw;
  height: 45vw;
  text-align: center;
  box-shadow: 5px 5px 31px -22px rgba(0, 0, 0, 0.75);
  padding: 10px;
}
.suggestion-image {
  max-width: 30vw;
  max-height: 15vw;
}
.suggestion-title {
  font-weight: bolder;
}
.suggestion-button {
  line-height: 1;
}
.suggestions {
  z-index: 9998;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.selected {
  font-weight: bolder;
}
@media (max-width: 320px) {
  .suggestion-title {
    font-size: 0.6em;
  }
  .suggestion-button {
    font-size: 0.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .suggestion-card {
    width: 30vw;
    height: 250px;
    text-align: center;
    border: 1px solid;
  }
}
@media (min-width: 992px) {
  .suggestion-card {
    width: 33.3333333333vw;
    height: auto;
  }
  .suggestion-image {
    max-width: 33.3333333333vw;
    max-height: 100px;
  }
  .suggestion-title {
    padding: 10px;
  }
}
/*# sourceMappingURL=suggestions.component.css.map */
