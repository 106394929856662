/* src/app/venues/venues.component.scss */
::ng-deep .mat-menu-panel {
  max-width: 480px !important;
}
.searchbox {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  height: 48px;
  border-bottom: 1px solid transparent;
  -webkit-transition-property: background, box-shadow;
  transition-property: background, box-shadow;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
input:focus {
  outline: none !important;
}
textarea:focus {
  outline: none !important;
}
.name {
  font-weight: bolder;
}
.status {
  font-weight: bolder;
}
.more_info {
  font-size: 0.9em;
}
.main {
  font-size: 1em;
  margin-top: 120px;
  width: 100vw;
}
.top {
  z-index: 996;
}
.tags {
  font-size: 0.8em;
  height: 50px;
}
.img-logo {
  height: 80px;
}
.logo {
  height: 100px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
mat-icon {
  vertical-align: middle;
}
.map-wrapper {
  height: calc(100vh - 100px);
  width: 100vw;
}
google-map {
  height: 100%;
  margin-bottom: 15px;
}
.venues {
  border-right: 1px solid lightgray;
}
.disabled {
  background-color: lightgray;
  opacity: 0.5;
}
.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: white;
}
.merchant-name {
  font-weight: bolder;
}
.merchant-info {
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 150px;
  padding: 10px;
  line-height: 1 !important;
  font-size: 0.8em;
}
.open-merchant {
  border: 0px !important;
  min-height: 50px;
  color: white;
  font-weight: bolder;
  font-size: 1.2em;
}
.open-merchant-div {
  width: 100%;
}
.searchinput {
  flex-grow: 1;
}
.selected-merchant {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 996;
}
.scenario_img {
  max-height: 20px;
  max-width: 20px;
  padding-left: 2px;
}
.favorite {
  text-align: right;
  height: 7px;
  margin-top: 5px;
}
.splitter {
  border-bottom: 1px solid lightgray;
}
.name {
  font-size: 1.4em;
}
.mat-mdc-menu-item.mdc-list-item {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.fol-img-logo {
  height: 50px;
}
.header-container-bottom {
  padding: 10px;
}
.section-master {
  padding: 30px 0;
}
.fol-title {
  color: #f0513f;
  margin-bottom: 30px;
  font-family: "Permanent Marker";
}
.btn__primary {
  background-color: #fdd329 !important;
  height: fit-content;
  font-family: "Permanent Marker";
  text-align: center;
  border-radius: 50px;
  padding: 9px 15px;
  font-size: 14px;
  margin-bottom: 15px;
  outline: 0;
  border: 0;
  line-height: 1.5;
}
.btn__primary.fol-show-on-map {
  margin-left: auto;
  background: white !important;
  border: 1px solid #d63f3d;
  color: #d63f3d;
}
.list-group-item-link {
  max-width: 350px;
}
.list-group-item-link > a {
  color: #f0513f;
}
@media (min-width: 1300px) {
  .list-group-item-link {
    max-width: 200px;
    margin-left: auto;
  }
}
.fol-searchbox {
  width: clamp(250px, 100%, 500px);
  position: relative;
  font-family: "Dosis";
}
.fol-searchbox > input {
  border-radius: 40px;
  max-width: 100%;
  height: 60px;
  background-color: #e6e7e8 !important;
}
.fol-searchbox > button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f0513f;
  height: 60px;
  width: 75px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  padding-right: 15px;
}
.fol-searchbox > button > img {
  width: 25px;
  height: auto;
}
.list-group-item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 25px 0;
}
.specialties {
  font-family: "Dosis";
  margin-left: 0;
}
.specialties :nth-child(odd) {
  transform: rotate(3deg);
}
.specialties :nth-child(even) {
  transform: rotate(-3deg);
}
.specialty {
  width: fit-content;
  border-radius: 15px;
  background-color: #e6e7e8;
  color: #d63f3d;
  margin-bottom: 5px;
  margin-right: 3px;
  padding: 5px 15px;
}
.item-title {
  font-size: 20px;
  color: #f0513f;
  font-family: "Dosis";
}
.fol-footer {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(https://www.frietjesonline.be/uploads/backgrounds/background.jpg?h=500&mode=max);
  background-position: 0 0, 50% 100% !important;
  background-size: auto, cover !important;
  background-repeat: repeat, no-repeat !important;
  background-attachment: scroll, fixed !important;
  width: 100%;
}
.fol-footer .section {
  padding: 60px 0;
}
.fol-footer .link-home > img {
  max-height: 120px;
}
.fol-footer .link-footer,
.fol-footer h4 {
  font-family: "Permanent Marker";
}
.fol-footer .container-details,
.fol-footer .sitemap-list {
  font-family: "Dosis";
}
.fol-footer a.sitemap-link {
  color: black;
}
.fol-footer .sm-link {
  display: inline-block;
  padding: 5px;
}
.fol-footer fa-icon {
  color: black;
}
.fol-footer ul {
  list-style: none;
}
@media (max-width: 320px) {
  .name {
    font-size: 1em;
  }
  .tags {
    height: 40px;
  }
}
@media (min-width: 321px) and (max-width: 413px) {
  .name {
    font-size: 1.1em;
  }
  .tags {
    height: 40px;
  }
}
@media (max-width: 767px) {
  .fol-title {
    margin-bottom: 18px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .searchinput {
    width: 60vw;
    flex-grow: 0;
  }
}
@media (min-width: 1024px) {
  .searchinput {
    width: 60vw;
    flex-grow: 0;
  }
  .venues:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 997;
    transform: scale(1.06);
  }
}
.fol {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.fol > section {
  flex: 1;
}
.fol > .img-logo {
  width: 270px;
  height: auto;
}
.fol > a.sitemap-list {
  color: #000000 !important;
}
#map {
  height: 500px;
}
/*# sourceMappingURL=venues.component.css.map */
