/* src/app/profile/group-edit/group-edit.component.scss */
.main {
  background-color: white;
  min-height: 100%;
}
.top-right {
  float: right;
  margin-top: 0px;
  margin-right: 0px;
  z-index: 9999;
}
table {
  width: 100%;
}
.mat-form-field {
  font-size: 14px;
  width: 100%;
}
.full-width {
  width: 100%;
}
/*# sourceMappingURL=group-edit.component.css.map */
