import {
  trigger,
  animate,
  transition,
  style,
  query,
  group
} from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('* => LoginPage, MenuPage => BasketPage, BasketPage => CheckoutPage, CheckoutPage => PaymentPage', [
    style({ position: 'relative' }),
    query(':enter', style({ zIndex: '101', position: 'fixed', width: '100%' })
      , { optional: true }
      ),
    query(':leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }
      ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)'}),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }
        ),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.7s ease-in-out', style({ transform: 'translateX(-20%)' }))
        ], { optional: true }
        )
      ])
    ]),
  transition('BasketPage => MenuPage, CheckoutPage => BasketPage, PaymentPage => CheckoutPage', [
    style({ position: 'relative' }),
    query(':enter', style({ zIndex: '101', position: 'fixed', width: '100%' })
      , { optional: true }
      ),
    query(':leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }
      ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)'}),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }
        ),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.7s ease-in-out', style({ transform: 'translateX(+20%)' }))
        ], { optional: true }
        )
      ])
    ]),
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 })],
      { optional: true }
      ),
    //query(
    //  ':leave',
    //  [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
    //  { optional: true }
    //),
    query(
      ':enter',
      [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
      { optional: true }
      )
    ])
  ]);


/*export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> LoginPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> FilterPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);
  */
