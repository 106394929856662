<ng-container *ngIf="fol == false">

<ng-container *ngIf="module != 'kitchen' && module != 'pos'">

<div class="d-flex flex-column" style="height: 100vh;">

<div 
	class="d-flex flex-column align-items-center overflow-hidden justify-content-between first-part">

	<div style="width:100%">
		<app-navbar [logoSize]="'none'"></app-navbar>
	</div>
	
</div>

	<div 
	class="flex-grow-1 d-flex flex-column overflow-hidden justify-content-around align-items-center welcome">

			<div class="d-block d-lg-none"><img style="width: 50vw" src="{{ logo }}"></div>

			<div class="d-flex flex-column" *ngIf="!userService.user; else welcome">

			 <div class="main-content">

			 	<div class="row align-items-center">
			 		<div class="col-12 col-lg-6">
			 							
				<div class="my-1">
					<button
					mat-flat-button
					color="primary"
					class="main-button"
					[routerLink]="['/login']">
						<div class="d-flex" style="width:100%">
							<div><mat-icon>vpn_key</mat-icon></div>
							<div style="text-align: center; flex-grow: 1">{{ 'main.log_in' | translate | capitalizeFirst }}</div>
						</div>
					</button>
				</div>
				<div class="my-1">
					<button mat-flat-button 
							color="primary" 
							class="main-button"
							[routerLink]="['/guest']">
						<div class="d-flex" style="width:100%">
							<div><mat-icon>supervised_user_circle</mat-icon></div>
							<div style="text-align: center; flex-grow: 1">{{ 'main.guest' | translate }}</div>
						</div>
					</button>
				</div>
				<div class="my-1">
					<mat-card class="main-card">
						
						<div class="pb-2">
							{{ 'main.register_message' | translate }}<a class="link" [routerLink]="['/register']">{{ 'main.register' | translate }}</a><br>
						</div>
						<div class="">
							
							<mat-icon>check</mat-icon>{{ 'main.register_benefits1' | translate }}<br>
							<mat-icon>check</mat-icon>{{ 'main.register_benefits2' | translate }}<br>
							<mat-icon>check</mat-icon>{{ 'main.register_benefits3' | translate }}<br>
							<mat-icon>check</mat-icon>{{ 'main.register_benefits4' | translate }}<br>

						</div>
						
					</mat-card>
				</div>


			 		</div>

			 		<div class="d-none d-lg-block col-6 text-center"><img style="width: 75%" src="{{ logo }}"></div>
			 		
			 	</div>


			 </div>

			</div>

			
			<ng-template #welcome>
				
				<ng-container *ngIf="userService.notifications.length == 0">
					<div class="d-block d-md-none">

						<fa-icon 
                 			class="fa-4x"
                 			[icon]="faConciergeBell"
                 			*ngIf="is_waiter">
              			</fa-icon>

					</div>
					<mat-card class="welcome-card" style="padding: 16px; background-color:#fff9!important;">
						<div class="mb-3">
							<fa-icon 
	                 			size="2x"
	                 			[icon]="faCircleUSer"
	                 			*ngIf="!userService.user.is_waiter">
	              			</fa-icon>
						</div>
						<div>{{ 'main.welcome' | translate }}</div>
						<div class="bolder">{{ userService.user.email }}</div>
					</mat-card>
				</ng-container>

				<ng-container *ngIf="distributingOrders && distributingOrders.length > 0">
					<order-notif class="d-flex justify-content-center w-100" [order]="distributingOrders[0]"></order-notif>
				</ng-container>
				
				<ng-container *ngIf="preparedOrders && preparedOrders.length > 0">
					<order-notif class="d-flex justify-content-center w-100" [order]="preparedOrders[0]"></order-notif>
				</ng-container>
				
				<notification 	*ngIf="userService.notifications.length > 0"
								class="px-3" style="max-width:600px"
								[@fadeInOut]></notification>

			</ng-template>
		
			

		<div class="container" style="width: 100%;max-width:900px;">

		<div class="order-type" style="text-align: center"
				 *ngIf="scenarios.length > 1 && (!userService.user || (userService.user && !userService.user.is_waiter))">
				{{ 'main.order_type' | translate }}
			</div>	
		
		<div 	class="d-flex flex-column justify-content-between align-items-center"
				*ngIf="userService.user && userService.user.is_waiter">
				
				<div *ngIf="waiter_data_loaded"
					 style="width:80%; border-radius: 5px; background-color: white; padding: 5px;">
					<data-request 
				  		[needsUserDetails]="false"
				  		[needsOrderDetails]="true"
				  		(completed)="onDataRequestCompleted($event)"
					></data-request>
				</div>
		</div>

		<div class="row"
			  *ngIf="!userService.user || (userService.user && !userService.user.is_waiter)"
			 [ngClass]=" {'big-padding': [2,4].indexOf(scenarios.length) > -1 }">
			
			<div *ngFor="let scenario of scenarios"
				 [ngClass]="{'col-12': [1].indexOf(scenarios.length) > -1, 'col-6': [2,4].indexOf(scenarios.length) > -1, 'col-md-3': scenarios.length == 4, 'col-4': scenarios.length == 3 }"
				 class="d-flex justify-content-center"
				 style="margin-bottom: -20px;text-align: center">
				<div class="scenario_container">
					<button mat-flat-button 
						color="primary" 
						class="scenario"
						(click)="selectScenario(scenario)">
    					<img class="scenario_img" src="assets/scenarios/{{ whitelabel }}/{{ scenario }}.png">
  					</button>
  					<div class="scenario-background">
  						<div class="scenario_text">{{ 'scenario.' + scenario | translate | titlecase }}</div>
  					</div>
				</div>
			</div>


		</div>

		<!--
		<div style="text-align: center;">
			<div class="order-type">of wil je een reservatie maken? ...</div>

			<div>
					<button mat-flat-button 
						color="primary" 
						class="scenario"
						(click)="selectScenario('reservation')">
    					<img class="scenario_img" src="assets/scenarios/{{ whitelabel }}/reservation.png">
  					</button>
  					<div class="scenario-background">
  						<div class="scenario_text">{{ 'scenario.reservation' | translate | titlecase }}</div>
  					</div>
				</div>
		</div>
		-->


	</div>

	
	<div 
		class="container footer-menu align-self-center pb-3">
		
		<!--
			 *ngIf="isCordovaApp();"
		-->
		
		<div class="row" *ngIf="isCordovaApp();">
			<div class="col-12" style="text-align: center;">
				<img class="pe-2" width="40" src='assets/qr/qr.png'/> <a class="qr" [routerLink]="['/qr-scan']">{{ 'main.qr_begin' | translate }}<p class= "qr_focus">{{ 'main.qr_end' | translate }}</p></a><br>
			</div>
		</div>
	</div>

</div>

</div>

<div class="overflow-hidden white-background">
</div>

<div class="overflow-hidden background"
	 [style.background]="'url(' + background_image + ')'"
	 [style.background-repeat]="background_repeat"
	 [style.background-size]="background_size"
	 [style.background-position]="'center'">
</div>

</ng-container>

</ng-container>



<ng-container *ngIf="fol == true">

<ng-container *ngIf="module != 'kitchen' && module != 'pos'">

		<div class="first-section">
			<div class="background-slideshow">
				<span id="first-bg">
				</span>
				<span id="second-bg">
				</span>
			</div>

			<div class="d-flex flex-column justify-content-between" style="height:100vh;">
				<app-navbar class="main-navbar" 
							[locationShown]="false">
				</app-navbar>
				
				<div class="middle-section">
					<div class="main-sentence">{{ 'fol.main_sentence' | translate }}</div>
					<div class="container mt-5 mb-5" style="width: 100%;max-width:900px;">
						<div class="order-type" style="text-align: center; color:white;font-size: 40px;"
								*ngIf="scenarios.length > 1 && (!userService.user || (userService.user && !userService.user.is_waiter))">
								{{ 'main.order_type' | translate }}
						</div>
						<div class="d-flex flex-column justify-content-between align-items-center"
								*ngIf="userService.user && userService.user.is_waiter">
							<div *ngIf="waiter_data_loaded"
									style="width:80%; border-radius: 5px; background-color: white; padding: 5px;">
								<data-request
									[needsUserDetails]="false"
									[needsOrderDetails]="true"
									(completed)="onDataRequestCompleted($event)">
								</data-request>
							</div>
						</div>
						<div class="row"
								*ngIf="!userService.user || (userService.user && !userService.user.is_waiter)"
								[ngClass]=" {'big-padding': [2,4].indexOf(scenarios.length) > -1 }">
							<div *ngFor="let scenario of scenarios"
								[ngClass]="{'col-12': [1].indexOf(scenarios.length) > -1, 'col-6': [2,4].indexOf(scenarios.length) > -1, 'col-md-3': scenarios.length == 4, 'col-4': scenarios.length == 3 }"
								class="d-flex justify-content-center"
								style="margin-bottom: -20px;text-align: center">
								<div class="scenario_container">
									<button mat-flat-button
										class="scenario"
										(click)="selectScenario(scenario)">
										<img class="scenario_img" src="assets/scenarios/{{ whitelabel }}/{{ scenario }}.png">
									</button>
									<div class="scenario-background">
										<div class="scenario_text">{{ 'scenario.' + scenario | translate | titlecase }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
				</div>
				
				<div class="scroll clickable" (click)="scrollDown()">
					<fa-icon [icon]="faMouseAlt" class="fa-4x"></fa-icon>
					<div>{{ 'fol.scroll' | translate }}</div>
				</div>
			</div>
		</div>
		
		<div class="second-section" id="scroll-to">
			<div class="container">
				<h2>{{ 'fol.how_to_order' | translate }}</h2>
				<div class="all-steps">
					<div class="one-step">
						<fa-icon [icon]="faLocationCircle" class="fa-5x far"></fa-icon>
						<div>1. {{ 'fol.step_1' | translate }}</div>
					</div>
					<div class="one-step">
						<fa-icon [icon]="faStoreAlt" class="fa-5x far"></fa-icon>
						<div>2. {{ 'fol.step_2' | translate }}</div>
					</div>
					<div class="one-step">
						<fa-icon [icon]="faFrenchFries" class="fa-5x far"></fa-icon>
						<div>3. {{ 'fol.step_3' | translate }}</div>
					</div>
					<div class="one-step">
						<fa-icon [icon]="faShoppingBag" class="fa-5x far"></fa-icon>
						<div>4. {{ 'fol.step_4' | translate }}</div>
					</div>
				</div>
			</div>
		
		</div>
		
		<footer>  
			<app-fol-footer></app-fol-footer>
		</footer>	
</ng-container>
</ng-container>