<venue-header [back_goes_to]="'menu'" [extra_info]="false"></venue-header>

<div class="container pt-2">
	
  <div class="form-container">

	<form *ngIf="!groupCreated && !merchant_group"
        [formGroup]="merchantGroup" 
        (ngSubmit)="onGroupFormSubmit()">

    <h2>{{ 'apply.title' | translate }}</h2>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'apply.group_name' | translate}}" formControlName="name">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <textarea matInput placeholder="{{ 'apply.group_description' | translate}}" formControlName="description"></textarea>
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'apply.company_name' | translate }}" formControlName="company_name">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'apply.vat' | translate }}" formControlName="vat_number">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'phone' | translate }}" formControlName="phone_number">
        <mat-error>{{ 'user.incorrect_phone' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput type="number" placeholder="{{ 'apply.number_of_users' | translate }}" formControlName="estimated_number_of_users">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <textarea matInput placeholder="{{ 'apply.remarks' | translate }}" formControlName="remarks"></textarea>
      </mat-form-field>
    </div>

    <div formGroupName="address_attributes">
      <div class="form-container">
        <mat-form-field>
          <input matInput placeholder="{{ 'apply.address_line_1' | translate }} " formControlName="line1">
        </mat-form-field>
      </div>

      <div class="form-container">
        <mat-form-field>
          <input matInput placeholder="{{ 'apply.address_line_2' | translate }}" formControlName="line2">
        </mat-form-field>
      </div>

      <div class="form-container">
        <mat-form-field>
          <input matInput placeholder="{{ 'apply.city' | translate }}" formControlName="city">
        </mat-form-field>
      </div>

      <div class="form-container">
        <mat-form-field>
          <input matInput placeholder="{{ 'apply.zip_code' | translate }}" formControlName="zipcode">
        </mat-form-field>
      </div>

      <div class="form-container">
        <mat-form-field>
        <mat-select placeholder="{{ 'apply.country' | translate }}" formControlName="country_alpha2">
          <mat-option *ngFor="let country of countries" [value]="country.value">
            {{ 'country.' + country.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>

    </div>

    <button mat-raised-button type="submit" [disabled]="!merchantGroup.valid" color="primary">{{ 'apply.submit' | translate }}</button>
	</form>

</div>

  <div *ngIf="groupCreated">
    <h2>{{ 'apply.submitted' | translate }}</h2>
  </div>

  <div *ngIf="merchant_group && merchant_group.approval_status == 'pending' ">
    <h2>{{ 'apply.applied' | translate }}</h2>
    {{ 'apply.applied_description' | translate:{ group_name: merchant_group.name } }}
  </div>

  <div *ngIf="merchant_group && merchant_group.approval_status == 'approved' ">

    <h2>{{ 'apply.approved' | translate }}</h2>
    <p [innerHTML]="'apply.approved_description' | translate:{ group_name: merchant_group.name }"></p>
  </div>

</div>
