<mat-nav-list>
  <div class="group d-flex" *ngFor="let group of data.groups; let i = index">
  	<div class="marker" *ngIf="group.id == data.compareWith">
  	</div>
  	<div style="width:100%;">
  		<a (click)="selectGroup(group)" mat-list-item>
    	<span mat-line>
        <div class="d-flex">
          
          <div style="width:50px;text-align:center">
            <img class="group_img" src='./assets/order_types/gray/group.png'>
          </div>
        
          <div>{{ group.name }}</div>
        </div>
      </span>
  		</a>
  	</div>
  </div>
  
</mat-nav-list>