<ng-container *ngIf="userService.notifications.length > 0">
<div 	*ngFor="let notification of userService.notifications"
		class="notification d-flex mb-1">
		<div style="min-width: 50px;flex-shrink:0">
			<mat-icon>{{ notification.notification_type }}</mat-icon>
		</div>
		<div class="d-flex flex-column">
			<div>	
			<strong>{{ notification.title }}</strong>
			<br/>
			{{ notification.description }}
			</div>
		</div>
</div>
</ng-container>