import { Component, EventEmitter, Input, Optional, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { valuesOfFilters } from '../venues/venues.component'
import { faChevronDown, faCheck, faFilter, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-venue-filters',
  templateUrl: './venue-filters.component.html',
  styleUrls: ['./venue-filters.component.scss']
})

export class VenueFiltersComponent {
  public filter_on_loyalty_points: boolean
  public filter_on_order_and_reward: boolean
  public filter_on_open_today: boolean
  public filter_on_favourite_merchants: boolean
  public selected_kitchen_type: string
  public selected_dish_type: string;
  public filter_count = 0;
  public kitchen_types = ['bakery', 'butcher', 'camping', 'cheese', 'chip_shop', 'cocktails', 'event', 'foodtruck', 'icecream', 'industrial', 'leisure', 'petrol', 'pizzeria', 'pub', 'restaurant', 'sandwich', 'supermarket', 'sushi']
  public dish_types = ["alcoholic_drink", "american_pizza", "sandwiches", "burgers", "curry", "desserts", "durum", "fries", "fresh_drinks", "soft_drinks", "gourmet_burgers", "halal", "home-made_burgers", "hotdogs", "ice_cream", "kapsalon", "chicken", "cold_dishes", "limousine_burgers", "machine_guns", "noodles", "breakfast", "panini", "pasta", "pita_kebab", "pizza", "poke_bowls", "ribs", "salads", "soups", "sushi", "pies", "taco", "thai", "vegan", "vegetarian", "meat", "wine", "wrap"]
  public valuesOfFilters: any;

  @Output() filtersChangedEvent = new EventEmitter<Array<any>>();
  @Input() valuesOfFiltersFromInput: any;
  @Input() total_entries: any;
  @Input() user_logged_in: boolean;

  faChevronDown = faChevronDown;
  faCheck = faCheck;
  faFilter = faFilter;
  faCircleInfo = faCircleInfo;

  constructor(
    public translate: TranslateService,
    @Optional() public dialogRef: MatDialogRef<VenueFiltersComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: valuesOfFilters,
  ) { 
    if (this.data) {
      this.setFilters(this.data);
      this.user_logged_in = this.data.user_logged_in;
    } else if (this.valuesOfFiltersFromInput) {
      this.setFilters(this.valuesOfFiltersFromInput);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.valuesOfFiltersFromInput) { this.setFilters(changes.valuesOfFiltersFromInput.currentValue); };
  };

  setFilters(data) {
    this.filter_on_loyalty_points = data.filter_on_loyalty_points;
    this.filter_on_order_and_reward = data.filter_on_order_and_reward;    
    this.filter_on_open_today = data.filter_on_open_today;
    this.filter_on_favourite_merchants = data.filter_on_favourite_merchants;
    this.selected_kitchen_type = data.selected_kitchen_type;
    this.selected_dish_type = data.selected_dish_type;
    this.setValuesOfFilters();
  }

  resetFilters() {
    this.filter_on_loyalty_points = false;
    this.filter_on_order_and_reward = false;
    this.filter_on_open_today = false;
    this.filter_on_favourite_merchants = false;
    this.selected_kitchen_type = null;
    this.selected_dish_type = null;
    this.setValuesOfFilters();
    this.filtersChangedEvent.emit(this.valuesOfFilters);
  }

  onFilterChange() {
    this.setValuesOfFilters();
    this.filtersChangedEvent.emit(this.valuesOfFilters);
  }

  filterKitchenType(kitchen_type) {
    if (this.selected_kitchen_type == kitchen_type) {
      this.selected_kitchen_type = null;
    } else {
      this.selected_kitchen_type = kitchen_type;
    }
    this.setValuesOfFilters();
    this.filtersChangedEvent.emit(this.valuesOfFilters);
  }
  
  filterDishType(dish_type) {
    if (this.selected_dish_type == dish_type) {
      this.selected_dish_type = null;
    } else {
      this.selected_dish_type = dish_type;
    }
    this.setValuesOfFilters();
    this.filtersChangedEvent.emit(this.valuesOfFilters);
  }

  onConfirm() {
    this.dialogRef.close(this.valuesOfFilters);
  }

  onCancel() {
    this.dialogRef.close();
  }

  private setValuesOfFilters() {
    this.valuesOfFilters = {
      "filter_on_loyalty_points": this.filter_on_loyalty_points,
      "filter_on_order_and_reward": this.filter_on_order_and_reward,
      "filter_on_open_today": this.filter_on_open_today,
      "filter_on_favourite_merchants": this.filter_on_favourite_merchants,
      "selected_kitchen_type": this.selected_kitchen_type,
      "selected_dish_type": this.selected_dish_type
    }
    this.setFilterCount();
  }

  private setFilterCount() {
    this.filter_count = 0;
    for (const [key, value] of Object.entries(this.valuesOfFilters)) {
      if (value) {
        this.filter_count += 1;
      }
    }
  }  
}
