/* src/app/profile/group/group.component.css */
.main {
  background-color: white;
  min-height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
/*# sourceMappingURL=group.component.css.map */
