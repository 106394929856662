import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '../services/order.service';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'group-order',
  templateUrl: './group-order.component.html',
  styleUrls: ['./group-order.component.css']
})
export class GroupOrderComponent implements OnInit, OnDestroy {

	@Input() group: any;
	@Input() orders: any;

  constructor(
    public orderService: OrderService,
    public util: UtilityService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  removeSuborder(orderId) {
    this.orderService.removeSuborder(orderId)
    .subscribe(
      response => {
        this.orders = this.orders.filter(function( order ) {
          return order.id !== orderId;
        });
        this.util.openSnackBar(this.translate.instant('my-orders.suborder_deleted'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('my-orders.suborder_deleted_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

}
