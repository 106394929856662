import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { AppHelper } from '../../helpers/app-helper';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { UserService } from '../../services/user.service';
import { MerchantService } from '../../services/merchant.service';
import { PubnubService } from '../../services/pubnub.service';

export const FadeInOut = [

trigger('fadeInOut', [
  state('void', style({
    opacity: 0
  })),
  transition('void => *', animate('500ms 300ms ease-in-out'))
  ])

];

@Component({
  selector: 'advisories',
  templateUrl: './advisories.component.html',
  styleUrls: ['./advisories.component.scss'],
  animations: [FadeInOut]
})
export class AdvisoriesComponent implements OnInit, OnDestroy {

  private merchantStatsReceivedSubscription: any;
  public fol = false;

  constructor(private appHelper: AppHelper,
              public userService: UserService,
              public merchantService: MerchantService,
              private pubnubService: PubnubService) { }

  ngOnInit() {

    this.fol = this.appHelper.isFol();


     this.merchantStatsReceivedSubscription = this.pubnubService.merchantStatsPushed.subscribe(
        (merchantStats) => {
          this.userService.basket.advisories = this.appHelper.listAdvisories();
        }
    );

  }

  ngOnDestroy() {
    if (this.merchantStatsReceivedSubscription) { this.merchantStatsReceivedSubscription.unsubscribe(); }
  }

  showAdvisories() {
  }



}
