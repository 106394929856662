import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-fol',
  templateUrl: './about-fol.component.html',
  styleUrls: ['./about-fol.component.scss']
})
export class AboutFolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
