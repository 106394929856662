/* src/app/payment/payment.component.scss */
.non-clickable {
  pointer-events: none;
}
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
.checkout-content {
  position: relative;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.fixed_top {
  position: sticky;
  width: 100vw;
  top: -114px;
  z-index: 998;
}
.title {
  font-weight: bolder;
  font-size: 1.5em;
}
.category {
  color: white;
  font-size: 1.4em;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.plus-points {
  border-radius: 5px;
  width: 100px;
  height: 70px;
  line-height: 70px;
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: bold;
}
@media (max-width: 991px) {
  .checkout-content {
    min-height: 70vh;
    margin-bottom: 80px;
  }
  .main-bgColor {
    min-height: 100vh;
  }
}
@media (min-width: 992px) and (max-width: 1144px) {
  .fixed_top {
    position: fixed;
    top: 0px;
  }
  .template1-checkout-content {
    margin-top: 220px;
    width: 70vw !important;
    padding-left: 15px;
    padding-right: 0vw;
  }
  .template2-checkout-content {
    margin-top: 220px;
    width: calc(100vw - 440px) !important;
    padding-left: 15px;
    padding-right: 0vw;
  }
}
@media (min-width: 1145px) {
  .fixed_top {
    position: fixed;
    top: 0px;
  }
  .template1-checkout-content {
    margin-top: 220px;
    width: 70vw !important;
    padding-left: 7vw;
    padding-right: 7vw;
  }
  .template2-checkout-content {
    margin-top: 220px;
    width: calc(100vw - 440px) !important;
    padding-left: 7vw;
    padding-right: 7vw;
  }
}
/*# sourceMappingURL=payment.component.css.map */
