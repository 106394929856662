import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { UserService} from '../services/user.service';
import { PubnubService } from '../services/pubnub.service';
import { UtilityService } from '../services/utility.service';
import { MerchantService } from '../services/merchant.service';

import { AppHelper } from '../helpers/app-helper';

import { Location } from '@angular/common';

import {environment} from '../../environments/environment';

import * as Sentry from '@sentry/browser';

export interface Hash { name: string; value: string; }

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public passwordResetSent = false;
  public background_image: string;
  public logo: string;
  public domain: string;

  errors = {};

  identifier = Math.floor(Math.random() * 1E16);

  user = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(/.+@.+\..+/)])
    }
  );

  public registration_ongoing = false;
  public fol = false;

  constructor(
    private userService: UserService,
    private pubnubService: PubnubService,
    public merchantService: MerchantService,
    private util: UtilityService,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private renderer: Renderer2,
    private appHelper: AppHelper,
    private route: ActivatedRoute
  ) {
    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {
    let whitelabel = environment.whitelabel;
    if (this.fol) { whitelabel = 'fol' };

    this.passwordResetSent = false;
    this.background_image = 'assets/backgrounds/' + whitelabel + '.jpg';
    this.logo = 'assets/logos/' + whitelabel + '.png';
    this.renderer.addClass(document.body, 'nobounce');

    this.route.queryParams
    .subscribe(params => {
      if (params.domain) this.domain = params.domain;
    });
  }

  ngOnDestroy() {
     this.renderer.removeClass(document.body, 'nobounce');
  }

  register() {

    if (!this.registration_ongoing) {

      this.registration_ongoing = true;

      const user_submit = JSON.parse(JSON.stringify(this.user.value));
      user_submit.email = user_submit.email.replace(/\s/g, '').toLowerCase().trim();

      console.log('WILL PASSWORD RESET WITH FOLLOWING DATA =>');
      console.log(user_submit);

      this.userService.passwordReset(user_submit)
      .subscribe(
        response => {
          this.passwordResetSent = true;
          this.registration_ongoing = false;
        },
        error => {
          this.registration_ongoing = false;
          this.util.openSnackBar(this.translate.instant('forgot_password.not_found'), this.translate.instant('ok'));
          this.registration_ongoing = false;
        }
        );

    }

  }

  anonymousUserToken(credentials) {

    console.log('WILL NOW TRY TO TOKEN THE USER WITH FOLLOWING CREDENTIALS =>');
    console.log(credentials);

    this.userService.anonymousUserToken(credentials)
    .subscribe(
      auth => {
        console.log('OK');
        console.log(auth);
        if (sessionStorage) sessionStorage.setItem('ngStorage-token', JSON.stringify(auth));
        if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
        this.userService.access_token = auth.access_token;
        this.getProfile();
      },
      error => {
        console.log(error);
        this.util.openSnackBar(this.translate.instant('user.login_error'), this.translate.instant('ok'));
        this.registration_ongoing = false;
      }
    );
  }

  getProfile() {

    this.userService.getProfile()
    .subscribe(
      user => {
        this.userService.setUser(user);
        this.appHelper.initSentryUser(user);
        this.translate.use(this.userService.user.locale);
        
        //coming from a merchant?
        if (this.merchantService.merchant) {
          this.appHelper.setVenue(this.merchantService.merchant.domain);
        } else {
          this.router.navigate(['/']);
        }  
        this.registration_ongoing = false;
      },
      error => {
        console.log(error);
        this.registration_ongoing = false
      }
      );

  }

  setError(error, attr) {
    this.errors[attr] = [attr, error[attr][0]].join(' ');
    this.user.controls[attr].setErrors({'incorrect': true});
  }

  goBack() {
    console.log('BACK CLICKED!');
    this.location.back();
  }

}
