<div class="d-flex flex-column center-screen">

	<div class="payconiq-frame">
		<img [src]="bancontact.details.qrCode.src | safeHtml">
	</div>

	<div class="payconiq-status" *ngIf="payment">
		{{ payment.amount | currency: 'EUR' }}<br>
	</div>

	<div class="cancel">
		<a (click)="goBack()">< {{ 'kiosk.cancel_payment' | translate }}</a>
	</div>

	<div>
		<img class="payconiq-info" src='/assets/payment_methods/bancontact_info.png'>
	</div>

</div>
