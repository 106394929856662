<div class="container-full" style="margin:auto;">

	<div>
		<app-navbar [frontColor]="'darkGrey'" [fol_bg]="fol"></app-navbar>
	</div>

	<!--
	<group-invitations>
	</group-invitations>
	-->
	
	<ng-container>

		<ul ngbNav #nav="ngbNav" 
			[(activeId)]="active" 
			class="nav-pills m-3"
			(activeIdChange)="loadTab($event)">
			<li [ngbNavItem]="1">
				<a ngbNavLink>{{ 'profile.my_groups' | translate }}</a>
				<ng-template ngbNavContent>
					<div class="card shadowed mb-3 p-2">

						 <!--
						 <h5 class="card-title">
						 	<strong>{{ 'profile.my_groups' | translate }}</strong>
						 </h5>-->

						<div class="card-content">

						<ul class="list-group list-group-flush font-dosis">
						    <li 	*ngFor="let group of userService.user?.groups"
						    		class="list-group-item">
						    	<div class="d-flex justify-content-between align-items-center w-100">
						    		<div 	(click)="openGroupEditDialog(group.id, true)"
						    				class="clickable d-flex flex-column">
						    			<div><strong>{{ group.name }}</strong></div>
										<div class="d-none d-md-inline">{{ group.description }}</div>
									</div>
									<div class="d-flex jutify-content-between align-items-center">
										<div>
											<button mat-stroked-button 
													color="primary"
												style="min-width:120px;margin-left:auto"
												(click)="openMembersDialog(group.id)">
												<mat-icon>account_box</mat-icon> {{ 'profile.members' | translate }}
											</button>
										</div>
										<div class="ms-2">
											<button mat-icon-button
													(click)="openDeleteGroupDialog(group.id)"
													color="light">
												<mat-icon>remove</mat-icon>
											</button>
										</div>
									</div>
								</div>
						    </li>
						</ul>

						<!--
						<mat-list>
							<mat-list-item *ngFor="let group of userService.user?.groups" class="mb-3 my-groups">
								<div class="mat-lines" style="overflow:hidden">
									<h5 mat-line
										[hidden]="groupNames[group.id]"
										(click)="groupNames[group.id]=group.name">
										{{ group.name }}
									</h5>
									<p mat-line
										[hidden]="groupNames[group.id]"
										(click)="groupNames[group.id]=group.name">
										{{ group.description}}
									</p>
								</div>

								<p mat-line *ngIf="groupNames[group.id]">
									<mat-form-field>
										<input matInput placeholder="Group name" [(ngModel)]="groupNames[group.id]">
									</mat-form-field>

									<button mat-icon-button (click)="updateGroup(group)">
										<mat-icon>check_circle</mat-icon>
									</button>

									<button mat-icon-button (click)="groupNames[group.id]=null">
										<mat-icon>cancel</mat-icon>
									</button>
								</p>

								<button mat-raised-button
									style="min-width:120px;margin-left:auto"
									(click)="openMembersDialog(group.id)">
									<mat-icon>account_box</mat-icon> {{ 'profile.members' | translate }}
								</button>

								<button mat-icon-button (click)="openDeleteGroupDialog(group.id)">
									<mat-icon>remove_circle</mat-icon>
								</button>

							</mat-list-item>
						</mat-list>
						-->

						</div>
					</div>
				</ng-template>
			</li>
		
			<li [ngbNavItem]="2">
				<a ngbNavLink>{{ 'profile.my_group_memberships' | translate }}</a>
				<ng-template ngbNavContent>
					<div class="card shadowed mb-3 p-2">

					 <!--
					 <h5 class="card-title">
					 	<strong>{{ 'profile.my_group_memberships' | translate }}</strong>
					 </h5>
					 -->

					<div class="card-content">
						
						<ul class="list-group list-group-flush font-dosis">
						    <li 	*ngFor="let group of userService.user?.membered_groups"
						    		class="list-group-item">
						    	<div class="d-flex justify-content-between align-items-center w-100">
						    		<div class="d-flex flex-column">
						    			<div><strong>{{ group.name }}</strong></div>
										<div class="d-none d-md-inline">{{ group.description }}</div>
									</div>
									<div class="d-flex jutify-content-between align-items-center">
										
										<div>
											<button
												mat-stroked-button
												color="primary" 
												style="min-width:120px;margin-left: auto;"
												(click)="openLeaveGroupConfirmationDialog(group.id)">
												<mat-icon>exit_to_app</mat-icon> {{ 'profile.leave' | translate }}
											</button>
										</div>

										<div>
											<button *ngIf="group.id != userService.user.preferred_group_id" 
													color="light"
													mat-icon-button 
													(click)="setPreferredGroup(group.id)">
												<mat-icon>star_border</mat-icon>
											</button>
										</div>

										<div>
											<button *ngIf="group.id == userService.user.preferred_group_id" 
													color="light"
													mat-icon-button>
												<mat-icon>star</mat-icon>
											</button>
										</div>
									</div>
								</div>
						    </li>
						</ul>

					</div>

				</div>
				</ng-template>
			</li>
		
			<li [ngbNavItem]="3">
				<a ngbNavLink>{{ 'profile.groups_for_my_shop' | translate }}</a>
				<ng-template ngbNavContent>
					<div 	*ngIf="userService.user?.merchant_groups && userService.user.merchant_groups.length > 0"
							class="card shadowed mb-3 p-2">

						<div class="d-flex justify-content-between align-items-center mb-3">
							 <h5 class="card-title">
							 	<strong>{{ 'profile.groups_for_my_shop' | translate }}</strong>
							 </h5>
							 <div>
								<button mat-mini-fab color="primary"
										(click)="openGroupEditDialog(null, false)">
					    			<mat-icon>add</mat-icon>
					  			</button>
					  		</div>
						</div>

						<div class="card-content">

							<div>
								<mat-form-field class="full-width">
									<mat-label>Groep zoeken</mat-label>
									<input 	matInput 
									type="search" 
									[ngbTypeahead]="search"
									[(ngModel)]="searchInitText"
									#productSearch>
								</mat-form-field>
							</div>

						<ul class="list-group list-group-flush font-dosis">
						    <li 	*ngFor="let group of merchant_groups | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
						    		class="list-group-item">
						    	<div class="d-flex justify-content-between align-items-center w-100">
						    		<div 	(click)="openGroupEditDialog(group.id, false)"
						    				class="clickable d-flex flex-column">
						    			<div><strong>{{ group.name }}</strong></div>
										<div class="d-none d-md-inline">{{ group.description }}</div>
									</div>
									<div class="d-flex jutify-content-between align-items-center">
										<div>
											<button mat-stroked-button 
													color="primary"
												style="min-width:120px;margin-left:auto"
												(click)="openMembersDialog(group.id)">
												<mat-icon>account_box</mat-icon> {{ 'profile.members' | translate }}
											</button>
										</div>
										<div>
											<button mat-icon-button 
													(click)="openDeleteGroupDialog(group.id)"
													color="primary">
												<mat-icon>remove</mat-icon>
											</button>
										</div>
									</div>
								</div>
						    </li>
						</ul>

						<mat-paginator [length]="merchant_groups.length"
				              [pageSize]="pageSize"
				              [pageSizeOptions]="[]"
				              [hidePageSize]="true"
				              [showFirstLastButtons]="true"
				              (page)="handlePageEvent($event)"
				              aria-label="Select page">
						</mat-paginator>
					
					</div>

				<!--
				<mat-card class="shadowed" *ngIf="userService.user?.merchant_groups && userService.user.merchant_groups.length > 0">

					<div class="d-flex">
						<div class="me-2">
							<mat-card-title>
								{{ 'profile.groups_for_my_shop' | translate }}
							</mat-card-title>
						</div>
						<div>
							<button mat-mini-fab color="primary"
									(click)="openGroupEditDialog()">
				    			<mat-icon>add</mat-icon>
				  			</button>
				  		</div>
				  	</div>

					<mat-form-field class="full-width">
						<mat-label>Groep zoeken</mat-label>
						<input 	matInput 
						type="search" 
						[ngbTypeahead]="search"
						[(ngModel)]="searchInitText"
						style="width:100%" 
						#productSearch>
					</mat-form-field>

					<mat-list>
						<mat-list-item *ngFor="let group of merchant_groups | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
							<div mat-line>
								<div 	(click)="openGroupEditDialog(group.id)"
								class="clickable">
								{{ group.name }}

								<span mat-line>
									{{ group.description}}
								</span>
							</div>

							<div *ngIf="merchantGroupNames[group.id]">
								<mat-form-field>
									<input matInput placeholder="Group name" [(ngModel)]="merchantGroupNames[group.id]">
								</mat-form-field>

								<button mat-icon-button (click)="updateMerchantGroup(group)">
									<mat-icon>check_circle</mat-icon>
								</button>

								<button mat-icon-button (click)="merchantGroupNames[group.id]=null">
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>

						<div class="buttons-container" *ngIf="group.approval_status == 'pending'">
							<button mat-button color="primary" (click)="updateGroupStatus(group.id, 'approved')">{{ 'approve' | translate }}</button>
							<button mat-button (click)="updateGroupStatus(group.id, 'rejected')">{{ 'reject' | translate }}</button>
						</div>

						<ng-container *ngIf="group.approval_status == 'approved'">
							<button mat-raised-button
							style="min-width:120px;"
							(click)="openMembersDialog(group.id)">
							<mat-icon>account_box</mat-icon> {{ 'profile.members' | translate }}
						</button>

						<button mat-icon-button (click)="deleteMerchantGroup(group.id)">
							<mat-icon>remove_circle</mat-icon>
						</button>
					</ng-container>

				</mat-list-item>
				</mat-list>

				<ngb-pagination
				[(page)]="page"
				[pageSize]="pageSize"
				[maxSize]="3"
				[rotate]="true" 
				[ellipses]="false" 
				[boundaryLinks]="true"
				[collectionSize]="merchant_groups.length"></ngb-pagination>

				</mat-card>
			-->
				</div>
				</ng-template>
			</li>
		</ul>
	</ng-container>

	<div [ngbNavOutlet]="nav" class="mt-2 mx-3"></div>
<!--
<button mat-raised-button color="primary" 
(click)="openGroupFormDialog()">
{{ 'groups.add_group' | translate }}
</button>
-->

