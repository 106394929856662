/* src/app/order-details/order-details.component.scss */
.fixed {
  position: fixed;
  top: 0;
  z-index: 998;
}
.main {
  margin-bottom: 50px;
  margin-top: 180px;
}
/*# sourceMappingURL=order-details.component.css.map */
