import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UserService} from '../services/user.service';
import { MerchantService} from '../services/merchant.service';

import { AppHelper } from '../helpers/app-helper';

import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css']
})
export class NavigatorComponent implements OnInit {

  private merchant_slug;
  private merchant_domain;

   constructor(
    private userService: UserService,
    private merchantService: MerchantService,
    private appHelper: AppHelper,
    private route: ActivatedRoute,
    private router: Router
   ) {

     this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        this.merchant_domain = params['merchant_slug'];
        if (this.merchant_domain.indexOf('one2three') == -1) { this.merchant_domain += '.one2three.be'; }
      });
  
     if (this.merchant_slug == 'kuehnenagel' || environment.whitelabel == 'kuehnenagel' ) {
       this.router.navigate(['login'], { queryParams: { domain: "kuehnenagel" } });
     } else if (this.merchantService.kiosk) {
        this.userService.destroyBasket();
        this.register();
     }

     //this part only valid when it's not a kiosk
     if (!this.merchantService.kiosk) {
       this.route.queryParams.subscribe(params => {
         if (params['qr_token']) {
           let qr_token = params['qr_token'];
           this.router.navigate(['menu'], { relativeTo: this.route, queryParams: { qr_token: qr_token, qr_scan: 1 } });
         } else {
           this.router.navigate(['menu'], { relativeTo: this.route });
         }       
       });
     }

   }

  ngOnInit() {
  }

  register() {

    let user_submit = new User();
    user_submit.email = "kiosk@one2three.be";
    user_submit.locale = 'nl';

    this.userService.anonymousUser(user_submit)
    .subscribe(
      user => {
        const credentials = {
          email: user.email,
          client_id: 'fb1fd14bb6bdcbad74ce47133392d1bd49a5c9dadc8f5301177d5e23932c17f4'
        };
        this.anonymousUserToken(credentials);
      },
      error => {
        console.log(error);
      }
    );
  }

  anonymousUserToken(credentials) {

    this.userService.anonymousUserToken(credentials)
    .subscribe(
      auth => {
        sessionStorage.setItem('ngStorage-token', JSON.stringify(auth));
        localStorage.setItem('ngStorage-token', JSON.stringify(auth));
        this.userService.access_token = auth.access_token;
        this.getProfile();
      },
      error => {}
    );
  }

  getProfile() {

    this.userService.getProfile()
    .subscribe(
      user => {
        this.userService.setUser(user);
        this.router.navigate(['menu'], { relativeTo: this.route });       
      },
      error => {
        console.log(error);
      }
      );

  }

}
