import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

import { TranslateService } from '@ngx-translate/core';

import { MerchantService } from '../services/merchant.service';
import { PubnubService } from '../services/pubnub.service';
import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';

import { AppHelper } from '../helpers/app-helper';

import { Location } from '@angular/common';

import { environment } from '../../environments/environment';

import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

import * as Sentry from '@sentry/browser';

export interface Hash { name: string; value: string; }

@Component({
  selector: 'app-auth-guest',
  templateUrl: './auth-guest.component.html',
  styleUrls: ['./auth-guest.component.scss']
})

export class AuthGuestComponent implements OnInit, OnDestroy {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  //TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Belgium, CountryISO.Netherlands];

  public background_image: string;
  public background_repeat = environment.background_repeat;
  public background_size = environment.background_size;
  public logo: string;

  public domain: string;
  public scenario: string;

  passwordMismatch;
  errors = {};

  identifier = Math.floor(Math.random() * 1E16);

  user = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(/.+@.+\..+/)]),
      phone: new UntypedFormControl(undefined, [Validators.required, this.phoneValidator]),
      policyApproved: new UntypedFormControl(false, [Validators.required, Validators.requiredTrue])
    }
  );

  public registration_ongoing = false;
  public fol = false;

  constructor(
    private userService: UserService,
    private pubnubService: PubnubService,
    public merchantService: MerchantService,
    private util: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private renderer: Renderer2,
    private appHelper: AppHelper
  ) {
    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {

     let whitelabel = environment.whitelabel;
     if (this.fol) { whitelabel = 'fol' };

    this.background_image = 'assets/backgrounds/' + whitelabel + '.jpg';
    this.logo = 'assets/logos/' + whitelabel + '.png';
    this.renderer.addClass(document.body, 'nobounce');

    this.route.queryParams
      .subscribe(params => {
        if (params.domain) this.domain = params.domain;
        if (params.scenario) this.scenario = params.scenario;
      });

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'nobounce');
  }

  phoneValidator(control: any) {

    try {
      let parsedValue = null;
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      if (control.value) parsedValue = phoneNumberUtil.parseAndKeepRawInput(control.value.internationalNumber);
      if (!parsedValue || !phoneNumberUtil.isValidNumber(parsedValue) || phoneNumberUtil.getNumberType(parsedValue) != PhoneNumberType.MOBILE) return { mobileNumber: 'no' }
      else return null;
    } catch (e) {
      return { mobileNumber: 'no' };
    }

  }

  register() {

    if (!this.registration_ongoing) {

      this.registration_ongoing = true;

      const user_submit = JSON.parse(JSON.stringify(this.user.value));
      user_submit.email = user_submit.email.replace(/\s/g, '').toLowerCase().trim();
      user_submit.phone = this.user.value.phone.internationalNumber.replace(/\s/g, '');
      user_submit.locale = this.translate.currentLang;

      this.userService.anonymousUser(user_submit)
        .subscribe(
          user => {
            //this.util.openSnackBar(this.translate.instant('user.register_success'), this.translate.instant('ok'));
            const credentials = {
              email: user.email,
              client_id: 'fb1fd14bb6bdcbad74ce47133392d1bd49a5c9dadc8f5301177d5e23932c17f4'
            };
            this.anonymousUserToken(credentials);
          },
          error => {
            console.log(error);
            Sentry.captureException(JSON.stringify(error));
            this.setError(error.error, 'email');
            //this.registerBtnOpts.active = false;
            this.util.openSnackBar(this.translate.instant('user.register_error'), this.translate.instant('ok'));
            this.registration_ongoing = false;
          }
        );

    }

  }

  anonymousUserToken(credentials) {

    this.userService.anonymousUserToken(credentials)
      .subscribe(
        auth => {
          if (sessionStorage) sessionStorage.setItem('ngStorage-token', JSON.stringify(auth));
          if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
          this.userService.access_token = auth.access_token;
          this.getProfile();
        },
        error => {
          console.log(error);
          this.util.openSnackBar(this.translate.instant('user.login_error'), this.translate.instant('ok'));
          this.registration_ongoing = false;
        }
      );
  }

  getProfile() {

    this.userService.getProfile()
      .subscribe(
        user => {
          this.userService.setUser(user);
          this.appHelper.initSentryUser(user);
          if (!this.merchantService.kiosk) this.translate.use(this.userService.user.locale);

          let is_reservation = false;
          if (this.scenario == 'reservation') is_reservation = true;

          if (this.domain) {
            this.appHelper.setVenue(this.domain, null, is_reservation);
          } else if (this.merchantService.merchant) {
            this.appHelper.setVenue(this.merchantService.merchant.domain, this.userService.local_location, is_reservation);
          } else {
            this.router.navigate(['/']);
          }
          this.registration_ongoing = false;
          //this.util.openSnackBar(this.translate.instant('profile.login_success'), this.translate.instant('ok'));
        },
        error => {
          console.log(error);
          this.registration_ongoing = false
        }
      );

  }

  setError(error, attr) {
    if (error.errors[attr]) this.errors[attr] = [attr, error.errors[attr][0]].join(' ');
    if (error[attr]) this.errors[attr] = [attr, error[attr][0]].join(' ');
    this.user.controls[attr].setErrors({ 'incorrect': true });
  }

  goBack() {
    this.location.back();
  }

}
