/* src/app/my-groups/my-groups.component.scss */
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  min-width: 100%;
}
.tile-text {
  position: absolute;
  left: 5px;
}
.buttons-container {
  min-width: 20%;
}
.mat-dialog-container {
  padding: 0px !important;
  background-color: white !important;
}
mat-card {
  padding: 16px;
}
mat-list-item {
  padding: 0 !important;
}
.mat-mdc-list-item.mdc-list-item--with-one-line {
  height: auto !important;
}
.mat-lines {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.mat-lines > h5 {
  font-weight: normal;
  font-size: inherit;
  margin-bottom: 0.3rem !important;
}
.mat-lines > p {
  font-size: 14px;
  margin-bottom: 0;
}
/*# sourceMappingURL=my-groups.component.css.map */
