@import "https://fonts.googleapis.com/css2?family=Dosis:wght@700&family=Permanent+Marker&display=swap";
@import "https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap";

/* src/app/about-fol/about-fol.component.scss */
h1.title {
  font-size: 45px;
  line-height: 1em;
  color: #f0513f;
  margin-bottom: 30px;
  font-family: "Permanent Marker";
}
p {
  font-family: "Dosis";
}
a.btnPrimary {
  text-decoration: none;
  font-family: "Permanent Marker";
  text-align: center;
  border-radius: 50px;
  padding: 9px 15px;
  font-size: 20px;
  width: 100%;
  outline: 0;
  border: 0;
  background-color: #f0513f;
  color: #fdd329;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
}
/*# sourceMappingURL=about-fol.component.css.map */
