<div class="main p-3">

<h2 mat-dialog-title>Groepsinformatie</h2>

<div class="form-container">

<form [formGroup]="group" (ngSubmit)="onGroupFormSubmit()">

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'apply.group_name' | translate }}" formControlName="name">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <textarea matInput placeholder="{{ 'apply.group_description' | translate }}" formControlName="description"></textarea>
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'apply.company_name' | translate }}" formControlName="company_name">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'apply.vat' | translate }}" formControlName="vat_number">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'phone' | translate }}" formControlName="phone_number">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput type="number" placeholder="{{ 'apply.number_of_users' | translate }}" formControlName="estimated_number_of_users">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <textarea matInput placeholder="{{ 'apply.remarks' | translate }}" formControlName="remarks"></textarea>
      </mat-form-field>
    </div>

    <button class="mat-raised-button me-1" type="button" (click)="close()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button type="submit" [disabled]="!group.valid" color="primary">{{ 'create' | translate }}</button>

</form>

</div>

</div>