import { Component, OnInit } from '@angular/core';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';

import { Order } from '../models/order';
import { Router, ActivatedRoute } from '@angular/router';

import { AppHelper } from '../helpers/app-helper';

declare var cordova: any;
declare var device: any;

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css']
})
export class OrderStatusComponent implements OnInit {

  public order: Order;
  private merchant_slug;

  private paramPM;
  private paramSTATUS;
  private paramID;

  public limitedConfirmation = false;
  public order_is_number_only = false;

  public reservation = null;
  public kiosk_has_printer = false;

  constructor(	private userService: UserService,
    public merchantService: MerchantService,
    private orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private appHelper: AppHelper) { }

  ngOnInit() {
    
    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        if (this.merchant_slug.indexOf('one2three') == -1) { this.merchant_slug += '.one2three.be'; }
        this.getMerchantByDomain(this.merchant_slug);
      });

    this.route.queryParams
    .subscribe(params => {
      this.paramPM = params.PM;
      this.paramSTATUS = params.STATUS;
      this.paramID = params.ID;

    });

    //does user have a basket?
    if (localStorage && localStorage.getItem('basket')) {
      this.userService.basket = JSON.parse(localStorage.getItem('basket'));
      if (this.userService.basket && 
          this.userService.basket.order && 
          this.userService.basket.order.order_lines && 
          this.userService.basket.order.order_lines.length == 0) {
        this.order_is_number_only = true;
      }
      this.userService.alterUpdateOngoing(false);
    }

    if (!this.userService.basket || !this.userService.basket.order || !this.userService.basket.order.id) { 

      if (this.paramPM && this.paramSTATUS && this.paramID) {

        this.limitedConfirmation = true;

      } else { this.router.navigate(['/']); }

    } else {
      this.order = JSON.parse(JSON.stringify(this.userService.basket.order));      
      //we need to get both the order and the merchant
      this.getOrder(this.userService.basket.order.id);

      //verify if it's a reservation, in this case, get the reservation details
      if (this.order.reservation_id) {
        this.orderService.getReservation(this.userService.basket.order.id).subscribe(
          response => {
              this.reservation = response;
              console.log(response);
          }, error => {
              console.log(error);
          }
         );
      }
    }

    if (this.merchantService.kiosk) {
      this.kiosk_has_printer = this.kioskHasPrinter();
    }
  }

  getOrder(order_id) {
  	this.orderService.getOrderV1(order_id).subscribe(
      response => {
      	this.order = response;


        if (['table','room'].indexOf(this.order.dist_location_typecode) > -1) { 
          this.userService.local_location = this.userService.basket.order.dist_location; 
        }

        if (this.order.state != 'composing' && this.order.state != 'canceled') this.userService.destroyBasket();
        else {
          
          //when mollie payment paid then we need to 'fake' a webhook
          
          if (this.paramPM == 'MOLLIE' && this.paramSTATUS == 'paid' && this.paramID == order_id) {
            
            this.orderService.mollieWebhook(order_id).subscribe(
            response => {
              this.order = response.order;
              //need to act when not composing and/or canceled
              if (this.order.state != 'composing' && this.order.state != 'canceled') this.userService.destroyBasket();
            },
            error => {
              console.log(error);
            });


          } else {
            this.router.navigate(['../menu'], { relativeTo: this.route });
          }
        }

        //automatic redirect to main screen when kiosk mode  
        if (this.merchantService.kiosk || this.userService.user.virtual_user) {
          setTimeout(() => {
            if (this.userService.local_location) {
              this.router.navigate(['../menu'], { queryParams: { qr_scan: 1, qr_token: this.userService.local_location.qr_token }, relativeTo: this.route });
            } else {
              if (this.merchantService.kiosk && this.userService.user && !this.userService.user.virtual_user) {
                this.userService.logoutUser();
                this.router.navigate(['login'], { queryParams: { domain: this.merchant_slug } });
              } else {
                this.router.navigate(['../menu'], { relativeTo: this.route });
              }
            }  
          }, 20000);
        } else if (this.userService.user.email.includes('table')) {
        	if (sessionStorage) sessionStorage.removeItem('ngStorage-token');
			    if (localStorage) localStorage.removeItem('ngStorage-token');
			    this.userService.destroyUser();
			    this.userService.access_token = null;
        }

      },
      error => {},
      () => {});
  }

  goMyOrders() {
  	this.router.navigate(['/my-orders']);
  }

  newOrder() {
    //use the dist_location of the order in case it's a table or room
    this.appHelper.setVenue(this.merchant_slug, this.userService.local_location);
  }

  showRoute() {

    if (typeof device !== 'undefined' && device.platform == 'iOS') {
      let href = "maps://?q=" + this.merchantService.merchant.address.latitude + "," + this.merchantService.merchant.address.longitude;
      cordova.InAppBrowser.open(href, '_system'); 
    } else if (typeof device !== 'undefined' && device.platform == 'Android') {
      let href =  "geo:0,0?q=" + this.merchantService.merchant.address.latitude + "," + this.merchantService.merchant.address.longitude;    
      cordova.InAppBrowser.open(href, '_system'); 
    } else { 
      let href = "https://www.google.com/maps/dir/?api=1&";
      href += "destination=" + this.merchantService.merchant.address.latitude + "," + this.merchantService.merchant.address.longitude;
      window.open(href,'_blank');
    }
  }

  getJoynLink() {
    const url = this.order.joyn_qr.substring(this.order.joyn_qr.indexOf('&content=') + 9);
    console.log(url);
    if (window.hasOwnProperty('cordova')) {
    	cordova.InAppBrowser.open(decodeURIComponent(url), '_system');
    } else {
      window.open(decodeURIComponent(url));
    }
    console.log('browser has now been opened...');
  }

  getMerchantByDomain(domain) {

    console.log(domain);
    this.merchantService.getMerchantByDomain(domain)
    .subscribe(
      response => {
        this.merchantService.merchant = this.merchantService.updateMerchant(response);
      },
      error => {
        console.log(error);
      }
      );
  }

  kioskHasPrinter() {
    return this.merchantService.kiosk.printers?.some(printer => printer.cloud_id);
  }
}
