/* src/app/auth-guest/auth-guest.component.scss */
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
.background {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.main {
  height: 100vh;
}
.logo {
  max-height: 60px;
  max-width: 140px;
}
.small-error {
  font-size: 0.8em;
}
.phone-placeholder {
  padding-left: 100px;
}
/*# sourceMappingURL=auth-guest.component.css.map */
