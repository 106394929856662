<div 	class="mt-3 advisory advisory-info d-flex" 
		[@fadeInOut] 
		*ngIf="merchantService.merchant.level_of_activity && merchantService.merchant.level_of_activity == 1">
		
		<div style="min-width: 50px;flex-shrink:0">
			<mat-icon>info</mat-icon>
		</div>
		<div class="d-flex flex-column">
			<div>
				{{ 'level_of_activity.' + merchantService.merchant.level_of_activity | translate }}
			</div>
		</div>
	
	</div>

	<div 	class="mt-3 advisory advisory-warning d-flex" 
			[@fadeInOut] 
			*ngIf="merchantService.merchant.level_of_activity && merchantService.merchant.level_of_activity == 2"
			[ngStyle]="fol ? {'color': '#f0513f'} : null">
		
		<div style="min-width: 50px;flex-shrink:0">
			<mat-icon>warning</mat-icon>
		</div>
		<div class="d-flex flex-column">
			<div>
				{{ 'level_of_activity.' + merchantService.merchant.level_of_activity | translate }}
			</div>
		</div>
	
	</div>
