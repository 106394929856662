/* src/app/input-loyalty-card/input-loyalty-card.component.scss */
.plus-points {
  border-radius: 5px;
  width: 100px;
  height: 70px;
  line-height: 70px;
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: bold;
}
/*# sourceMappingURL=input-loyalty-card.component.css.map */
