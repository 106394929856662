<venue-header 	[extra_info]="false"
				[back_shown]="cancel_button_shown" 
				[back_goes_to]="'/'">
</venue-header>

<div class="container mt-5">

	<div class="border rounded p-3">

	<div 	class="mt-3">

		<ng-container *ngIf="payment && payment.status == 'pending'">

			<div class="row">

			<div class="col-6">
				<div style="font-size:1.6em; font-weight:bold;">
						{{ 'payment_terminal.instructions' | translate }}
				</div>
				<div style="font-size:1.3em;"
					 class="d-flex justify-content-between">
					<div>{{ 'payment_terminal.protection_hands' | translate }}</div>
					<div><img style="width: 100px;" src='assets/protection_hands.png'></div>
				</div>
			</div>

			<!-- <div class="col-6 d-flex justify-content-center">
				<div style="position:relative;">
					<img src='assets/ccv.png'>
					<div style="position:absolute; top:166px; left: 170px; width:150px; text-align: center; font-size:34px; font-weigh:bold; color:black;">
						{{ order?.price | currency:'EUR' }}
					</div>
				</div>
			</div> -->

			</div>

		</ng-container>

		<ng-container *ngIf="payment && payment.status == 'manualintervention'">

			<div style="font-size:2em; font-weight:bold;">
					De status van je betaling/bestelling is onduidelijk.
					Gelieve assistentie te vragen.
			</div>

		</ng-container>

		<ng-container>
			
			<div 	*ngIf="payment?.status == 'failed'"
					style="font-size:2em; font-weight:bold;">
					Fout
			</div>
			<div  	*ngIf="payment?.status == 'failed'"
					style="font-size:2em;">
					{{ 'payment_terminal.failed' | translate }}
			</div>

		</ng-container>

		<ng-container *ngIf="payment && payment.status == 'success'">

			<ng-container>
				<div style="font-size:2em; font-weight:bold;">
					Betaling geslaagd.
				</div>
				
				<div class="d-flex flex-column justify-content-between mt-5">
					<div>
						<div class="border rounded p-3">
							<div style="font-size:4em; font-weight: bold;">{{ order?.kiosk_number }}</div>
							<div style="font-size:2em;">
								{{ 'order.kiosk_confirmation' | translate }}
							</div>
						</div>
					</div>
					<div class=mt-5>
						<pre style="white-space: pre-wrap;">{{ ticket }}</pre>
					</div>
				</div>
			</ng-container>

		</ng-container>

	</div>

	</div>


</div>

<br>
<br>