<div *ngIf="suggestions && suggestions.length > 0"
	 [@fadeInOut]>

	<div class="subtitle"
	*ngIf="showTitle">
	{{ 'suggestions.subtitle' | translate }}
</div>
<div class="suggestions">
	<div class="d-flex flex-row">
		<ng-container *ngFor="let product of suggestions">
			<div class="p-1">
				<div class="suggestion-card">
					<mat-card-content class="d-flex flex-column justify-content-between" style="height: 100%">
						<div>
							<img class="suggestion-image" *ngIf="product.main_image" src="{{ product.main_image.medium_url }}">
						</div>

						<div class="suggestion-image" *ngIf="!product.main_image && product.category" style="position:relative">
							<div style="position:absolute;top:0px;right:0px;z-index:490"
							*ngIf="product.in_promo">
							<img class="promo" src='./assets/promo/promo.png'>
						</div>
						<img class="icon-img suggestion-image" src="{{ product.category.icon?.medium_url }}">
					</div>

					<div class="suggestion-title">{{ product.name }}</div>
					<div>
						<button mat-flat-button 
						class="suggestion-button"
						color="primary"
						(click)="openProduct($event,product)">
						{{ 'suggestions.add' | translate }}
					</button>
				</div>
			</mat-card-content>
		</div>
	</div>
</ng-container>
</div>
</div>

</div>