<carousel (activeSlideChange)="log($event)">
  <slide *ngFor="let advertisement of advertisements; let index=index">
    <img 	(click)='redirectToAdvertisement(advertisement.url)' 
    		class="advertisement"
    		[src]="advertisement.image.web_ad_url" 
    		alt="image slide" 
    		style="display: block; width: 100%; cursor: pointer;"
    		*ngIf="advertisement.url && advertisement.image">
    <img 	class="advertisement" 
    		[src]="advertisement.image.web_ad_url" 
    		alt="image slide" 
    		style="display: block; width: 100%;"
    		*ngIf="!advertisement.url && advertisement.image">
  </slide>
</carousel>
