<ng-container *ngIf="merchantService.merchant">

<basketbar 	class="d-block"
			[feature]="'checkout'" 
			*ngIf="userService.basket && userService.basket.order.order_lines.length > 0" 
			[showDetails]="false">
</basketbar>

<div *ngIf="selectedProduct" 
class="selected-product bg-white"
[@slideUp]>

<product [category]="selectedProduct.category" [product]="selectedProduct" (canceled)="onProductCanceled($event)" (updateOngoing)="onUpdateOngoing($event)"></product>

</div>

<div class="main-bgColor" *ngIf="userService.basket">
	<venue-header [back_goes_to]="'menu'" [extra_info]="false"></venue-header>	
	
	<div class="titlebar px-3"
		[ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
		{{ 'basket.title' | translate }}
	</div>
	
	<div style="margin-bottom: 150px;">
		<div class="p-3" style="position:relative;">
			<order 	[order]="userService.basket.order"
					[showTotal]="true"
					[comment_editable]="comment_editable"></order>
		</div>

		<div  	class="d-block d-md-none m-3"
				style="font-size: 0.9em;"
				*ngIf="!userService.basket.order.price_shown">
			<div>
				<strong>p.m. = Prijzen zijn indicatief!</strong>
			</div>
			<div>
			De uiteindelijk te betalen prijs komt overeen met de gewogen producten en zal u bij afhaling in de winkel meegedeeld worden.
			</div>
		</div>


		<div class="ps-3">
			<suggestions (productSelected)="onProductSelected($event)"></suggestions>
		</div>

		<div class="ad-card mb-2">
        	<app-advertisements [location]="'Basket'"></app-advertisements>
      	</div>

	</div>

</div>

</ng-container>