<div 	*ngIf="userService.basket.advisories && userService.basket.advisories.info.length > 0" 
		class="advisory advisory-info d-flex" 
		[ngStyle]="fol ? {'color': 'rgb(253, 211, 41)'} : null"
		[@fadeInOut]>
		
		<div style="min-width: 50px;flex-shrink:0">
			<mat-icon>info</mat-icon>
		</div>
		<div class="d-flex flex-column">
			<div  *ngFor="let advisory of userService.basket.advisories.info">
				<ng-container *ngIf="userService.basket.advisories.info.length > 1">- </ng-container>
				{{ advisory }}
			</div>
		</div>
	
	</div>

	<div *ngIf="userService.basket.advisories && userService.basket.advisories.warning.length > 0" 
		 class="mt-3 advisory advisory-warning d-flex" 
		 [ngStyle]="fol ? {'color': '#f0513f'} : null"
		 [@fadeInOut]>
		
		<div style="min-width: 50px;flex-shrink:0">
			<mat-icon>warning</mat-icon>
		</div>
		<div class="d-flex flex-column">
			<div *ngFor="let advisory of userService.basket.advisories.warning">
				<ng-container *ngIf="userService.basket.advisories.warning.length > 1">- </ng-container>
				{{ advisory }}
			</div>
		</div>
	
	</div>