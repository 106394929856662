<venue-header [extra_info]="false" [back_goes_to]="'/'"></venue-header>

<div class="container mt-5">

	<div class="border rounded p-3">

	<div 	class="mt-3">

		<ng-container *ngIf="!payment || payment?.status == 'start' || payment?.status == 'busy'">

			<div class="row">

			<div class="col-6">
				<div style="font-size:1.6em; font-weight:bold;">
						{{ 'payment_terminal.instructions' | translate }}
				</div>
				<div style="font-size:1.3em;"
					 class="d-flex justify-content-between">
					<div>{{ 'payment_terminal.protection_hands' | translate }}</div>
					<div><img style="width: 100px;" src='assets/protection_hands.png'></div>
				</div>
			</div>

			<div class="col-6 d-flex justify-content-center">
				<div style="position:relative;">
					<img src='assets/valina.png'>
					<div style="position:absolute; top:96px; left: 31px; width:150px; text-align: center; font-size:34px; font-weigh:bold; color:white;">
						{{ order?.price | currency:'EUR' }}
					</div>
				</div>
			</div>

			</div>

		</ng-container>

		<ng-container>
			
			<div 	*ngIf="payment?.status == 'error' && payment?.error == '1'"
					style="font-size:2em; font-weight:bold;">
					Fout
			</div>
			<div 	*ngIf="payment?.error == '1'"
					style="font-size:2em;">
				De betaling werd afgebroken.<br>
				{{ payment?.errormsg }} ({{payment?.incidentcode}})
			</div>

		</ng-container>

		<ng-container *ngIf="payment && payment?.status == 'final'">

			<ng-container *ngIf="payment.approved == '1'">
				<div style="font-size:2em; font-weight:bold;">
					Betaling geslaagd.
				</div>
				
				<div class="d-flex flex-column justify-content-between mt-5">
					<div>
						<div class="border rounded p-3">
							<div style="font-size:4em; font-weight: bold;">{{ order?.kiosk_number }}</div>
							<div style="font-size:2em;">
								{{ 'order.kiosk_confirmation' | translate }}
							</div>
						</div>
					</div>
					<div class=mt-5>
						<pre style="white-space: pre-wrap;">{{ ticket }}</pre>
					</div>
				</div>
			</ng-container>

		</ng-container>

	</div>

	</div>


</div>

<!--
<div *ngIf="payment">
	<strong>Worldline</strong><br>
	<br>
	AMOUNT: {{ payment?.amount }}<br>
	TERMINAL: {{ payment?.terminal }}<br>
	<br>
	STATUS: {{ payment?.status }}<br>
	<br>
	CALLBACKCODE: {{ payment?.callbackcode }}<br>
	CALLBACKDONETIME: {{ payment?.callbackdonetime }}<br>
	<br>
	APPROVED: {{ payment?.approved }}<br>
	CANCELLED: {{ payment?.cancelled }}<br>
	ERROR: {{ payment?.error }}<br>
	ERRORMSG: {{ payment?.errormsg }}<br>
	INCIDENTCODE : {{ payment?.incidentcode }}<br>
	<br>
	RECEIVED : {{ payment?.receivedtime }}<br>
	LAST UPDATE : {{ payment?.lastupdate }}<br>
	TIME: {{ payment?.time }}<br>
	<br>
	REFERENCE: {{ payment?.reference }}<br>
	SSAI: {{ payment?.ssai }}<br>
	TXID: {{ payment?.txid }}<br>
</div>
-->


<br>
<br>