import { Component, OnDestroy, OnInit } from '@angular/core';

import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';

import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-bancontact',
  templateUrl: './bancontact.component.html',
  styleUrls: ['./bancontact.component.css']
})
export class BancontactComponent implements OnInit, OnDestroy {

  public bancontact: any;
  public payment: any;
  private timer: any;

  constructor(private userService: UserService,
    private merchantService: MerchantService,
    private orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private angularLocation: AngularLocation,
    private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {

    if (!this.merchantService.merchant || !this.userService.basket) {
      this.goBack();
    } else {
      this.userService.setPaymentOngoing(true);
      this.bancontact = this.userService.basket.order.bancontact;
      this.verifyPaymentStatus();
    }
  }

  ngOnDestroy() {
    if (this.timer) clearTimeout(this.timer);
  }

  verifyPaymentStatus() {
    if (!this.userService.basket || !this.userService.basket.order) this.goBack();
    else {
      if (this.userService.basket.order.id) { this.getPaymentStatus(this.userService.basket.order.id, null); }
      this.timer = setTimeout(() => {
        this.verifyPaymentStatus();
      }, 2000);
    }
  }

  getPaymentStatus(order_id, payment_id) {
    this.orderService.getPaymentStatus(order_id, payment_id).subscribe(
      response => {
        this.payment = response;

        if (this.payment.status == 'expired' ||
          this.payment.status == 'failed' ||
          this.payment.status == 'canceled') this.goBack();
        else if (this.payment.status == 'paid') {
          this.userService.setPaymentOngoing(false);
          this.router.navigate(['../order-status'], { relativeTo: this.route, queryParams: { PM: 'MOLLIE', STATUS: 'paid', ID: order_id } });
        }
      },
      error => {
        console.log(error);
      });
  }

  goBack() {
    this.userService.setPaymentOngoing(false);
    this.angularLocation.back();
  }

}
