import { Component, OnInit } from '@angular/core';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-contact-us-support',
  templateUrl: './contact-us-support.component.html',
  styleUrls: ['./contact-us-support.component.scss']
})
export class ContactUsSupportComponent implements OnInit {

  public faFacebook = faFacebook;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document
  ) { }

  ngOnInit(): void {
    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    s.src = '././assets/js/fol_support_form_script.js';
    this.renderer.appendChild(this._document.body, s);
  }

}
