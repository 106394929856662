<div class="container-full" align="center">

	<app-navbar [frontColor]="'darkGrey'" [fol_bg]="fol"></app-navbar>
	<!--
	<app-navbar [fol_bg]="true"></app-navbar>
	-->

	<div class="main-content px-3 pt-3" align="center">
	
	<group-invitations>
	</group-invitations>

	<!--<h2>
		{{ 'profile.title' | translate }}
	</h2>
	-->

	<mat-spinner style="margin:20px auto;" *ngIf="!userService.user"></mat-spinner>

	<form #f="ngForm"
		  class="px-1" 
		  [formGroup]="user" 
		  (ngSubmit)="onSubmit()">
				<input formControlName="id" type="hidden">

				<div class="form-container">
					<mat-form-field>
						<mat-label>{{ 'user.email' | translate }}</mat-label>
						<input matInput formControlName="email" placeholder="{{ 'user.email' | translate }}">
						<mat-icon matSuffix>email</mat-icon>
					</mat-form-field>
				</div>

				<div class="form-container">
					<mat-form-field>
						<mat-label>{{ 'phone' | translate }}</mat-label>
						<input type="tel" matInput  formControlName="phone" placeholder="{{ 'phone' | translate }}">
						<mat-icon matSuffix>phone</mat-icon>
						<mat-error>{{ 'user.incorrect_phone' | translate }}</mat-error>
					</mat-form-field>
				</div>

				<div class="form-container">
					<mat-form-field>
					  <mat-select placeholder="{{ 'profile.choose_language' | translate }}" formControlName="locale">
					    <mat-option *ngFor="let language of languages" [value]="language.value">
					      {{ language.viewValue }}
					    </mat-option>
					  </mat-select>
				    <mat-icon matSuffix>language</mat-icon>
					</mat-form-field>
				</div>

				<button mat-raised-button type="submit" [disabled]="!user.valid" color="primary">{{ 'update' | translate }}</button>
			</form>

	<mat-accordion *ngIf="userService.user">

		<mat-expansion-panel *ngIf="1==2">
			<mat-expansion-panel-header>
				<mat-panel-title>
					{{ 'profile.my_email_addresses' | translate }}
				</mat-panel-title>
			</mat-expansion-panel-header>

			<mat-list>
				<mat-list-item>
					<p matLine>
						<span> {{userService.user.email}} </span>
					</p>
				</mat-list-item>

				<mat-list-item *ngFor="let identity of userService.user.user_identities">
					<p matLine>
						<span> {{identity.email}} </span>
					</p>
					<button *ngIf="!identity.confirmed" mat-raised-button style="min-width:140px;" (click)="resendInvitation()">
						<mat-icon>email</mat-icon> Resend
					</button>
					<button *ngIf="identity.confirmed" mat-raised-button style="min-width:140px;">
						<mat-icon>check</mat-icon> Default
					</button>   
				</mat-list-item>
			</mat-list>

		</mat-expansion-panel>

	</mat-accordion>

	<ng-container *ngIf="!userService.user.is_anonymous_user">
		<div class="mb-2 font-dosis" style="margin-top:40px;">
		
			<strong>{{ 'request_account_deletion.title' | translate }}</strong>
			<br/>
			{{ 'request_account_deletion.description' | translate }}	
		</div>

		<div class="font-dosis">
			<button mat-raised-button (click)="removeAccount()" 
					[disabled]="account_removal_requested"
					color="warn">{{ 'request_account_deletion.button' | translate }}</button>
		</div>
	</ng-container>

	<div *ngIf="account_removal_requested" class="font-dosis">
		{{ 'request_account_deletion.confirmation' | translate }}
	</div>

</div>
</div>
