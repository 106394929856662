import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { MerchantService } from '../services/merchant.service';

@Component({
  selector: 'app-kiosk-timeout-dialog',
  templateUrl: './kiosk-timeout-dialog.component.html',
  styleUrls: ['./kiosk-timeout-dialog.component.scss']
})
export class KioskTimeoutDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<KioskTimeoutDialogComponent>,
    public merchantService: MerchantService
  ) { }

  public timer = 15;
  private subscribe;

  ngOnInit() {
    if (this.merchantService.kiosk.kiosk_time_to_continue_order) {
      this.timer = this.merchantService.kiosk.kiosk_time_to_continue_order;
    }
    this.startTimer();
  }

  startTimer() {
    const source = interval(1000);
    this.subscribe = source.subscribe(val => {
      this.timer = this.timer - 1;
      if (this.timer === 0) {
        this.close();
      }
    });
  }

  continueOrder() {
    this.subscribe.unsubscribe();
    this.dialogRef.close(true);
  }

  close() {
    this.subscribe.unsubscribe();
    this.dialogRef.close(false);
  }
}
