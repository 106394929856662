/* src/app/components/authentication/authentication.component.scss */
.form-container {
  display: flex;
  flex-direction: column;
}
.login_button {
  width: 100%;
  font-weight: bolder;
  font-family: "Permanent marker";
  font-size: 19px;
}
.form-container > * {
  width: 100%;
}
::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none !important;
}
button.number {
  height: 4rem !important;
  width: 80% !important;
  font-size: 3rem;
  background-color: rgb(111, 111, 111);
  border: solid 0.5px black;
}
.row1 :first-child {
  border-top-left-radius: 20px;
}
.row1 :last-child {
  border-top-right-radius: 20px;
}
.row4 :first-child {
  border-bottom-left-radius: 20px;
}
.row4 :last-child {
  border-bottom-right-radius: 20px;
}
.number-pad {
  max-width: 500px;
}
/*# sourceMappingURL=authentication.component.css.map */
