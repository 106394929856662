/* src/app/privacy-policy/privacy-policy.component.scss */
p.p1 {
  margin: 0px 0px 0px 0px;
}
p.p2 {
  margin: 0px 0px 0px 0px;
  min-height: 13px;
}
p.p4 {
  margin: 0px 0px 0px 35.4px;
}
p.p5 {
  margin: 0px 0px 0px 53.4px;
  min-height: 13px;
}
p.p6 {
  margin: 0px 0px 0px 0px;
}
p.p7 {
  margin: 0px 0px 0px 0px;
  min-height: 11px;
}
p.p9 {
  margin: 0px 0px 0px 0px;
  color: #232323;
}
li.li1 {
  margin: 0px 0px 0px 0px;
}
li.li3 {
  margin: 0px 0px 18px 0px;
}
li.li8 {
  margin: 5px 0px 5px 0px;
}
span.s1 {
  color: #000000;
}
span.s3 {
  color: #000000;
}
span.s4 {
  text-decoration: underline;
}
ul.ul1 {
  list-style-type: disc;
}
.container {
  color: #666666;
  font-family: "Century Gothic", sans-serif;
}
/*# sourceMappingURL=privacy-policy.component.css.map */
