<div class="main p-3">

<button mat-icon-button
        mat-dialog-close
        tabindex="-1"
        class="top-right"
        style="z-index: 1;">
  <mat-icon>close</mat-icon>
</button>

<h2 mat-dialog-title>{{ 'group_member.title' | translate }} <strong>{{ group?.name }}</strong> </h2>

  <form [formGroup]="groupMember" (ngSubmit)="onGroupMemberFormSubmit()">
    <mat-list>
      <mat-list-item>
        <mat-form-field class="full-width">
          <input matInput placeholder="{{ 'group_member.email' | translate }}" formControlName="email">
        </mat-form-field>
        <button mat-icon-button type="submit" [disabled]="!groupMember.valid" style="margin-top:-20px;">
          <mat-icon color="primary">add_circle</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </form>

  <mat-list>
    <mat-list-item style="font-size:0.9em;" *ngFor="let groupMember of groupMembers" class="my-groups">
      
      <div mat-line>  
        <div class="d-flex flew-row justify-content-start align-items-center">
          <div class="me-3 d-flex justify-content-between align-items-center">
            <mat-icon *ngIf="group?.user && group?.user.email == groupMember.email">supervised_user_circle</mat-icon>
            <mat-icon *ngIf="!group?.user || (group?.user.email != groupMember.email)" 
                      style="color:lightgray;">
                    supervised_user_circle
            </mat-icon>
          </div>
          <div>
            {{ groupMember.email }}
          </div>
        </div>
      </div>

      <mat-chip-option  *ngIf="groupMember.has_account"
                        class="ms-3" 
                        color="accent" 
                        disabled
                        selected>one2three</mat-chip-option>
      
      <!--
      <span class="badge badge-secondary" [ngClass]="{ 'badge-primary': groupMember.invitation_accepted }">{{ (groupMember.invitation_accepted ? 'accepted' : 'pending') | translate }}
      </span>
      -->

      <button mat-icon-button
              tabindex="-1"
              style="margin-left: auto;"
              (click)="deleteGroupMember(groupMember.id)">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>

</div>
