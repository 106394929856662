/* src/app/auth/auth.component.scss */
.template_pos {
  height: 100vh;
  font-size: 1.3em;
}
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
.background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
}
.privacy_statement {
  padding-left: 40px;
  padding-right: 40px;
  font-size: 10px;
}
.white-background {
  z-index: -2;
  position: absolute;
  top: 0px;
  left: 0pc;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
}
.template_default {
  height: 100vh;
  font-size: 0.9em;
}
.logo {
  max-height: 60px;
  max-width: 140px;
}
.logo-big {
  max-height: 180px;
  max-width: 420px;
}
/*# sourceMappingURL=auth.component.css.map */
