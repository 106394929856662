import { Component, OnInit, OnDestroy } from '@angular/core';

import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';

import { Router, ActivatedRoute } from '@angular/router';
import { Location as AngularLocation } from '@angular/common';

import { DeviceDetectorService } from 'ngx-device-detector';

import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-worldline',
  templateUrl: './worldline.component.html',
  styleUrls: ['./worldline.component.css']
})
export class WorldlineComponent implements OnInit, OnDestroy {

  public worldline: any;
  public payment: any;
  public ticket: any;
  private timer: any;
  public deviceInfo = null;
  public isMobile;
  public order;

  constructor(	private userService: UserService,
  				private merchantService: MerchantService,
  				private orderService: OrderService,
  				private router: Router,
  				private route: ActivatedRoute,
  				private angularLocation: AngularLocation,
  				private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {

  	this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    if (!this.isMobile) { this.isMobile = this.deviceService.isTablet() };

  	if (!this.merchantService.merchant || !this.userService.basket) { 
  		this.goBack();
  	} else {
      this.userService.setPaymentOngoing(true);
  		this.worldline = this.userService.basket.order.worldline;
      this.order = this.userService.basket.order;
      setTimeout(() => {
        this.verifyPaymentStatus();
      }, 3000);
  	}

    //this.verifyPaymentStatus();

  }

  ngOnDestroy() {

  	if (this.timer) clearTimeout(this.timer);
    this.userService.setPaymentOngoing(false);
  
  }

  verifyPaymentStatus() {
  	if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) this.getPaymentStatus(this.userService.basket.order.id, this.worldline.ssai);
  	//this.getPaymentStatus(3975925, 2840205);
    this.timer = setTimeout(() => {
      this.verifyPaymentStatus();
 	}, 2000);
  }

  loadDataPromises(order_id, payment_id): Observable<any[]> {

    const paymentStatusPromise = this.orderService.getPaymentStatus(order_id, payment_id);
    const orderPromise = this.orderService.getOrderV1(order_id);

    return forkJoin([paymentStatusPromise, orderPromise]);
  }

  getPaymentStatus(order_id, payment_id) {


    this.loadDataPromises(order_id, payment_id).subscribe(
        responseList => {

            this.payment = responseList[0];
            this.order.kiosk_number = responseList[1].kiosk_number;

            if (this.payment.ticket) {
              this.ticket = "";
              this.payment.ticket.forEach(line => {
                  this.ticket += line[1];
              });
              this.ticket = this.ticket.replaceAll("\r","\n");
            }

            if (this.payment.status == 'failed') {
              this.goBack();
            } else if (this.payment.status == 'final') {
              if (this.payment.approved == '1') this.proceedToOrderStatus();
              else if (this.payment.cancelled == '1' || this.payment.error == '1') this.goBack();
            }

        },
        error => {
            console.log(error);
        }
    );


  	/*this.orderService.getPaymentStatus(order_id, payment_id).subscribe(
      response => {
      	this.payment = response;

        if (this.payment.ticket) {
          this.ticket = "";
          this.payment.ticket.forEach(line => {
              this.ticket += line[1];
          });
          this.ticket = this.ticket.replaceAll("\r","\n");
        }

        if (this.payment.status == 'failed') {
          this.goBack();
        } else if (this.payment.status == 'final') {
      		if (this.payment.approved == '1') this.proceedToOrderStatus();
          else if (this.payment.cancelled == '1' || this.payment.error == '1') this.goBack();
      	}
      },
      error => {
      	console.log("PAYMENT STATUS ERROR =>");
      	console.log(error);
      });*/

  }

  public toggleDevice() {
    this.isMobile = !this.isMobile;
  }

  goBack() {
    if (this.timer) clearTimeout(this.timer);
    setTimeout(() => {
      this.angularLocation.back();
    }, 10000);
  }

  proceedToOrderStatus() {
    if (this.timer) clearTimeout(this.timer);
    /*setTimeout(() => {
       this.router.navigate(['../order-status'],{ relativeTo: this.route });
    }, 10000);*/

    this.userService.destroyBasket();

    setTimeout(() => {
          this.router.navigate(['../menu'], { relativeTo: this.route });
    }, 20000);

  }

}
