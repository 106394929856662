	<ng-container *ngFor="let order of orders">

<!--
		<mat-card class="shadowed mb-2 p-1">

		<mat-card-title>
			{{ order.reference }}
		</mat-card-title>
		<mat-card-subtitle>
			{{ order.comment }}
		</mat-card-subtitle>
		
					<order	[order]="order"
					[editable]="false">
				</order>
				<button mat-flat-button 
						color="warn" 
						*ngIf="order.state == 'review' && order.payment_state == 'unpaid'"
						(click)="removeSuborder(order.id)">{{ 'my-orders.cancel_order' | translate }}</button>

			</mat-card>
-->

<div class="card mb-2">
  <div class="card-body">
    <h4 class="card-title"><strong>{{ order.reference }}</strong></h4>
    <h5 class="card-title">{{ order.comment }}</h5>
    <p class="card-text">
    		<order	[order]="order"
					[editable]="false">
				</order>
    </p>
    <a class="btn btn-primary"
    	 (click)="removeSuborder(order.id)"
    	 *ngIf="order.state == 'review' && order.payment_state == 'unpaid'">{{ 'my-orders.cancel_order' | translate }}
    </a>
  </div>
</div>

	</ng-container>


<!--
			<div class="col-4">{{ order.user_email }}</div>
      <div class="col-auto mr-auto">{{ order.reference }}</div>
      <div class="col-auto">{{ order.merchant_currency }}{{ order.price }}</div>
      

      <button mat-icon-button (click)="removeSuborder(order.id)" class="remove-suborder-btn">
        <mat-icon>remove_circle</mat-icon>
      </button>
  -->