import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../../services/user.service';
import { MerchantService } from '../../services/merchant.service';
import { GroupService} from '../../services/group.service';
import { UtilityService } from '../../services/utility.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {

  public groupObject;
  public group;
  public qr_token;

  countries = [
    { name: 'Belgium', value: 'BE' },
    { name: 'France', value: 'FR' }
  ];

  timings = [];

  who_pays_options = [
    {value: 'user' },
    {value: 'owner' }
  ];

  payment_method_options = [
    {value: 'BCMCMOLLIE' },
    {value: 'CCMOLLIE' },
    {value: 'PAYCONIQ'},
    {value: 'CASH'},
    {value: 'INVOICE'},
    {value: 'MONIZZEMEAL'},
    {value: 'IDEALMOLLIE'}
  ];

  merchants = [];


  constructor(
    private userService: UserService,
    private merchantService: MerchantService,
    private groupService: GroupService,
    public dialogRef: MatDialogRef<GroupEditComponent>,
    public dialog: MatDialog,
    private util: UtilityService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    for (let i = 6 ; i < 18; i++) {
      for (let j = 0 ; j < 60 ; j+=5) {

        let formattedHour = i.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });

        let formattedMinutes = j.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });

        this.timings.push({ value: formattedHour + ":" + formattedMinutes });
      }
    }
    
  }

  ngOnInit() {

    let merchant_id_validators = [];
    if (!this.data.limited) merchant_id_validators = [Validators.required];

    this.group = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      approval_status: new UntypedFormControl('approved'),
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      company_name: new UntypedFormControl('', []),
      vat_number: new UntypedFormControl('', []),
      phone_number: new UntypedFormControl('', []),
      who_pays: new UntypedFormControl('',[]),
      payment_methods: new UntypedFormControl('',[]),
      instant_checkout: new UntypedFormControl('',[]),
      checkout_at: new UntypedFormControl('',[]),
      user_email: new UntypedFormControl('',[]),
      merchant_id: new UntypedFormControl('',merchant_id_validators),
      address_attributes: new UntypedFormGroup({
        id: new UntypedFormControl('',[]),
        line1: new UntypedFormControl('', [Validators.required]),
        line2: new UntypedFormControl(''),
        city: new UntypedFormControl('', [Validators.required]),
        country_alpha2: new UntypedFormControl('', [Validators.required]),
        zipcode: new UntypedFormControl('', [Validators.required])
      })
    });

  	if (this.data.group_id) { this.getGroup(); }

    for (let role of this.userService.user.roles) {
      if (role.merchant_id) this.merchants.push({ id: role.merchant.id, name: role.merchant.name });
    }

  }

  getGroup() {
    this.groupService.getGroup(this.data.group_id)
    .subscribe(
      group => {

      	this.groupObject = group;

        let address_id = null;
        let line1 = '';
        let line2 = '';
        let zipcode = '';
        let city = '';
        let country_alpha2 = '';
        let user_email = "";
        let merchant_id = null;

      	//if (!group.instant_checkout) group.instant_checkout = false;
      	if (!group.who_pays) group.who_pays = 'owner'
        if (group.address && group.address.line1) line1 = group.address.line1;
        if (group.address && group.address.line2) line2 = group.address.line2;
        if (group.address && group.address.zipcode) zipcode = group.address.zipcode;
        if (group.address && group.address.city) city = group.address.city;
        if (group.address && group.address.country_alpha2) country_alpha2 = group.address.country_alpha2;
        if (group.address && group.address.id) address_id = group.address.id;
        if (group.user && group.user.email) user_email = group.user.email;
        if (group.merchant_id) merchant_id = group.merchant_id;

        console.log(group);

        this.group.patchValue({
        	  id: group.id,
            name: group.name,
            description: group.description,
            company_name: group.company_name,
            vat_number: group.vat_number,
            phone_number: group.phone_number,
            who_pays: group.who_pays,
            payment_methods: group.payment_methods,
            instant_checkout: group.instant_checkout,
            checkout_at: group.checkout_at,
            user_email: user_email,
            merchant_id: merchant_id,
            address_attributes: {
              id: address_id,
              line1: line1.trim(),
              line2: line2.trim(),
              zipcode: zipcode.trim(),
              city: city.trim(),
              country_alpha2: country_alpha2
            }
  		});

      if (group.qr_token) this.qr_token = group.qr_token;

      },
      error => {
        console.log(error);
      }
    );
  }

  openConfirmDialog() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: (this.translate.instant('apply.qr_confirmation_message'))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action == 'confirmed')  {
          this.generateNewQrToken();
        }
      }
    });
  }

  generateNewQrToken() {
    this.groupService.generateNewQrToken(this.data.group_id)
    .subscribe(
      group => {
        this.qr_token = group.qr_token;
      },
      error => {
        console.log(error);
      }
    );
  }

  onSubmit() {

    if (this.group.value.id) {

      this.groupService.updateGroup(this.group.value)
      .subscribe(
        group => {
      	  console.log(group);
          this.dialogRef.close({ action: 'success', group: group });
        },
        error => {
          console.log(error);
        }
      );

    } else {

      this.groupService.createGroup(this.group.value)
      .subscribe(
        group => {
          console.log(group);
          this.dialogRef.close({ action: 'success', group: group });
        },
        error => {
          console.log(error);
        }
      );

    }

	}

}
