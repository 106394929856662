<!-- Kiosk mode => {{ merchantService.kiosk?.id }} -->

<div class="overflow-hidden background"
	 [style.background]="'url(' + background_image + ')'"
	 [style.background-repeat]="background_repeat"
	 [style.background-size]="background_size"
	 [style.background-position]="'center'">

  <div class="settings_kiosk font-dosis" *ngIf="merchantService.kiosk">
    <a (click)="disableKiosk()">Disable kiosk mode</a>
  </div>

  <div class="set-kiosks" *ngIf="!merchantService.kiosk">
    <div class="instructions" >
      Please pick the kiosk you want to enable
    </div>
    <div class="buttons-set-kiosks font-dosis">
      <div *ngFor="let kiosk of kiosks">
        <button (click)="setKiosk(kiosk)">
            <div style="font-size:20px;">{{ kiosk.name }}</div>
            <div>Printer</div>
            <div>{{ kiosk.printer}}</div>
        </button>
      </div>
    </div>
  </div>
</div>