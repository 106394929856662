import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { MerchantService } from '../services/merchant.service';
import { UserService} from '../services/user.service';

import { OrderTypeSheetComponent } from '../sheets/order-type-sheet/order-type-sheet.component';
import { GroupSheetComponent } from '../sheets/group-sheet/group-sheet.component';

import { AppHelper } from '../helpers/app-helper';

import { faEdit } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.css']
})
export class OrderTypeComponent implements OnInit {

  faEdit = faEdit;

  public groups;

  @Output() emitUpdate = new EventEmitter<boolean>();

  constructor(public merchantService: MerchantService,
              public userService: UserService,
              private bottomSheet: MatBottomSheet,
              private appHelper: AppHelper) { }

  ngOnInit() {
    this.groups = this.userService.user.membered_groups.filter(group => { return group.merchant_id == this.merchantService.merchant.id && group.approval_status == 'approved' });
  }

  openOrderTypeSheet(): void {

    const order_types = [];
    order_types.push('personal');
    if (this.groups.length > 0) order_types.push('group');

    const sheetRef = this.bottomSheet.open(OrderTypeSheetComponent, { data: { order_types: order_types, compareWith: this.userService.basket.order.order_type }, });
    const orderTypeSubscription = sheetRef.instance.orderTypeSelected.subscribe((order_type) => {
      this.selectOrderType(order_type);
      sheetRef.dismiss();
  });
  sheetRef.afterDismissed().subscribe(() => {
      orderTypeSubscription.unsubscribe();
  });
  }

  openGroupSheet(): void {

    const sheetRef = this.bottomSheet.open(GroupSheetComponent, { data: { groups: this.groups, compareWith: this.userService.basket.order.group_id }, });
    const groupSubscription = sheetRef.instance.groupSelected.subscribe((group) => {
      this.selectGroup(group);
      sheetRef.dismiss();
  });
  sheetRef.afterDismissed().subscribe(() => {
      groupSubscription.unsubscribe();
  });
  }

  selectGroup(group) {
    this.appHelper.setOrderGroupInformation(group);
    console.log("#### UPDATE CHECKOUT FIELDS TRIGGERED order-type.component selectGroup");
    this.appHelper.updateCheckoutFieldsV2().subscribe(response => {
        this.emitToParent();
    }, error => {
        this.emitToParent();
    });
  }


  selectOrderType(order_type) {
    
    this.appHelper.selectOrderType(order_type);
    console.log("#### UPDATE CHECKOUT FIELDS TRIGGERED order-type.component selectOrderType");
    this.appHelper.updateCheckoutFieldsV2().subscribe(response => {
        this.emitToParent();
    }, error => {
        this.emitToParent();
    });

  }

  emitToParent() {
    this.emitUpdate.emit(true);
   }

   getGroupName(group_id) {
      const group = this.userService.user.membered_groups.find(x => x.id == group_id);
      if (group) { return group.name; }
      else { ''; }
   }


}
