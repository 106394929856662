/* src/app/my-orders/my-orders.component.scss */
.button-row mat-spinner-button {
  margin-right: 4px;
}
.mat-chip-list {
  margin-right: 5px;
}
.order {
  border: 1px solid darkgray;
  box-shadow: 0px 0px 2px 1px lightgrey;
}
.title {
  font-weight: bold;
}
.map-wrapper {
  height: 100%;
  width: 100%;
}
google-map {
  height: 70%;
}
.delivery-title {
  font-weight: bolder;
  text-size: 1.2em;
}
.delivery-content {
  height: 200px;
  position: absolute;
  bottom: 0px;
  background-color: white;
  z-index: 9998;
  width: 100%;
}
.delivery-content-part {
  text-align: center;
  margin-top: 80px;
}
.dot {
  position: absolute;
  top: -60px;
  left: calc(50% - 60px);
  height: 120px;
  width: 120px;
  border-radius: 10%;
  display: inline-block;
  text-align: center;
  padding-top: 35px;
  color: white;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
@media (min-width: 768px) {
  .map-wrapper {
    height: 70vh;
  }
  .delivery-content {
    width: 50vw;
  }
  .dot {
    left: calc(25vw - 60px);
  }
}
/*# sourceMappingURL=my-orders.component.css.map */
