import { Component, OnInit, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { PubnubService } from '../services/pubnub.service';
import { UtilityService } from '../services/utility.service';

import { Router, ActivatedRoute } from '@angular/router';
import {environment} from '../../environments/environment';
import { Location } from '@angular/common';

import { AppHelper } from '../helpers/app-helper';
import { FeaturesHelper } from '../helpers/features-helper';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  public background_repeat = environment.background_repeat;
  public background_size = environment.background_size;

  public background_image: string;
  public logo: string;
  public unauthorized: boolean = false;
  public domain: string;
  public scenario: string;
  public module: string = "consumer";

  public template = "template_default";
  public fol = false;

  /*auth = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
    grant_type: new UntypedFormControl('password')
  });

  public badge_number = "";

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (event.keyCode == 13) {
      let auth = { badge_number: this.badge_number, grant_type: 'password' };
       this.userService.onLoginFormSubmit(auth)
      .subscribe(
        auth => {
          console.log("SUCCES => ");
          console.log(auth);
          if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
          this.userService.access_token = auth.access_token;
          this.getProfile();
        },
        error => {
          console.log("ERROR =>");
          console.log(error);
        }
      );
      this.badge_number = "";
    } else {
      this.badge_number += event.key;
    }
  }*/

  constructor(
    public userService: UserService,
    public merchantService: MerchantService,
    private pubnubService: PubnubService,
    private util: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private renderer: Renderer2,
    private appHelper: AppHelper,
    private featuresHelper: FeaturesHelper
  ) {
    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {

    let whitelabel = environment.whitelabel;
    if (this.fol) { whitelabel = 'fol' };

    this.background_image = 'assets/backgrounds/' + whitelabel + '.jpg';
    this.logo = 'assets/logos/' + whitelabel + '.png';
    this.renderer.addClass(document.body, 'nobounce');

    this.route.queryParams
      .subscribe(params => {
        if (params.unauthorized) this.unauthorized = params.unauthorized;
        if (params.domain) this.domain = params.domain;
        if (params.scenario) this.scenario = params.scenario;
        if (params.module) this.module = params.module;

        if (this.module == "pos") this.template = 'template_pos';
        if (this.module == "kitchen") this.template = 'template_pos';

        if (params.unauthorized && this.userService.user) {
          this.userService.destroyUser();
        } else if (this.userService.user) { this.router.navigate(['/']); }

      });

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'nobounce');
  }

  goBack() {
    if (this.unauthorized) { this.router.navigate(['/']); }
    else { this.location.back(); };
  }

  doAuthenticated(event) {
    
    let is_reservation = false;
    let merchants = [];
    if (this.scenario == 'reservation') is_reservation = true;
  
    if (this.module == "pos" || this.module == "kitchen") { 
      if (this.domain) {
        this.featuresHelper.redirectToFeature(`${this.module}`, this.domain, true)
      } else {
        this.featuresHelper.redirectToFeature(`${this.module}`, false, true)
      } 
    } else if (this.domain) { this.appHelper.setVenue(this.domain, null, is_reservation); }
    else if (this.merchantService.merchant) {
       this.appHelper.setVenue(this.merchantService.merchant.domain, this.userService.local_location, is_reservation);
    } else {
       this.router.navigate(['/']);
    }
  }

}
