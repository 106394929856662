import { Component, OnInit } from '@angular/core';

import { MerchantService } from '../../services/merchant.service';
import { AppHelper } from '../../helpers/app-helper';

import { trigger, state, style, animate, transition } from '@angular/animations';

export const FadeInOut = [

trigger('fadeInOut', [
  state('void', style({
    opacity: 0
  })),
  transition('void => *', animate('500ms 300ms ease-in-out'))
  ])

];

@Component({
  selector: 'level-of-activity',
  templateUrl: './level-of-activity.component.html',
  styleUrls: ['./level-of-activity.component.css'],
  animations: [FadeInOut]
})
export class LevelOfActivityComponent implements OnInit {

  public fol = false;

  constructor(public merchantService: MerchantService,
              private appHelper: AppHelper) {
    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {
  }

}
