<div class="body">
  <header>
    <div class="header-container">
      <app-navbar [fol_bg]="true"></app-navbar>
    </div>
  </header>
  
  <div class="content">
    <h1 class="title text-center">
      Contact
    </h1>
    
    
    <div class="container mb-5 d-flex flex-column text-center">
      <div class="text-center">
        Heeft u een vraag of opmerking? Neem dan gerust contact met ons op via onderstaand formulier.
      </div>
    
      <div class="text-center">
        <p>
          Interesse om partner te worden? Klik <a [routerLink]="['/contact-us-demo']" style="text-decoration: none;">hier</a> om uw frituur en/of restaurant vrijblijvend aanmelden. <br>
          Klik <a [routerLink]="['/about-us']" style="text-decoration: none;">hier</a> voor meer informatie over ons platform.
        </p>
      </div>
    
      <div id="zf_div_WPl4YXLz9Ft0lI386rGB6P7U4s7Em4X2K7o1z1TMmaw">
      </div>
    </div>
  </div>
  
  <footer>  
    <app-fol-footer></app-fol-footer>
  </footer>
</div>
