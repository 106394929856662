/* src/app/notification/notification.component.scss */
.warning {
  font-size: 50px;
  color: orange;
}
.notification-card {
  text-align: center;
  width: 75%;
  max-width: 600px;
}
/*# sourceMappingURL=notification.component.css.map */
