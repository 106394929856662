/* src/app/main/main.component.scss */
.main-button.mat-mdc-unelevated-button.mat-mdc-button-base {
  height: 46px;
}
video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 150%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.footer-menu {
  width: 100vw;
}
.app-toolbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}
.white {
  color: white;
}
.main-content {
  width: 85vw;
}
.main-button {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: bolder;
}
.info-card {
  text-align: center;
  width: 75%;
  max-width: 600px;
}
.welcome-card {
  text-align: center;
  width: 75%;
  max-width: 600px;
}
.mat-mdc-card {
  box-shadow: none !important;
}
.main-card {
  text-align: left;
  width: 100%;
  font-size: 0.9em;
}
mat-icon {
  vertical-align: middle;
}
p {
  padding-top: 5px;
  white-space: pre-line;
  font-weight: bolder;
}
.bolder {
  font-weight: bolder;
}
.main {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.qr {
  color: black !important;
  display: inline;
}
.qr_focus {
  font-weight: bolder;
  text-decoration: underline;
  display: inline;
}
.mat-fab {
  border-radius: 5% !important;
}
.white-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -2;
}
.background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.order-type {
  font-size: 1.4em;
  font-weight: bolder;
  margin-bottom: 10px;
}
.scenario {
  padding: 0px !important;
  margin: 0px !important;
}
.scenario_img {
  max-height: 90%;
  max-width: 90%;
}
.first-section {
  height: 100vh;
  font-family: "Permanent Marker";
}
#first-bg {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("./media/slide2.jpeg");
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: block;
}
#second-bg {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("./media/slide1.jpeg");
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: block;
  opacity: 0;
}
.main-sentence {
  background-color: rgba(240, 81, 63, 0.8);
  -ms-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
  color: #e6e7e8;
  font-size: 40px;
  line-height: 1.1em;
  letter-spacing: 1px;
  padding: 20px 40px 40px;
  border-radius: 40px;
  text-align: center;
  max-width: 650px;
}
button.scenario {
  background-color: rgba(240, 81, 63, 0.8);
}
.fol-searchbox {
  width: clamp(250px, 100%, 500px);
  position: relative;
  font-family: "Dosis";
}
.fol-searchbox > input {
  border-radius: 40px;
  max-width: 100%;
  height: 60px;
}
.fol-searchbox > button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f0513f;
  height: 60px;
  width: 75px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  padding-right: 15px;
}
.fol-searchbox > button > img {
  width: 25px;
  height: auto;
}
.middle-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.payment-methods {
  margin-top: 20px;
  margin-left: auto;
}
.payment-methods > img {
  height: 25px;
  float: right;
}
.scroll {
  font-family: "Dosis";
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -40px;
}
@media (max-width: 767px) {
  .main-sentence {
    font-size: 28px;
    padding: 20px 15px;
  }
  .searchbox {
    font-size: 15px;
  }
  .middle-section {
    padding: 0 15px;
  }
}
@media (max-width: 480px) {
  .main-sentence {
    font-size: 22px;
    padding: 15px;
  }
}
.second-section {
  background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(https://www.frietjesonline.be/images/frietjes2.svg);
  background-size: cover, auto 200%;
  background-position: center;
  font-family: "Permanent Marker";
}
.second-section h2 {
  color: #f0513f;
  margin-bottom: 60px;
  text-align: center;
  font-size: 45px;
}
.second-section > .container {
  padding-top: 100px;
  padding-bottom: 80px;
}
.all-steps {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.all-steps :first-child > div,
.all-steps :nth-child(3) > div {
  transform: rotate(3deg);
}
.all-steps :nth-child(2) > div,
.all-steps :last-child > div {
  transform: rotate(-3deg);
}
.one-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f0513f;
  width: 100%;
  padding: 0 15px;
}
.one-step > div {
  width: 100%;
  display: block;
  background-color: #fdd329;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50px;
  font-family: "Dosis";
  margin-top: 35px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .all-steps {
    max-width: 540px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .second-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .second-section > .container {
    max-width: 720px;
  }
  .all-steps {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .one-step {
    max-width: 50%;
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .second-section > .container {
    max-width: 960px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .all-steps {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  .one-step {
    width: 25%;
  }
}
.fol-footer {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(https://www.frietjesonline.be/uploads/backgrounds/background.jpg?h=500&mode=max);
  background-position: 0 0, 50% 100% !important;
  background-size: auto, cover !important;
  background-repeat: repeat, no-repeat !important;
  background-attachment: scroll, fixed !important;
  width: 100%;
}
.fol-footer .section {
  padding: 60px 0;
}
.fol-footer .link-home > img {
  max-height: 120px;
}
.fol-footer .link-footer,
.fol-footer h4 {
  font-family: "Permanent Marker";
}
.fol-footer .container-details,
.fol-footer .sitemap-list {
  font-family: "Dosis";
}
.fol-footer a.sitemap-link {
  color: black;
}
.fol-footer .sm-link {
  display: inline-block;
  padding: 5px;
}
.fol-footer fa-icon {
  color: black;
}
.fol-footer ul {
  list-style: none;
}
@media (max-width: 374px) {
  .scenario_container {
    width: 110px;
  }
  .scenario {
    height: 60px;
    width: 60px;
    z-index: 998;
  }
  .big-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .scenario_text {
    font-weight: bolder;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    height: 50px;
    width: 110px;
    position: relative;
    top: -27px;
    padding-top: 27px;
    padding-bottom: 10px;
    border-radius: 3px;
    margin: auto;
  }
  .main-content {
    width: 85vw;
  }
  .info-card {
    font-size: 0.7em;
  }
}
@media (min-width: 375px) and (max-width: 767px) {
  .scenario_container {
    width: 110px;
  }
  .scenario {
    height: 60px;
    width: 60px;
    z-index: 998;
  }
  .big-padding {
    padding-left: 50px;
    padding-right: 50px;
  }
  .scenario_text {
    font-weight: bolder;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    height: 70px;
    width: 110px;
    position: relative;
    top: -40px;
    padding-top: 40px;
    padding-bottom: 20px;
    border-radius: 5px;
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-card {
    font-size: 1em;
  }
  .main-content {
    width: 650px;
  }
  .scenarios {
    width: 650px;
    position: absolute;
    left: 50%;
    margin-left: -325px;
  }
  .scenario_container {
    width: 160px;
  }
  .scenario {
    height: 100px;
    width: 100px;
    z-index: 998;
  }
  .scenario_text {
    font-size: 1.4em;
    font-weight: bolder;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    height: 120px;
    width: 160px;
    position: relative;
    top: -70px;
    padding-top: 70px;
    padding-bottom: 20px;
    border-radius: 5px;
    margin: auto;
  }
}
@media (min-width: 992px) {
  .main-card {
    font-size: 1em;
  }
  .main-content {
    width: 900px;
  }
  .scenarios {
    width: 900px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -450px;
  }
  .scenario_container {
    width: 160px;
  }
  .scenario {
    height: 100px;
    width: 100px;
    z-index: 998;
  }
  .scenario_text {
    font-size: 1.4em;
    font-weight: bolder;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    height: 120px;
    width: 160px;
    position: relative;
    top: -70px;
    padding-top: 70px;
    padding-bottom: 20px;
    border-radius: 5px;
    margin: auto;
  }
}
/*# sourceMappingURL=main.component.css.map */
