<div class="container-full">

	<app-navbar [frontColor]="'darkGrey'"
				[fol_bg]="fol"></app-navbar>

	<group-invitations></group-invitations>

	<div class="m-3">

	<ng-container *ngIf="userService.user">

		<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills"
			(activeIdChange)="loadTab($event)">
			<li [ngbNavItem]="1">
				<a ngbNavLink>{{ 'profile.normal_orders' | translate }}</a>
				<ng-template ngbNavContent>

					<div ngbAccordion>
						<div 	ngbAccordionItem="{{ order.id }}"
								[collapsed]="order.is_collapsed"
								*ngFor="let order of orders; trackBy: trackByIndex">
						<h5 ngbAccordionHeader>
						  
						  <button ngbAccordionButton
						  		  (click)=handleCollapse(order)>
						  <div class="row w-100"
						  	   [ngStyle]="{'font-weight': order.is_collapsed ? 'normal' : 'bold' }">										
											<div class="col-12 col-md-4 col-lg-3">{{ order.merchant_name }}</div>
										<div *ngIf="order.dist_timing == 'asap'" class="col-12 col-md-4 col-lg-3">{{ 'asap' | translate | capitalizeFirst }}</div>
										<div *ngIf="order.dist_timing == 'timed'" class="col-12 col-md-4 col-lg-3">{{ order.dist_timing_target | date:'medium':'':userService.user.locale}}</div>
										 <div  *ngIf="order.price_shown; else pro_memory" 
						              			 class="col-12 col-md-4 col-lg-1 align-right">
						            			 {{ order.price | currency:'EUR' }}
						        			</div>
						        			<ng-template #pro_memory>
						            			<div class="col-12 col-md-4 col-lg-1">
						            				p.m.
						            			</div>
						        			</ng-template>


										<div class="col-12 col-md-4 col-lg-2">{{ 'scenario.' + order.scenario | translate | capitalizeFirst }}</div>
										
										<div class="col-12 col-md-4 col-lg-1">{{ 'order.' + order.state | translate | capitalizeFirst }}</div>
										
									</div>

								</button>

						</h5>

						<div ngbAccordionCollapse>
						  <div ngbAccordionBody>
						    
						  	<ng-template>

						  	<div>
											<div class="mt-2 row">
												<div class="col-12"
													 *ngIf="order.state == 'distributing'">
														<order-notif 	class="d-flex justify-content-center w-100" 
																		style="z-index:99999;"
																		[order]="order" (deliverySelected)="onDeliverySelected($event)">
														</order-notif>													
												</div>
												<div class="col-12 col-lg-8">
													<div 	class="border rounded bg-light p-2"
															*ngIf="!order.group_id" style="max-width: 600px;">
														<order [order]="order" 
															   [taxons_shown]="false"
															   [showTotal]="true" 
															   [editable]="false"></order>
													</div>
													<div 
															*ngIf="order.group_id">
														<group-order *ngIf="order.suborders"
														[orders]="order.suborders">
													</group-order>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="d-flex flex-column">
													<div><strong>{{ 'order.delivery_address' | translate | capitalizeFirst }}:</strong> {{ order.dist_location_name }}</div>
													<div>
														<strong>{{ 'order.payment_method' | translate | capitalizeFirst }}: </strong> 
														<span 	*ngIf="order.payment_state === 'paid'"
																class="badge bg-success">
															{{ 'payment_method.' + order.payment_method | translate | capitalizeFirst }}
														</span>
														<span 	*ngIf="order.payment_state !== 'paid'"
																class="badge bg-warning">
															{{ 'payment_state.' + order.payment_state | translate | capitalizeFirst}}
														</span>
													</div>
													<div><strong>{{ 'order.name' | translate | capitalizeFirst }}:</strong> {{ order.reference }}</div>
													<div><strong>{{ 'order.remark' | translate | capitalizeFirst }}:</strong> {{ order.comment }}</div>
													<ng-container *ngIf="order.dist_location_typecode == 'vending_machine' && vending_machine_order">
														<div><strong>{{ 'order.unlock_code' | translate | capitalizeFirst }}:</strong> {{ vending_machine_order.unlock_code }}</div>
													</ng-container>
													<div class="my-3">
														
														<!-- preparation: modify an order
														<button mat-flat-button [disabled]="false" (click)="modify($event, order)" color="primary">
															<strong>{{ 'order.modify' | translate | capitalizeFirst }}</strong>
														</button>
														-->
														
														<button mat-flat-button [disabled]="false" (click)="reOrder($event, order)" color="primary">
															<strong>{{ 'order.reorder' | translate | capitalizeFirst }}</strong>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>

								</ng-template>



						  </div>
						</div>
						</div>
					</div>
					
				</ng-template>
			</li>

			<li [ngbNavItem]="2">
				<a ngbNavLink>{{ 'profile.suborders' | translate }}</a>
				<ng-template ngbNavContent>

					<div ngbAccordion>
						<div 	ngbAccordionItem
								[collapsed]="order.is_collapsed"
								*ngFor="let order of subOrders">
						<h5 ngbAccordionHeader>
						  <button ngbAccordionButton
						  		  (click)=handleCollapse(order)>
						  	<div class="row w-100"
								 [ngStyle]="{'font-weight': order.is_collapsed ? 'normal' : 'bold' }">
								<div class="col-12 col-md-4 col-lg-2">{{ order.merchant_name }}</div>
								<div class="col-12 col-md-4 col-lg-3">{{ order.created_at | date:'medium' }}</div>
								<div class="col-12 col-md-4 col-lg-1">{{ order.price | currency:'EUR' }}</div>
								<div class="col-12 col-md-4 col-lg-2">{{ 'scenario.' + order.scenario | translate | capitalizeFirst }}</div>
								<div class="col-12 col-md-4 col-lg-3">{{ 'order.' + order.state | translate | capitalizeFirst }}</div>
							</div>
						</button>

						</h5>

						<div ngbAccordionCollapse>
						  <div ngbAccordionBody>

						  	<ng-template>

						  	<div>
									<div class="mt-2 row">
										<div class="col-12 col-lg-8">
											<div 	class="border rounded bg-light p-2"
													style="max-width: 600px;">
												<order [order]="order" 
													   [taxons_shown]="false"
													   [showTotal]="true" 
													   [editable]="false"></order>
											</div>
									</div>
									<div class="col-12 col-lg-4">
										<div class="d-flex flex-column">
											<div><strong>{{ 'order.delivery_address' | translate | capitalizeFirst }}: </strong> {{ order.dist_location_name }}</div>
											<div><strong>{{ 'order.payment_method' | translate | capitalizeFirst }}: </strong> 
												
												<span 	*ngIf="order.payment_state == 'paid'"
														class="badge bg-success">
														{{ 'payment_method.' + order.payment_method | translate | capitalizeFirst }}</span>

												<span 	class="badge bg-warning" 
														*ngIf="order.payment_state == 'unpaid'">
														{{ 'payment_state.' + order.payment_state | translate | capitalizeFirst}}
												</span>
											</div>
											<div><strong>{{ 'order.name' | translate | capitalizeFirst }}:</strong> {{ order.reference }}</div>
											<div><strong>{{ 'order.remark' | translate | capitalizeFirst }}:</strong> {{ order.comment }}</div>
										</div>
									</div>
								</div>
								<button mat-flat-button 
													*ngIf="order.state == 'review' && order.payment_state == 'unpaid'" 
													color="warn" 
													(click)="cancelSuborder(order.id)">
												{{ 'my-orders.cancel_order' | translate }}
											</button>
							</div>

							</ng-template>
						

						  </div>
						</div>
						</div>
					</div>

				</ng-template>
			</li>

			<li [ngbNavItem]="3">
				<a ngbNavLink>{{ 'profile.group_orders' | translate }}</a>
				<ng-template ngbNavContent>

					<div ngbAccordion>
						<div 	ngbAccordionItem
								 *ngFor="let group of userService.groupOrders; trackBy: trackByIndex">
						<h5 ngbAccordionHeader>
						  <button ngbAccordionButton>

						  	<div class="d-flex justify-content-between align-items-center w-100">
										<div class="d-flex">
											<div><strong>{{ group.name }}</strong></div>
											<div class="d-none d-md-block ms-3">{{ group.description }}</div>
										</div>
										<div class="d-flex">
											
											<div *ngIf="!group.checkout_at && !group.instant_checkout">
												<button
													mat-flat-button
													(click)="finalize($event, group)"
													class="me-1"
													color="primary">
													{{ 'basketbar.finalize_order' | translate }}
													<span class="d-none d-md-inline badge bg-primary">{{ group.in_review_group_orders.length }}</span>
												</button>
											</div>

											<div>
													<button mat-flat-button color="warn" 
															(click)="openRemoveAllSubordersDialog(group)"
															class="me-1"
															*ngIf="!group.who_pays || group.who_pays == 'owner'">
															{{ 'profile.remove_suborders' | translate }}
													</button>
											</div>

										</div>
									</div>

						</button>

						</h5>

						<div ngbAccordionCollapse>
						  <div ngbAccordionBody>

						  	<div>
										<group-order 	[group]="group"
														[orders]="group.in_review_group_orders"
														(count)="updateGroupOrderCount($event,group)">	
										</group-order>
									</div>

						  </div>
						</div>
						</div>
					</div>

				</ng-template>
			</li>

		</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

</ng-container>

</div>

</div>
