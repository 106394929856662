import { Component, OnInit, OnDestroy, NgZone, Renderer2 } from '@angular/core';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

import { UtilityService } from '../services/utility.service';
import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';

@Component({
  selector: 'app-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.css']
})
export class QrScanComponent implements OnInit, OnDestroy {

  public action = "merchant";

  constructor(public merchantService: MerchantService,
              public userService: UserService,
              public router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private renderer: Renderer2,
              private location: Location,
              private util: UtilityService,
              private translate: TranslateService
              ) { }

  ngOnInit() {
  	this.renderer.addClass(document.body, 'transparent');
  	if (this.isCordovaApp()) { this.prepare(); }

     this.route.queryParams
      .subscribe(params => {
        if (params.action) this.action = params.action;
      });
  }

  ngOnDestroy() {
  	this.renderer.removeClass(document.body, 'transparent');
  	this.hide();
  }

  prepare() {
  	console.log('prepare clicked');
  	(<any>window).QRScanner.prepare(this.onDone.bind(this));
  }

  show() {
  	console.log('show clicked');
  	(<any>window).QRScanner.show();
  }

  hide() {
  	console.log('hide clicked');
  	if ((<any>window).QRScanner) { (<any>window).QRScanner.hide() };
  }

  scan() {
  	console.log('scan clicked');
  	(<any>window).QRScanner.scan(this.displayContents.bind(this));
  }

  connectLoyaltyCard(qr_code) {
    console.log("Connect Loyalty Card...");
    this.userService.connectLoyaltyCard(qr_code)
      .subscribe(
        result => {
          console.log(result);
          this.util.openSnackBar(this.translate.instant('qrscan.success_connect_loyalty_card'), this.translate.instant('ok'), 10000);
          this.backClicked();
        },
        error => {
          console.log(error);
        }
      );
  } 

  getMerchantDomain(qr_code) {

    this.merchantService.getMerchantDomain(qr_code)
    .subscribe(
      response => {
        console.log('MERCHANT BY DOMAIN =>');
        console.log(response);
        //is this a valid merchant?
        const url = response.url;
        console.log(url);
        const domain = url.substring(0, url.indexOf('/'));
        console.log(domain);

        let shortened_domain = domain;
        if (shortened_domain.includes('.')) { shortened_domain = shortened_domain.substring(0, shortened_domain.indexOf('.')); }

        this.merchantService.getMerchantByDomain(domain).subscribe(
          merchant => {
              console.log(merchant);
              //this.userService.scenario = 'takeaway';
              this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
              this.hide();
              this.ngZone.run(() => this.router.navigate(['/' + shortened_domain + '/menu'], { queryParams: { qr_scan: 1, qr_token: qr_code } })).then();
          },
          error => {
              console.log(error);
          });
      },
      error => {
        console.log(error);
      }
      );
  }

  onDone(err, status) {
		if (err) {
            // here we can handle errors and clean up any loose ends.
            console.error(err);
        }
        if (status.authorized) {
        	console.log('authorized');
        	this.show();
        	this.scan();
            // W00t, you have camera access and the scanner is initialized.
            // QRscanner.show() should feel very fast.
        } else if (status.denied) {
        	console.log('denied');
            // The video preview will remain black, and scanning is disabled. We can
            // try to ask the user to change their mind, but we'll have to send them
            // to their device settings with `QRScanner.openSettings()`.
        } else {
        	console.log('something else');
            // we didn't get permission, but we didn't get permanently denied. (On
            // Android, a denial isn't permanent unless the user checks the "Don't
            // ask again" box.) We can ask again at the next relevant opportunity.
        }
	}

	displayContents(err, scanned_url) {
        if (err) {
            // an error occurred, or the scan was canceled (error code `6`)
        } else {
            // The scan completed, display the contents of the QR code:
            //alert(scanned_url);
            if (this.action == 'merchant') { 
              const last_dash = scanned_url.lastIndexOf('/');
              const last_part = scanned_url.substring(last_dash + 1);
              console.log(scanned_url);
              console.log(last_part);
              this.getMerchantDomain(last_part) 
            } else { this.connectLoyaltyCard(scanned_url) }; 
        }
    }

  backClicked() {
    this.location.back();
  }

  isCordovaApp() {
    return window.hasOwnProperty('cordova');
  }

}
