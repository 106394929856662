import {throwError as observableThrowError} from 'rxjs';

import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen, retry, catchError, timeout } from 'rxjs/operators';

import {environment} from '../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

const getErrorMessage = (maxRetry: number) =>
  'Tried to load Resource over XHR for ' + maxRetry + ' times without success. Giving up.';

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;

export function retryWithBackoff(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
          if (retries-- > 0) {
            if (error.status == 401) return throwError(error);
            else {
              const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
              return of(error).pipe(delay(backoffTime));
            }
          }
          return throwError(error);
        }
       ))));
}

@Injectable()
export class ApiReservationService {
  api_url: string = environment.base_api_url;
  headers: HttpHeaders = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');
  constructor(private http: HttpClient,
              private translate: TranslateService) {
  }

  get(path: string, access_token: any, params?: any | {}): Observable<any> {

    let headers = this.headers;
    if (access_token) { headers = headers.append('Authorization', 'Bearer ' + access_token); }
    headers = headers.append('X-ACCESS-TOKEN', '3f1cab3f9086e76af83af4b2ee9b575f3f6571b42a34e931a29a7425a1362861');
    headers = headers.append('Accept-Language', this.translate.currentLang);

    //let endpoint = 'https://prod-one2three-appservice-reservationscustomerapi-we.azurewebsites.net' + path;
    //let endpoint = 'https://dev-one2three-appservice-reservationcustomerapi-we.azurewebsites.net' + path;
    let endpoint = 'https://staging-one2three-appservice-reservationcustomerapi-we.azurewebsites.net' + path;

    return this.http.get(endpoint, {headers: headers, params: params}).pipe(
      timeout(10000),
      retryWithBackoff(1500, 5),
      catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    return observableThrowError(error);
  }

}