<div class="main p-3 d-flex flex-column justify-content-between align-items-center">

  <div class="d-flex justify-content-between align-items-center p-3 py-1 first-part" style="width:100vw;">
    <div style="width:100px;">
      <mat-icon class="clickable" (click)="goBack()">arrow_back</mat-icon>
    </div>
    <div style="text-align: center; flex-grow:1;" *ngIf="!merchantService.kiosk">
      <img class="logo" src="{{ logo }}">
    </div>
    <div class="d-flex align-items-center justify-content-end"
          style="text-align:right;width:100px;">
      <app-language-selection  *ngIf="domain && domain.indexOf('kuehnenagel') > -1 && merchantService.kiosk"></app-language-selection>
    </div>
  </div>

  <mat-card class="main-card p-3"
            *ngIf="passwordResetSent">
            <div class="title">{{ 'forgot_password.check_emails' | translate }}</div>
            {{ 'forgot_password.check_emails_description' | translate: { email: user.value.email } }}
            <div style="text-align:center;">
              <a class="link" [routerLink]="['/login']">{{ 'user.login' | translate }}</a>
            </div>
  </mat-card>

  <mat-card class="main-card p-3">
  <form #f="ngForm" [formGroup]="user">
    <div class="title">{{ 'forgot_password.question' | translate }}</div>
    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'user.email' | translate }}" formControlName="email" required>
        <mat-error>{{ errors['email'] }}</mat-error>
      </mat-form-field>
    </div>

    <div style="text-align:right;">
      <a class="link" [routerLink]="['/register']">{{ 'user.register' | translate }}</a>
    </div>

    <div class="py-3" style="text-align: center;">
      <div>
        <button mat-flat-button [disabled]="!user.valid" (click)="register()" color="primary">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div *ngIf="registration_ongoing" class="pe-2"><mat-spinner [color]="'accent'"
                       [diameter]="20"></mat-spinner>
                     </div>
                     <div>
          {{ 'forgot_password.reset' | translate }}
        </div>
      </div>
        </button>
      </div>
    </div>

  </form>
</mat-card>

  <div>
  </div>
</div>

<div class="overflow-hidden background"
   [style.background]="'url(' + background_image + ')'"
   [style.background-size]="'cover'"
   [style.background-position]="'center'">
</div>