import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'group-sheet',
  templateUrl: './group-sheet.component.html',
  styleUrls: ['./group-sheet.component.scss']
})
export class GroupSheetComponent implements OnInit {

	public groupSelected = new EventEmitter();


  constructor(public userService: UserService,
  			  @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    console.log('THESE ARE THE groups');
    console.log(this.data.groups);
  }

  selectGroup(group) {
  	this.groupSelected.emit(group);
  }

}
