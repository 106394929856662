/* src/app/checkout/checkout.component.scss */
.checkout-radio-group {
  display: inline-flex;
  flex-direction: column;
}
.checkout-radio-button {
  margin: 5px;
}
mat-card {
  margin-bottom: 10px;
}
.mat-stepper-horizontal {
  background: none !important;
}
.mat-select-content {
  width: 250px;
}
mat-form-field {
  width: 250px;
}
.non-clickable {
  pointer-events: none;
}
.main-bgColor {
  min-height: 100vh;
  min-height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
.checkout-content {
  position: relative;
  min-height: 70vh;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.fixed_top {
  position: sticky;
  width: 100vw;
  top: -114px;
  z-index: 998;
}
.title {
  font-weight: bolder;
  font-size: 1.5em;
}
.category {
  color: white;
  font-size: 1.4em;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
@media (max-width: 991px) {
  .checkout-content {
    min-height: 70vh;
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1144px) {
  .fixed_top {
    position: fixed;
    top: 0px;
  }
  .template1-checkout-content {
    margin-top: 220px;
    width: 70vw !important;
    padding-left: 15px;
    padding-right: 0vw;
  }
  .template2-checkout-content {
    margin-top: 220px;
    width: calc(100vw - 440px) !important;
    padding-left: 15px;
    padding-right: 0vw;
  }
}
@media (min-width: 1145px) {
  .fixed_top {
    position: fixed;
    top: 0px;
  }
  .template1-checkout-content {
    margin-top: 220px;
    width: 70vw !important;
    padding-left: 7vw;
    padding-right: 7vw;
  }
  .template2-checkout-content {
    margin-top: 220px;
    width: calc(100vw - 440px) !important;
    padding-left: 7vw;
    padding-right: 7vw;
  }
}
/*# sourceMappingURL=checkout.component.css.map */
