/* src/app/menu/menu.component.scss */
#backgroundVideo {
  position: fixed;
  right: -388px;
  min-width: 170%;
  min-height: 170%;
}
.scenario_selector {
  border-radius: 18px;
  text-align: center;
  min-width: 360px;
  padding: 24px;
}
.content {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.content::-webkit-scrollbar {
  display: none;
}
.bar-add {
  font-size: 1.4em;
  flex-grow: 1;
  text-align: center;
  font-weight: bolder;
}
.closed_highlight {
  font-size: 1.2em;
}
.full-width {
  width: 100%;
}
.white-icon {
  color: white;
}
.cat-nav {
  position: fixed;
  width: 30px;
  height: 30px;
  position: fixed;
  top: 103px;
}
.cat-nav-v2 {
  position: absolute;
  width: 30px;
  height: 37px;
  padding-top: 7px;
  z-index: 99999;
}
.kiosk-part-1 {
  font-size: 45px;
  padding: 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}
.kiosk-part-1 > .highlight {
  border-radius: 6px;
}
.kiosk-part-2 {
  color: white;
  opacity: 0.8;
  height: 100px;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
}
.kiosk-start {
  font-size: 2em;
  color: white;
  text-align: right;
}
.kiosk-logo {
  max-height: 180px;
  max-width: 180px;
}
.highlight {
  line-height: 1;
  margin-top: 30px;
  padding: 20px;
}
.payment-method_img {
  max-height: 40px;
  max-width: 100px;
}
.category-screen {
  background-color: white;
  position: fixed;
  width: 100vw;
  height: 100%;
  padding: 0px;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-y: scroll;
}
.category-button-container {
  position: fixed;
  right: 20px;
  z-index: 9999;
}
.filter-button-container {
  position: fixed;
  right: 20px;
  z-index: 9999;
}
.svg-icon {
  padding-top: 5px;
  margin: auto;
  width: 80px;
  height: 80px;
}
.category-mainbox {
  position: sticky;
  top: 96px;
  z-index: 996;
}
.selected {
  font-weight: bolder;
}
.selected-left {
  font-weight: bolder;
  border-left: 4px solid;
}
.categorybar::-webkit-scrollbar {
  display: none;
}
.category-mainbox::-webkit-scrollbar {
  display: none;
}
.fixed_top {
  position: sticky;
  width: 100vw;
  top: -64px;
  z-index: 998;
}
.main {
  background-color: white !important;
  min-height: 100%;
  margin-bottom: 100px;
}
.basket_title {
  font-weight: bolder;
  font-size: 1.5em;
}
.category {
  font-size: 1.4em;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.menu-positioner {
  position: fixed;
  left: 20px;
  width: 200px;
  height: calc(100vh - 210px);
  overflow-y: auto;
  margin-top: 20px;
  padding-bottom: 120px;
}
.menu-container {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.menu-background {
  padding: 10px;
  color: white;
  border-radius: 5px;
}
.basket-title {
  font-size: 18px;
  font-weight: bolder;
  position: relative;
}
.advertisement {
  border-radius: 5px;
}
.mobile-adjuster {
  margin-top: -40px;
}
.categoryOverviewContainer {
  overflow-y: hidden;
}
.mat-mdc-mini-fab {
  background-color: #333;
}
@media (max-width: 375px) {
  .categories-container {
    padding-top: 10px;
  }
  .cat-nav {
    top: 49px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 376px) and (max-width: 768px) {
  .categories-container {
    padding-top: 10px;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .categories-container {
    padding-top: 10px;
  }
  .mobile-adjuster {
    margin-top: 0px;
  }
  .menu-blocks {
    margin-left: -23px;
    margin-right: -23px;
  }
  .fixed_top {
    position: fixed;
    top: 0px;
  }
  .menu {
    position: relative;
    margin-top: 130px;
  }
  .category-mainbox {
    top: 160px;
  }
  .cat-nav {
    top: 167px;
  }
}
@media (min-width: 992px) {
  .categoryOverviewContainer {
    margin-top: -60px;
    padding-top: 70px;
    margin-left: -60px;
    padding-left: 60px;
    margin-right: -60px;
    padding-right: 60px;
    margin-bottom: -60px;
    padding-bottom: 60px;
  }
  .mobile-adjuster {
    margin-top: 0px;
  }
  .menu-blocks {
    margin-left: -23px;
    margin-right: -23px;
  }
  .categories {
    width: calc(100vw - 680px) !important;
  }
  .category-col:not(:focus):hover {
    border-radius: 5px;
    z-index: 1;
    transform: scale(1.1);
  }
  .fixed_top {
    position: fixed;
    top: 0px;
  }
  .menu {
    margin-top: 200px;
  }
  .category-mainbox {
    top: 200px;
  }
  .cat-nav {
    top: 207px;
  }
}
.category-col {
  transition: all 0.2s ease-in-out;
}
.full-height {
  height: calc(100vh - 250px);
}
.menu-card {
  width: 100%;
  cursor: pointer;
  position: relative;
}
.template1.search_area {
  height: 50px;
}
@media (min-width: 992px) {
  .template1.basket {
    top: 260px;
  }
}
@media (min-width: 992px) {
  .template2.basket {
    top: 220px;
  }
}
/*# sourceMappingURL=menu.component.css.map */
