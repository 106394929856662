import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PhoneNumberUtil, PhoneNumber,PhoneNumberType } from 'google-libphonenumber';

import { GroupService } from '../services/group.service';
import { UtilityService } from '../services/utility.service';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

export interface Country { name: string; value: string; }

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit {

	public groupCreated;
	public groupExist;
	public merchant_group;

  countries: Country[] = [
    { name: 'Belgium', value: 'BE' }
  ];

	merchantGroup = new UntypedFormGroup({
		id: new UntypedFormControl(''),
		name: new UntypedFormControl('', [Validators.required]),
		description: new UntypedFormControl(''),
		merchant_id: new UntypedFormControl(''),
    company_name: new UntypedFormControl('', [Validators.maxLength(50)]),
    vat_number: new UntypedFormControl('', [Validators.maxLength(30)]),
    phone_number: new UntypedFormControl('',[Validators.required, this.phoneValidator]),
    estimated_number_of_users: new UntypedFormControl(''),
    remarks: new UntypedFormControl(''),

		address_attributes: new UntypedFormGroup({
			line1: new UntypedFormControl('', [Validators.required]),
			line2: new UntypedFormControl(''),
			city: new UntypedFormControl('', [Validators.required]),
			country_alpha2: new UntypedFormControl('', [Validators.required]),
			zipcode: new UntypedFormControl('', [Validators.required])
		})
	});

  constructor(
		public groupService: GroupService,
		private util: UtilityService,
		private merchantService: MerchantService,
		private userService: UserService,
		private route: ActivatedRoute,
    private translate: TranslateService
  ) {
		this.groupCreated = false;
  }

	ngOnInit() {
		this.setMerchantGroup();
	}

   phoneValidator(control: any) {

    try {
      let parsedValue = null;
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      if (control.value) parsedValue = phoneNumberUtil.parseAndKeepRawInput(control.value);
      if (!parsedValue || !phoneNumberUtil.isValidNumber(parsedValue) || phoneNumberUtil.getNumberType(parsedValue) != PhoneNumberType.MOBILE) return { mobileNumber: 'no' }
      else return null;
    } catch (e) {
      return { mobileNumber: 'no' };
    }

  }

	setMerchantGroup() {
		const merchant_id = this.merchantService.merchant.id;
		const merchant_groups = this.userService.user.groups.map(function(group) {
      if (merchant_id == group.merchant_id) { return group; }
    });
    this.merchant_group = merchant_groups.filter(function (group) { return group != undefined; })[0];
	}

  onGroupFormSubmit() {
		this.merchantGroup.value.merchant_id = this.merchantService.merchant.id;
    this.groupService.createMerchantGroup({ group: this.merchantGroup.value })
    .subscribe(
      group => {
				this.groupCreated = true;
        this.getProfile();
        this.util.openSnackBar(this.translate.instant('apply.submitted'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('groups.merchant_group_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  getProfile() {

    this.userService.getProfile()
    .subscribe(
      user => {
        this.userService.user = user;
      },
      error => {
        console.log(error);
      }
      );
  }

  /*validatePhone() {
    if (isValidNumber(this.merchantGroup.value.phone_number)) {
      this.merchantGroup.controls.phone_number.setErrors(null);
    } else {
      this.merchantGroup.controls.phone_number.setErrors({'incorrect': true});
    }
  }*/

}
