import { ElementRef, Output, Directive, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({
  selector: '[appear]'
})
export class AppearDirective implements AfterViewInit, OnDestroy {
  @Output()
  appear: EventEmitter<void>;

  elementPos: number;
  elementHeight: number;

  scrollPos: number;
  
  subscriptionScroll: Subscription;
  subscriptionResize: Subscription;

  visible = false;

  constructor(private element: ElementRef) {
    this.appear = new EventEmitter<void>();
  }

  saveDimensions() {
    const bound = this.element.nativeElement.getBoundingClientRect();
    this.elementPos = this.getOffsetTop(this.element.nativeElement);
    this.elementHeight = bound.height;
  }
  saveScrollPos() {
    this.scrollPos = window.scrollY;
  }

  
  getOffsetTop(element: any) {
    let offsetTop = element.offsetTop || 0;
    if (element.offsetParent) {
      offsetTop += this.getOffsetTop(element.offsetParent);
    }
    return offsetTop;
  }
  
  checkVisibility() {
    this.saveDimensions();
    if (this.isVisible() && !this.visible) {
        this.visible = true;
        this.appear.emit();
    } else {
      this.visible = false;
    }
  }
  isVisible() {
    return (
      this.scrollPos >= this.elementPos - 300 && this.scrollPos < this.elementPos - 300 + this.elementHeight 
    );
  }

  subscribe() {
    this.subscriptionScroll = fromEvent(window, 'scroll')
      .subscribe(() => {
        this.saveScrollPos();
        this.checkVisibility();
      });
    this.subscriptionResize = fromEvent(window, 'resize')
      .subscribe(() => {
        this.saveDimensions();
        this.checkVisibility();
      });
  }
  unsubscribe() {
    if (this.subscriptionScroll) {
      this.subscriptionScroll.unsubscribe();
    }
    if (this.subscriptionResize) {
      this.subscriptionResize.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.saveDimensions();
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
}
