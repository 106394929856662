<div class="subtitle">
	{{ 'order_type.subtitle' | translate }}
</div>

<div class="types d-flex align-items-center font-dosis" *ngIf="userService.basket && userService.basket.order.order_type">
	
	
	<div class="" style="width:75px;text-align: center">
		<img class="image"
			 src='assets/order_types/gray/{{ userService.basket.order.order_type }}.png'/>
	</div>

	<div class="">
		<div class="display-inline">
			<strong>
				{{ 'order_type.' + userService.basket.order.order_type | translate | capitalizeFirst}}
			</strong>
			<i 	class="ps-2 clickable no-selection secondary_low bold" 
				*ngIf="groups.length > 0"
				[ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
				(click)="openOrderTypeSheet()">
				<fa-icon 
                    class="secondary_low"
                    [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
                    [icon]="faEdit">
              </fa-icon>
			  {{ 'order_type.edit' | translate }}
			</i>
		</div>

		<ng-container *ngIf="userService.basket.order.order_type == 'group'">

			<div 	class="clickable no-selection"
					(click)="openGroupSheet()">
            	{{ getGroupName(userService.basket.order.group_id) }} 
            	<ng-container *ngIf="groups.length > 1">
	            	<i>
	            	<fa-icon 
	                    class="ps-1 secondary_low"
	                    [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
	                    [icon]="faEdit">
	              </fa-icon>
	                <span class="secondary_low bold"
	                	 [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
	          			{{ 'order_type.edit' | translate }}
	          		</span>
	          	</i>
	          </ng-container>
          	</div>

		</ng-container>	
	</div>

</div>
