/* src/app/profile/members/members.component.css */
.top-right {
  float: right;
  margin-top: 0px;
  margin-right: 0px;
}
table {
  width: 100%;
}
.mat-form-field {
  font-size: 14px;
  width: 100%;
}
.main {
  background-color: white;
  min-height: 100%;
}
.full-width {
  width: 100%;
}
mat-list-item {
  height: auto !important;
}
/*# sourceMappingURL=members.component.css.map */
