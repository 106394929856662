<ng-container *ngIf="necessaryData && !is_reservation">

  <basketbar 	class="d-block d-lg-none" 
  [feature]="'final'" 
  [showDetails]="false"
  [position]="'web'" ></basketbar>


  <div class="d-none d-lg-block">
    <div class="{{ template }} basket" *ngIf="userService.basket">
      <div  class="{{ template }} basket-main-content shadow p-3"
      [ngStyle]="{'max-height': basketHeight }">
      <div class="title">
        {{ 'checkout.basket_title' | translate }}
      </div>
      <div class="basket-content">
        <order  [editable]="false"
                [order]="userService.basket.order"></order>
      </div>

    </div>

    <div class="mt-2">

    <basketbar  
    [position]="'relative'" 
    [feature]="'paymentWeb'">
  </basketbar>

</div>

  <div *ngIf="!editable" (click)="goToMenu($event)">
    <a class="link font-dosis">{{ 'checkout.back_to_menu' | translate }}</a>
  </div>

</div>
</div>

<div class="fixed_top">
  <venue-header [extra_info]="false"></venue-header>
  <div  class="titlebar px-3"
        [ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
      {{ 'checkout.header_payments' | translate }}
  </div>
</div>

<div class="main-bgColor">

  <div class="checkout-content {{ template }}-checkout-content">

    <ng-container>

      <div class="py-3" *ngIf="userService.basket.order.order_type == 'personal' || userService.basket.order.group_who_pays == 'user'">
        <voucher *ngIf="merchantService.hasFeature(userService.basket.order.merchant_id,'f_coupons')"></voucher>
      </div>

      <div class="py-3" *ngIf="(userService.basket.order.order_type == 'personal' || userService.basket.order.group_who_pays == 'user') && userService.basket.order.price_after_coupons > 0">
        <payment-method (monizzeBalance)="updateMonizzeBalance($event)" (emitUpdate)="getUpdate($event)"></payment-method>
        <div  *ngIf="invoice_request || userService.basket.order.merchant_id == 35"
        class="mt-2 ms-1 d-flex">
        <div>
          <mat-checkbox [(ngModel)]="userService.basket.order.invoice_requested"></mat-checkbox>
        </div>
        <div class="ms-2">
          {{ 'checkout.invoice_check' | translate }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="user_loyalty_point">
      <div class="mb-3"
          *ngIf="userService.basket.order.payment_method == 'WALLET' || loyalty_points">
        <loyalty-card [flipped]="false" [user_loyalty_point]="user_loyalty_point" [large_screen]="true"></loyalty-card>
      </div>
    </ng-container>

    <!-- <div class="py-3" *ngIf="loyalty_points">
    
      <div class="loyalty-card w-100" style="height:250px;">

        <div class="front loyalty-card-background-2"
          [style.background]="'url(' + background_url + ')'"
          [style.background-position]="'center'"
          [style.background-size]="'cover'">
          <div class="card-body loyalty-card-background d-flex flex-column justify-content-between p-3" style="border-radius:10px;height: 250px;">
            <div class="d-flex flew-row justify-content-between align-items-center" style="height: 100px;">
              <div class="text-center"><img width="60px;" src='{{ logo }}'></div>
              <div><img width="100px;" src="{{ merchantService.merchant.image?.medium_url }}"></div>
              <div style="width: 60px; text-align: right;">
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div  class="plus-points"
                    [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#ad151c'}">
                +{{ loyalty_points }}
              </div>
              <div class="ps-3">
                <ng-container *ngIf="loyalty_points > 1; else earn_one">{{ 'loyalty_points.earn_multiple' | translate:{ loyalty_points: loyalty_points } }}</ng-container>
                <ng-template #earn_one>
                  {{ 'loyalty_points.earn_one' | translate:{ loyalty_points: loyalty_points } }}
                </ng-template>
              </div>
            </div>
          </div>
        </div>
     </div>
    </div> -->

  <advisories></advisories>
  <div class="mt-2">
    <notification></notification>
  </div>

</ng-container>

<basketbar class="d-none d-lg-block" [monizze_balance]="monizze_balance" [position]="'relative'" [feature]="'final'" [showDetails]="false"></basketbar>

</div>

<form name="monizzeForm" id="monizzeForm" method="post">
 <input type="hidden" id="URL_ACCEPTED" name="URL_ACCEPTED" value="">
 <input type="hidden" id="URL_CANCELED" name="URL_CANCELED" value="">
 <input type="hidden" id="URL_DECLINED" name="URL_DECLINED" value="">
 <input type="hidden" id="MID" name="MID" value="">
 <input type="hidden" id="MREF" name="MREF" value="">
 <input type="hidden" id="AMOUNT" name="AMOUNT" value="">
 <input type="hidden" id="TYPE" name="TYPE" value=""> 
 <input type="hidden" id="EMAIL" name="EMAIL" value="">
 <input type="hidden" id="SHOP" name="SHOP" value=""> 
 <input type="hidden" id="SIGN" name="SIGN" value="">
</form>


</div>

</ng-container>

<ng-container *ngIf="necessaryData && is_reservation">

   <basketbar   class="d-block d-lg-none" 
  [feature]="'final'" 
  [showDetails]="false"
  [position]="'web'" ></basketbar>
  
  <div class="container">
    <div>
       <img class="logo" src="{{ merchantService.merchant.image?.medium_url }}" style="border-radius: 5px;">
    </div>
    <div>
      
      <h1>{{ userService.basket.order.dist_location.name }}</h1>
      
      <order  [editable]="false"
              [taxons_shown]="!is_reservation"
              [order]="userService.basket.order"></order>

    </div>
    <div>
      <payment-method (monizzeBalance)="updateMonizzeBalance($event)" (emitUpdate)="getUpdate($event)"></payment-method>
    </div>
    <div class="mt-3">
       <basketbar  
          [position]="'relative'"
          [backButtonShown]="false" 
          [feature]="'final'">
        </basketbar>
    </div>
  </div>
</ng-container>