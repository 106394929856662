import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationStart } from '@angular/router';
import { MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';

import { ConfirmationComponent } from '../order/confirmation/confirmation.component';
import { NavigationComponent }      from '../navigation/navigation.component';
import { OrderService } from '../services/order.service';
import { MerchantService } from '../services/merchant.service';
import { UserService} from '../services/user.service';

import { map, switchMap, tap } from 'rxjs/operators';

import { AppHelper } from '../helpers/app-helper';

import { filter } from 'rxjs/operators';

import {environment} from '../../environments/environment';

import { trigger, state, transition, animate, style } from '@angular/animations';

import { faConciergeBell, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { faBars, faLocationCircle, faStoreAlt, faFrenchFries, faShoppingBag, faMouseAlt } from '@fortawesome/pro-regular-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { FeaturesHelper } from '../helpers/features-helper';
import { Merchant } from '../models/merchant';
import { animate as motion_animate } from "motion"

declare var Splashscreen: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
  trigger('slideInOut', [
    transition(':enter', [
      style({transform: 'translateY(+500%)'}),
      animate('1000ms 1500ms ease-out', style({transform: 'translateY(0%)'}))
      ])
    ]),
  trigger('slideInOut2', [
    transition(':enter', [
      style({transform: 'translateY(+500%)'}),
      animate('1000ms 2000ms ease-out', style({transform: 'translateY(0%)'}))
      ])
    ]),
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate('1000ms 1000ms')),
    ]),

  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(1000)),
    ])

  ]
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {

  public faCircleUSer = faCircleUser;
  public faConciergeBell = faConciergeBell;
  public faBars = faBars;
  public faLocationCircle = faLocationCircle;
  public faStoreAlt = faStoreAlt;
  public faFrenchFries = faFrenchFries;
  public faShoppingBag = faShoppingBag;
  public faMouseAlt = faMouseAlt;
  public faFacebook = faFacebookF;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;
  
  public showVideo = false;
  public background_image: string;
  public whitelabel = environment.whitelabel;
  public scenarios = environment.scenarios;
  public background_repeat = environment.background_repeat;
  public background_size = environment.background_size;
  public distributingOrders: any;
  public preparedOrders: any;
  public orders = [];
  public vendingMachineTimer;

  private dialogRef: any;
  private _routerSubscription: any;
  private merchant_domain: string;

  private geoSubscription;

  private timer;
  private bgtimer;
  private bggtimer;

  public logo;

  public waiter_data_loaded = false;

  public module = "none";
  public fol = false;

  public image_name = "../../assets/frietjesonline/slide1.jpeg"

  @ViewChild('first_section') firstSection: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private merchantService: MerchantService,
    public userService: UserService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private appHelper: AppHelper,
    private featuresHelper: FeaturesHelper) {
      
      this.route.params.subscribe(
        params => {
            this.merchant_domain = params['merchant_slug']
        });
  }

  ngOnInit() {

    if (environment.pos) this.module = "pos";
    if (environment.kitchen) this.module = "kitchen";
    this.fol = this.appHelper.isFol();

    if (this.module != "none") {
      if (this.userService.user) {
        if (this.merchant_domain) {
          this.featuresHelper.redirectToFeature(`${this.module}`, this.merchant_domain)
        } else {
        this.featuresHelper.redirectToFeature(`${this.module}`)
        } 
      } else { 
        if (this.merchant_domain) {
          this.router.navigate(['/login'], { queryParams: { module: `${this.module}` , domain: this.merchant_domain }})
        } else {
          this.router.navigate(['/login'], { queryParams: { module: `${this.module}` }})
        }
      }
    }

    this.background_image = 'assets/backgrounds/' + environment.whitelabel + '.jpg';
    
    if (!this.fol) this.renderer.addClass(document.body, 'nobounce'); 

    //a virtual user should not come to the main page
    if (this.userService.user && this.userService.user.virtual_user) this.userService.logoutUser();

    //this.repeatOrders();

    this.logo = './assets/logos/' + environment.whitelabel + '.png';

    if (this.userService.user && this.userService.user.is_waiter) { this.startWaiter(); }

    //verify if we are in a kiosk mode
    if (this.merchantService.kiosk) {
      this.appHelper.setVenue(this.merchantService.kiosk.merchant_slug);
    }

    this.scenarios = environment.scenarios;

    if (!this.appHelper.doesFileExist(`assets/scenarios/${this.whitelabel}/takeaway.png`)) { this.whitelabel = "guide"; };

  }

  getDistributingOrders() {

    this.orderService
        .getDistributingOrders(this.userService.user.id)
        .subscribe(
          (res: any) => {
            if (!this.distributingOrders) { this.distributingOrders = res.orders }
            else if (res.orders.length > 0) { this.distributingOrders[0] = res.orders[0]; };
          },
          err => {
            console.warn(err);
          }
        );
  }

  getPreparedOrders() {
    this.orderService.getPreparedOrdersForVendingMachine(this.userService.user.id).subscribe(
      (res: any) => {
        if (!this.preparedOrders) { this.preparedOrders = res.orders }
        else if (res.orders.length > 0) { this.preparedOrders[0] = res.orders[0]; };
      },
      err => {
        console.warn(err);
      }
    )
  }

  ngAfterViewInit() {
    motion_animate("#second-bg", { opacity: [0, 0.01, 1, 0.99, 0]}, { duration: 30, repeat: Infinity, offset:[0, 0.49, 0.5, 0.99, 1] })
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'nobounce');
    if (this.timer) clearTimeout(this.timer);
    if (this.vendingMachineTimer) clearTimeout(this.vendingMachineTimer);
    if (this.bggtimer) clearTimeout(this.bggtimer);
  }

  showOrderConfirmationDialog(orderId) {
    this.orderService.getOrder(orderId)
    .subscribe(
      response => {
        const data = { merchant: this.merchantService.merchant, order: response };
        const confirmationDialogConfig = new MatDialogConfig();
        confirmationDialogConfig.data = { data: data };
        confirmationDialogConfig.disableClose = true;
        confirmationDialogConfig.autoFocus = true;

        this.dialog.open(ConfirmationComponent, confirmationDialogConfig);
      },
      error => {
        console.log(error);
      }
      );
  }

  selectScenario(scenario) {
    this.userService.scenario = scenario;
    if (!this.merchantService.singleMerchantWhitelabel) { this.router.navigate(['/venues'], { queryParams: { scenario: scenario }}); }
    else {
      let domain = this.merchantService.merchant.domain;
      if (domain.includes('.')) { domain = domain.substring(0, domain.indexOf('.')); }
      this.router.navigate(['/' + domain + '/menu']);
    }
  }

  isCordovaApp() {
    return window.hasOwnProperty('cordova');
  }

  repeatOrders() {
      if (this.userService.user) {
        this.getDistributingOrders();
        this.getPreparedOrders();
      }

      this.timer = setTimeout(() => {
          this.repeatOrders();

         }, 60000);
      }

   startWaiter() {
     this.merchantService.getMerchantById(this.userService.user.waiter_role.merchant_id.toString())
      .subscribe(
        response => {
          this.userService.scenario = 'waiter';
          this.setVenue(response.domain);
        },
        error => {
          console.log(error);
        }
      );
     
   }

    setVenue(domain) {

      this.merchantService.getMerchantByDomain(domain).pipe(
        switchMap((merchant: any) => {
            this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
            return this.appHelper.loadMerchantData(merchant.id);
           } )).subscribe(
          responseList => {
            let res = responseList[4];
            this.merchantService.merchant.menu = res['menu'];
            this.merchantService.merchant.mainCategories = res['mainCategories'];
            this.merchantService.merchant.categories = res['categories'];
            this.merchantService.merchant.products = res['products'];

            //does user have a basket?
            if (localStorage && localStorage.getItem('basket')) {
                this.userService.basket = JSON.parse(localStorage.getItem('basket'));
                this.userService.alterUpdateOngoing(false);
            }

            this.appHelper.initBasket().subscribe(
            response => {
              this.waiter_data_loaded = true;
              this.userService.alterUpdateOngoing(false);
            },
            error => {
              console.log(error);
            });

          },
          error => {
            if (error.status == 401) {
              this.router.navigate(['/private'], { queryParams: { unauthorized: true, domain: domain } });
            } else {
              this.router.navigate(['/']);
            }
          }
        );

  }

  onDataRequestCompleted(event) {
    let domain = this.merchantService.merchant.domain;
    if (domain.includes('.')) { domain = domain.substring(0, domain.indexOf('.')); }
    this.router.navigate([domain + '/menu'], { relativeTo: this.route });
  }

  scrollDown() {
    document.getElementById("scroll-to").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
