<div class="subtitle">
  {{ 'voucher.subtitle' | translate }}
</div>

<ngx-spinner type="ball-pulse"></ngx-spinner>

<div class="font-dosis">
   <div style="flex-grow:1">
      <mat-form-field style="width:100%">
         <mat-label>{{ 'voucher.placeholder' | translate }}</mat-label>
        <input matInput [(ngModel)]="coupon_code" type="text" #voucherSearch>
      </mat-form-field>
</div>
<div>
    <button mat-flat-button
            class="voucher-button font-permanent-marker"
            color="primary"
            [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
            (click)="validateCoupon()">
          {{ 'voucher.confirm' | translate }}
    </button>
</div>

<div class="row" style="width:100%" *ngFor="let coupon of userService.basket.coupons">
	
  <div class="col-12 col-md-3" style="font-weight: bold;" *ngIf="!coupon.is_generic">{{ coupon.coupon_code.split('.').join('') }}</div>
  <div class="col-12 col-md-3" style="font-weight: bold;" *ngIf="coupon.is_generic">{{ coupon.generic_code }}</div>
	
  <div class="col-11 col-md-8">
    <ng-container *ngIf="!coupon.is_generic">{{ uniqueCouponFeedback(coupon) }}</ng-container>
    <ng-container *ngIf="coupon.is_generic">{{ genericCouponFeedback(coupon) }}</ng-container>
  </div>
  <div class="col-1 col-md-1 text-right">
  	<button mat-icon-button (click)="removeCoupon(coupon)" style="text-align:right;">
  		<mat-icon>remove_circle_outline</mat-icon>
  	</button>
  </div>
</div>

</div>