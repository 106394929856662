/* src/app/venue-header/venue-header.component.scss */
.background {
  z-index: 998;
  width: 100vw;
  height: 160px;
  background-size: cover;
  top: -110px;
}
.z-9999 {
  z-index: 9999;
}
.fol-header {
  background:
    linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.3),
      hsla(0, 0%, 100%, 0.3)),
    url(https://www.frietjesonline.be/uploads/backgrounds/background.jpg?h=500&mode=max);
  background-size: auto, cover;
  background-repeat: repeat;
}
.venuebar {
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 1.4em;
  font-weight: bolder;
}
.merchant_name {
  position: relative;
  font-weight: bolder;
  text-align: center;
  flex-grow: 1;
}
.fol-merchant-name {
  color: #f0513f;
}
.background_image {
  width: 100%;
  height: 200px;
}
mat-icon {
  vertical-align: middle;
}
.logo {
  height: 80px;
}
@media (max-width: 768px) {
  .fol-merchant-name {
    font-size: 0.8em;
  }
}
@media (min-width: 992px) {
  .fol-merchant-name {
    font-size: 1.2em;
  }
  .logo {
    height: 70px;
    margin-top: 43px;
    margin-left: 16px;
  }
  .background {
    height: 200px;
  }
}
/*# sourceMappingURL=venue-header.component.css.map */
