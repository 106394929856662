import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { distinctUntilChanged, debounceTime, filter, map, tap, switchMap, catchError } from 'rxjs/operators';
import { fromEvent, Subject, Observable, of } from 'rxjs';

import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { TranslateService } from '@ngx-translate/core';

import { CurrencyPipe } from '@angular/common'

import { AppHelper } from '../helpers/app-helper';

import { UtilityService } from '../services/utility.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit, AfterViewInit {

  public whitelabel = "guide";

  public coupon_code: string;
  public coupon_code_error: string;
  public coupon_codes: Array<string> = new Array();

  public fol = false;

  @ViewChild('voucherSearch') voucherSearchInput: ElementRef;

  constructor(		public orderService: OrderService,
  					      public userService: UserService,
                  public merchantService: MerchantService,
  					      private spinner: NgxSpinnerService,
                  private util: UtilityService,
                  private translate: TranslateService,
                  private appHelper: AppHelper,
                  private cp: CurrencyPipe) {

      this.whitelabel = environment.whitelabel;
      this.fol = this.appHelper.isFol();

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  getIndicesOf(searchStr, str, caseSensitive) {
    
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    
    if (!str || str == undefined || str.length == 0) return [];

    let startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
  }

  uniqueCouponFeedback(coupon) {
    const amount = this.cp.transform(coupon.value_remaining - coupon.new_value_remaining, "EUR");
    const amount_left = this.cp.transform(coupon.new_value_remaining, "EUR");
    return this.translate.instant("voucher.feedback", { amount: amount, amount_left: amount_left });
  }

  genericCouponFeedback(coupon) {
    const amount = this.cp.transform(coupon.value_remaining - coupon.new_value_remaining, "EUR");
    return this.translate.instant("voucher.feedback_generic", { amount: amount });
  }


  validateCoupon() {

    if (this.coupon_code && this.coupon_code != undefined) {

      let verifCode = this.coupon_code.split('.').join('');
      //hardcoding for Bubble Company
      if (verifCode.startsWith("303")) { verifCode = "0" + verifCode } ;

      this.coupon_code_error = undefined;

      let coupon_already_used = false;
      for (const coupon of this.userService.basket.coupons) {
        console.log(coupon);
        if (coupon.coupon_code.split('.').join('') == verifCode) { coupon_already_used = true; }
        if (coupon.generic_code && coupon.generic_code.toUpperCase() == verifCode.toUpperCase()) { coupon_already_used = true; }
      }

      if (coupon_already_used) {
        this.util.openSnackBar(this.translate.instant('voucher.used_already'), 'ok');
      } else {

        //this.spinner.show();
        this.orderService
        .validateCouponCode(this.userService.basket.order.merchant_id, verifCode)
        .subscribe(
          (res: any) => {
            //this.spinner.hide();
            const coupon = res;
            if (coupon.valid) {
              this.addCoupon(coupon);
              this.coupon_code = '';
              this.updateOrder();
            } else {
              this.util.openSnackBar(this.translate.instant('voucher.invalid'), 'ok');
            }
          },
          err => {
            this.spinner.hide();
            this.util.openSnackBar(this.translate.instant('voucher.invalid'), 'ok');
            console.log(err);
          }
          );

      }

    }
  }

  addCoupon(coupon) {
    coupon.value_remaining = +coupon.value_remaining;
    coupon.new_value_remaining = coupon.value_remaining;
  	this.userService.basket.coupons.push(coupon);
  }

  removeCoupon(coupon) {
    let index = 0;
    for (const c of this.userService.basket.coupons) {
      if (c.coupon_code == coupon.coupon_code) {
        this.userService.basket.coupons.splice(index, 1);
        this.updateOrder();
        break;
      }
      index++;
    }
  }

  updateOrder() {

     this.userService.alterUpdateOngoing(true);
     this.spinner.show('order');
     this.orderService.updateOrder(this.userService.getBasket().order, false)
        .subscribe(
          (res: any) => {
            //this.userService.getBasket().order = res;
            //this.appHelper.calculateOrderTotalPrice();
            //this.userService.saveBasketInStorage();
            this.appHelper.setBasketOrder(res, this.merchantService.merchant);
          },
          err => {
            console.log(err);
          },
          () => {
            this.userService.alterUpdateOngoing(false);
            this.spinner.hide('order');
          }
        );

  }

}
