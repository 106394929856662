import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import { MerchantService } from '../services/merchant.service';

@Component({
  selector: 'app-find-merchant',
  templateUrl: './find-merchant.component.html',
  styleUrls: ['./find-merchant.component.css']
})
export class FindMerchantComponent implements OnInit {

	public url;

  constructor(private router: Router,
  			  public merchantService: MerchantService) { }

  ngOnInit() {

  	this.url = JSON.stringify(this.router.url);
		console.log('AND THIS IS THE URL =========>');
		console.log(this.url.substring(1));
		//this.router.navigate(['/']);
		//this.getMerchantByDomain("localhost:4200/123bakkerij");
  }

  getMerchantByDomain(domain) {

    console.log(domain);
    this.merchantService.getMerchantByDomain(domain)
    .subscribe(
      response => {
        console.log('MERCHANT BY DOMAIN =>');
        console.log(response);
        this.merchantService.merchant = response;
        this.router.navigate(['/menu']);
      },
      error => {
        console.log(error);
      }
      );
  }

}
