/* src/app/worldline/worldline.component.css */
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.payconiq-mobile {
  margin-left: 27px;
  margin-right: 27px;
}
.payconiq-link {
  color: black;
}
.payconiq-frame {
  margin-top: 20px;
  width: 270px;
  height: 315px;
  background-image: url(/assets/payment_methods/payconiq_frame.png);
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 27px;
  padding-left: 27px;
  padding-right: 27px;
}
.payconiq-status {
  text-align: left;
  padding-top: 27px;
  width: 270px;
  font-size: 16px;
}
.cancel {
  text-align: right;
  width: 270px;
  margin-bottom: 30px;
}
@media (max-width: 717px) {
  .payconiq-info {
    width: 270px;
  }
}
@media (min-width: 718px) {
  .payconiq-info {
    width: 717px;
  }
}
/*# sourceMappingURL=worldline.component.css.map */
