/* src/app/components/fol-footer/fol-footer.component.scss */
.fol-footer {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("./media/background_footer.jpg");
  background-position: 0 0, 50% 100% !important;
  background-size: auto, cover !important;
  background-repeat: repeat, no-repeat !important;
  background-attachment: scroll, fixed !important;
  width: 100%;
}
a.btnPrimary {
  text-decoration: none;
  font-family: "Permanent Marker";
  text-align: center;
  border-radius: 50px;
  padding: 9px 15px;
  font-size: 20px;
  width: 100%;
  outline: 0;
  border: 0;
  background-color: #f0513f;
  color: #fdd329;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
}
.link-home > img {
  max-height: 120px;
}
.link-footer,
h4 {
  font-family: "Permanent Marker";
}
.container-details,
.sitemap-list {
  font-family: "Dosis";
}
a.sitemap-link {
  color: black;
}
.sm-link {
  display: inline-block;
  padding: 5px;
}
fa-icon {
  color: black;
}
ul {
  list-style: none;
}
/*# sourceMappingURL=fol-footer.component.css.map */
