<h2 mat-dialog-title>{{ 'checkout.modal_title' | translate }}</h2>

<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 float-left">
      <img src="{{ merchant.image.medium_url }}">
    </div>

    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 float-left">
      <h4>{{ merchant.name }}</h4>
      <ng-container *ngIf="merchant.address">
        <div><i class="fa fa-map-marker"></i>{{ merchant.address.line1 }}</div>
        <div>{{ merchant.address.zipcode }} - {{ merchant.address.city }}</div>
        <div>{{ merchant.address.country }}</div>
      </ng-container>
      <div *ngIf="merchant.settings.s_public_phone">{{ merchant.settings.s_public_phone }}</div>
      <div *ngIf="merchant.settings.s_public_email">{{ merchant.settings.s_public_email }}</div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <p *ngIf="order.order_type == 'group'">{{ 'checkout.modal_text_group' | translate }}</p>
    <p *ngIf="order.order_type != 'group'">{{ 'checkout.modal_text' | translate }}</p>
    <ul>
      <li><b>{{ 'checkout.order_code' | translate }}:</b> {{ order.code }}</li>
      <li *ngIf="order.order_type != 'group'"><b>{{ 'checkout.delivery_method' | translate }}:</b> {{ order.scenario }}</li>
      <li *ngIf="order.dist_timing === 'timed' && order.order_type != 'group'"><b>{{ 'checkout.estimated_delivery_time' | translate }}:</b> {{ order.dist_timing_target | date:'H:mm d/MM/yy' }}</li>
      <li *ngIf="order.scenario === 'delivery'"><b>{{ 'checkout.delivery_location' | translate }}:</b> {{ order.dist_location_name }}</li>
      <li *ngIf="merchant.features.payments && order.payment_method && order.payment_method != 'CASH'"><b>{{ 'checkout.transaction_id' | translate }}:</b> {{ transaction_id }}</li>
    </ul>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button color="primary" tabindex="-1" mat-dialog-close>OK</button>
</mat-dialog-actions>
