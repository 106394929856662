import { Component, OnInit } from '@angular/core';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { TaxonService } from '../services/taxon.service';

import { AppHelper } from '../helpers/app-helper';

import { Router, ActivatedRoute } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { map, switchMap, tap } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ]),
  trigger('slideUp', [
    state('void', style({
      transform: 'translateY(100%)'
    })),
    transition('void <=> *', animate('0.5s ease-in-out')),
    ])
  ]
})
export class OrderDetailsComponent implements OnInit {

  public checkoutFields: any;
  public advisories: any;

  public needsUserDetails = false;

  private merchant_slug;

  public loading = true;

  constructor(
  	public merchantService: MerchantService,
    public userService: UserService,
    public orderService: OrderService,
    public productService: ProductService,
    public taxonService: TaxonService,
    private router: Router,
    private route: ActivatedRoute,
    private appHelper: AppHelper,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private util: UtilityService) {

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        if (this.merchant_slug.indexOf('one2three') == -1) { this.merchant_slug += '.one2three.be'; }
      });

  }

  ngOnInit() {
    if (!this.userService.user) { this.needsUserDetails = true; }
    else {
      //to make sure there is no local location stored that was scanned from QR code
      this.userService.local_location = null;
    }
    this.setVenue(this.merchant_slug);
  }

  setVenue(domain) {

      this.spinner.show('orderDetails');

      this.merchantService.getMerchantByDomain(domain).pipe(
        switchMap((merchant: any) => {
            this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
            return this.appHelper.loadMerchantData(merchant.id);
           } )).subscribe(
          responseList => {
            let res = responseList[4];
            this.merchantService.merchant.menu = res['menu'];
            this.merchantService.merchant.mainCategories = res['mainCategories'];
            this.merchantService.merchant.categories = res['categories'];
            this.merchantService.merchant.products = res['products'];

            const menu_only = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only');

            if (menu_only) {

               this.onDataRequestCompleted(null);

            /*} else if (this.merchantService.merchant.settings.s_closed == true) {

              this.loading = false;
              this.spinner.hide('orderDetails');
            */

            } else {

            //does user have a basket?
            if (localStorage && localStorage.getItem('basket')) {
                this.userService.basket = JSON.parse(localStorage.getItem('basket'));
                this.userService.alterUpdateOngoing(false);
            }

            this.appHelper.initBasket().subscribe(
            response => {
              console.log(response);
              this.loading = false;
              this.spinner.hide('orderDetails');
            },
            error => {
              console.log(error);
              this.loading = false;
              this.spinner.hide('orderDetails');
            });

            }

          },
          error => {
            if (error.status == 401) {
              this.router.navigate(['/private'], { queryParams: { unauthorized: true, domain: domain } });
            } else {
              this.router.navigate(['/']);
            }
          }
        );

  }

  /*
  getVenue() {

    this.merchantService.getMerchantByDomain(this.merchantService.merchant.domain)
    .subscribe(
      merchant => {
        this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
        console.log('UPDATE MERCHANT INFO => ');
        console.log(this.merchantService.merchant);
      },
      error => {
        console.log(error);
      }


      );
  }
  */

  onDataRequestCompleted(event) {
    this.router.navigate(['../menu'], { relativeTo: this.route });
  }
  
}
