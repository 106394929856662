import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'group-invitations',
  templateUrl: './group-invitations.component.html',
  styleUrls: ['./group-invitations.component.css']
})
export class GroupInvitationsComponent implements OnInit {

  user;

  constructor(
    public userService: UserService,
    private util: UtilityService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  acceptInvitation(groupId) {
    this.userService.acceptInvitation(groupId)
    .subscribe(
      group => {
        this.userService.user.open_invited_groups = this.userService.user.open_invited_groups.filter(function( group ) {
          return group.id !== groupId;
        });
        this.util.openSnackBar(this.translate.instant('group_member.added'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('group_member.add_error'), this.translate.instant('ok'));
      }
    );
  }

  refuseInvitation(groupId) {
    this.userService.leaveGroup(groupId)
    .subscribe(
      group => {
        this.userService.user.open_invited_groups = this.userService.user.open_invited_groups.filter(function( group ) {
          return group.id !== groupId;
        });
        this.util.openSnackBar(this.translate.instant('group_invitations.refused_success'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('group_invitations.refused_error'), this.translate.instant('ok'));
      }
    );
  }

}
