import { Component, OnInit, OnDestroy, AfterViewInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationComponent } from '../order/confirmation/confirmation.component';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';

import { AppHelper } from '../helpers/app-helper';

import { Order } from '../models/order';

import Keyboard from "simple-keyboard";

import { faBells } from '@fortawesome/pro-solid-svg-icons';

declare var cordova: any;

@Component({
	selector: 'checkout-kiosk',
	templateUrl: './checkout-kiosk.component.html',
	styleUrls: ['./checkout-kiosk.component.scss']
})
export class CheckoutKioskComponent implements OnInit, OnDestroy, AfterViewInit {

  private user_loyalty_point;

	public sending = false;
  public order: Order;
  public feature: string;

	public address: any;
  public checkoutFields: any;
  public pm_on_top: boolean = true;
  public show_comment_input: boolean = true;
  public show_reference_input: boolean = true;
  public kiosk: any;
  
  public keyboard: Keyboard;
  public keyboard_input: any;

  public show_buzzer = false;
  faBells = faBells;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (this.show_buzzer) {
      if (event.keyCode == 13) {
        document.getElementById("basketbar-button").focus();
      }
    }
  }

  reference = new UntypedFormGroup(
    {
      referenceControl: new UntypedFormControl('')
    }
   )

  public referencePlaceholder = 'naam';

  private valueChangeSubscription: any;
  private basketPushedSubscription: any;

	constructor(
		public merchantService: MerchantService,
		public userService: UserService,
    private translate: TranslateService,
		private orderService: OrderService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private appHelper: AppHelper) {}

	ngOnInit() {
    this.referencePlaceholder = this.translate.instant('naam');

    if (!this.merchantService.merchant || !this.userService.basket) { this.router.navigate(['../menu'], { relativeTo: this.route }); }
    else {

      this.feature = 'final';

      this.order = this.userService.basket.order;
      this.appHelper.prepareScenario();
      this.appHelper.preparePaymentMethod();

      this.getUpdate();

      this.valueChangeSubscription = this.reference.valueChanges.subscribe(data => {
         this.userService.basket.order.reference = data.referenceControl;
         this.userService.alterUpdateOngoing(false);
     });

   this.reference.patchValue({
            referenceControl: this.userService.basket.order.reference
   });

   this.basketPushedSubscription = this.userService.basketPushed.subscribe((basket) => {
       if (!this.userService.basket) { this.router.navigate(['../menu'], { relativeTo: this.route }); }
     });

    }

    this.kiosk = this.merchantService.kiosk
    if (this.kiosk.pm_on_top) { this.pm_on_top = this.merchantService.kiosk.pm_on_top; }
    if (this.kiosk.enable_comment !== 'undefined') { 
      this.show_comment_input = this.kiosk.enable_comment; 
    }
    if (this.kiosk.enable_reference !== 'undefined') { 
      this.show_reference_input = this.kiosk.enable_reference; 
    }

    this.getCredit();

    if (this.kiosk.buzzers_enabled) {
      this.show_buzzer = true;
    }
	}

  ngOnDestroy() {
    if (this.valueChangeSubscription) this.valueChangeSubscription.unsubscribe();
    if (this.basketPushedSubscription) this.basketPushedSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    
    if (this.show_buzzer) {
      document.getElementById("buzzerInput").focus();
    }

    if (this.merchantService.merchant && this.merchantService.merchant.id === 743) {
      this.keyboard = new Keyboard({
          onChange: input => this.onChange(input),
          onKeyPress: button => this.onKeyPress(button)
        });
    }
 
  }

  setKeyboardInput(input:string) {

    if (!this.keyboard) { return };

    if (input === 'comment') { this.keyboard.setInput(this.userService.basket.order.comment); }
    else if (input) { this.keyboard.setInput(this.reference.get(input).value); }; 

    this.keyboard_input = input;
  }

  onChange = (input: string) => {
    if (this.keyboard_input === 'comment') { this.userService.basket.order.comment = input; }
    else if (this.keyboard_input) { this.reference.get(this.keyboard_input).patchValue(input); }; 
    this.userService.basket.order.reference = input;
    console.log("Input changed", input);
  };

  onKeyPress = (button: string) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  onInputChange = (event: any) => {
    console.log("INPUT CHANGE...");
    this.keyboard.setInput(event.target.value);
  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  getUpdate() {
    this.userService.alterUpdateOngoing(true);
     this.userService.saveBasketInStorage();
     this.appHelper.updateCheckoutFieldsV2().subscribe(
       res => { 
         this.userService.alterUpdateOngoing(false);
         console.log(res);

     },
       err => { console.log(err) }
     );
  }

  goToMenu(event) {
    this.router.navigate(['../menu'], { relativeTo: this.route });
  }

   getCredit() {
     if (this.userService.hasUserLoyaltyPoints() && this.merchantService.merchant) { 
       this.user_loyalty_point = this.userService.getUserLoyaltyPoint(this.merchantService.merchant.id);
     };
   }

    updateNoOfPersons(event, change) {
    if (this.userService.basket.order.no_of_persons) { this.userService.basket.order.no_of_persons += change; }
    else { this.userService.basket.order.no_of_persons = 0 + change; }
    event.stopPropagation();
  }

}
