<div  *ngIf="merchantService.merchant && merchantService.merchant.id === 743"
      class="keyboard d-flex justify-content-center align-items-center py-3"
      style="background-color: #ececec;width: 100vw;position:fixed; bottom:100px; left:0px; z-index:9999;">
  <div class="simple-keyboard"></div>
</div>

<ng-container *ngIf="feature">
  <basketbar class="d-block" [feature]="feature" [showDetails]="false" [position]="'web'"></basketbar>

  <div class="fixed_top">
    <venue-header [extra_info]="false"
                  [role]="'checkout'">
    </venue-header>
    <div class="titlebar px-3"
        [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">{{ 'checkout.header_order' | translate }}
    </div>
  </div>

  <div class="main-bgColor">

    <div class="checkout-content">

       <ng-container *ngIf="show_buzzer">
        <mat-card>
            <div class="d-flex justify-content-center">
              <img src="../../assets/Buzzer.png" style="height: 600px; width: 600px;margin-left: -5px">
              <fa-icon [icon]="faBells" class="fa-3x" style="position: absolute; top:40%"></fa-icon>
              <div class="form-container d-flex align-items-center" style="position: absolute; top:51%">
                <form #f="ngForm" [formGroup]="reference" style="width:73%">
                  <mat-form-field style="width:100%;" class="buzzer">
                    <mat-label>{{ 'kiosk.buzzer_number' | translate | capitalizeFirst }}</mat-label>
                    <input matInput 
                           id="buzzerInput"
                           type="number"
                           (focus)="setKeyboardInput('referenceControl')"
                           formControlName="referenceControl"
                           style="font-size: 4rem; text-align: center;"
                           [required]="kiosk.buzzer_required">
                  </mat-form-field>
                </form>
              </div>
            </div>
          </mat-card>
        </ng-container>

      <ng-container *ngIf="pm_on_top">

        <mat-card class="p-3 my-3 shadow-sm" 
                  *ngIf="userService.basket.order.order_type == 'personal' && userService.basket.order.price > 0
                         && userService.basket.order.payment_method != 'WALLET'">
          <payment-method (emitUpdate)="getUpdate($event)"></payment-method>
        </mat-card>        
      </ng-container>

    <ng-container *ngIf="user_loyalty_point">
       <div class="mt-3 mb-3"
          *ngIf="userService.basket.order.payment_method == 'WALLET'">
        <loyalty-card [flipped]="false" [user_loyalty_point]="user_loyalty_point" [large_screen]="true"></loyalty-card>
      </div>
    </ng-container>

      <mat-card class="shadow-sm">
        <div class="p-3" style="position:relative;">
          <order  [order]="userService.basket.order" 
                  [editable]="true" 
                  [showTotal]="true">
          </order>
        </div>
        <!--
        <div class="ps-3">
          <suggestions (productSelected)="onProductSelected($event)"></suggestions>
        </div>
      -->
      </mat-card>

      <ng-container>

        <mat-card class="shadow-sm p-3 my-3"
            *ngIf="merchantService.hasFeature(userService.basket.order.merchant_id,'f_coupons')">

          <div class="py-3" *ngIf="userService.basket.order.order_type == 'personal' || userService.basket.order.group_who_pays == 'user'">
            <voucher></voucher>
          </div>
        </mat-card>

        <mat-card class="shadow-sm p-3 my-3"
            *ngIf="merchantService.hasFeature(userService.basket.order.merchant_id,'f_loyalty_points') &&  userService.user.virtual_user">
          <div class="py-3">
            <app-input-loyalty-card></app-input-loyalty-card>
          </div>
        </mat-card>

        <mat-card   *ngIf="show_comment_input || (!show_buzzer && show_reference_input)"
                    class="shadow-sm p-3 my-3">
          <div class="subtitle">
            {{ 'checkout.extra_info' | translate }}
          </div>

          <div class="form-container" *ngIf="show_comment_input">
            <mat-form-field>
              <mat-label>{{ merchantService.merchant.comment_title }}</mat-label>
              <input    (focus)="setKeyboardInput('comment')"
                        matInput type="text"
                        [(ngModel)]="userService.basket.order.comment"
                        [required]="kiosk.comment_required">
            </mat-form-field>
          </div>

          <!--
          <div class="form-container">
            <form #f="ngForm" [formGroup]="reference">
              <mat-form-field style="width:100%;">
                <mat-label>{{ referencePlaceholder | capitalizeFirst }}</mat-label>
                <input matInput type="text" 
                      (focus)="setKeyboardInput('referenceControl')"
                      formControlName="referenceControl">
              </mat-form-field>
            </form>
          </div>
          -->

          <!--
          <div class="d-flex justify-content-center align-items-center">
           <fa-icon 
                class="fa-4x"
                size=""
                [icon]="faBells">
          </fa-icon>
          </div>

            <form #f="ngForm" [formGroup]="reference">
                <input matInput type="number" 
                      (focus)="setKeyboardInput('referenceControl')"
                      formControlName="referenceControl">
            </form>
          -->

          <ng-container *ngIf="!show_buzzer && show_reference_input">
            <div class="form-container">
              <form #f="ngForm" [formGroup]="reference">
                <mat-form-field style="width:100%;">
                  <mat-label>{{ referencePlaceholder | capitalizeFirst }}</mat-label>
                  <input matInput type="text" 
                        (focus)="setKeyboardInput('referenceControl')"
                        formControlName="referenceControl"
                        [required]="kiosk.reference_required">
                </mat-form-field>
              </form>
            </div>
          </ng-container>

        </mat-card>

      
      <!--
         <div class="py-3" *ngIf="userService.basket.order.scenario == 'eatin'">

            <mat-card>

            <div class="subtitle">
                {{ 'checkout.no_of_persons' | translate }}
            </div>

            <div class="d-flex justify-content-between align-items-center" style="max-width: 150px;">

            <div>
                    <button (click)="updateNoOfPersons($event, -1)" 
                    [disabled]="!userService.basket.order.no_of_persons || userService.basket.order.no_of_persons == 0" 
                        mat-icon-button>
                      <mat-icon  style="cursor: pointer;">remove_circle_outline</mat-icon>
                  </button>
            </div>
            
            <div style="font-size: 1.6em; text-align:center;width:30px;display:inline">
                {{ userService.basket.order.no_of_persons }}
            </div>
            
            <div>
                <button (click)="updateNoOfPersons($event, +1)" 
                    mat-icon-button>
                    <mat-icon>
                      add_circle_outline
                    </mat-icon>
                </button>
            </div>

            </div>

          </mat-card>

          </div>
        -->

        <ng-container *ngIf="!pm_on_top">
          <mat-card class="p-3 my-3 shadowed"
                    *ngIf="userService.basket.order.order_type == 'personal' && userService.basket.order.price > 0
                    && userService.basket.order.payment_method != 'WALLET'">
            <payment-method (emitUpdate)="getUpdate($event)"></payment-method>
          </mat-card>
        </ng-container>

        <div class="mb-3">
          <advisories></advisories>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>