import { Component, OnDestroy, OnInit } from '@angular/core';

import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';

import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-payconiq',
  templateUrl: './payconiq.component.html',
  styleUrls: ['./payconiq.component.css']
})
export class PayconiqComponent implements OnInit, OnDestroy {

  public payconiq: any;
  public payment: any;
  private timer: any;

  public deviceInfo = null;
  public isMobile;
  public isKiosk = false;

  constructor(	private userService: UserService,
  				private merchantService: MerchantService,
  				private orderService: OrderService,
  				private router: Router,
  				private route: ActivatedRoute,
  				private angularLocation: AngularLocation,
  				private deviceService: DeviceDetectorService,
          private util: UtilityService,
          private translate: TranslateService) {
  }

  ngOnInit() {

  	this.deviceInfo = this.deviceService.getDeviceInfo();
  	console.log(this.deviceInfo);
    this.isMobile = this.deviceService.isMobile();
    if (!this.isMobile) { this.isMobile = this.deviceService.isTablet() };
    if (this.merchantService.kiosk) { this.isMobile = false; this.isKiosk = true; }

  	if (!this.merchantService.merchant || !this.userService.basket) { 
  		this.goBack();
  	} else {
      this.userService.setPaymentOngoing(true);
  		this.payconiq = this.userService.basket.order.payconiq;
  		this.verifyPaymentStatus();
  	}
  }

  ngOnDestroy() {
  	if (this.timer) clearTimeout(this.timer);
  }

  verifyPaymentStatus() {
  	if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) this.getPaymentStatus(this.userService.basket.order.id, this.payconiq.transactionId);
  	this.timer = setTimeout(() => {
      this.verifyPaymentStatus();
 	}, 2000);
  }

  getPaymentStatus(order_id, payment_id) {
  	this.orderService.getPaymentStatus(order_id, payment_id).subscribe(
      response => {
      	this.payment = response;
      	if (this.payment.status == 'EXPIRED' ||
      		this.payment.status == 'FAILED' ||
      		this.payment.status == 'CANCELLED') this.goBack();
      	else if (this.payment.status == 'SUCCEEDED') {
          this.userService.setPaymentOngoing(false);
          if (this.userService.basket.order.device_type == 'POS') {
            //trigger for sending to the FDM
            this.userService.destroyBasket();
            this.util.openSnackBar(this.translate.instant('pos.payment_succesful'), this.translate.instant('ok'), 6000)
            this.router.navigate(['../pos'], { relativeTo: this.route })
          } else {
            this.router.navigate(['../order-status'],{ relativeTo: this.route });
          }
      	}
      },
      error => {
      	console.log("PAYMENT STATUS ERROR =>");
      	console.log(error);
      });
  }

  public toggleDevice() {
    this.isMobile = !this.isMobile;
  }

  goBack() {
    this.userService.setPaymentOngoing(false);
  	this.angularLocation.back();
  }

}
