import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { UserService} from '../../services/user.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'group-form',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})

export class GroupComponent {
  group = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
    company_name: new UntypedFormControl('', [Validators.maxLength(50)]),
    vat_number: new UntypedFormControl('', [Validators.maxLength(30)]),
    phone_number: new UntypedFormControl(''),
    estimated_number_of_users: new UntypedFormControl(''),
    remarks: new UntypedFormControl('')
  });

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<GroupComponent>,
    private util: UtilityService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  close() {
    this.dialogRef.close({ action: 'cancelled' });
  }

  onGroupFormSubmit() {
    this.userService.createGroup(this.group.value)
    .subscribe(
      group => {
        this.dialogRef.close({ action: 'success', group: group });
        this.util.openSnackBar('Group created successfully', this.translate.instant('ok'));
      },
      error => {
        console.log(error);
      }
    );
  }
}
