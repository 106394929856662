import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent }      from './main/main.component';
import { ContactComponent }      from './contact/contact.component';
import { CheckoutComponent }      from './checkout/checkout.component';
import { CheckoutKioskComponent }      from './checkout-kiosk/checkout-kiosk.component';
import { NavigationComponent }      from './navigation/navigation.component';
import { ApplyComponent }      from './apply/apply.component';
import { CategoriesComponent }      from './categories/categories.component';
import { MenuComponent }      from './menu/menu.component';
import { ReservationComponent }      from './reservation/reservation.component';
import { AboutUsComponent }      from './about-us/about-us.component';
import { ProfileComponent }      from './profile/profile.component';
import { MyGroupsComponent }      from './my-groups/my-groups.component';
import { MyOrdersComponent }      from './my-orders/my-orders.component';
import { MyLoyaltyPointsComponent }      from './my-loyalty-points/my-loyalty-points.component';
import { PrivacyPolicyComponent }      from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent }      from './terms-and-conditions/terms-and-conditions.component';
import { BasketComponent }      from './basket/basket.component';
import { VenuesComponent }      from './venues/venues.component';
import { OrderStatusComponent }      from './order-status/order-status.component';
import { OrderDetailsComponent }      from './order-details/order-details.component';
import { PaymentComponent }      from './payment/payment.component';
import { BancontactComponent }      from './bancontact/bancontact.component';
import { PayconiqComponent }      from './payconiq/payconiq.component';
import { WorldlineComponent }      from './worldline/worldline.component';
import { CCVComponent }      from './ccv/ccv.component';
import { PaymentMethodComponent }      from './payment-method/payment-method.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuestComponent } from './auth-guest/auth-guest.component';
import { AuthCovid19Component } from './covid19/covid19.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegistrationComponent } from './registration/registration.component';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { LocationComponent } from './location/location.component';
import { SettingsComponent } from './settings/settings.component';
import { FindMerchantComponent } from './find-merchant/find-merchant.component';
import { NavigatorComponent } from './navigator/navigator.component';

import { MerchantGuard } from './guards/merchant-guard.service';
import { ScenarioGuard } from './guards/scenario-guard.service';
import { UserGuard } from './guards/user-guard.service';
import { PosAppComponent } from './pos/pos-app.component';
import { LoyaltyCardDetailsComponent } from './loyalty-card-details/loyalty-card-details.component';
import { AboutFolComponent } from './about-fol/about-fol.component';
import { ContactUsDemoComponent } from './contact-us/contact-us-demo.component';
import { ContactUsSupportComponent } from './contact-us/contact-us-support.component';

const routes: Routes = [
  { path: '', component: MainComponent, data: {animation: 'RootPage'} },

  { path: 'categories', component: CategoriesComponent, data: {animation: 'CategoriesPage'} },

  { path: 'qr-scan', component: QrScanComponent, data: {animation: 'QRScanPage'} },
  { path: 'location', component: LocationComponent, data: {animation: 'LocationPage'} },

  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'signage', loadChildren: () => import('./signage/signage.module').then(m => m.SignageModule) },
  { path: 'registry', loadChildren: () => import('./registry/registry.module').then(m => m.RegistryModule) },
  { path: 'merchant-selection', loadChildren: () => import('./merchant-selection/merchant-selection.module').then(m => m.MerchantSelectionModule) },


  { path: 'navigation', component: NavigationComponent, data: {animation: 'NavigationPage'} },
  { path: 'my-groups', component: MyGroupsComponent, data: {animation: 'MyGroupsPage'} },
  { path: 'my-orders', component: MyOrdersComponent, data: {animation: 'MyOrdersPage'} },
  { path: 'my-loyalty-points', component: MyLoyaltyPointsComponent, data: {animation: 'MyLoyaltyPoints'} },
  { path: 'profile', component: ProfileComponent, data: {animation: 'ProfilePage'} },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: {animation: 'PrivacyPolicyPage'} },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: {animation: 'TermsAndConditionsPage'} },
  { path: 'venues', component: VenuesComponent, data: {animation: 'VenuesPage'} },
  { path: 'venues/:filter', component: VenuesComponent, canActivate: [UserGuard], data: {animation: 'VenuesPage'} },
  { path: 'login', component: AuthComponent, data: {animation: 'LoginPage'} },
  { path: 'private', component: AuthComponent, data: {animation: 'LoginPage'} },
  { path: 'guest', component: AuthGuestComponent,  data: {animation: 'GuestPage'} },
  { path: 'covid19', component: AuthCovid19Component,  data: {animation: 'GuestPage'} },
  { path: 'forgot-password', component: ForgotPasswordComponent,  data: {animation: 'ForgotPasswordPage'} },
  { path: 'register', component: RegistrationComponent, data: {animation: 'RegisterPage'} },
  { path: 'payment-method', component: PaymentMethodComponent, data: {animation: 'PaymentMethodPage'} },
  { path: 'about-us', component: AboutFolComponent, data: {animation: 'AboutFolPage'} },
  { path: 'contact-us-demo', component: ContactUsDemoComponent, data: {animation: 'ContactUsPage'} },
  { path: 'contact-us-support', component: ContactUsSupportComponent, data: {animation: 'ContactUsPage'} },

    //{ path: ':merchant_slug', redirectTo: ':merchant_slug/menu', pathMatch: 'full' },
    { path: ':merchant_slug', component: NavigatorComponent, data: {animation: 'NavigatorPage'} },
    { path: ':merchant_slug/settings', component: SettingsComponent, data: {animation: 'SettingsPage'} },
    { path: ':merchant_slug/product-detail', redirectTo: ':merchant_slug/menu', pathMatch: 'full' },
    { path: ':merchant_slug/apply', component: ApplyComponent, data: {animation: 'ApplyPage'} },
    { path: ':merchant_slug/order-status', component: OrderStatusComponent, data: {animation: 'OrderStatusPage'} },
    { path: ':merchant_slug/menu', component: MenuComponent, data: {animation: 'MenuPage'} },
    { path: ':merchant_slug/reservation', component: ReservationComponent, data: {animation: 'ReservationPage'} },
    { path: ':merchant_slug/about-us', component: AboutUsComponent, data: {animation: 'AboutUsPage'} },
    { path: ':merchant_slug/contact', component: ContactComponent, data: {animation: 'ContactPage'} },
    { path: ':merchant_slug/basket', component: BasketComponent, data: {animation: 'BasketPage'} },
    { path: ':merchant_slug/profile', component: ProfileComponent, data: {animation: 'ProfilePage'} },
    { path: ':merchant_slug/order-details', component: OrderDetailsComponent, data: { animation: 'OrderDetailsPage'} },
    { path: ':merchant_slug/checkout', component: CheckoutComponent, data: {animation: 'CheckoutPage'} },
    { path: ':merchant_slug/checkout-kiosk', component: CheckoutKioskComponent, data: {animation: 'CheckoutKioskPage'} },
    { path: ':merchant_slug/pos', loadChildren: () => import('./pos/pos.module').then(m => m.PosModule) },
    { path: ':merchant_slug/admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: ':merchant_slug/kitchen', loadChildren: () => import('./kitchen/kitchen.module').then(m => m.KitchenModule) },
    { path: ':merchant_slug/kds', loadChildren: () => import('./kitchen/kitchen.module').then(m => m.KitchenModule) },
    { path: ':merchant_slug/payment', component: PaymentComponent, data: {animation: 'PaymentPage'} },
    { path: ':merchant_slug/bancontact', component: BancontactComponent, data: {animation: 'BancontactPage'} },
    { path: ':merchant_slug/payconiq', component: PayconiqComponent, data: {animation: 'PayconiqPage'} },
    { path: ':merchant_slug/worldline', component: WorldlineComponent, data: {animation: 'WorldlinePage'} },
    { path: ':merchant_slug/ccv', component: CCVComponent, data: {animation: 'CCVPage'} },
    { path: ':merchant_slug/payment-method', component: PaymentMethodComponent, data: {animation: 'PaymentMethodPage'} },
    { path: ':merchant_slug/apply', component: ApplyComponent, data: {animation: 'ApplyPage'} },
    { path: ':merchant_slug/loyalty-card', component: LoyaltyCardDetailsComponent, data: {animation: 'LoyaltyCardDetailsPage'} },

    //Cannot match route ... 
    { path: '**', redirectTo: '/', pathMatch: 'full' }

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })
	],
  	exports: [
  		RouterModule
  ]
})
export class AppRoutingModule { }
