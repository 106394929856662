/* src/app/category-dialog/category-dialog.component.scss */
.category_name {
  overflow: hidden;
  word-break: break-word;
}
.svg-icon {
  padding-top: 5px;
  margin: auto;
  width: 80px;
  height: 80px;
}
.category_box {
  font-size: 60px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.3s ease-in-out;
  transition-delay: 2s;
  height: 100%;
}
.show_menu {
  width: 100%;
  font-weight: bolder;
  font-family: "Permanent marker";
  font-size: 19px;
}
/*# sourceMappingURL=category-dialog.component.css.map */
