import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-loyalty-card-details',
  templateUrl: './loyalty-card-details.component.html',
  styleUrls: ['./loyalty-card-details.component.css']
})
export class LoyaltyCardDetailsComponent {

  public user_loyalty_point: any;
  public selectedQrCode: any;
  private merchant_slug: any;
  private merchant_id: number;

  constructor(
    private userService: UserService,
    private merchantService: MerchantService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
    });
   }

  ngOnInit(): void {
    this.getMerchantAndUserLoyaltyPoint();
  }

  getMerchantAndUserLoyaltyPoint() {
    if (this.merchantService.merchant) {
      this.merchant_id = this.merchantService.merchant.id;
      this.GetUserLoyaltyPointAndQrCode();
    } else {
      this.getMerchantByDomain().subscribe(
        merchant => {
          this.merchant_id = merchant.id;
          this.GetUserLoyaltyPointAndQrCode();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  getMerchantByDomain(): any {
    const merchant = new Observable(observer => {
      this.merchantService.getMerchantByDomain(this.merchant_slug).subscribe(
        merchant => {
					observer.next(merchant);
        },
        error => {
          console.log(error);
        }
        );
      });
      
    return merchant;
  }

  GetUserLoyaltyPointAndQrCode() {
    if (this.userService.hasUserLoyaltyPoints()) { 
      this.user_loyalty_point = this.userService.getUserLoyaltyPoint(this.merchant_id);
      this.selectedQrCode = this.user_loyalty_point.qr_code;
    };
  }
}
