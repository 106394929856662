<div class="main-bgColor"
	 *ngIf="merchantService.merchant">
	<venue-header [back_goes_to]="'menu'" [extra_info]="false"></venue-header>	
	
	<div class="">

		<div 	class="titlebar px-3"
		[ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
		{{ 'about_us.general_info' | translate }}
	</div>
	<div class="container content p-3 d-flex justify-content-between">
		<div class="white-space-pre me-3 pb-3">
			<div>
				{{ merchantService.merchant.description }}
			</div>
			<div class="d-none d-lg-block pt-3">

				
				<div *ngIf="merchantService.merchant.address && merchantService.merchant.address.line1">
					<img class="me-1" width=25 src='./assets/icons/map_navbar.png'>
					<a (click)="openMaps()" class="ms-3">
						{{ merchantService.merchant.address.line1 }}, {{ merchantService.merchant.address.zipcode }} {{ merchantService.merchant.address.city }}, {{ merchantService.merchant.address.country }}
					</a>
				</div>

				<div *ngIf="merchantService.merchant.settings.s_public_phone">
					<img class="me-1" width=25 src='./assets/icons/telefoon-grijs.png'>
					<a href="tel:{{ merchantService.merchant.settings.s_public_phone }}" class="ms-3">
						{{ merchantService.merchant.settings.s_public_phone }}
					</a>
				</div>
				<div *ngIf="merchantService.merchant.settings.s_public_email">
					<img class="me-1" width=25 src='./assets/icons/mail-grijs.png'>
					<a href="mailto: {{ merchantService.merchant.settings.s_public_email }}" class="ms-3">
						{{ merchantService.merchant.settings.s_public_email }}
					</a>
				</div>

				<!--
				<div class="d-flex flex-row">
					<div style="width:25px;font-weight:700;font-family:'Roboto Condensed';">BTW</div>
					<p class="ms-3">
						!-- {{ merchantService.merchant.settings.s_vat_number }} --
						BE 0535.701.405
						</p>
				</div>
				-->

			</div>
		</div>
		<div class="d-none d-lg-block">
			<img 	width="600px"
					*ngIf="merchantService.merchant.venue_image"
					src="{{ merchantService.merchant.venue_image.large_url }}">
		</div>
	</div>

	<div class="d-block d-lg-none">
		<img 	*ngIf="merchantService.merchant.venue_image"
				src="{{ merchantService.merchant.venue_image.large_url }}"
				style="width:100%;">
	</div>

	<div class="d-block d-lg-none container content p-3">

		<div *ngIf="merchantService.merchant.address && merchantService.merchant.address.line1">
			<img class="me-1" width=25 src='./assets/icons/map_navbar.png'>
			<a (click)="openMaps()">
				{{ merchantService.merchant.address.line1 }}, {{ merchantService.merchant.address.zipcode }} {{ merchantService.merchant.address.city }}, {{ merchantService.merchant.address.country }}
			</a>
		</div>
		<div *ngIf="merchantService.merchant.settings.s_public_phone">
			<img class="me-1" width=25 src='./assets/icons/telefoon-grijs.png'>
			<a href="tel:{{ merchantService.merchant.settings.s_public_phone }}">
				{{ merchantService.merchant.settings.s_public_phone }}
			</a>
		</div>
		<div *ngIf="merchantService.merchant.settings.s_public_email">
			<img class="me-1" width=25 src='./assets/icons/mail-grijs.png'>
			<a href="mailto: {{ merchantService.merchant.settings.s_public_email }}">
				{{ merchantService.merchant.settings.s_public_email }}
			</a>
		</div>
	</div>
	<div 	class="titlebar px-3"
	[ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
	{{ 'about_us.hours' | translate }}
</div>
<div class="p-3">
	<div class="container">
		<app-contact></app-contact>
	</div>
</div>
<div 	*ngIf="hasSocialMediaData"
class="titlebar px-3"
[ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
{{ 'about_us.social' | translate }}
</div>
<div class="container content p-3 d-flex">
	<div class="p-1" 
	*ngIf="merchantService.merchant.settings.s_facebook_url && merchantService.merchant.settings.s_facebook_url.length > 1">
	<a (click)="goSocial('facebook')">
		<img width=25 src='./assets/social_media/facebook.png'>
	</a>
</div>
<div class="p-1" 
*ngIf="merchantService.merchant.settings.s_instagram_url && merchantService.merchant.settings.s_instagram_url.length > 1">
<a (click)="goSocial('instagram')">
	<img width=25 src='./assets/social_media/instagram.png'>
</a>
</div>
<div class="p-1" 
*ngIf="merchantService.merchant.settings.s_twitter_url && merchantService.merchant.settings.s_twitter_url.length > 1">
<a (click)="goSocial('twitter')">
	<img width=25 src='./assets/social_media/twitter.png'>
</a>
</div>
</div>
<div class="p-3">
</div>

</div>

</div>

<!--
<div class="main p-3">

	<app-navbar></app-navbar>

	<div class="row">
		<div class="col-12 col-md-4 pb-3">

			<mat-card>
				<mat-card-content>
					{{ merchantService.merchant.address.line1 }} <br/>
			{{ merchantService.merchant.address.zipcode }} {{ merchantService.merchant.address.city }}<br/>
			{{ merchantService.merchant.address.country }}<br/><br/>
			{{ merchantService.merchant.settings.s_public_phone }}<br/>
			{{ merchantService.merchant.settings.s_public_email }}
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12 col-md-8">
			<mat-card style="color: black; background-color: white;">
				<h2>{{ 'about_us.over' | translate }} {{ merchantService.merchant.name }}</h2>
				<mat-card-content>
					<span class="white-space-pre">{{ merchantService.merchant.description }}</span>
				</mat-card-content>
				<img *ngIf="merchantService.merchant.venue_image"
					 mat-card-image src="{{ merchantService.merchant.venue_image.large_url }}">
			</mat-card>
		</div>
	</div>

</div>

-->