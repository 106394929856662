import { Component, OnInit, NgZone } from '@angular/core';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

declare var dataLayer: Array<any>;
declare var cordova: any;
declare var device: any;

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  public hasSocialMediaData = false;
  private merchant_slug: any;

  constructor(public merchantService: MerchantService,
              public userService: UserService,
              public router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private titleService: Title,
              private metaTagService: Meta) {


        this.route.params.subscribe(
          params => {
            this.merchant_slug = params['merchant_slug'];
        });
  
  }

  ngOnInit() {
  	this.getVenue();

    dataLayer.push({
      'screenPath': 'about',
      'screenName': 'About Us'
    });
    dataLayer.push({'event': 'appScreenView'});

    dataLayer.push({
      'appEventCategory': 'about',
      'appEventAction': 'click',
      'appEventLabel': 'about button'
    });
    dataLayer.push({'event': 'appEvent'});

  }

  getVenue() {

		this.merchantService.getMerchantByDomain(this.merchant_slug)
		.subscribe(
			merchant => {
				this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
        
        if (this.merchantService.merchant.settings.s_facebook_url && this.merchantService.merchant.settings.s_facebook_url.length > 1) { this.hasSocialMediaData = true; }
        else if (this.merchantService.merchant.settings.s_instagram_url && this.merchantService.merchant.settings.s_instagram_url.length > 1) { this.hasSocialMediaData = true; }
        else if (this.merchantService.merchant.settings.s_twitter_url && this.merchantService.merchant.settings.s_twitter_url.length > 1) { this.hasSocialMediaData = true; }        


        //set title and meta tags
        this.titleService.setTitle(this.merchantService.merchant.name);
        this.metaTagService.updateTag(
          { name: 'description', content: this.merchantService.merchant.description }
        );

			},
			error => {
				console.log(error);
			}
			);
	}

  goSocial(social) {

    let href =  "https://" + this.merchantService.merchant.settings.s_facebook_url;
    if (social == 'instagram') { href =  "https://" + this.merchantService.merchant.settings.s_instagram_url; }
    else if  (social == 'twitter') { href =  "https://" + this.merchantService.merchant.settings.s_twitter_url; }

    if (window.hasOwnProperty('cordova')) { cordova.InAppBrowser.open(href, '_system'); }
    else { window.location.href = href; }
  }

  openMaps() {

    if (typeof device !== 'undefined' && device.platform == 'iOS') {
      let href = "maps://?q=" + this.merchantService.merchant.address.latitude + "," + this.merchantService.merchant.address.longitude;
      cordova.InAppBrowser.open(href, '_system'); 
    } else if (typeof device !== 'undefined' && device.platform == 'Android') {
      let href =  "geo:0,0?q=" + this.merchantService.merchant.address.latitude + "," + this.merchantService.merchant.address.longitude;    
      cordova.InAppBrowser.open(href, '_system'); 
    } else { 
      let href = "https://maps.google.com/?q=" + this.merchantService.merchant.address.latitude + "," + this.merchantService.merchant.address.longitude;
      window.open(href,'_blank');
    }
  }

}
