/* src/app/about-us/about-us.component.scss */
.main-bgColor {
  min-height: 100vh;
}
body {
  background-color: transparent !important;
}
.white-space-pre {
  white-space: pre-line;
}
/*# sourceMappingURL=about-us.component.css.map */
