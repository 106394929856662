import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationComponent } from '../order/confirmation/confirmation.component';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';

import { AppHelper } from '../helpers/app-helper';
import { StylingHelper } from '../helpers/styling-helper';

import { Order } from '../models/order';

import { UtilityService } from '../services/utility.service';

import { NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../../environments/environment';

declare var cordova: any;

@Component({
	selector: 'checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  public template: string;
  public whitelabel = "guide";

  private merchant_slug;

	public sending = false;
  public order: Order;
  public feature: string;

	public address: any;
  public checkoutFields: any;

  public basketHeight = 'calc(100vh - 350px)';

  reference = new UntypedFormGroup(
    {
      commentControl: new UntypedFormControl('', []),
      referenceControl: new UntypedFormControl('', [])
    }, { updateOn: 'change' }
   )

  public referencePlaceholder = 'naam';
  public commentPlaceholder = 'comment';

  private valueChangeSubscription: any;

  public fol = false;

	constructor(
		public merchantService: MerchantService,
		public userService: UserService,
    private translate: TranslateService,
		private orderService: OrderService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilityService,
    private spinner: NgxSpinnerService,
    private appHelper: AppHelper,
    private stylingHelper: StylingHelper) {

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
      });

     this.whitelabel = environment.whitelabel;
     this.fol = this.appHelper.isFol();

  }

  ngOnInit() {

    this.referencePlaceholder = this.translate.instant('naam');

    try {

      if (!this.merchantService.merchant || !this.userService.basket ||this.userService.basket.order.order_lines.length == 0) { 
        this.router.navigate(['../menu'], { relativeTo: this.route }); 

      } else {

        this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);
        this.appHelper.verifyBasketOrderState(this.merchant_slug);

        this.basketHeight = this.appHelper.getBasketHeight();

        this.order = this.userService.basket.order;
        this.appHelper.prepareScenario();

        if (this.userService.basket.order.group_order && this.userService.basket.order.group_who_pays == 'user') { 
          this.feature = 'final'; 
        } else if (this.userService.basket.order.order_type == 'personal' || this.userService.basket.order.group_who_pays == 'user') { 
          this.feature = 'payment';
          
          //extra verification to possibly skip the payment screen
          if ( this.userService.basket.checkoutFields.payment_method.length ==1 && 
              !this.merchantService.hasFeature(this.userService.basket.order.merchant_id,'f_coupons')) {
            const fixed_payment_method = this.userService.basket.checkoutFields.payment_method[0];
            if (fixed_payment_method.value == "CASH" && fixed_payment_method.is_supported && fixed_payment_method.is_enabled) {
              this.userService.basket.order.payment_method = "CASH";
              this.feature = 'final';
            }
          }

          //extra verification to skip the payment screen for waiter
          let employee_role = this.userService.user.roles.filter(role => { return role.typecode == 'merchant_employee' && role.merchant_id ==  this.userService.basket.order.merchant_id } );
          if (this.userService.basket.order.scenario == "waiter") {
             this.userService.basket.order.payment_method = "CASH";
             this.feature = 'final';
          }

        } else { 
          this.feature = 'final'; 
        }

     
        if (this.merchantService.merchant.settings.s_reference_required) {
          this.reference.get('referenceControl').markAsTouched();
          this.reference.get('referenceControl').setValidators([Validators.required]);
        }

        this.commentPlaceholder = this.merchantService.merchant.comment_title;
        let comment_characters = 50;
        if (this.merchantService.merchant.settings.s_comment_characters) { comment_characters = this.merchantService.merchant.settings.s_comment_characters; };
        
        let validators = [];

        this.reference.get('commentControl').markAsTouched();
        
        if (this.merchantService.merchant.settings.s_comment_enabled && this.merchantService.merchant.settings.s_comment_required) {
          validators.push(Validators.required);
        }

        if (this.merchantService.merchant.settings.s_comment_enabled) {
            this.commentPlaceholder += ` (max ${comment_characters} ${this.translate.instant('characters')})`;
        }

        if (this.merchantService.merchant.settings.s_comment_characters) { validators.push(Validators.maxLength(this.merchantService.merchant.settings.s_comment_characters)); }
        else { validators.push(Validators.maxLength(50)); };
      
        this.reference.get('commentControl').setValidators(validators);

        this.valueChangeSubscription = this.reference.valueChanges.subscribe(data => {
          this.userService.basket.order.reference = data.referenceControl;
          this.userService.basket.order.comment = data.commentControl;
          this.userService.alterUpdateOngoing(false);
        });

        this.reference.patchValue({
          referenceControl: this.userService.basket.order.reference,
          commentControl: this.userService.basket.order.comment
        });

      }

    } catch (err) {
      console.log(err);
      this.router.navigate(['../menu'], { relativeTo: this.route });
    }

  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) this.valueChangeSubscription.unsubscribe();
  }

  getUpdate(received: boolean) {
     this.userService.saveBasketInStorage();
     this.userService.alterUpdateOngoing(true);
     this.appHelper.updateCheckoutFieldsV2().subscribe(
      respone => {
        this.userService.alterUpdateOngoing(false);
      },
      error => {
        this.userService.alterUpdateOngoing(false);
      });
  }

  goToMenu(event) {
    this.router.navigate(['../menu'], { relativeTo: this.route });
  }

   updateNoOfPersons(event, change) {
    if (this.userService.basket.order.no_of_persons) { this.userService.basket.order.no_of_persons += change; }
    else { this.userService.basket.order.no_of_persons = 0 + change; }
    event.stopPropagation();
  }


}
