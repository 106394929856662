<!-- FADED BACKGROUND -->
<div 
	class="faded-bg" 
	(click)="backgroundClicked()"
	*ngIf="selectedProduct" 
	[@fadeInOut]>
</div>

<!-- SPINNER -->
<ngx-spinner name="menu" type="ball-pulse"></ngx-spinner>

<!-- SELECTED PRODUCT -->

<div *ngIf="selectedProduct" 
class="selected-product mat-app-background rounded">

<product 	[category]="selectedCategory" 
					[product]="selectedProduct" 
					(canceled)="onProductCanceled($event)" 
					(updateOngoing)="onUpdateOngoing($event)" 
					(dataRequested)="onShowDataRequestScreen($event)" 
					[dataNeeded]="needsUserDetails || needsOrderDetails">
</product>

</div>

<!-- DATA REQUEST SCREEN -->
<!--
<div *ngIf="data_request_screen_shown" 
class="selected-product mat-app-background rounded"
style="min-height:300px;">

<div class="first-part" style="height:100%;">
	<data-request 
	[needsUserDetails]="needsUserDetails"
	[needsOrderDetails]="needsOrderDetails"
	(completed)="onDataRequestCompleted($event)"
	(updated)="onDataRequestUpdated($event)"
	></data-request>
</div>


</div>
-->

<!-- MOBILE SEARCH -->

<div class="d-block d-lg-none filter-button-container"
	 [ngStyle]="{'bottom': userService.user && userService.basket && basketHasContent ? '70px' : '20px' }"
	 *ngIf="mobileSearchEnabled && !selectedProduct && !data_request_screen_shown && !needsKioskDetails"
	 [@fadeInOut]>
        <button mat-mini-fab 
        		color="primary" 
        		aria-label="Search button"
        		(click)="toggleMobileSearch()">
          <mat-icon>search</mat-icon>
        </button>
</div>

<!-- KIOSK DETAILS -->

<div *ngIf="mobileSearch && mobileSearchEnabled && !selectedProduct && !data_request_screen_shown && !needsKioskDetails"
	 style="padding:5px; margin: 0px; position:fixed;right:70px;z-index:9998;background-color:white; border-radius: 5px; box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);"
	 [ngStyle]="{'bottom': userService.user && userService.basket && basketHasContent ? '75px' : '25px' }"
	 [@fadeInOut]>
				<input 	matInput 
					type="search" 
					placeholder="{{ 'i_search' | translate }}"
					[ngbTypeahead]="search"
					[(ngModel)]="searchInitText"
					style="width:100%" 
					#mobileProductSearch>
			</div>


<div 	*ngIf="needsKioskDetails && merchantService.merchant" 
		class="kiosk-details"
		[style.background]="'url(' + background_image + ') center center / cover'"
		[@slideUp]>

		<!--
		<video width="1080" height="1920" controls autoplay muted loop id="backgroundVideo">
 			<source src="assets/backgrounds/brasserieone2three.mp4" type="video/mp4">
	  </video>
	 -->
	 
  <div class="d-flex flex-column justify-content-between" style="width:100vw;height:100vh"
			[ngStyle]="{'visibility': printer_issue ? 'visible' : 'hidden'}">
  	
  	<div style="position: fixed; bottom: 20px; right: 20px; font-size:40px;">
  		<div style="font-weight: 'bold'">Deze kiosk is tijdelijk buiten gebruik.</div>
  		<div>{{ printer_issue_description }}</div>
  	</div>

  </div>		

		<div class="d-flex flex-column justify-content-between" style="width:100vw;height:100vh;position:absolute;top:0;left:0"
		[ngStyle]="{'visibility': printer_issue ? 'hidden' : 'visible'}">
			<div 	class="kiosk-part-1 d-flex flex-column justify-content-center align-items-end" 
					style="flex-grow:1;">
				<app-language-selection></app-language-selection>
				<div class="highlight"
					 [ngStyle]="{'background-color': hexToRgbA(merchantService.merchant.primary_color) }">

					 <strong>{{ merchantService.merchant.name }}</strong>
				</div>

				<div class="clickable highlight" #kioskOrderHere
						(click)="selectOneScenario()">
						{{ 'kiosk.order_here' | translate | uppercase }}
				</div>
				
				<div class="my-4 d-flex flex-row">

					<div 	class="scenario_selector ms-4 clickable"
								[ngStyle]="{'background-color': hexToRgbA(merchantService.merchant.primary_color)}"
								style=""
								(click)="updateKioskOrder('takeaway')"
								*ngIf="merchantService.merchant.settings.s_scenario_takeaway_enabled">
						<div>
							<img height="100" src="./assets/scenarios/guide/takeaway.png">
						</div>
						<div>
							{{ 'scenario.takeaway_kiosk' | translate | capitalizeFirst }}
						</div>
					</div>

					<div 	class="scenario_selector ms-4 clickable"
								[ngStyle]="{'background-color': hexToRgbA(merchantService.merchant.primary_color)}"
								(click)="updateKioskOrder('eatin')"
								*ngIf="merchantService.merchant.settings.s_scenario_eatin_enabled">
						<div>
							<img height="100" src="./assets/scenarios/guide/eatin.png">
						</div>
						<div>
								{{ 'scenario.eatin' | translate | capitalizeFirst  }}
							</div>
					</div>

				</div>

				<div class="my-4 d-flex flex-row">

					<div class="scenario_selector ms-4 clickable"
								style="font-size:1em;"
								[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
								(click)="sendNumberOrder()"
								*ngIf="merchantService.kiosk.modes.includes('counter')">
						<div>{{ 'kiosk.order_at_counter' | translate }}</div>
						<div style="font-size:0.5em;">{{ 'kiosk.click_for_number' | translate }}</div>
					</div>

				</div>

			</div>

			<div 	
					style="height:300px;flex-grow:0;flex-shrink:0">
				<div class="d-flex flex-column justify-content-between align-items-center" style="height:100%">
										
					<div class="m-3"
							 style="flex-grow:1;text-align:center;">
						<img class="kiosk-logo" src="{{ merchantService.merchant.image?.large_url }}">

					</div>

					<div class="d-flex justify-content-end h-100 w-100 px-3 m-3">
						<ng-container *ngIf="payment_methods.length > 0; else onlyCash;">
								<div 
										class="d-flex py-1 justify-content-end align-items-center">
									<div	class="d-flex align-items-center" 	
												style="padding-left:50px;height:100%; text-align: right;"
									 			*ngFor="let payment_method of payment_methods">
								 		<img class="payment-method_img" src='./assets/payment_methods/{{ payment_method.value }}.png'>
									</div>
								</div>
							</ng-container>
					</div>
						
				</div>
			</div>
			<div 	class="kiosk-part-3"
					style="height:200px;flex-grow:0;flex-shrink:0"
					[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}">
				<div class="d-flex justify-content-between align-items-center" style="height:100%">
					<div style="flex-grow:1;text-align:center;">
						<img class="kiosk-logo" src="{{ merchantService.merchant.image?.large_url }}">
					</div>
					
					<div 	*ngIf="merchantService.kiosk.modes.includes('counter')"
								class="clickable" (click)="sendNumberOrder()" style="width:70vw;text-align:center;">
						<div class="pr-3 kiosk-start">
							{{ 'kiosk.order_at_counter' | translate | uppercase }}
						</div>
						<div class="pr-3 font-dosis kiosk-number" style="font-size:1.2em; text-align: right;">
							{{ 'kiosk.click_for_number' | translate }}
						</div>
					</div>			
		</div>

</div>

</div>

</div>

<ng-container *ngIf="template == 'template1'">

	<!-- MOBILE BASKET BAR -->
<basketbar  
class="d-block d-lg-none"
*ngIf="basketHasContent" 
[position]="'web'" 
[feature]="basketFeature" 
(animationStateChanged)="onAnimationStateChanged($event)">
</basketbar>

<div *ngIf="!headerLoading" class="fixed_top" [@fadeInOut]>
	<venue-header 
			[back_shown]="!userService.user || (backGoesTo || !userService.user.virtual_user)"
			[back_goes_to]="backGoesTo" (back_clicked)="backClicked()">
	</venue-header>
</div>

<div  class="category-mainbox"
			*ngIf="!headerLoading && !loading && mainCategories && mainCategories.length > 1 ">

<mat-icon *ngIf="searchText.length == 0 && selectedCategoryIndex > 0"
	class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
	(click)="moveCategory(-1)" 
	style="left: 0"
	[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil }"
	[@fadeInOut]>
	keyboard_arrow_left
</mat-icon>

<mat-icon 	*ngIf="searchText.length == 0 && selectedCategoryIndex < mainCategories.length - 1"
class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
(click)="moveCategory(+1)" 
style="right: 0;"
[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
[@fadeInOut]>
keyboard_arrow_right
</mat-icon>

<div 	#categorybar 
		class="categorybar no_selection" 
		[ngStyle]="{'opacity': categoryScreen == true || searchText.length > 0 ? 0 : 1, 'background-color':  !fol && merchantService.merchant.primary_color}"
		[@fadeInOut]>

	<div class="list-thumbnail"
		 [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}">
		
		<ng-container *ngFor="let sameLevelTaxon of mainCategories">

		<div 
			  class="img-thumb clickable no_selection" 
			  [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}" 
			  (click)="clickTaxon(sameLevelTaxon)">
			<div 	id="mainCategory_{{ sameLevelTaxon.id }}"
					class="py-1 px-3">
				<div 	id="{{ sameLevelTaxon.name }}" 
							[ngStyle]="{ 'color': fol ? '#fdd329' : nil }"
							[ngClass]="{ 'selected': selectedCategory && sameLevelTaxon.id == selectedCategory.id }"
						class="category font_secondary">
					{{ sameLevelTaxon.name  }}
				</div>
			</div>
		</div>

		</ng-container>

	</div>
</div>

</div>

<img hidden 
*ngIf="merchantService.merchant && merchantService.merchant.header_image"
src="{{ merchantService.merchant.header_image.medium_url }}">

<div class="main">
	<div *ngIf="!loading && !headerLoading" [@fadeInOut] class="main">

		<div class="menu">

		<div 	class="py-3 px-3"
				*ngIf="searchText.length > 0">
		</div>

		<div *ngFor="let category of menu; index as i">
			<category 	[searchText]="searchText" 
						[taxon]="category" 
						(productSelected)="onProductSelected(category, $event)"
						*ngIf="category.is_enabled">
			</category>
			<div *ngFor="let level1 of category.subcategories; index as j">
				<category 	[searchText]="searchText"  
							[taxon]="level1" 
							(productSelected)="onProductSelected(level1, $event)"
							*ngIf="level1.is_enabled">
				</category>
				<div *ngFor="let level2 of level1.subcategories; index as k">
					<category 	[searchText]="searchText" 
								[taxon]="level2" 
								(productSelected)="onProductSelected(level2, $event)" 
								(appear)="taxonAppears(level2)"
								*ngIf="level2.is_enabled">
					</category>
				</div>
			</div>
		</div>

		<div *ngIf="['xs','sm'].includes(breakpoint)">
			<app-advertisements [location]="'Menu, tbd exact location (2)'"></app-advertisements>
		</div>

	</div>

	<div *ngIf="['lg','xl'].includes(breakpoint)">
		<div class="template1 basket" [@fadeInOut]>
	
			<div class="template1 search_area">
			<mat-form-field class="full-width">
    			<mat-label>{{ 'i_search' | translate }}</mat-label>
				<input 	matInput 
					type="text" 
					[ngbTypeahead]="search"
					[(ngModel)]="searchInitText"
					style="width:100%" 
					#productSearch>
					<button *ngIf="searchInitText" matSuffix mat-icon-button aria-label="Clear" (click)="clearText()">
			    	<mat-icon>clear</mat-icon>
			  </button>
				<mat-icon class="clickable" matSuffix *ngIf="!searchInitText">search</mat-icon>
				</mat-form-field>
			</div>

			<div *ngIf="!menu_only && userService.user && userService.basket"
				 class="template1 basket-main-content shadow p-3 mt-3"
				 [ngStyle]="{'max-height': basketHeight }">

				<div class="basket_title">
					{{ 'basket.title' | translate }}
				</div>

				<div *ngIf="!basketHasContent">
					{{ 'basket.empty' | translate }}
				</div>

				<!--
				<div class="mb-2" 
						 style="font-size:0.8em;"
						 *ngIf="userService.basket && userService.basket.checkoutFields">

					<scenario *ngIf="userService.basket && userService.basket.checkoutFields"
										[condensed]=true
										(emitUpdate)="getUpdate($event)">
					</scenario>

					<div 	class="py-1" *ngIf="userService.basket && userService.basket.checkoutFields && userService.basket.order.scenario != 'waiter'"
							[@fadeInOut]>
						<timing 
							[condensed]=true
							(emitUpdate)="getUpdate($event)">
						</timing>
				
					</div>

				</div>
				-->


				<div class="basket-content">
					<order
					[order]="userService.basket.order"
					(updated)="onDataRequestUpdated($event)">
				</order>
			</div>

		</div>

		<basketbar  
		*ngIf="userService.user && userService.basket && basketHasContent" 
		[position]="'relative'" 
		[feature]="basketFeature" 
		(animationStateChanged)="onAnimationStateChanged($event)"
		>
	</basketbar>

				<level-of-activity>
				</level-of-activity>

	<div class="mt-2">
		<advisories 	*ngIf="!menu_only && userService.user && userService.basket && userService.basket.checkoutFields">
		</advisories>
	</div>

	<div class="mt-2">
		<notification></notification>
	</div>
			<app-advertisements [location]="'Menu, tbd exact location (3)'"></app-advertisements>
		</div>

	</div>
</div>

</div>

</ng-container>














<ng-container *ngIf="template == 'template1'">

	<!-- MOBILE BASKET BAR -->
<basketbar  
class="d-block d-lg-none"
*ngIf="basketHasContent" 
[position]="'web'" 
[feature]="basketFeature" 
(animationStateChanged)="onAnimationStateChanged($event)">
</basketbar>

<!--
<div 	*ngIf="categoryScreen && merchantService.merchant"
		class="category-screen"
		style="background-color:white;"
		[@slideUp]>
	<venue-header 
		[back_goes_to]="backGoesTo" 
		[back_shown]="!userService.user || (backGoesTo || !userService.user.virtual_user)"
		(back_clicked)="backClicked()"></venue-header>

	<div *ngIf="['xs','sm'].includes(breakpoint)">
		<app-advertisements [location]="'Menu, tbd exact location (1)'"></app-advertisements>
	</div>

	<div class="container">
	<div class="row">
		
		<div class="col-3" *ngFor="let sameLevelTaxon of mainCategories">


			<div 
			class="clickable no_selection" 
			(click)="changeTaxon(sameLevelTaxon)">
			<div style="margin: auto; margin-top:10px; margin-bottom:5px;padding: 5px; border-radius: 5px; border-color: white; width:40px; height: 40px;"
			[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}">
				<img width=30 height=30 src='{{  sameLevelTaxon.icon.svg_url }}'>
			</div>
			<div 	id="mainCategory_{{ sameLevelTaxon.id }}">
			<div 	id="{{ sameLevelTaxon.name }}" 
			class=""
			style="font-size: 12px; text-align: center;">
			{{ sameLevelTaxon.name  }}
			</div>
			</div>
			</div>
		</div>
	</div>
	</div>

</div>
-->

<div *ngIf="!headerLoading" class="fixed_top" [@fadeInOut]>
	<venue-header 
			[back_shown]="!userService.user || (backGoesTo || !userService.user.virtual_user)"
			[back_goes_to]="backGoesTo" (back_clicked)="backClicked()">
	</venue-header>
</div>

<!--
<div  class="category-mainbox"
			*ngIf="!headerLoading && !loading && mainCategories && mainCategories.length > 1 ">

<mat-icon *ngIf="searchText.length == 0 && selectedCategoryIndex > 0"
	class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
	(click)="moveCategory(-1)" 
	style="left: 0"
	[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil }"
	[@fadeInOut]>
	keyboard_arrow_left
</mat-icon>

<mat-icon 	*ngIf="searchText.length == 0 && selectedCategoryIndex < mainCategories.length - 1"
class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
(click)="moveCategory(+1)" 
style="right: 0;"
[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
[@fadeInOut]>
keyboard_arrow_right
</mat-icon>

<div 	#categorybar 
		class="categorybar no_selection" 
		[ngStyle]="{'opacity': categoryScreen == true || searchText.length > 0 ? 0 : 1, 'background-color': !fol && merchantService.merchant.primary_color}"
		[@fadeInOut]>

	<div class="list-thumbnail"
		 [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}">
		
		<ng-container *ngFor="let sameLevelTaxon of mainCategories">

		<div 
			  class="img-thumb clickable no_selection" 
			  [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}" 
			  (click)="clickTaxon(sameLevelTaxon)">
			<div 	id="mainCategory_{{ sameLevelTaxon.id }}"
					class="py-1 px-3">
				<div 	id="{{ sameLevelTaxon.name }}" 
						[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
						[ngClass]="{ 'selected': selectedCategory && sameLevelTaxon.id == selectedCategory.id }"
						class="category font_secondary">
					{{ sameLevelTaxon.name  }}
				</div>
			</div>
		</div>

		</ng-container>

	</div>
</div>

</div>

<img hidden 
*ngIf="merchantService.merchant && merchantService.merchant.header_image"
src="{{ merchantService.merchant.header_image.medium_url }}">

<div class="main">
	<div *ngIf="!loading && !headerLoading" [@fadeInOut] class="main">

		<div class="menu">

		<div 	class="py-3 px-3"
				*ngIf="searchText.length > 0">
		</div>

		<div *ngFor="let category of menu; index as i">
			<category 	[searchText]="searchText" 
						[taxon]="category" 
						(productSelected)="onProductSelected(category, $event)"
						*ngIf="category.is_enabled">
			</category>
			<div *ngFor="let level1 of category.subcategories; index as j">
				<category 	[searchText]="searchText"  
							[taxon]="level1" 
							(productSelected)="onProductSelected(level1, $event)"
							*ngIf="level1.is_enabled">
				</category>
				<div *ngFor="let level2 of level1.subcategories; index as k">
					<category 	[searchText]="searchText" 
								[taxon]="level2" 
								(productSelected)="onProductSelected(level2, $event)" 
								(appear)="taxonAppears(level2)"
								*ngIf="level2.is_enabled">
					</category>
				</div>
			</div>
		</div>

		<div *ngIf="['xs','sm'].includes(breakpoint)">
			<app-advertisements [location]="'Menu, tbd exact location (2)'"></app-advertisements>
		</div>

	</div>


	<div *ngIf="['lg','xl'].includes(breakpoint)">
		<div class="template1 basket" [@fadeInOut]>
			
			<div class="template1 search_area">
			<mat-form-field class="full-width">
    			<mat-label>{{ 'i_search' | translate }}</mat-label>
				<input 	matInput 
					type="text" 
					[ngbTypeahead]="search"
					[(ngModel)]="searchInitText"
					style="width:100%" 
					#productSearch>
					<button *ngIf="searchInitText" matSuffix mat-icon-button aria-label="Clear" (click)="clearText()">
			    	<mat-icon>clear</mat-icon>
			  </button>
				<mat-icon class="clickable" matSuffix *ngIf="!searchInitText">search</mat-icon>
				</mat-form-field>
			</div>

			<div *ngIf="!menu_only && userService.user && userService.basket"
				 class="template1 basket-main-content shadow p-3 mt-3"
				 [ngStyle]="{'max-height': basketHeight }">

				<div class="basket_title">
					{{ 'basket.title' | translate }}
				</div>

				<div *ngIf="!basketHasContent">
					{{ 'basket.empty' | translate }}
				</div>

				<div class="basket-content">
					<order
					[comment_editable]="comment_editable"
					[order]="userService.basket.order"
					(updated)="onDataRequestUpdated($event)">
				</order>
			</div>

		</div>

		<basketbar  
		*ngIf="userService.user && userService.basket && basketHasContent" 
		[position]="'relative'" 
		[feature]="basketFeature" 
		(animationStateChanged)="onAnimationStateChanged($event)"
		>
	</basketbar>

				<level-of-activity>
				</level-of-activity>

	<div class="mt-2">
		<advisories 	*ngIf="!menu_only && userService.user && userService.basket && userService.basket.checkoutFields">
		</advisories>
	</div>

	<div class="mt-2">
		<notification></notification>
	</div>
			<app-advertisements [location]="'Menu, tbd exact location (3)'"></app-advertisements>
		</div>

	</div>

</div>

</div>
-->

</ng-container>



<!-- TEMPLATE 2 -->
<ng-container *ngIf="template == 'template2'">

	<!-- MOBILE BASKET BAR -->
<basketbar  
class="d-block d-lg-none"
*ngIf="basketHasContent" 
[position]="'web'" 
[feature]="basketFeature" 
(animationStateChanged)="onAnimationStateChanged($event)">
</basketbar>

<div *ngIf="mobileSearch && mobileSearchEnabled && !selectedProduct && !data_request_screen_shown && !needsKioskDetails"
style="padding:5px; margin: 0px; position:fixed;right:70px;z-index:9998;background-color:white; border-radius: 5px; box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);"
[ngStyle]="{'bottom': userService.user && userService.basket && basketHasContent ? '75px' : '25px' }"
[@fadeInOut]>
<input 	matInput 
type="search" 
placeholder="{{ 'i_search' | translate }}"
[ngbTypeahead]="search"
[(ngModel)]="searchInitText"
style="width:100%; border:none;" 
#mobileProductSearch>
</div>

<div *ngIf="!headerLoading" class="fixed_top" [@fadeInOut]>
	<venue-header 
		[back_goes_to]="backGoesTo" 
		[back_shown]="!userService.user || (backGoesTo || !userService.user.virtual_user)"
		(back_clicked)="backClicked()">
	</venue-header>
</div>

<div  class="d-block d-lg-none category-mainbox"
			*ngIf="!headerLoading && !loading && mainCategories && mainCategories.length > 1 ">

<mat-icon *ngIf="searchText.length == 0 && selectedCategoryIndex > 0"
	class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
	(click)="moveCategory(-1)" 
	style="left: 0"
	[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
	[@fadeInOut]>
	keyboard_arrow_left
</mat-icon>

<mat-icon 	*ngIf="searchText.length == 0 && selectedCategoryIndex < mainCategories.length - 1"
class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
(click)="moveCategory(+1)" 
style="right: 0;"
[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
[@fadeInOut]>
keyboard_arrow_right
</mat-icon>


<div 	#categorybar 
class="categorybar no_selection"  
[ngStyle]="{ 'background-color': !fol &&  merchantService.merchant.primary_color}"
[@fadeInOut2]="categoryScreen == true || searchText.length > 0 ? 'invisible' : 'visible'">

<div class="list-thumbnail"
[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}">

<ng-container *ngFor="let sameLevelTaxon of mainCategories">

	<div 
	class="img-thumb clickable no_selection" 
	[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}" 
	(click)="clickTaxon(sameLevelTaxon)">
	<div 	id="mainCategory_{{ sameLevelTaxon.id }}"
	class="py-1 px-3">
	<div 	id="{{ sameLevelTaxon.name }}" 
	[ngStyle]="{ 'color': fol ? '#fdd329' : nil }"
	[ngClass]="{ 'selected': selectedCategory && sameLevelTaxon.id == selectedCategory.id }"
	class="category font_secondary">
	{{ sameLevelTaxon.name  | capitalizeFirst }}
</div>
</div>
</div>

</ng-container>

</div>
</div>

</div>

<img hidden 
*ngIf="merchantService.merchant && merchantService.merchant.header_image"
src="{{ merchantService.merchant.header_image.medium_url }}">

<div class="main">
	<div *ngIf="!loading && !headerLoading" [@fadeInOut] class="main mobile-adjuster">

		<div class="menu">

			<!-- THE MAIN MENU PART -->
			<div class="d-flex flex-colum justify-content-start">

				<div style="width:240px;"
						 *ngIf="['lg','xl'].includes(breakpoint)">

					<div class="menu-positioner">

					 <div class="d-flex justify-content-center mb-2">
        		<img src="{{ merchantService.merchant.image?.medium_url }}">
     			</div>

					<div class="menu-container">

						<div class="basket-title w-100 py-1 px-2 mb-3"
			 		style="border-radius: 5px;">
						Menu
					</div>

						<div class="template2 search_area mt-3 py-1 px-3">
			<mat-form-field class="full-width">
				<mat-label>{{ 'i_search' | translate }}</mat-label>
				<input 	matInput 
				type="text" 
				[ngbTypeahead]="search"
				[(ngModel)]="searchInitText"
				style="width:100%" 
				#productSearch>
				<button *ngIf="searchInitText" matSuffix mat-icon-button aria-label="Clear" (click)="clearText()">
					<mat-icon>clear</mat-icon>
				</button>
				<mat-icon class="clickable"matSuffix *ngIf="!searchInitText">search</mat-icon>
			</mat-form-field>
		</div>

					<div 	class="py-3 px-1"
							style="border-radius: 0px 0px 5px 5px; overflow-y: scroll; max-height: 40vw;"
							*ngIf="searchText.length == 0"
							[@fadeInOut]>

				<ng-container *ngFor="let category of menu; index as i">
					<div class="ps-1" *ngFor="let level1 of category.subcategories; index as j">
						
						<div 	class="clickable ps-3" 
								(click)="clickTaxon(level2)"
								*ngFor="let level2 of level1.subcategories; index as k"
								[ngClass]="{ 'selected-left': selectedCategory && level2.id == selectedCategory.id }"
								[ngStyle]="{'border-color': !fol ? merchantService.merchant.primary_color : 'rgb(253, 211, 41)' }">
							<ng-container
								*ngIf="!level2.empty && level2.is_enabled">
								{{ level2.name | capitalizeFirst }}
							</ng-container>
						</div>
					</div>
				</ng-container>

					</div>








					</div>

					

				

	</div>

				</div>

				<!-- CATEGORY TEST -->

				<div 	#categoryOverviewContainer
						class="categoryOverviewContainer"
						[ngStyle]="{'height.px': categoryScreen ? category_overview_height : auto}">

				<div style="margin-bottom: 20px;">

				<div 	*ngFor="let category of menu; index as i">
					<category 	[searchText]="searchText" 
					[index]="i" 
					[taxon]="category" 
					(productSelected)="onProductSelected(category, $event)"
					*ngIf="category.is_enabled">
					</category>
					<div *ngFor="let level1 of category.subcategories; index as j">
						<category 	[searchText]="searchText"  
						[index]="i+j" 
						[taxon]="level1" 
						(productSelected)="onProductSelected(level1, $event)"
						*ngIf="level1.is_enabled">
						</category>
						<ng-container *ngFor="let level2 of level1.subcategories; index as k">	
							<div *ngIf="merchantService.merchant.products.length < 400 || (selectedCategory && level2.index == selectedCategory.index) || searchText.length > 0">
							<category 	
							id="{{ level2.name }}"
							[searchText]="searchText" 
							[index]="i+j+k" 
							[taxon]="level2" 
							(productSelected)="onProductSelected(level2, $event)" 
							(appear)="taxonAppears(level2)"
							*ngIf="level2.is_enabled">
							</category>
						</div>
						</ng-container>
					</div>
				</div>

				</div>

					<div
							 *ngIf="['xs','sm'].includes(breakpoint) && !categoryScreen && !basketHasContent"
							 style="z-index: 499;position:fixed; bottom: 0px; left: 0; bottom: 0">
						<app-advertisements [location]="'Menu, mobile'"></app-advertisements>
					</div>
							
				</div>

		</div>

</div>

<div class="template2 basket mb-2"
	 *ngIf="['lg','xl'].includes(breakpoint) && !basketHasContent"
	 [@fadeInOut]>

	 <div class="mb-3"
	 	  *ngIf="loyalty_enabled && this.userService.user && this.loyalty_points">
	 	 <loyalty-card [flipped]="false" [user_loyalty_point]="this.loyalty_points" [large_screen]="true"></loyalty-card>
	 </div>
	
	<div 	class="mb-2"
			*ngIf="merchantService.merchant.promotion && merchantService.merchant.promotion.android_enabled">
		<merchant-promotion>
		</merchant-promotion>
	</div>

	<!-- advertisement categorie, full screen -->
	<app-advertisements [location]="'Categories, desktop'"></app-advertisements>

</div>

<div class="template2 basket"
	 *ngIf="['lg','xl'].includes(breakpoint) && basketHasContent"
	 [@fadeInOut]>

		<div *ngIf="!menu_only && userService.user && userService.basket"
			 class="template2 basket-main-content"
			 [ngStyle]="{'max-height': basketHeight }"
			 [@fadeInOut]>

			 <div class="basket-title w-100 py-1 px-2"
			 style="border-radius: 5px;"
			 [@fadeInOut]>
			{{ 'basket.title' | translate }}
		</div>

		<div 	class="p-2"
				*ngIf="!basketHasContent">
			{{ 'basket.empty' | translate }}
		</div>

		<!--
		<div class="mb-2" 
						 style="font-size:0.8em;"
						 *ngIf="userService.basket && userService.basket.checkoutFields">

					<scenario *ngIf="userService.basket && userService.basket.checkoutFields"
										[condensed]=true
										(emitUpdate)="getUpdate($event)">
					</scenario>

					<div 	class="py-1" *ngIf="userService.basket && userService.basket.checkoutFields && userService.basket.order.scenario != 'waiter'"
							[@fadeInOut]>
						<timing 
							[condensed]=true
							(emitUpdate)="getUpdate($event)">
						</timing>
				
					</div>

		</div>
		-->

		<div class="basket-content p-2"
			 *ngIf="basketHasContent">
			<order 
				[order]="userService.basket.order"
				[comment_editable]="comment_editable"
				(updated)="onDataRequestUpdated($event)">
			</order>
		</div>

		</div>

		<div class="mt-2 mb-2">
			<basketbar  
				*ngIf="userService.user && userService.basket && basketHasContent" 
				[position]="'relative'" 
				[feature]="basketFeature" 
				[backButtonShown]="false"
				(animationStateChanged)="onAnimationStateChanged($event)"
			>
			</basketbar>
		</div>

		<level-of-activity>
		</level-of-activity>

		<advisories 	*ngIf="!menu_only && userService.user && userService.basket && userService.basket.checkoutFields"
						[@fadeInOut]>
		</advisories>

		<div class="mt-2">
			<notification></notification>
		</div>

		<app-advertisements [location]="'Menu, desktop'"></app-advertisements>
	
</div>

</div>

</div>

</ng-container>


<ng-container *ngIf="template == 'template_kiosk'">

			<!-- MOBILE BASKET BAR -->
		<basketbar  
			class="d-block"
			*ngIf="basketHasContent" 
			[position]="'web'" 
			[feature]="basketFeature" 
			(animationStateChanged)="onAnimationStateChanged($event)">
		</basketbar>

		<div *ngIf="mobileSearch && mobileSearchEnabled && !selectedProduct && !data_request_screen_shown && !needsKioskDetails"
				style="padding:5px; margin: 0px; position:fixed;right:70px;z-index:9998;background-color:white; border-radius: 5px; box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);"
				[ngStyle]="{'bottom': userService.user && userService.basket && basketHasContent ? '75px' : '25px' }"
				[@fadeInOut]>
			<input 	matInput 
						type="search" 
						placeholder="{{ 'i_search' | translate }}"
						[ngbTypeahead]="search"
						[(ngModel)]="searchInitText"
						style="width:100%" 
						#mobileProductSearch>
		</div>

		<div *ngIf="!headerLoading" class="fixed_top" [@fadeInOut]>
			<venue-header 
				[back_goes_to]="backGoesTo" 
				[back_shown]="true"
				(back_clicked)="backClicked()">
			</venue-header>
		</div>

		<div  class="d-block d-lg-none category-mainbox"
					*ngIf="!headerLoading && !loading && mainCategories && mainCategories.length > 1 ">

			<mat-icon *ngIf="searchText.length == 0 && selectedCategoryIndex > 0"
				class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
				(click)="moveCategory(-1)" 
				style="left: 0"
				[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
				[@fadeInOut]>
				keyboard_arrow_left
			</mat-icon>

			<mat-icon 	*ngIf="searchText.length == 0 && selectedCategoryIndex < mainCategories.length - 1"
			class="cat-nav-v2 white-icon bg_primary clickable no_selection" 
			(click)="moveCategory(+1)" 
			style="right: 0;"
			[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
			[@fadeInOut]>
			keyboard_arrow_right
			</mat-icon>

			<div 	#categorybar 
			class="categorybar no_selection" 
			[ngStyle]="{ 'background-color': !fol && merchantService.merchant.primary_color}"
			[@fadeInOut2]="categoryScreen == true || searchText.length > 0 ? 'invisible' : 'visible'">

				<div class="list-thumbnail"
				[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}">

					<ng-container *ngFor="let sameLevelTaxon of mainCategories">

						<div 
						class="img-thumb clickable no_selection" 
						[ngStyle]="{'color': whitelabel !== 'fol' ? 'white' : '#fdd329', 'background-color': whitelabel !== 'fol' && merchantService.merchant.primary_color}" 
						(click)="clickTaxon(sameLevelTaxon)">
							<div 	id="mainCategory_{{ sameLevelTaxon.id }}"
										class="py-1 px-3">
								<div 	id="{{ sameLevelTaxon.name }}" 
											[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
											[ngClass]="{ 'selected': selectedCategory && sameLevelTaxon.id == selectedCategory.id }"
											class="category font_secondary">
									{{ sameLevelTaxon.name  | capitalizeFirst }}
								</div>
							</div>
						</div>

					</ng-container>
				</div>
			</div>
		</div>

	<img hidden 
	*ngIf="merchantService.merchant && merchantService.merchant.header_image"
	src="{{ merchantService.merchant.header_image.medium_url }}">

	<div class="main">
		<div *ngIf="!loading && !headerLoading" [@fadeInOut] class="main mobile-adjuster">

			<div class="menu">

				<!-- THE MAIN MENU PART -->
				<div class="d-flex flex-colum justify-content-start">
					<div style="width:240px;"
							*ngIf="['lg','xl'].includes(breakpoint)">
						<div class="menu-positioner">

							<div 	class="row g-0">
								<div 	class="category-col col-12"
										*ngFor="let category of mainCategories; let index=index"
										(click)="clickTaxon(category, true)">
									<div class="m-2">
										<div class="menu-card shadow-sm d-flex flex-column justify-content-between h-100">
											<div style="height:100px">
												
												<div style="border-radius: 5px 5px 0px 0px; transition: all 0.3s ease-in-out; transition-delay: 4.5s; height: 100%;"
														*ngIf="category.background_url"
														[ngStyle]="{ 'background-image': 'url(' + category.background_url + ')', 'background-position': 'center', 'background-size': 'cover' }">
												</div>

												<div style="background-color: white; border-radius: 5px; transition: all 0.3s ease-in-out; transition-delay: 2s; height: 100%;"
														*ngIf="!category.background_url">
														<p 	 class="svg-icon"
															[ngStyle]="{'fill': !fol && merchantService.merchant.primary_color}"
															[innerHTML]="category.icon_data">
														</p>
												</div>
											</div>
											<div 	class="menu-background bg_primary d-flex flex-column justify-content-center align-items-center" style="color:white;border-radius: 0px 0px 5px 5px;height:55px; text-align:center;line-height:1.0"
												[ngStyle]="{'color': fol ? '#fdd329' : nil, 'background-color': !fol && merchantService.merchant.primary_color}">
												<div class="p-1">
													{{ category.name | capitalizeFirst }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>				
						</div>
					</div>

					<!-- CATEGORY TEST -->
<!--
					<div 	#categoryOverviewContainer
							class="categoryOverviewContainer"
							[ngStyle]="{'height.px': categoryScreen ? category_overview_height : auto}">

						<div style="margin-bottom: 20px;">
							
							<div 	*ngFor="let category of menu; index as i"
									[ngStyle]="{'opacity': categoryScreen ? 0 : 1 }">
								<category [searchText]="searchText" 
													[index]="i" 
													[taxon]="category" 
													(productSelected)="onProductSelected(category, $event)"
													*ngIf="category.is_enabled">
								</category>
								<div *ngFor="let level1 of category.subcategories; index as j">
									<category [searchText]="searchText"  
										[index]="i+j" 
										[taxon]="level1" 
										(productSelected)="onProductSelected(level1, $event)"
										*ngIf="level1.is_enabled">
									</category>
									<div *ngFor="let level2 of level1.subcategories; index as k">
										<category id="{{ level2.name }}"
														[searchText]="searchText" 
														[index]="i+j+k" 
														[taxon]="level2" 
														(productSelected)="onProductSelected(level2, $event)" 
														(appear)="taxonAppears(level2)"
														*ngIf="level2.is_enabled && selectedCategory && level2.id == selectedCategory.id">
										</category>
									</div>
								</div>
							</div>
						</div>

						<div class="mt-2"
								*ngIf="['xs','sm'].includes(breakpoint)">
							<app-advertisements [location]="'Menu, tbd exact location (4)'"></app-advertisements>
						</div>
						<div style="height:40vh;">
						</div>
					
					</div>

-->

				</div>
			</div>

			<div class="template2 basket mb-2"
				*ngIf="['lg','xl'].includes(breakpoint) && !basketHasContent"
				[@fadeInOut]>

				<div class="mb-3"
						*ngIf="loyalty_enabled && this.userService.user && this.userService.user.user_loyalty_points &&  this.userService.user.user_loyalty_points.length > 0">
					<loyalty-card [flipped]="false" [user_loyalty_point]="userService.user.user_loyalty_points[0]" [large_screen]="true"></loyalty-card>
				</div>
				
				<div 	class="mb-2"
						*ngIf="merchantService.merchant.promotion && merchantService.merchant.promotion.android_enabled">
					<merchant-promotion></merchant-promotion>
				</div>

				<app-advertisements [location]="'Menu, tbd exact location (5)'"></app-advertisements>

			</div>

		</div>

	</div>

</ng-container>



















