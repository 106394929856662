<div class="row g-0" style="max-height:65vh; overflow: auto;">

<!--
	<basketbar  
[position]="'relative'" 
[feature]="'menu'" 
(animationStateChanged)="onAnimationStateChanged($event)">
</basketbar>
-->
	
	<div  class="col-12"
				*ngIf="merchantService.merchant.settings.s_closed || userService.user || data.needsUserDetails">
	<data-request 
	[show_basketbar]="false"
	[needsUserDetails]="data.needsUserDetails"
	[needsOrderDetails]="data.needsOrderDetails"
	(completed)="onDataRequestCompleted($event)"
	(updated)="onDataRequestUpdated($event)"
	></data-request>

</div>

<div class="col-12"
		 *ngIf="data.categories_shown">

	<div class="container my-2">

		<div class="menu-blocks row g-0">

			<div 	class="mx-1 my-3"
			*ngIf="['xs','sm'].includes(breakpoint) && userService.basket && userService.basket.order && userService.basket.order.scenario == 'table' && merchantService.merchant.promotion && merchantService.merchant.promotion.android_enabled">
			<merchant-promotion>
			</merchant-promotion>
		</div>

		<div 	class="clickable category-col col-6 col-lg-4"
		*ngFor="let category of data.mainCategories; let index=index"
		(click)="clickTaxon(category, true)">
		<div class="m-1">
			<div class="menu-card shadow-sm d-flex flex-column justify-content-between h-100">
				<div style="height:100px">

					<div style="border-radius: 5px 5px 0px 0px; transition: all 0.3s ease-in-out; transition-delay: 4.5s; height: 100%;"
					*ngIf="category.background_url"
					[ngStyle]="{ 'background-image': 'url(' + category.background_url + ')', 'background-position': 'center', 'background-size': 'cover' }">
				</div>

				<div 
				class="d-flex justify-content-center category_box"
				[ngStyle]="{'background-color': !fol ? 'white' : '#fdd329'}"
				*ngIf="!category.background_url">

				<p 	 class="svg-icon"
						 [ngStyle]="{'fill': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#f0513f'}"
						 [innerHTML]="category.icon_data">
			</p>

		</div>

	</div>
	<div 	class="menu-background bg_primary d-flex flex-column justify-content-center align-items-center" 
	style="color:white;border-radius: 0px 0px 5px 5px;height:55px; text-align:center;line-height:1.0"
	[ngStyle]="{'background-color': !fol ? 'white' : '#fdd329','color': fol ? '#f0513f' : 'white'}">
	<div class="p-1 category_name">{{ category.name | capitalizeFirst }}</div>
</div>
</div>
</div>
</div>
</div>



</div>

</div>	

</div>

<mat-dialog-actions align="end"
										*ngIf="userService.user">
		<button mat-button [mat-dialog-close]="true" 
					class="show_menu"
					[ngStyle]="{'color': fol ? '#f0513f' : nil, 'background-color': fol ? '#fdd329' : nil }"
					>Bekijk menu
		</button>
</mat-dialog-actions>