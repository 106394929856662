/* src/app/checkout-kiosk/checkout-kiosk.component.scss */
.checkout-radio-group {
  display: inline-flex;
  flex-direction: column;
}
.checkout-radio-button {
  margin: 5px;
}
mat-card {
  margin-bottom: 10px;
}
.mat-stepper-horizontal {
  background: none !important;
}
.mat-select-content {
  width: 250px;
}
mat-form-field {
  width: 250px;
}
.non-clickable {
  pointer-events: none;
}
.main-bgColor {
  min-height: 100vh;
  min-height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
.checkout-content {
  position: relative;
  min-height: 70vh;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 200px;
}
.fixed_top {
  position: sticky;
  width: 100vw;
  top: -114px;
  z-index: 998;
}
.title {
  font-weight: bolder;
  font-size: 1.5em;
}
.category {
  color: white;
  font-size: 1.4em;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.simple-keyboard {
  max-width: 850px;
}
/*# sourceMappingURL=checkout-kiosk.component.css.map */
