<!--
			<button (click)="prepare()">QR Scanner</button>
			<button (click)="show()">QR Show</button>
			<button (click)="scan()">QR Scan</button>
			-->

			<div class="p-1" style="margin:20px; border-radius: 5px; background-color: darkred; color:white; text-align:center">
				<ng-container *ngIf="action == 'loyalty'">Klantenkaart koppelen met jou account</ng-container>
				<ng-container *ngIf="action == 'merchant'">Scan de QR code van de zaak</ng-container>
			</div>

			<button style="position: fixed; bottom: 20px; left: 20px" mat-flat-button color="primary" (click)="backClicked()">
				{{ 'qrscan.back' | translate }}
			</button>