import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tagFilter',
    pure: false
})

export class TagFilterPipe implements PipeTransform {
        transform(items: any): any {
        	return items.filter(function( tag ) {
               return tag != 'fol' && tag != 'myfoodorder' && tag != 'table123' && tag != 'frietjesonline' && tag != 'v2.0' && tag != 'setup' && tag != 'guide' && tag != 'myfoodorder' && tag != 'cadixfood' && tag != 'foottie' && tag != 'foodmaker' && tag != 'industrie313' && tag != 'frituurconnyenfranky';
          	});
          }
}
