import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { AppHelper } from '../helpers/app-helper';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-input-loyalty-card',
  templateUrl: './input-loyalty-card.component.html',
  styleUrls: ['./input-loyalty-card.component.scss']
})
export class InputLoyaltyCardComponent {

  public logo: any;
  public background_url = "assets/backgrounds/empty.png";
  public loyalty_points: number;
  public loyalty_card_qr_code: string;

  constructor (
    public merchantService: MerchantService,
    public userService: UserService,
    private translate: TranslateService,
    private orderService: OrderService,
    private util: UtilityService,
    private spinner: NgxSpinnerService,
    private appHelper: AppHelper) { }

  ngOnInit() {
    this.logo = './assets/logos/' + environment.whitelabel + '.png';
    if (this.merchantService.merchant.header_image) { this.background_url = this.merchantService.merchant.header_image.large_url; };
    this.getLoyaltyPoints(this.userService.basket.order.id);
  }

  getLoyaltyPoints(order_id) {
    this.orderService.getLoyaltyPoints(order_id, this.loyalty_card_qr_code).subscribe(
      response => {
        this.loyalty_points = +response.points;
        this.loyalty_points = Math.floor(this.loyalty_points);
      },
      error => {
        console.log(error);
      },
      () => {});
  }

  checkIfSix() {
    if (this.loyalty_card_qr_code.match(/^\d{6}$/)) {
      this.addLoyaltyCardNumber();
    }
  }

  addLoyaltyCardNumber() {
      this.userService.basket.order.loyalty_card_qr_code = this.loyalty_card_qr_code;
      this.getLoyaltyPoints(this.userService.basket.order.id);
  }

  removeLoyaltyCardNumber() {
    this.userService.basket.order.loyalty_card_qr_code = null
    this.updateOrder();
  }

  updateOrder() {
    this.userService.alterUpdateOngoing(true);
    this.spinner.show('order');
    this.orderService.updateOrder(this.userService.getBasket().order, false).subscribe(
      (res: any) => {
        this.appHelper.setBasketOrder(res, this.merchantService.merchant);
      },
      err => {
        console.log(err);
      },
      () => {
        this.userService.alterUpdateOngoing(false);
        this.spinner.hide('order');
      }
    );
  }
}
