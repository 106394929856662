export class User {


  	    public id: number;
        public email: string;
        public username: string;

        public is_anonymous_user: boolean;
        public locale: string;
        public phone: string;
        public preferrred_group_id: number;
        public preferred_group_type: string;

        public roles: any;

        public group_member: any;
        public group_orders: any;
        public groups: any;
        public my_group_order: any;

        public points: number;
        public token: boolean;
        public user_invitations: any;

        public users_group: any;
        public users_group_members: any;

        public webpush_endpoint: any;
        public webpush_auth: any;
        public webpush_p256dh: any;

        public virtual_user: boolean = false;
        public is_waiter: boolean = false;
        public waiter_role: any;

        public user_loyalty_points: any;

  constructor() {}

}
