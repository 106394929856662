import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PhoneNumberUtil, PhoneNumber,PhoneNumberType } from 'google-libphonenumber';

import { PubnubService } from '../services/pubnub.service';

import { UserService} from '../services/user.service';
import { UtilityService } from '../services/utility.service';
import { MerchantService } from '../services/merchant.service';

import { Location } from '@angular/common';

import { AppHelper } from '../helpers/app-helper';

import {environment} from '../../environments/environment';

import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';

export interface Hash { name: string; value: string; }

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent {

  public background_repeat = environment.background_repeat;
  public background_size = environment.background_size;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  //TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Belgium, CountryISO.Netherlands];

  public background_image: string;

  public domain: string;
  public scenario: string;
  public logo: string;

  passwordMismatch;
  errors = {};

  languages: Hash[] = [
    { name: this.translate.instant('english'), value: 'en' },
    { name: this.translate.instant('french'), value: 'fr' },
    { name: this.translate.instant('nederlands'), value: 'nl' }
  ];

  user = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(/.+@.+\..+/)]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      password_confirmation: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl(undefined, [Validators.required, this.phoneValidator]),
      locale: new UntypedFormControl('nl', [Validators.required]),
      policyApproved: new UntypedFormControl(false, [Validators.required, Validators.requiredTrue])
    }
  );

  public fol = false;

  phoneValidator(control: any) {

    try {
      let parsedValue = null;
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      if (control.value) parsedValue = phoneNumberUtil.parseAndKeepRawInput(control.value.internationalNumber);
      if (!parsedValue || !phoneNumberUtil.isValidNumber(parsedValue) || phoneNumberUtil.getNumberType(parsedValue) != PhoneNumberType.MOBILE) return { mobileNumber: 'no' }
      else return null;
    } catch (e) {
      return { mobileNumber: 'no' };
    }

  }

  constructor(
    private userService: UserService,
    public merchantService: MerchantService,
    private util: UtilityService,
    private router: Router,
    private route:ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private appHelper: AppHelper,
    private pubnubService: PubnubService
  ) {
    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {

    let whitelabel = environment.whitelabel;
    if (this.fol) { whitelabel = 'fol' };

    this.user.controls.locale.setValue(this.translate.currentLang);

    this.user.valueChanges.subscribe(form => {
      this.checkPasswords();
    });

    this.background_image = 'assets/backgrounds/' + whitelabel + '.jpg';
    this.logo = 'assets/logos/' + whitelabel + '.png';

     this.route.queryParams
      .subscribe(params => {
        if (params.domain) this.domain = params.domain;
        if (params.scenario) this.scenario = params.scenario;
      });

  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }


  checkPasswords() {
    this.passwordMismatch = this.user.value.password != this.user.value.password_confirmation;
    if (this.passwordMismatch) {
      this.user.controls.password_confirmation.setErrors({'incorrect': true});
    }
    else {
     this.user.controls.password_confirmation.setErrors(null);
    }
  }

  register() {
    const user_submit = JSON.parse(JSON.stringify(this.user.value));
    user_submit.email = user_submit.email.replace(/\s/g, '').toLowerCase().trim();
    user_submit.phone = this.user.value.phone.internationalNumber.replace(/\s/g, '');

    console.log(user_submit);

    this.userService.registerUser({ user: user_submit })
    .subscribe(
      user => {
        this.util.openSnackBar(this.translate.instant('user.register_success'), this.translate.instant('ok'));
        const credentials = {
          username: this.user.value.email,
          password: this.user.value.password,
          grant_type: 'password'
        };
        this.loginUser(credentials);
      },
      error => {
        console.log(error.error);
        this.setError(error.error, 'email');
        this.util.openSnackBar(error.error.email[0], this.translate.instant('ok'));
      }
    );
  }

  loginUser(credentials) {
    this.userService.onLoginFormSubmit(credentials)
    .subscribe(
      auth => {
        if (sessionStorage) sessionStorage.setItem('ngStorage-token', JSON.stringify(auth));
        if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
        this.userService.access_token = auth.access_token;
        this.getProfile();
      },
      error => {
        this.util.openSnackBar(this.translate.instant('user.login_error'), this.translate.instant('ok'));
      }
    );
  }

  getProfile() {

    this.userService.getProfile()
    .subscribe(
      user => {
        this.userService.setUser(user);
        this.appHelper.initSentryUser(user);
        
        let is_reservation = false;
        if (this.scenario == 'reservation') is_reservation = true;

        if (this.domain) { 
            this.appHelper.setVenue(this.domain, null, is_reservation); 
        } else if (this.merchantService.merchant) {
          this.appHelper.setVenue(this.merchantService.merchant.domain, this.userService.local_location, is_reservation);
        } else {
          this.router.navigate(['/']);
        }  

      },
      error => {
        console.log(error);
      }
      );

  }

  setError(error, attr) {
    this.errors[attr] = [attr, error[attr][0]].join(' ');
    this.user.controls[attr].setErrors({'incorrect': true});
  }

  goBack() {
    console.log('BACK CLICKED!');
    this.location.back();
  }

  changeLanguage() {
    this.translate.use(this.user.controls.locale.value);
  }

}
