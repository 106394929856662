import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class ClosedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
