<div class="container py-2 px-0" style="margin:auto;">

    <div class="first-part">
        <app-navbar [frontColor]="'darkGrey'" [locationShown]="false"></app-navbar>
    </div>

    <p class="title">{{ 'navigation.loyalty_points' | translate }}</p>

    <!-- Large screens -->

    <div class="d-block m-3">
        <ng-container *ngTemplateOutlet="explanation; context:{}"></ng-container>
    </div>

    <div class="d-block container mt-3">

        <div class="row">

            <div class="col-12 col-md-6 col-xl-4 my-3" *ngIf="user_loyalty_points.length == 0">
                <loyalty-card [user_loyalty_point]="empty_user_loyalty_point" [large_screen]="true">
                </loyalty-card>
            </div>

            <div *ngFor="let user_loyalty_point of user_loyalty_points; let i = index"
                class="col-12 col-md-6 col-xl-4 my-3">
                <loyalty-card [max_index]="max_index" (max_index_changed)="max_index = $event" [flipped]="flipped"
                    (flipped_changed)="flipped = $event" [user_loyalty_point]="user_loyalty_point"
                    [large_screen]="true"></loyalty-card>
            </div>

        </div>

        <div class="mt-3" *ngIf="user_loyalty_points.length > 0">
            <ng-container *ngTemplateOutlet="infoCard; context:{}"></ng-container>
        </div>

    </div>

</div>

<ng-template #infoCard>
    <fa-icon class="pe-1" [icon]="faInfoCircle">
    </fa-icon>
    {{ 'loyalty_points.click_for_info' | translate }}
</ng-template>

<ng-template #explanation>

    <div class="p-3 card">

        <ng-container *ngIf="translate.currentLang == 'nl'">

            <strong>Wat zijn getrouwheidspunten?</strong>
            Elke keer wanneer je bestelt bij een handelaar dewelke een klantenkaart aanbiedt via zijn/haar bestelsite of
            -app ontvang je getrouwheidspunten. Je kan deze getrouwheidspunten inwisselen voor kortingen, gratis
            producten of andere aanbiedingen.
            <br><br>
            <strong>Hoeveel getrouwheidspunten krijg ik?</strong>
            Het aantal getrouwheidspunten dat je ontvangt per bestelling of bij specifiek product hangt af van handelaar
            tot handelaar.
            <br><br>
            <strong>Hoe wissel ik mijn getrouwheidspunten in?</strong>
            In het menu van jouw handelaar vind je een categorie ‘Beloningen’. Bij elk van deze beloningen kun je zien
            hoeveel getrouwheidspunten je nodig hebt om er gebruik van te maken.
            <br><br>
            <strong>Hoe weet ik hoeveel getrouwheidspunten ik heb?</strong>
            Klik op de respectievelijke klantenkaart om te weten hoeveel getrouwheidspunten je momenteel hebt. Je kan
            hier eveneens de volledige historiek zien van de gespaarde en verzilverde punten.

            <br><br>
            <ng-container *ngIf="isCordovaApp()">
                <strong>Klantenkaart koppelen met jouw account</strong><br>
                <button class="my-1" mat-raised-button type="submit" color="primary" [routerLink]="'/qr-scan'"
                    [queryParams]="{ action: 'loyalty'}">Activeer nieuwe klantenkaart</button>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="translate.currentLang == 'en'">

            <strong>What are loyalty points?</strong>
            Every time you order from a merchant who offers a loyalty card through his / her ordering site or app, you
            will receive loyalty points. You can exchange these loyalty points for discounts, free products or other
            offers.
            <br><br>
            <strong>How many loyalty points do I get?</strong>
            The number of loyalty points that you receive per order or with a specific product depends from merchant to
            merchant.
            <br><br>
            <strong>How do I redeem my loyalty points?</strong>
            In the menu of your merchant you will find a category "Rewards". With each of these rewards you can see how
            many loyalty points you need to use them.
            <br><br>
            <strong>How do I know how many loyalty points I have?</strong>
            Click on the respective loyalty card to find out how many loyalty points you currently have. You can also
            see the full history of the points saved and redeemed here.
            <br><br>

            <ng-container *ngIf="isCordovaApp()">
                <strong>Link loyalty card with your account</strong><br>
                <button class="my-1" mat-raised-button type="submit" color="primary" [routerLink]="'/qr-scan'"
                    [queryParams]="{ action: 'loyalty'}">Activate new loyalty card</button>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="translate.currentLang == 'fr'">

            <strong>Que sont les points de fidélité?</strong>
            Chaque fois que vous commandez chez un commerçant qui propose une carte de fidélité via son site de commande
            ou son application, vous recevrez des points de fidélité. Vous pouvez échanger ces points de fidélité contre
            des remises, des produits gratuits ou d'autres offres.
            <br><br>
            <strong>Combien de points de fidélité puis-je obtenir?</strong>
            Le nombre de points de fidélité que vous recevez par commande ou pour un produit spécifique dépend d'un
            commerçant à l'autre.
            <br><br>
            <strong>Comment puis-je échanger mes points de fidélité?</strong>
            Dans le menu de votre commerçant, vous trouverez une catégorie «Récompenses». Avec chacune de ces
            récompenses, vous pouvez voir le nombre de points de fidélité dont vous avez besoin pour les utiliser.
            <br><br>
            <strong>Comment savoir combien de points de fidélité j'ai?</strong>
            Cliquez sur la carte de fidélité correspondante pour connaître le nombre de points de fidélité dont vous
            disposez actuellement. Vous pouvez également consulter l'historique complet des points enregistrés et
            échangés ici.
            <br><br>

            <ng-container *ngIf="isCordovaApp()">
                <strong>Associez la carte de fidélité à votre compte</strong><br>
                <button class="my-1" mat-raised-button type="submit" color="primary" [routerLink]="'/qr-scan'"
                    [queryParams]="{ action: 'loyalty'}">Activer une nouvelle carte de fidélité</button>
            </ng-container>

        </ng-container>

    </div>

</ng-template>

<!--
    <div class="mt-3 box" 
            [@slideInOut]="user_loyalty_point.animationState">

        
        <div class="row" style="width:100%;">
            <div    class="col-xxs-6 col-md-6 col-lg-3"
                    *ngFor="let product of user_loyalty_point.products">
                <div class="reward-card d-flex flex-column justify-content-between"
                 
                 (click)="appHelper.setVenue(user_loyalty_point.merchant.domain)">
                <div style="font-weight:bold; font-size:1.2em;">
                    {{ product.name }}
                </div>
                <div class="pb-1 text-center">
                    <img    *ngIf="product.main_image"
                            src="{{ product.main_image.medium_url }}"
                            style='max-height:150px; max-width:100%;'>
                </div>
                <div class="">
                    HERE
                    <h6><span   class="badge" 
                                style="color: white;"
                                [ngStyle]="{'background-color': user_loyalty_point.merchant.primary_color ? user_loyalty_point.merchant.primary_color : '#ad151c'}">
                        <fa-icon
                            class="pe-1" 
                            [icon]="faCoin">
                        </fa-icon>
                        {{ product.loyalty_points }}
                    </span></h6>
                </div>
                <div>
                    {{ product.description }}
                </div>
                </div>

            </div>
        </div>
        
     </div>
 -->