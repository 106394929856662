<ng-container *ngIf="feature">

  <basketbar class="d-block d-lg-none" [feature]="feature" [showDetails]="false" [position]="'web'"></basketbar>


  <div class="d-none d-lg-block">
    <div class="{{ template }} basket" *ngIf="userService.basket">
      <div  class="{{ template }} basket-main-content shadow p-3"
            [ngStyle]="{'max-height': basketHeight }">
        <div class="title">
          {{ 'checkout.basket_title' | translate }}
        </div>
        <div class="basket-content">
          <order  [editable]="false"
                  [order]="userService.basket.order"></order>
        </div>
        
      </div>

      <div class="mt-2">

      <basketbar  
      [position]="'relative'" 
      [feature]="'checkoutWeb'">
    </basketbar>

  </div>

    <div *ngIf="!editable" (click)="goToMenu($event)">
      <a class="link font-dosis">{{ 'checkout.back_to_menu' | translate }}</a>
    </div>

  </div>
</div>

<div class="fixed_top">
  <venue-header [extra_info]="false"></venue-header>
  <div class="titlebar px-3"
       [ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">{{ 'checkout.header_order' | translate }}
  </div>
</div>

<div class="main-bgColor">

  <div class="checkout-content {{ template }}-checkout-content">

    <ng-container>

      <div class="py-3" *ngIf="!merchantService.kiosk && userService.basket.order.scenario != 'table'">
        <scenario (emitUpdate)="getUpdate($event)">
        </scenario>
      </div>

      <div class="py-3" *ngIf="userService.basket.order.scenario == 'waiter'">

      <div class="subtitle">
          {{ 'checkout.no_of_persons' | translate }}
      </div>

      <div class="d-flex justify-content-between align-items-center" style="max-width: 150px;">

      <div>
              <button (click)="updateNoOfPersons($event, -1)" 
              [disabled]="!userService.basket.order.no_of_persons || userService.basket.order.no_of_persons == 0" 
                  mat-icon-button>
                <mat-icon  style="cursor: pointer;">remove_circle_outline</mat-icon>
            </button>
      </div>
      
      <div style="font-size: 1.6em; text-align:center;width:30px;display:inline">
          {{ userService.basket.order.no_of_persons }}
      </div>
      
      <div>
          <button (click)="updateNoOfPersons($event, +1)" 
              mat-icon-button>
              <mat-icon>
                add_circle_outline
              </mat-icon>
          </button>
      </div>

      </div>

      </div>

      <div class="py-3" *ngIf="!merchantService.kiosk">
        <timing (emitUpdate)="getUpdate($event)"></timing>
      </div>

      <form #f="ngForm" 
            [formGroup]="reference">

          <div class="subtitle">
            {{ 'checkout.extra_info' | translate }}
          </div>

      <div class="form-container font-dosis" *ngIf="this.merchantService.merchant.settings.s_comment_enabled">
        <mat-form-field style="width:100%;">
          <mat-label>{{ commentPlaceholder | capitalizeFirst }}</mat-label>
          <input  matInput 
                  type="text" 
                  formControlName="commentControl">
        </mat-form-field>
      </div>

      <div class="form-container font-dosis">
          <mat-form-field style="width:100%;">
          <mat-label>{{ referencePlaceholder | capitalizeFirst }}</mat-label>
          <input matInput 
                 type="text"
                 maxlength="50" 
                 formControlName="referenceControl">
        </mat-form-field>
      </div>

      </form>

      <advisories></advisories>
      <div class="mt-2">
        <notification></notification>
      </div>

      <!--
      <div class="mb-2">
        <app-advertisements [location]="'Checkout'"></app-advertisements>
      </div>
      -->

    </ng-container>

        <basketbar class="d-none d-lg-block" [position]="'relative'" [feature]="feature" [showDetails]="false"></basketbar>
  </div>

</div>

</ng-container>