import { Component, OnInit } from '@angular/core';
import { SlideInOutAnimation } from './animation';

import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { ProductService} from '../services/product.service';
import { TranslateService } from '@ngx-translate/core';

import { AppHelper } from '../helpers/app-helper';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-my-loyalty-points',
  templateUrl: './my-loyalty-points.component.html',
  styleUrls: ['./my-loyalty-points.component.scss'],
  animations: [SlideInOutAnimation,
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate('350ms')),
    ])
  ]
})

export class MyLoyaltyPointsComponent implements OnInit {

	public user_loyalty_points;
  public empty_user_loyalty_point;
  public cardAmount = 1;
	public flipped = false;
	public max_index = 100;

  public faInfoCircle = faInfoCircle;

  constructor(	private userService: UserService,
  				public merchantService: MerchantService,
  				public productService: ProductService,
          public translate: TranslateService,
  				public appHelper: AppHelper) { }

  ngOnInit(): void {
    this.initiateLoyaltyCards();
    this.getProfile();

    this.empty_user_loyalty_point = {
      flipped: false,
      empty: true,
      merchant: {
        header_image: {
          large_url: ''
        },
        image: {
          url: ''
        } 
      }
    }
  }

   getProfile() {

    this.userService.getProfile()
    .subscribe(
      user => {
        this.userService.user = user;
        this.initiateLoyaltyCards();
       
      },
      error => {
        console.log(error);
      }
      );
  }

  initiateLoyaltyCards() {
    this.user_loyalty_points = this.userService.user.user_loyalty_points;
    this.cardAmount =  this.user_loyalty_points.length;
    if (this.user_loyalty_points.length == 0) { this.cardAmount = 1 };
        let z_index = 100;
        for (let user_loyalty_point of this.user_loyalty_points) {
          user_loyalty_point.z_index = z_index--;
        }
  }

  /*getLoyaltyProducts(loyalty_point) {

  			const query = "q[catalog_owner_type_eq]=Merchant&q[catalog_owner_id_eq]=" + loyalty_point.merchant.id + "&q[is_loyalty_true]=1";
			this.productService
        	.getProductsByQuery(query)
        	.subscribe(
          		(res: any) => {
          			loyalty_point.products = res.products;
          		},
          		err => {
          			console.log(err);
          		}
        	);

    }*/

    isCordovaApp() {
      return window.hasOwnProperty('cordova');
    }

}
