<div class="fol-footer container-fluid">
  <div class="row">
    <div class="container">
      <div class="row">
        <section class="section col-md-12 my-3 my-sm-5">
          <div class="row">
            <div class="col-lg-5 footer-block">
              <div class="row">
                <div class="col-md-6 text-center">
                  <a href="" class="link-home">
                    <img class="img-fluid" src="{{ logo }}">
                  </a>
                </div>
                <div class="col-md-6 d-none d-sm-block">
                  <h4 class="title text-center text-lg-left link-footer">
                    <span>frietjesonline.be BV</span>
                  </h4>
                  <address class="container-details text-center text-lg-left">
                    <div>
                      <div><span>Mijlstraat 153</span></div>
                      <div><span>2570 Duffel</span></div>
                    </div>
                    <div>
                      <div><span>Ond. nr:</span><span>0659.973.944</span></div>
                      <div><span>BTW nr:</span><span>BE 0659.973.944</span></div>
                      <div><span>Rpr te Antwerpen</span></div>
                      <div>
                        <span>Email: </span>
                        <a href="mailto:info@frietjesonline.be" class="sitemap-link">
                          <span>info&#64;frietjesonline.be</span>
                        </a>
                      </div>
                    </div>
                  </address>
                </div>
              </div>
            </div>
            <div class="col-lg-4 footer-block text-center text-lg-left container sitemap align-self-center">
              <div class="row mx-1 my-4">
                <a [routerLink]="['/contact-us-demo']" class="btnPrimary">{{ 'fol.become_partner' | translate }}</a>
              </div>
            </div>
            <div class="col-lg-3 footer-block align-self-center">
              <h4 class="title text-center">{{ 'fol.follow_us' | translate }}</h4>
              <div class="divSocialMedia container-socialmedia text-center">
                <div class="sm-link"><a href="https://www.facebook.com/FrietjesonlineBE/" target="_blank"><fa-icon
                      [icon]="faFacebook" class="fa-2x"></fa-icon></a></div>
                <div class="sm-link"><a href="https://twitter.com/frietjesonline" target="_blank"><fa-icon
                      [icon]="faTwitter" class="fa-2x"></fa-icon></a></div>
                <div class="sm-link"><a href="https://www.instagram.com/frietjesonline/?hl=nl" target="_blank"><fa-icon
                      [icon]="faInstagram" class="fa-2x"></fa-icon></a></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>