import { Component, ElementRef, OnInit,AfterViewInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';
import { AppHelper } from '../helpers/app-helper';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {

  public slots;
  public days = ["mon","tue","wed","thu","fri","sat","sun"];
  public closing_days = [0,1,2,3,4,5,6];
  public hours = [];
  public contactCreated = false;
  public holiday_periods = [];

  public whitelabel = environment.whitelabel;
  public fol = false;

  lat: number = 51.678418;
  lng: number = 7.809007;

  private divWidth: any;

  infoWindowClosed = true;

  contact = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl((this.userService.user && this.userService.user.email), [Validators.required, Validators.email]),
    phone: new UntypedFormControl('', [Validators.required]),
    comment: new UntypedFormControl('', [Validators.required])
  });

  @ViewChild('myIdentifier') myIdentifier: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.myIdentifier && this.myIdentifier.nativeElement) {
    	this.divWidth = this.myIdentifier.nativeElement.offsetWidth;
    	this.calculateSlots();
    }
  }

  constructor(
    public merchantService: MerchantService,
    public userService: UserService,
    private ref: ChangeDetectorRef,
    private util: UtilityService,
    private translate: TranslateService,
    private appHelper: AppHelper,
  ) {

    this.fol = this.appHelper.isFol();

  }

  ngOnInit() {
  	this.slots = this.merchantService.merchant.current_week_scheme.slots;
    this.getHolidayPeriods();

    //this.divWidth = this.myIdentifier.nativeElement.offsetWidth;
    //this.calculateSlots();
  }

  /*ngAfterContentInit() {
    let merchant = this.merchantService.merchant;
    let merchantAddress = merchant.address;
    let address = merchantAddress.line1 + (merchantAddress.line2 ? (', ' + merchantAddress.line2) : '');
    let mapProp = {
      center: new google.maps.LatLng(merchantAddress.latitude, merchantAddress.longitude),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    setTimeout(() => {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      let location = new google.maps.LatLng(merchantAddress.latitude, merchantAddress.longitude);
      let marker = new google.maps.Marker({
        position: location,
        map: this.map
      });
      let infowindow = new google.maps.InfoWindow({
        content:
          '<h1>' + merchant.name + '</h1>' +
          '<h2>Address: ' + address + '</h2>' +
          '<h2>Contact: ' + merchant.settings.s_public_phone + '</h2>'
      });

      marker.addListener('click', () => {
        if(this.infoWindowClosed) infowindow.open(this.map, marker);
        this.infoWindowClosed = false;
      });

      google.maps.event.addListener(infowindow, 'closeclick', () => {
        this.infoWindowClosed = true;
      });
    }, 500);
  }*/

  calculateSlots() {

    this.closing_days = [0,1,2,3,4,5,6];

  	var min_hour = 10;
  	var max_hour = 18;

  	for (let slot of this.slots) {
	    
      var hour = slot.start_at_s/60/60;
	    var day = Math.floor(hour/24);
	    var start_hour_of_day = hour-(day*24);
	    var end_hour_of_day = start_hour_of_day + slot.duration/60/60;

      var start_int_hour = Math.floor(start_hour_of_day);
      var start_int_minutes = (start_hour_of_day - start_int_hour) * 60;

      var end_int_hour = Math.floor(end_hour_of_day);
      var end_int_minutes = (end_hour_of_day - end_int_hour) * 60;

      slot.start_of_day = new Date().setHours(start_int_hour,start_int_minutes,0,0);
      slot.end_of_day = new Date().setHours(end_int_hour,end_int_minutes,0,0);
	  
	    if (start_hour_of_day <= min_hour) {
	  	  min_hour = Math.floor(start_hour_of_day);
	  	  if (min_hour == start_hour_of_day) min_hour = min_hour-1;
	    }

      if (end_hour_of_day > max_hour) max_hour = Math.ceil(end_hour_of_day);
      else if (end_hour_of_day == max_hour) { max_hour++ };    

      if (max_hour > 24) { max_hour = 24; }

  	}

  	this.hours = [];
  	for (hour = min_hour; hour < max_hour; hour++) {
  		this.hours.push(hour);
  	}
 
  	var toUse = 100;
  	if (this.divWidth != undefined) toUse = this.divWidth - 50;
    //if (toUse > 600) toUse = 600;

  	var seconds_to_cover = (max_hour-min_hour)*60*60
  	var divider = seconds_to_cover/toUse;

  	for (let slot of this.slots) {
  		var hour = slot.start_at_s/60/60;
  		var day = Math.floor(hour/24);
		  var start_px = (slot.start_at_s - day*86400) - (min_hour*60*60);

      this.closing_days = this.closing_days.filter(x => x !== day);
		
		  slot['day'] = day;
  		 slot['start_px'] = Math.floor(start_px/divider);
  		slot['width_px'] = Math.floor(slot.duration/divider);
  		slot['total_width_px'] = toUse;
  	}
  	this.ref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.myIdentifier && this.myIdentifier.nativeElement) {
  	  this.divWidth = this.myIdentifier.nativeElement.offsetWidth;
  	  this.calculateSlots();
    }
  }

  getHolidayPeriods() {

    this.merchantService.getHolidayPeriods(this.merchantService.merchant.id)
    .subscribe(
      holiday_periods => {
        this.holiday_periods = holiday_periods.filter(holiday_period => holiday_period.enabled == true);
        for (let holiday_period of holiday_periods) {
          let diff = new Date(holiday_period.dates["endDate"]).getTime() - new Date(holiday_period.dates["startDate"]).getTime();
          if (diff / 1000 / 60 / 60 > 24) { holiday_period.multiple_days = true; }
          else { holiday_period.multiple_days = false; }
        }
        this.ref.detectChanges();
      }, error => {
        console.log(error);
      });
  }

  createContact() {
    this.merchantService.createContact(this.merchantService.merchant.id, this.contact.value)
    .subscribe(
      contact => {
        this.contactCreated = true;
        this.util.openSnackBar(this.translate.instant('contact.success'), this.translate.instant('ok'));
      },
      error => {
        console.log(error);
        this.util.openSnackBar(this.translate.instant('contact.error'), this.translate.instant('ok'));
      }
    );
  }
}
