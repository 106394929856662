import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter, Renderer2, HostListener } from '@angular/core';

import { MerchantService} from '../services/merchant.service';
import { UserService} from '../services/user.service';
import { TaxonService } from '../services/taxon.service';

import { Router, ActivatedRoute } from '@angular/router';

import { Location } from '@angular/common';

import { AppHelper } from '../helpers/app-helper';

import { faInfoCircle, faHeartCircle, faUserCircle, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faEdit, faCancel, faWallet, faHeartCircle as faHeartCircleSolid, faUserCircle as faUserCircleSolid } from '@fortawesome/pro-solid-svg-icons';

import { environment } from '../../environments/environment';

@Component({
  selector: 'venue-header',
  templateUrl: './venue-header.component.html',
  styleUrls: ['./venue-header.component.scss']
})
export class VenueHeaderComponent implements OnInit {

  faInfoCircle = faInfoCircle;
  faHeartCircle = faHeartCircle;
  faUserCircle = faUserCircle;
  faUserCircleSolid = faUserCircleSolid;
  faHeartCircleSolid = faHeartCircleSolid;
  faArrowLeft = faArrowLeft;
  faWallet = faWallet;
  faCancel = faCancel;
  faEdit = faEdit;

  public user_loyalty_point: any;
  public whitelabel = "guide";
  public fol = false;
  public breakpoint = "sm";

  @Input() extra_info = true;
  @Input() back_goes_to = 'back';
  @Input() back_shown = true;
  @Input() role = "menu";
  @Output() back_clicked = new EventEmitter<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.breakpoint = this.appHelper.getBootstrapBreakpoint();
  }

  constructor(
  	public merchantService: MerchantService,
  	public userService: UserService,
    private location: Location,
    private taxonService: TaxonService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private appHelper: AppHelper	) { 

    this.fol = this.appHelper.isFol();

  }

  ngOnInit() {
      this.whitelabel = environment.whitelabel;
      this.merchantService.calculateDistance(this.merchantService.merchant);
      if (this.merchantService.kiosk) this.extra_info = false;
      this.getCredit();
  }

  goToInfo() {
    this.router.navigate(['../about-us'], { relativeTo: this.route });
  }

  backClicked() {

    //in any case
    this.renderer.removeClass(document.body, 'noscroll');

    if (this.userService.user && this.userService.user.is_waiter) {
      this.router.navigate(['/']);
    } else if (this.back_goes_to && this.back_goes_to == 'back') {
      this.location.back();
    } else if (this.back_goes_to) { 
      if (this.userService.local_location) {
        this.router.navigate(['../' + this.back_goes_to], { relativeTo: this.route, queryParams: {qr_token: this.userService.local_location.qr_token }} ); 
      } else {
        this.router.navigate(['../' + this.back_goes_to], { relativeTo: this.route }); 
      }
    }

    this.back_clicked.emit(true);
    
  }

  toggleFav(merchant, event) {
    this.appHelper.toggleFav(merchant, event);
  }

  getCredit() {
    if (this.merchantService.merchant && this.userService.hasUserLoyaltyPoints()) { 
      this.user_loyalty_point = this.userService.getUserLoyaltyPoint(this.merchantService.merchant.id);
    };
  }
}
