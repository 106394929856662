import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare var StatusBar: any;
declare var device: any;

if (environment.production) {
  enableProdMode();
}

//const bootstrap = () => { platformBrowserDynamic().bootstrapModule(AppModule); };

const bootstrap = () => { platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && environment.production && environment.whitelabel == 'guide') {
    navigator.serviceWorker.register('/ngsw-worker.js').then(function(registration) {
      console.log('Service worker registration succeeded:', registration);
    }, /*catch*/ function(error) {
      console.log('Service worker registration failed:', error);
    });

  }
}).catch(err => console.log(err)); };

if (typeof window['cordova'] !== 'undefined') {
  document.addEventListener('deviceready', () => {
  	//StatusBar.overlaysWebView(true);
    //StatusBar.hide();
    
    setTimeout(function() {
    	(navigator as any).splashscreen.hide();
    }, 3000);

    //alert("THE PLATFORM =>");
    //alert(device.platform);

    bootstrap();

  }, false);
} else {
  bootstrap();
}