import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

import { Basket } from '../models/basket';
import { Product } from '../models/product';

import { AppHelper } from '../helpers/app-helper';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { ActivatedRoute } from '@angular/router';

import { Location } from '@angular/common';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(350)),
    ]),
    trigger('slideUp', [
      state('void', style({
        transform: 'translateY(150%)'
      })),
      transition('void <=> *', animate('0.5s ease-in-out')),
    ]),
  ]
})
export class BasketComponent implements OnInit {

  public basket: Basket;
  public selectedProduct: Product;
  public merchant_slug: string;
  public basketHasContent = false;
  public comment_editable = false;

  public whitelabel = environment.whitelabel;
  public fol = false;

  constructor(public userService: UserService,
    public merchantService: MerchantService,
    private renderer: Renderer2,
    private appHelper: AppHelper,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute) {

    this.fol = this.appHelper.isFol();

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
      });

  }

  ngOnInit() {
    if (!this.merchantService.merchant) { this.router.navigate(['../menu'], { relativeTo: this.route }); }

    this.comment_editable = this.merchantService.hasFeature(this.merchantService.merchant.id, "f_comment_on_orderline");
  }

  onProductSelected(product) {
    console.log('BASKET - ON PRODUCT SELECTED CLICKED!!!!!!!!');
    this.selectedProduct = product;
    this.renderer.addClass(document.body, 'noscroll');
    if (this.appHelper.isMobile()) { this.location.go(this.merchant_slug + '/product-detail'); }
  }

  onProductCanceled() {
    console.log('##MENU##onProductCanceled');
    this.renderer.removeClass(document.body, 'noscroll');
    if (this.appHelper.isMobile()) { this.location.go(this.merchant_slug + '/menu'); }
    this.selectedProduct = null;
    this.basketHasContent = this.checkBasketHasContent();
  }

  checkBasketHasContent() {
    if (this.userService.basket && this.userService.basket.order.order_lines.length > 0) { return true; }
    else { return false; }
  }

}
