<ng-template #useCredentials>
  <form [formGroup]="auth">
    <div class="form-container">
        <mat-form-field>
          <mat-label>{{ 'user.email' | translate }} </mat-label>
          <input  matInput 
                  placeholder="{{ 'user.email' | translate }}" 
                  formControlName="username"
                  name="email"
                  autocomplete="email">
        </mat-form-field>
    </div>

    <div class="form-container">
        <mat-form-field>
          <mat-label>{{ 'user.password' | translate }}</mat-label>
          <input  matInput 
                  [type]="hide ? 'password' : 'text'"
                  placeholder="{{ 'user.password' | translate }}" 
                  name="password"
                  formControlName="password"
                  >
            <mat-icon (click)="toggleHide($event)" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
    </div>

    <div style="text-align:right;">

      <a  *ngIf="!userService.group && !merchantService.kiosk"
          class="link font-dosis" 
          [routerLink]="['/guest']" 
          [queryParams]="{domain: domain, scenario: scenario}">{{ 'main.guest' | translate }}</a><br>
      <a class="link font-dosis" [routerLink]="['/forgot-password']" [queryParams]="{domain: domain}">{{ 'user.forget_password' | translate }}</a><br/>
      <a class="link font-dosis" [routerLink]="['/register']" [queryParams]="{domain: domain, scenario: scenario}">{{ 'user.register' | translate }}</a>

    </div>

    <div class="py-3" style="text-align: center">
      
      <div>
        <button mat-flat-button 
                [disabled]="!auth.valid" 
                color="primary"
                [ngStyle]="{'background-color': fol ? '#f0513f' : nil, 'color': fol ? '#fdd329' : nil }"
                [ngClass]="{'login_button': fol}"
                (click)="onLoginFormSubmit()">
        <div class="d-flex flex-row justify-content-start align-items-center">
            <div *ngIf="ongoing" class="pe-2"><mat-spinner [color]="'accent'"
                       [diameter]="20"></mat-spinner>
                     </div>
                     <div>
          {{ 'user.login' | translate }}
        </div>
      </div>
      </button>
      </div>
      
      
      <!-- <div class="pt-3 privacy_statement">
        <div class="d-flex align-self-center">
          <div style="text-align: left">Door in te loggen ga je akkoord met onze algemene voorwaarden en ons privacy statement</div>
        </div>
      </div> -->
    </div>
	</form>

  <button mat-button
          *ngIf="domain == 'kuehnenagel' && merchantService.kiosk"
          class="small d-flex ms-auto"
          (click)="useCredentialsToLogin()">{{ 'user.use_badge' | translate }}</button>
</ng-template>


<div *ngIf="(use_badge || use_code) else useCredentials">
  <ng-container *ngIf="use_badge else useCode">
    <div class="h3 text-center my-3 d-flex flex-row">
      <fa-icon [icon]="faIdBadge" class="fa-3x mx-2"></fa-icon>
      <div style="white-space:pre;width:100%">{{ 'user.login_badge' | translate}}</div>
    </div>
    
    <button mat-button class="small d-flex ms-auto" (click)="useCredentialsToLogin()">{{ 'user.use_credentials' | translate }}</button>
  </ng-container>
  
  <ng-template #useCode>
    <div class="d-flex justify-content-center" style="font-size:4rem;height:100px;">
      <div class="data">{{ show_dots }}</div>
    </div>

    <div class="container number-pad">
      <div class="row row1">
        <button class="col number" (click)="clickNumber('1')" mat-flat-button>1</button>
        <button class="col number" (click)="clickNumber('2')" mat-flat-button>2</button>
        <button class="col number" (click)="clickNumber('3')" mat-flat-button>3</button>
      </div>
      <div class="row">
        <button class="col number" (click)="clickNumber('4')" mat-flat-button>4</button>
        <button class="col number" (click)="clickNumber('5')" mat-flat-button>5</button>
        <button class="col number" (click)="clickNumber('6')" mat-flat-button>6</button>
      </div>
      <div class="row">
        <button class="col number" (click)="clickNumber('7')" mat-flat-button>7</button>
        <button class="col number" (click)="clickNumber('8')" mat-flat-button>8</button>
        <button class="col number" (click)="clickNumber('9')" mat-flat-button>9</button>
      </div>
      <div class="row row4">
        <button class="col number" (click)="erase()" mat-flat-button style="font-size: 2.5rem;">
          <fa-icon [icon]="faArrowLeft">
        </fa-icon></button>
        <button class="col number" (click)="clickNumber('0')" mat-flat-button>0</button>
        <button class="col number" (click)="onSubmitCode()" mat-flat-button style="font-size: 2.5rem;">
          <fa-icon [icon]="faCheck"></fa-icon>
        </button>
      </div>
    </div>
  </ng-template>
</div>