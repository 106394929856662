<div class="text-center d-flex flex-column" style="height:100%">
  <div mat-dialog-title>
    <h1 class="mat-h1">{{ 'kiosk.session_timeout_title' | translate }}</h1>
  </div>
  
  <mat-dialog-content>
    {{ 'kiosk.session_timeout_content' | translate }}
    <h1 style="font-size:7rem;" class="mt-4">{{ timer }} </h1>
  </mat-dialog-content>

  <button mat-raised-button 
    [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
    style="color:white"
    class="mb-4 w-75 align-self-center" (click)="continueOrder()">
    {{ 'kiosk.session_timeout_button' | translate }}
  </button>
</div>

