import { Component, Input, OnInit } from '@angular/core';

import { User } from '../models/user';
import { MerchantService } from '../services/merchant.service';
import { UserService} from '../services/user.service';
import { UtilityService } from '../services/utility.service';
import { TranslateService } from '@ngx-translate/core';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { AppHelper } from '../helpers/app-helper';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';

import { GroupComponent } from '../profile/group/group.component';
import { GroupEditComponent } from '../profile/group-edit/group-edit.component';
import { MembersComponent } from '../profile/members/members.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

import { Observable } from 'rxjs';
import { map, switchMap, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface Language {
  value: string;
  viewValue: string;
}

@Component({
	selector: 'my-groups',
	templateUrl: './my-groups.component.html',
	styleUrls: ['./my-groups.component.scss']
})
export class MyGroupsComponent implements OnInit {

  public active = 1;

  page: number = 1;
  pageSize: number = 10;

  groupNames = {};
  merchantGroupNames = {};

  languages: Language[] = [
    {value: 'en', viewValue: this.translate.instant('english')},
    {value: 'fr', viewValue: this.translate.instant('french')},
    {value: 'nl', viewValue: this.translate.instant('nederlands')}
  ];

	//fullUser: any;
	user = new UntypedFormGroup({
      id: new UntypedFormControl(''),
    	email: new UntypedFormControl('', [Validators.required, Validators.email]),
    	phone: new UntypedFormControl(''),
    	locale: new UntypedFormControl('')
  	});

    
    public whitelabel: string;
    public merchant_groups = [];
    
    public searchText = '';
    public searchInitText = '';

    public fol = false;
    
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map(term => {
        this.searchText = term;
        this.merchant_groups = this.userService.user.merchant_groups.filter(group => {
          return group.name.toLowerCase().includes(this.searchText.toLowerCase());
        });

        /*window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });*/

     })
      );

	constructor(
    public userService: UserService,
    public merchantService: MerchantService,
		private snackBar: MatSnackBar,
    private util: UtilityService,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private appHelper: AppHelper

  ) {

    this.fol = this.appHelper.isFol();

   }

  openGroupFormDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '50vw';

    const dialogRef = this.dialog.open(GroupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      response => {
        if (response && response.action == 'success') {
          this.userService.user.groups.push(response.group);
        }
      }
    );
  }

  openGroupEditDialog(group_id = null, limited = true) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '50vw';
    
    if (this.appHelper.isMobile()) {
      dialogConfig.minWidth = '90vw';
    }

    dialogConfig.height = '90vh';
    dialogConfig.data = { group_id: group_id, limited: limited };

    const dialogRef = this.dialog.open(GroupEditComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      response => {
        if (response && response.action == 'success') { 
          this.getProfile();
        };
      }
    );

  }

  openMembersDialog(group_id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '50vw';
    //dialogConfig.maxWidth = "90vw";
    
    if (this.appHelper.isMobile()) {
      dialogConfig.minWidth = '90vw';
    }

    dialogConfig.height = '90vh';
    dialogConfig.data = { group_id: group_id };

    this.dialog.open(MembersComponent, dialogConfig);
  }

	ngOnInit() {
    this.whitelabel = environment.whitelabel;
    if (this.userService.user) {
		  this.getProfile();
    }
    else {
      this.router.navigate(['/login']);
    }
	}

	getProfile() {

		this.userService.getProfile()
		.subscribe(
			user => {
				this.userService.setUser(user);
        this.user.patchValue({
            id: user.id,
    				email: user.email,
    				phone: user.phone,
    				locale: user.locale
  				});

        this.merchant_groups = this.userService.user.merchant_groups
			},
			error => {
				console.log(error);
			}
			);
	}

	onSubmit() {
    this.userService.updateProfile(this.user.value)
    .subscribe(
      user => {
        this.translate.use(this.user.value.locale);
        this.util.openSnackBar(this.translate.instant('profile.updated_success'), this.translate.instant('ok'));
      },
      error => {
        console.log(error);
      }
    );

    console.log(this.user);
    console.log(this.user.value.email);
	}

  openDeleteGroupDialog(groupId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: this.translate.instant('profile.confirm_group_delete'),
      group_id: groupId
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      response => {
        if (response.action == 'confirmed') { this.deleteGroup(response.data.group_id); }
      }
    );
  }

  updateGroup(group) {
    const group_data = { id: group.id, name: this.groupNames[group.id] };
    this.userService.updateGroup(group_data)
    .subscribe(
      group => {
        this.userService.user.groups = this.userService.user.groups.filter(function( userGroup ) {
          if (userGroup.id == group.id) { userGroup.name = group.name; }
          return userGroup;
        });

        this.util.openSnackBar(this.translate.instant('profile.group_updated_success'), this.translate.instant('ok'));
        this.groupNames[group.id] = null;
      },
      error => {
        this.util.openSnackBar(this.translate.instant('profile.group_updated_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  updateMerchantGroup(group) {
    const group_data = { id: group.id, name: this.merchantGroupNames[group.id] };
    this.userService.updateGroup(group_data)
    .subscribe(
      group => {
        this.userService.user.merchant_groups = this.userService.user.merchant_groups.filter(function( userGroup ) {
          if (userGroup.id == group.id) { userGroup.name = group.name; }
          return userGroup;
        });

        this.util.openSnackBar(this.translate.instant('profile.group_name_updated'), this.translate.instant('ok'));
        this.merchantGroupNames[group.id] = null;
      },
      error => {
        this.util.openSnackBar(this.translate.instant('profile.group_name_update_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  deleteGroup(groupId) {
    this.userService.deleteGroup(groupId)
    .subscribe(
      group => {
        this.userService.user.groups = this.userService.user.groups.filter(function( group ) {
          return group.id !== groupId;
        });
        this.util.openSnackBar(this.translate.instant('profile.group_deleted_success'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('profile.group_deleted_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  deleteMerchantGroup(groupId) {
    this.userService.deleteGroup(groupId)
    .subscribe(
      group => {
        this.userService.user.merchant_groups = this.userService.user.merchant_groups.filter(function( group ) {
          return group.id !== groupId;
        });
        this.util.openSnackBar(this.translate.instant('profile.group_deleted_success'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('profile.group_deleted_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  openLeaveGroupConfirmationDialog(groupId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: this.translate.instant('profile.confirm_leave_group'),
      group_id: groupId
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      response => {
        if (response && response.action == 'confirmed') { this.leaveGroup(response.data.group_id); }
      }
    );
  }

  leaveGroup(groupId) {
    this.userService.leaveGroup(groupId)
    .subscribe(
      group => {
        this.userService.user.membered_groups = this.userService.user.membered_groups.filter(function( group ) {
          return group.id !== groupId;
        });
        this.util.openSnackBar(this.translate.instant('profile.group_left_success'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('profile.group_left_error'), this.translate.instant('ok'));
      }
    );
  }

  setPreferredGroup(groupId) {
    const current_preferred_group = this.userService.user.preferred_group_id;
    this.userService.user.preferred_group_id = groupId;
    this.userService.setPreferredGroup(groupId, this.userService.user.id)
    .subscribe(
      groupMember => {
        this.util.openSnackBar(this.translate.instant('profile.prefrences_update_success'), this.translate.instant('ok'));
      },
      error => {
        this.userService.user.preferred_group_id = current_preferred_group;
        this.util.openSnackBar(this.translate.instant('profile.preferences_update_error'), this.translate.instant('ok'));
      }
    );
  }

	/*resendInvitation() {
    	this.snackBar.openFromComponent(PizzaPartyComponent, {
      		duration: 3000,
    	});
  }*/

  updateGroupStatus(groupId, status) {
    const group_attributes = { id: groupId, approval_status: status };
    this.userService.updateGroupStatus(group_attributes)
    .subscribe(
      group => {
        this.userService.user.merchant_groups = this.userService.user.merchant_groups.filter(function( merchantGroup ) {
          if (merchantGroup.id == group.id) { merchantGroup.approval_status = group.approval_status; }
          return merchantGroup;
        });

        this.util.openSnackBar(this.translate.instant('groups.' + group.approval_status + '_success'), this.translate.instant('ok'));
      },
      error => {
        this.util.openSnackBar(this.translate.instant('profile.group_status_update_error'), this.translate.instant('ok'));
        console.log(error);
      }
    );
  }

  loadTab(event) {
  }

   handlePageEvent(e: PageEvent) {
    this.page = e.pageIndex + 1;
  }


}
