<header>
  <div class="header-container">
    <app-navbar [fol_bg]="true"></app-navbar>
  </div>
</header>

<h1 class="title text-center">
  Registreren als frituur / eethuis
</h1>


<div class="container mb-5 d-flex flex-column text-center">
  <div class="text-center">
    <p>
      Interesse om partner te worden? Hier kan u uw frituur en/of restaurant vrijblijvend aanmelden.
      Klik <a [routerLink]="['/about-us']" style="text-decoration: none;">hier</a> voor meer informatie over ons platform.
    </p>

    <p>
      Indien u een vraag voor ons heeft als consument, klik dan <a [routerLink]="['/contact-us-support']" style="text-decoration: none;">hier</a>.
    </p>
  </div>


  <div id="zf_div_xAW5ewddQLgPMDHXbOdm4-7IiP3o_v-TPFiKRBX1p1I">
  </div>
</div>

<footer>  
  <app-fol-footer></app-fol-footer>
</footer>

