<div class="first-part">
    <app-navbar [background]="true" [frontColor]="'darkGrey'"></app-navbar>
    <!--
         <app-navbar [background]="true" [frontColor]="'darkGrey'" [fol_bg]="true"></app-navbar>
    -->
</div>

<div class="container" style="padding-top:0px;padding-bottom:50px;">

    <div style="padding-bottom:20px;">
 
<ng-container *ngIf="translate.currentLang == 'nl'">

<style>
<!--
 /* Font Definitions */
 @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
    {font-family:Calibri;
    panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
    {font-family:"Segoe UI";
    panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
    {font-family:"Century Gothic";
    panose-1:2 11 5 2 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin-top:0in;
    margin-right:0in;
    margin-bottom:8.0pt;
    margin-left:0in;
    line-height:107%;
    font-size:11.0pt;
    font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
    {color:blue;
    text-decoration:underline;}
.MsoChpDefault
    {font-family:"Calibri",sans-serif;}
.MsoPapDefault
    {margin-bottom:8.0pt;
    line-height:107%;}
@page WordSection1
    {size:595.3pt 841.9pt;
    margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
    {page:WordSection1;}
 /* List Definitions */
 ol
    {margin-bottom:0in;}
ul
    {margin-bottom:0in;}
-->
</style>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:24.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Algemene Voorwaarden Klanten</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Deze 'Algemene Voorwaarden Klanten' zijn van toepassing op de
relatie tussen one2three  en de Klanten. Handelaars verwijzen wij door naar de
'Algemene Voorwaarden Handelaars'.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>1. Definities</span></p>

</div>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Aanbod&nbsp;</span></b><span lang=NL-BE style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>: het product –en
dienstenaanbod van de Handelaar dat door de Klant via het Platform bij de
Handelaar kan worden besteld.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>Algemene Voorwaarden Klanten</b>: deze algemene voorwaarden.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Algemene Voorwaarden Handelaars</span></b><span lang=NL-BE
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>:
de algemene voorwaarden zoals van toepassing op het Aanbod van de Handelaars.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Bestelling</span></b><span lang=NL-BE style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>: een bestelling van de
Klant via het Platform bij de Handelaar van het door de Klant geselecteerde
Aanbod.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>Klant</b>: een natuurlijk persoon of rechtspersoon die een Bestelling plaats
via het Platform.<br>
<br>
<b>Overeenkomst</b>: een overeenkomst tussen de Klant en de Handelaar met
betrekking tot een Bestelling en de bezorging of het afhalen van de Bestelling.<br>
<br>
<b>Platform</b>: de website(s), apps, tools en andere hulpmiddelen van one2three
en aan haar gelieerde bedrijven en zakelijke partners waarop de Service
beschikbaar wordt gemaakt.<br>
<br>
<b>Handelaar</b>: een bedrijf dat gerechten, dranken en aanverwante artikelen
bereidt en verkoopt en voor de totstandkoming en betaling van Overeenkomsten
het Platform gebruikt.<br>
<br>
<b>Handelaarsinformatie</b>: de informatie van de Handelaar met betrekking tot,
onder andere, bedrijfs- en contactgegevens, algemene informatie, productaanbod
(gerechten, bijgerechten, opties en dranken), prijzen voor ieder afzonderlijk
product (inclusief BTW), bedrijfslogo, grafisch materiaal, bezorggebied,
bezorgkosten en minimale bestelbedragen.<br>
<br>
<b>Service</b>: de commerciële diensten en/of werkzaamheden die door one2three
worden aangeboden aan de Handelaar, onder meer bestaande uit het publiceren van
het Aanbod, het faciliteren van het tot stand komen van Overeenkomsten en het
doorsturen van Bestellingen van Klanten naar de relevante Handelaar.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>one2three</b>: one2three NV handelend voor zichzelf en namens elke
rechtspersoon of persoon die zij direct of indirect controleert.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>2. Identiteit van one2three</span></p>

</div>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three NV handelend onder de naam ‘one2three’</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Zetel:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Kantersteen 47</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1000 Brussel</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>België<br>
ONR BE0535.701.405</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
Correspondentieadres:<br>
Mijlstraat 153</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2570 Duffel</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>België</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
Email: </span><span lang=NL-BE><a href="mailto:info@one2three.be"><span
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif'>info&#64;one2three.be</span></a></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
Tel:&nbsp;+32 2 50 30 620 (tijdens werkdagen bereikbaar van 09:00 tot 18:00)</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>3. Toepasselijkheid</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>De Algemene Voorwaarden Klanten zijn van toepassing op Bestellingen
geplaatst door Klanten via het Platform in het kader van de Service. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Op het Aanbod zijn daarnaast, indien van toepassing, de Algemene
Voorwaarden van de Handelaars van toepassing.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>De Klant gaat door middel van het plaatsen van een Bestelling volgens
artikel 5.1 onmiddellijk een Overeenkomst met de Handelaar aan voor de levering
van het door de Klant geselecteerde Aanbod. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE> </span><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Volgens boek VI van het Wetboek Economisch Recht geldt geen herroepingsrecht
voor voedingsmiddelen en bederfelijke waren. De Klant is bijgevolg gebonden aan
de Bestelling, behoudens in geval van gebreken in de Service.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>4. Het Aanbod</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three publiceert het Aanbod namens de Handelaar op het
Platform, overeenkomstig de door de Handelaar aangeleverde Handelaarsinformatie.
One2three aanvaardt geen verantwoordelijkheid of aansprakelijkheid voor de
inhoud van het Aanbod en van de Handelaarsinformatie op het Platform. De
Handelaar kan ingrediënten en additieven gebruiken voor gerechten en dranken,
die allergieën en intoleranties kunnen veroorzaken. Als een Klant allergisch is
voor bepaalde voedingsmiddelen, raden wij aan om telefonisch contact op te
nemen met de Handelaar voor actuele allergeneninformatie voordat de bestelling
wordt geplaatst.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three aanvaardt in het algemeen geen aansprakelijkheid voor
de kwaliteit van producten en diensten geleverd door de Handelaar op grond van
een Bestelling via het Platform, of voor enige verborgen of niet-verborgen
gebreken in dergelijke producten.  </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three is alleen aansprakelijk voor of gehouden tot
schadevergoeding voor grove tekortkomingen met betrekking tot diens
verplichtingen voortvloeiende uit de Overeenkomst, dan wel voor haar opzet of
bedrog. In ieder geval is de aansprakelijkheid van one2Three beperkt tot
directe schade, met uitsluiting van enige indirecte schade.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>5. De Overeenkomst</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>De Overeenkomst komt tot stand op het moment dat de Klant de
Bestelling plaatst door het klikken op de knop 'Bestelling Bevestigen' tijdens
het proces van het plaatsen van een Bestelling via het Platform.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Na het plaatsen van de Bestelling, zal one2three de Bestelling
elektronisch aan de Klant bevestigen.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>De Overeenkomst kan alleen door de Handelaar worden uitgevoerd
als de Klant correcte en volledige contact- en adresgegevens verstrekt bij het
plaatsen van de Bestelling. De Klant heeft de plicht om onjuistheden in
verstrekte of vermelde betaalgegevens onverwijld aan one2three of de Handelaar
te melden.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>In verband met informatie over de status van zijn Bestelling,
dient de Klant na het plaatsen van de Bestelling telefonisch of per e-mail
(zoals aangegeven bij het plaatsen van de Bestelling) bereikbaar te zijn voor
zowel de Handelaar als one2three.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Indien de Klant kiest voor bezorging van de Bestelling, dan
dient de Klant aanwezig te zijn op het door de Klant aangegeven bezorgadres om
de Bestelling in ontvangst te kunnen nemen.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Indien de Klant kiest voor het afhalen van de Bestelling, dan
dient de Klant op de gecommuniceerde tijd aanwezig te zijn op de afhaallocatie
van de Handelaar, die in de bevestigingsmail, tekstbericht of push notificatie
door het Platform is weergegeven.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Bij aflevering van de Bestelling kan de Handelaar vragen om
identificatie van de Klant als de Bestelling alcoholische producten of andere
producten met een leeftijdslimiet bevat. Als de Klant zich niet adequaat kan
identificeren of niet voldoet aan de minimumleeftijdseisen, weigert de
Handelaar de betreffende producten aan de Klant te leveren. In dit geval kunnen
annuleringskosten in rekening worden gebracht.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three aanvaardt geen aansprakelijkheid in verband met de
uitvoering van de Overeenkomst.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>6. Ontbinding van de
Overeenkomst en annulering van de Bestelling</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Bestellingen kunnen niet door de Klant bij one2three of via het
Platform worden geannuleerd. Annulering van de Bestelling door de Klant is
enkel mogelijk mits uitdrukkelijk akkoord van  de Handelaar.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>De Handelaar heeft het recht de Bestelling te annuleren indien met
inbegrip van, maar niet beperkt tot, het Aanbod niet meer beschikbaar is, de
Klant een incorrect of onbereikbaar telefoonnummer, andere contactgegevens
heeft opgegeven of in geval van overmacht. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Indien de Klant een valse Bestelling plaatst (bijvoorbeeld door
foutieve contactgegevens door te geven, niet te betalen of door niet aanwezig
te zijn op de bezorglocatie of afhaallocatie om de Bestelling in ontvangst te
kunnen nemen) of anderszins zijn plichten die voortvloeien uit de Overeenkomst
niet nakomt, dan kan one2three besluiten om toekomstige Bestellingen van de
betreffende Klant te weigeren.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three heeft het recht om namens de Handelaar Bestellingen te
weigeren en Overeenkomsten te annuleren, indien er gerede twijfel bestaat over
de juistheid of authenticiteit van de Bestelling of de contactgegevens van de
Klant. Indien one2three een Bestelling annuleert die al betaald is, dan zal
one2three ervoor zorgen dat dit bedrag overgeboekt wordt op dezelfde rekening
als waarvan de betaling heeft plaatsgevonden. Indien de Klant schijnbaar valse
of frauduleuze Bestellingen plaatst, dan kan one2three hiervan aangifte doen
bij de politie.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>7. Betaling</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Op het moment dat de Overeenkomst tot stand is gekomen
overeenkomstig artikel 5.1 van deze Algemene Voorwaarden Klanten, is de Klant
verplicht om de Handelaar te betalen voor de Bestelling. Door de Klant kan aan
deze betalingsverplichting worden voldaan door te betalen met een online
betaalmiddel via het Platform of door betaling aan de Handelaar aan de deur of
op de afhaallocatie.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Behoudens het bepaalde in artikel 6.4, van deze Algemene
Voorwaarden Klanten, is het (gedeeltelijk) terugboeken van een online betaling
alleen mogelijk indien de Bestelling niet (geheel) geleverd kan worden. De
terugboeking vindt altijd plaats op dezelfde rekening als waarvan de betaling
heeft plaatsgevonden en zal worden uitgevoerd door de Handelaar.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>8. Klachtenregeling</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Klachten van de Klant over het Aanbod, de Bestelling of de
uitvoering van de Overeenkomst, dienen bij de Handelaar te worden neergelegd.
De exclusieve verantwoordelijkheid voor het Aanbod van de Handelaar en de
uitvoering van de Overeenkomst ligt bij de Handelaar. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Als de Klant klachten zou hebben gelinkt aan het Platform en de Bestellingen
geplaatst door Klanten via het Platform in het kader van de Service, kan deze
one2three contacteren via e-mail of telefonisch.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>9. Inzicht en correctie
van opgeslagen persoonsgegevens</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three verwerkt persoonlijke gegevens van de Klant. Op de
verwerking van persoonsgegevens is het&nbsp;</span><span lang=NL-BE><a
href="https://www.one2three.app/privacy-policy"><span style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#1574F5'>Privacy Statement</span></a></span><span
lang=NL-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;van toepassing.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=NL-BE>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=NL-BE> </span></p>

</div>

</ng-container>

<ng-container *ngIf="translate.currentLang == 'en'">

<style>
<!--
 /* Font Definitions */
 @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
    {font-family:Calibri;
    panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
    {font-family:"Segoe UI";
    panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
    {font-family:"Century Gothic";
    panose-1:2 11 5 2 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin-top:0in;
    margin-right:0in;
    margin-bottom:8.0pt;
    margin-left:0in;
    line-height:107%;
    font-size:11.0pt;
    font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
    {color:blue;
    text-decoration:underline;}
.MsoChpDefault
    {font-family:"Calibri",sans-serif;}
.MsoPapDefault
    {margin-bottom:8.0pt;
    line-height:107%;}
@page WordSection1
    {size:595.3pt 841.9pt;
    margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
    {page:WordSection1;}
 /* List Definitions */
 ol
    {margin-bottom:0in;}
ul
    {margin-bottom:0in;}
-->
</style>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:24.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Customer General Terms and Conditions </span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>These 'Customer General Terms and Conditions' apply to the
relationship between one2three and the Customers. Merchants are referred to the
'Merchant General Terms and Conditions'.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1. Definitions</span></p>

</div>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Offer</span></b><span lang=EN-GB style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>: the product and
service offering of the Merchant that can be ordered by the Customer from the
Merchant via the Platform.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>Order</b>: an order placed by the Customer via the Platform with the
Merchant of the Offer selected by the Customer.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>Customer</b>: a natural or legal person who places an Order via the
Platform.<br>
<br>
<b>Customer General Terms and Conditions: </b>these general terms and
conditions.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Agreement</span></b><span lang=EN-GB style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>: an agreement between
the Customer and the Merchant regarding an Order and the delivery or collection
of the Order.<br>
<br>
<b>Platform: </b>the website(s), apps, tools and other resources of one2three
and its affiliates and business partners on which the Service is made
available.<br>
<br>
<b>Merchant: </b>a company that prepares and sells food, beverages and related
items and uses the Platform for the creation and payment of Agreements.<br>
<br>
<b>Merchant General Terms and Conditions: </b>the terms and conditions as
applicable to the Merchant’s Offer.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Merchant Information</span></b><span lang=EN-GB
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>:
the information provided by the Merchant regarding, among other things, company
and contact details, general information, product offerings (dishes, side
dishes, options and beverages), prices for each individual product (including
VAT), company logo, graphic material, delivery area, delivery fees and minimum
order amounts.<br>
<br>
<b>Service</b>: the commercial services and/or activities offered by one2three
to the Merchant, including publishing the Offer, facilitating the conclusion of
Agreements and forwarding Orders from Customers to the relevant Merchant.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>one2three</b>: one2three NV acting for itself and on behalf of any legal
entity or person that it directly or indirectly controls.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2. Identity of one2three</span></p>

</div>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three NV trading under the name 'one2three'.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Headquarters:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Avenue de Tervueren 34, b13</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1040 Brussels</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Belgium<br>
ONR BE0535.701.405</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
Correspondence address:<br>
Mijlstraat 153</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2570 Duffel</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Belgium</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
Email: </span><span lang=NL-BE><a href="mailto:info@one2three.be"><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif'>info&#64;one2three.be</span></a></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
Tel: +32 2 50 30 620 (available on weekdays from 09:00 to 18:00)</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3. Applicability</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>The Customer General Terms and Conditions apply to Orders placed
by Customers through the Platform within the framework of the Service. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>In addition, if applicable, the Merchant General Terms and
Conditions apply to the Offer.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>The Customer, by placing an Order according to article 5.1, immediately
enters into a Contract with the Merchant for the delivery of the Offer selected
by the Customer.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>According to Book VI of the Belgian Code of Economic Law, no
right of revocation applies to food and perishable goods. Consequently, the
Customer is bound by the Order, except in the case of defects in the Service.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4. The Offer</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three publishes the Offer on the Platform on behalf of the Merchant,
in accordance with the Merchant Information provided by the Merchant. One2three
accepts no responsibility or liability for the content of the Offer and of the Merchant
Information on the Platform. The Merchant may use ingredients and additives for
dishes and drinks, which may cause allergies and intolerances. If a Customer is
allergic to certain foods, we recommend contacting the Merchant by phone for
current allergen information before placing the order.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three generally accepts no liability for the quality of
products and services provided by the Merchant pursuant to an Order through the
Platform, or for any hidden or non-hidden defects in such products.</span><span
lang=EN-GB> </span><span lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three shall only be liable or obliged to pay damages for
gross failures with respect to its obligations under the Agreement, or for its wilful
misconduct or fraud.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>In any event, one2three's liability shall be limited to direct
damages to the exclusion of any indirect damages.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;line-height:normal'><span lang=EN-GB
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>5. The Agreement</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>The Agreement is concluded at the time the Customer passes the
Order by clicking on the &quot;Confirm Order&quot; button during the process of
placing an Order through the Platform.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Upon passing of the Order, one2three will confirm the Order to
the Customer electronically.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>The Agreement can only be executed by the Merchant if the
Customer provides correct and complete contact and address details when placing
the Order. The Customer has the duty to immediately report inaccuracies in
payment data provided or stated to one2three or the Merchant.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>In connection with information about the status of its Order,
the Customer must be available to both the Merchant and one2three by telephone
or email (as indicated when placing the Order) after placing the Order.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>If the Customer chooses delivery of the Order, the Customer must
be present at the delivery address indicated by the Customer in order to
receive the Order.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>If the Customer chooses to pick up the Order, the Customer must
be present at the Merchant's pick-up location at the communicated time, which
is shown in the confirmation email, text message or push notification by the
Platform.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Upon delivery of the Order, the Merchant may request
identification of the Customer if the Order contains alcoholic products or
other products with an age limit. If the Customer cannot adequately identify
himself or does not meet the minimum age requirements, the Merchant shall
refuse to deliver the relevant products to the Customer. In this case, a
cancellation fee may be charged</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three assumes no liability in connection with the
performance of the Agreement.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>6. Dissolution of the Agreement and cancellation of the Order</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Orders cannot be cancelled by the Customer with one2three or via
the Platform. Cancellation of the Order by the Customer is only possible with
the express agreement of the Merchant.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>The Merchant is entitled to cancel the Order if, including, but
not limited to, the Offer is no longer available, the Customer has provided an
incorrect or unreachable telephone number, other contact details or in case of
force majeure.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>If the Customer places a false Order (for example, by providing
incorrect contact information, failing to pay, or failing to be present at the
delivery or pick-up location to receive the Order) or otherwise fails to fulfil
its obligations under the Agreement, one2three may decide to refuse future
Orders from the affected Customer.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three has the right to refuse Orders on behalf of the
Merchant and cancel Agreements if there is reasonable doubt about the accuracy
or authenticity of the Order or contact details of the Customer. If one2three cancels
an Order that has already been paid for, one2three will ensure that this amount
is transferred to the same account from which payment was made. If the Client
places apparently false or fraudulent Orders, one2three may report this to the
police.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>7. Payment</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>At the time the Agreement is concluded in accordance with article
5.1 of these Customer General Terms and Conditions, the Customer is obliged to
pay the Merchant for the Order. This payment obligation can be fulfilled by the
Customer by paying with an online payment method via the Platform or by paying
the Merchant at the door or at the pick-up location.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Subject to the provisions of article 6.4 of these Customer General
Terms and Conditions, an online payment may only be (partially) reversed if the
Order cannot be (fully) delivered. The reversal shall always be made to the
same account from which the payment was made and shall be executed by the
Merchant.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>8. Complaint procedure</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Complaints by the Customer about the Offer, the Order or the
performance of the Agreement, should be lodged with the Merchant. The exclusive
responsibility for the Merchant's Offer and the performance of the Agreement
lies with the Merchant. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>If the Customer should have any complaints linked to the
Platform and the Orders placed by Customers through the Platform within the
framework of the Service, they may contact one2three by email or by telephone.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=EN-GB style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>9. Insight and correction of stored personal data</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three processes personal data of the Customer. The
processing of personal data is subject to the </span><span lang=NL-BE><a
href="https://www.one2three.app/privacy-policy"><span lang=EN-GB
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#1574F5'>Privacy
Statement</span></a></span><span lang=EN-GB style='font-size:10.5pt;font-family:
"Century Gothic",sans-serif;color:#111111'>.</span></p>

<p class=MsoNormal>&nbsp;</p>

</div>



</ng-container>

<ng-container *ngIf="translate.currentLang == 'fr'">


<style>
<!--
 /* Font Definitions */
 @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
    {font-family:Calibri;
    panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
    {font-family:"Century Gothic";
    panose-1:2 11 5 2 2 2 2 2 2 4;}
@font-face
    {font-family:"Segoe UI";
    panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin-top:0in;
    margin-right:0in;
    margin-bottom:8.0pt;
    margin-left:0in;
    line-height:107%;
    font-size:11.0pt;
    font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
    {color:blue;
    text-decoration:underline;}
.MsoChpDefault
    {font-family:"Calibri",sans-serif;}
.MsoPapDefault
    {margin-bottom:8.0pt;
    line-height:107%;}
@page WordSection1
    {size:595.3pt 841.9pt;
    margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
    {page:WordSection1;}
 /* List Definitions */
 ol
    {margin-bottom:0in;}
ul
    {margin-bottom:0in;}
-->
</style>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=FR-BE style='font-size:24.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Conditions Générales Clients</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Les présentes &quot;Conditions Générales Clients&quot;
s'appliquent à la relation entre one2three et les Clients. Les Commerçants sont
invités à consulter les &quot;Conditions Générales Commerçants&quot;.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=FR-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1. Définitions</span></p>

</div>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Offre </span></b><span lang=FR-BE style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>: les offres de produits
et de services du Commerçant qui peuvent être commandées par le Client auprès du
Commerçant via la Plateforme.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>Commande </b>: une commande passée par un Client via la Plateforme auprès du
Commerçant de l'Offre sélectionnée par le Client.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Conditions Générales Clients</span></b><span lang=FR-BE
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>
: les présentes conditions générales.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Conditions Générales Commerçants</span></b><span lang=FR-BE
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>
: les conditions générales applicables à l’Offre des Commerçants.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Contrat&nbsp;: </span></b><span lang=FR-BE style='font-size:
10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>un contrat entre
le Client et le Commerçant relatif à une Commande et à la livraison ou à
l'encaissement de la Commande.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Client </span></b><span lang=FR-BE style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>: une personne physique
ou morale qui passe une Commande via la Plateforme.<br>
 <br>
<b>Plateforme </b>: le(s) site(s) web, les apps, les outils et les autres
ressources de one2three et de ses sociétés affiliées et partenaires commerciaux
sur lesquels le Service est mis à disposition.<br>
<br>
<b>Commerçant : </b>une entreprise qui prépare et vend des aliments, des
boissons et des articles connexes et qui utilise la Plateforme pour la conclusion
et le paiement des Contrats.<br>
<br>
<b>Informations Commerçant </b>: les informations fournies par le Commerçant concernant,
entre autres, l'entreprise et les coordonnées, les informations générales, la
gamme de produits (plats, accompagnements, options et boissons), les prix de
chaque produit individuel (TVA comprise), le logo de l'entreprise, le matériel
graphique, la zone de livraison, les frais de livraison et le montant minimum
de commande.<br>
<br>
<b>Service </b>: les services et/ou activités commerciales proposés par one2three
au Commerçant, y compris la publication de l'Offre, la facilitation de la
conclusion du Contrat et la transmission des Commandes des Clients au
Commerçant concerné.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<b>one2three </b>: one2three NV (SA) agissant en son nom et au nom de toute
entité légale ou personne contrôlant directement ou indirectement.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=FR-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2. Identité de one2three</span></p>

</div>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three NV opérant sous le nom de &quot;one2three&quot;.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Siège  :</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Avenue de Tervueren 34, b13</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1040 Bruxelles</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Belgique<br>
BCE BE 0535.701.405</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
Adresse de correspondance :<br>
Mijlstraat 153</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2570 Duffel</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Belgique</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
Courriel : </span><span lang=NL-BE><a href="mailto:info@one2three.be"><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif'>info&#64;one2three.be</span></a></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
Tél : +32 2 50 30 620 (disponible tous les jours de la semaine de 09h00 à
18h00)</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'><br>
<br>
</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=NL-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3. Applicabilité</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Les Conditions Générales Clients s'appliquent aux Commandes
passées par les Clients via la Plateforme dans le cadre du Service. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>En outre, le cas échéant, les Conditions Générales Commerçants s'appliquent
à l'Offre.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Le Client, en passant une Commande selon l’article 5.1, conclut immédiatement
un Contrat avec le Commerçant pour la livraison de l'Offre sélectionnée par le
Client. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Selon le livre VI du Code belge de droit économique, le Client
ne dispose pas de droit de renonciation pour les produits alimentaires et les
biens de consommation périssables. Le Client est donc lié par la Commande, sauf
en cas de défaut de Service.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;line-height:normal'><span lang=FR-BE
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;line-height:
normal;border:none;padding:0in'><span lang=NL-BE style='font-size:18.0pt;
font-family:"Century Gothic",sans-serif;color:#111111'>4. L'Offre</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three publie l'Offre sur la Plateforme pour le compte du
Commerçant, conformément aux Informations du Commerçant. One2three décline
toute responsabilité quant au contenu de l'Offre et des Informations du
Commerçant sur la Plateforme. Le Commerçant peut utiliser des ingrédients et
des additifs pour les plats et les boissons, qui peuvent provoquer des
allergies et des intolérances. Si un Client est allergique à certains aliments,
nous recommandons de contacter le Commerçant par téléphone pour obtenir des
informations actuelles sur les allergènes avant de passer la Commande.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three décline généralement toute responsabilité quant à la
qualité des produits et services fournis par le Commerçant conformément à une Commande
passée par la Plateforme, ou quant à tout défaut caché ou non caché de ces
produits. One2three ne sera responsable ou obligée de verser des indemnités
qu'en cas de négligence grave concernant ses obligations en vertu du Contrat,
ou en cas de faute intentionnelle ou de fraude.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>En tout état de cause, la responsabilité de one2three est
limitée aux dommages directs, à l'exclusion de tout dommage indirect.</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=FR-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=NL-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>5. Le Contrat</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Le Contrat est conclu au moment où le Client passe la Commande
en cliquant sur le bouton &quot;Confirmer la Commande&quot; lors du processus
de passation d'une Commande via la Plateforme.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Après avoir passé la Commande, one2three confirmera la Commande
au Client par voie électronique.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Le Contrat ne peut être exécuté par le Commerçant que si le
Client fournit des coordonnées et une adresse correcte et complète lors de la
passation de la Commande. Le Client a le devoir de signaler immédiatement à one2three
ou au Commerçant les inexactitudes dans les données de paiement fournies ou
déclarées.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>En ce qui concerne les informations relatives au statut de sa Commande,
le Client doit pouvoir être contacté par le Commerçant et one2three par
téléphone ou par courrier électronique (comme indiqué lors de la Commande)
après avoir passé la Commande.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Si le Client choisit de se faire livrer la Commande, le Client
doit être présent à l'adresse de livraison indiquée par le Client afin de
recevoir la Commande.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Si le Client choisit de récupérer la Commande, le Client doit
être présent au lieu de retrait du Commerçant à l'heure communiquée, telle
qu'indiquée dans le courriel de confirmation, le SMS ou la notification push
par la Plateforme.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>A la livraison de la Commande, le Commerçant peut demander une
identification du Client si la Commande contient des produits alcoolisés ou
d'autres produits avec une limite d'âge. Si le Client ne peut pas s'identifier
de manière adéquate ou ne remplit pas les conditions d'âge minimum, le Commerçant
refusera de livrer les produits concernés au Client. Dans ce cas, des frais
d'annulation peuvent être facturés.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three n'assume aucune responsabilité en rapport avec
l'exécution du Contrat.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=FR-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>6. Dissolution du contrat et annulation de la Commande</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Les Commandes ne peuvent pas être annulées par le Client chez one2three
ou via la Plateforme. L'annulation de la Commande par le Client n'est possible
qu'avec le consentement exprès du Commerçant.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Le Commerçant a le droit d'annuler la Commande si, y compris,
mais sans s'y limiter, l'Offre n'est plus disponible, le Client a fourni un
numéro de téléphone ou d'autres coordonnées incorrectes ou injoignables ou en
cas de force majeure.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Si le Client passe une fausse Commande (par exemple en
fournissant des coordonnées incorrectes, en omettant de payer ou en omettant
d'être présent au lieu de livraison ou de collecte pour recevoir la Commande)
ou ne respecte pas ses obligations en vertu du Contrat, one2three peut décider
de refuser les futures Commandes du Client concerné.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three est en droit de refuser des Commandes au nom du Commerçant
et d'annuler des Contrats s'il existe un doute raisonnable sur l'exactitude ou
l'authenticité de la Commande ou des coordonnées du Client. Si one2three annule
une Commande qui a déjà été payée, one2three s'assurera que ce montant est
transféré sur le même compte que celui à partir duquel le paiement a été
effectué. Si le Client passe des Commandes apparemment fausses ou frauduleuses,
one2three peut les signaler à la police.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=NL-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>7. Paiement</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Au moment où le Contrat est conclu conformément à l'article 5.1
des présentes Conditions Générales Clients, le Client est tenu de payer le Commerçant
pour la Commande. Cette obligation de paiement peut être remplie par le Client
en payant avec un mode de paiement en ligne via la Plateforme ou en payant le Commerçant
à la porte ou au lieu de retrait.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Sous réserve des dispositions de l'article 6.4 des présentes Conditions
Générales Clients, un paiement en ligne ne peut être (partiellement) annulé que
si la Commande ne peut être (entièrement) livrée. L'annulation sera toujours
effectuée sur le même compte que celui à partir duquel le paiement a été
effectué et sera exécutée par le Commerçant.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=NL-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>8. Procédure de plainte</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Les réclamations du Client concernant l'Offre, la Commande ou
l'exécution du Contrat doivent être soumises au Commerçant. La seule
responsabilité de l'Offre du Commerçant et de l'exécution du Contrat incombe au
Commerçant. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>Si le Client a des réclamations relatives à la Plateforme et aux
Commandes passées par les Clients via la Plateforme dans le cadre du Service,
il peut contacter one2three par courriel ou par téléphone.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid #CCCCCC 1.0pt;padding:0in 0in 0in 0in'>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;border:none;
padding:0in'><span lang=FR-BE style='font-size:18.0pt;font-family:"Century Gothic",sans-serif;
color:#111111'>9. Consultation et correction des données personnelles stockées</span></p>

</div>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:15.0pt;text-align:justify;text-indent:-.25in;line-height:normal'><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=FR-BE style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;
color:#111111'>One2three traite les données personnelles du Client. Le
traitement des données personnelles est soumis à la </span><span lang=NL-BE><a
href="https://www.one2three.app/privacy-policy"><span lang=FR-BE
style='font-size:10.5pt;font-family:"Century Gothic",sans-serif;color:#1574F5'>déclaration
de confidentialité</span></a></span><span lang=FR-BE style='font-size:10.5pt;
font-family:"Century Gothic",sans-serif;color:#111111'>.</span></p>

<p class=MsoNormal><span lang=FR-BE>&nbsp;</span></p>

</div>



</ng-container>

</div>
