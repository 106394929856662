<div class="first-part">
    <app-navbar [background]="true" [frontColor]="'darkGrey'"></app-navbar>
    <!--
        <app-navbar [background]="true" [frontColor]="'darkGrey'" [fol_bg]="true"></app-navbar>
    -->
</div>

<div id="content" class="container" style="padding-top:0px;padding-bottom:50px;">

<ng-container *ngIf="translate.currentLang == 'en'">

  <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>&lsquo;one2three NV&rsquo; Privacy Statement</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>https://one2three.community</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Welcome to our privacy policy! What to expect from our privacy policy?</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>1/ Introduction and explanation of some privacy concepts</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>2/ Who we are</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>3/ What information about you can we process and why?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>4/ Who receives the personal data?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>5/ How long do we keep your personal data</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>6/ What rights do you have to guarantee the protection of your personal data?&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>7/ How are your data secured?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>1/ INTRODUCTION AND EXPLANATION OF SOME PRIVACY CONCEPTS:</span></strong></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Your privacy is important to us, as it is to you. One2three NV makes every effort to process your personal data in a proper, legal and transparent way. This Privacy Statement applies to the website:<br>&nbsp;the one2three community websitehttps://one2three.community/, the web app, the Android app, the iOS App owned by and made available by one2three NV.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Further details about the general processing of personal data concepts are available from the privacy commission at the following address:&nbsp;</span><a href="https://www.dataprotectionauthority.be/privacy-topics"><span style=''>click here</span></a><span style=''>.&nbsp;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If you believe that we are failing to comply with your rights as set out below, you are entitled at all times to submit a report to the Data Protection Authority:<br> Drukpersstraat 35, 1000 Brussels, Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35, <br>&nbsp;e-mail: contact&#64;apd-gba.be</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>This privacy policy may change from time to time, for example as a result of one2three NV&rsquo;s new processing activities. We advise you to regularly check this privacy policy for any changes. We will always post the most current version on our website.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>2/ WHO WE ARE:</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;'><span style=''>The One2three NV company has developed an online ordering platform tailored to your snack bar, fries outlet, sandwich shop, bar, (company) restaurant, hotel, ... whose address is Kantersteen 57, 1000 Brussels and Crossroads Bank for Enterprises registration number is BE0535,701,405.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;'><span style=''>If you have any questions or would like more information, you can always contact the manager at the following e-mail address:&nbsp;</span><a href="mailto:info@one2three.be"><span style=''>info&#64;one2three.be</span></a><span style=''>.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>3/ WHAT INFORMATION ABOUT YOU CAN WE PROCESS AND WHY?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We collect data during the use of our ordering platform:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''><br>&nbsp;In order to be able to offer you the right products and services and depending on the situation, one2three NV needs certain types of information from you. The information that one2three NV requests and collects via its activities, use of the platform or via the website and apps and with which we can identify you, is called personal data. Below we explain why one2three NV requests personal data from you and for which purposes these will be used.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Information we collect from you for registering on our one2three ordering platform and the various apps:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>With registration:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>With one2three it is very easy to quickly place your orders at your favourite snack bar, French fries outlet, sandwich shop, bar, (company) restaurant, hotel, ... For this we ask you to register. As a user of our platform or one of our apps, the following personal data is collected:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We ask for your e-mail address in order to be able to register, identify you and process your order.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We ask you for your telephone number to contact you if this is necessary in connection with your order.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We ask you your language in order to be able to speak to you in the correct language.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We use location information such as your address to deliver your order correctly and to be able to recommend the right traders in your area.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Internet surfing and browsing behaviour can be tracked to prevent fraud and disputes as well as to improve and optimise our services. See our cookie policy for further details.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Technical data about the equipment you use may be kept for statistical purposes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We keep a record of your orders in case of disputes, for statistical purposes and to serve you faster and better the next time.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Data we can access through the consumer apps if you register:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>In order to process your orders in the best possible way and allow you to enjoy the right services, we have access to the following data via the consumer apps on your PC, smartphone or tablet:</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Your location information to show the nearest retailer.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Notifications to send you messages to confirm your orders</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Your camera to scan QR codes</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Without registration:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>With one2three it is very easy to quickly place your orders at your favourite snack bar, French fries outlet, sandwich shop, bar, (company) restaurant, hotel, ... For this purpose, we ask you to provide the information below. As a user of our platform or one of our apps, the following personal information is collected:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We ask for your e-mail address in order to register, identify you and process your order.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We ask you for your telephone number to contact you if this is necessary in connection with your order.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We ask you your language in order to be able to speak to you in the correct language.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We use location information such as your address to deliver your order correctly and to be able to recommend the right traders in your area.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Internet surfing and browsing behaviour can be tracked to prevent fraud and disputes as well as to improve and optimise our services. See our cookie policy for further details.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Technical data about the equipment you use may be kept for statistical purposes.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Payment details:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>A trader&rsquo;s method of payment is tracked in order to prevent fraud and disputes. We also keep track of the payment status in order to follow up the payment properly and correctly together with the trader.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Your bank or credit card number is processed via various secure payment systems that meet strict security standards. Bank and credit card numbers are used only for online payments and are not kept for other purposes.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Data we collect to prevent fraud, non-payment and bogus orders.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>To prevent fraudulent use and to limit the risk of undesirable activities on the platform of one2three and its traders, we collect your transaction data, information about where you purchased your products and information about your digital consent to make payments.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Improving our services:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>One2three stores and analyses your transaction data to improve the one2three services. This data is used to improve the user-friendliness of the platform and apps.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We process this data anonymously and cannot use it to identify you.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Information to our users and contacts:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If you have already used our platform, we may send you information or notifications, because of the justified interest in doing do. Recommendations concerning services and products available from one2three traders are based on your order history.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Cookies:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Cookies are small information files that are automatically stored on or read from the visitor&rsquo;s device (including a computer, tablet or smartphone) when a website is visited or an app is installed and used. This is done via the web browser or the app on the device. The information that a cookie obtains about your use of the websites or apps can be transferred to one2three&rsquo;s or a third party&rsquo;s own secure servers.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>See our cookiebot for more on our cookie policy.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>THIRD-PARTY WEBSITES</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>This privacy and cookie statement does not apply to third-party websites that are connected to our website via links. For example, this privacy and cookie statement does not apply to (online) traders where you buy products or services. We cannot guarantee that these third parties will handle your personal data in a reliable and safe manner. We therefore advise you to read the privacy statement of these websites before using these services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><u><span style=''>Data concerning suppliers, partners and service providers:</span></u></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Personal data necessary for the fulfilment of an agreement or contract with suppliers, partners or service providers is also collected. This mainly applies to contact details such as e-mail addresses and the telephone numbers of contact persons in order to facilitate communication</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><u><span style=''>Sensitive data:</span></u></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>As a data controller, we do not collect and process so-called sensitive data, namely:</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership;</span></li>
</ul>
<ul style="list-style-type: disc;">
    <li><span style=''>Genetic or biometric data (e.g. facial images and fingerprints);</span></li>
    <li><span style=''>Health data;</span></li>
    <li><span style=''>Data relating to sexual behaviour or sexual orientation.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Any such sensitive personal information that might be communicated to us would not be used by us and would be deleted.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><u><span style=''>Age:</span></u></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If you are under 13 years of age, you may not use our platform unless you have your parents&rsquo; permission. If your children under the age of 13 were to use our platform, you should provide us with the appropriate consent to process the data via the e-mail address&nbsp;</span><a href="mailto:info@one2three.be"><span style=''>info&#64;one2three.be</span></a><span style=''>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>As a data processor, we do not collect and process personal data of minors unless we have permission to do so.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>4/ WHO RECEIVES THE PERSONAL DATA?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Your personal data will normally not be passed on to other parties.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Only employees of one2three NV or companies related to one2three NV, which need your personal data to perform their duties, may consult your personal data except in the following cases:</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If required for the provision of our services:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>We work together with a number of third parties who may also process your personal data. These are recipients that operate exclusively in the European Union and individuals or organisations that have a direct relationship with one2three NV. These are, for example, partners or contractors responsible for activating and installing the application.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>The transfer of your data is limited to the data they need to perform their task on our behalf. We ensure that they, like us, manage your data securely, respectfully and with due care and we provide the contractual safeguards for this purpose.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If you give us permission to do so; if one2three NV were to provide personal data to third parties in other situations, this would be subject to an explicit notification, with an explanation concerning the third party, the purposes of the transfer and the processing.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If there is a legal obligation to share your data with government agencies.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>5/ HOW LONG DO WE KEEP YOUR PERSONAL DATA</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>It goes without saying that we store your personal data in accordance with any statutory time limits and any statute of limitations that may apply. For example, for invoices 7 years.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>As part of our customer management process, we keep data for as long as is necessary to achieve our goals and to be able to serve you effectively as a customer.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>As a registered user your data will be kept as long as necessary to achieve the required goals. You can always ask for your account to be deleted.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>6/ WHAT RIGHTS DO YOU HAVE TO GUARANTEE THE PROTECTION OF YOUR PERSONAL DATA</span></strong></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Right of access, inspection and rectification</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Right of access and inspection: you have the right to inspect, free of charge, any information we have about you and to find out what it is used for.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Right of rectification: you have the right to request the rectification of your incorrect personal data, as well as to add to incomplete personal data.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Right to object</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>You are entitled to object to the processing of your personal data on the grounds of serious and justifiable reasons. Please note, however, that you cannot object to the processing of personal data which is necessary for us to fulfil a legal obligation, the fulfilment of the contract or our legitimate interest, for as long as these data are necessary for the purposes for which they were collected.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>You are also entitled at any time to object to the use of personal data for direct marketing purposes. In such a case, you do not even have to provide reasons.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Right of free transfer of data</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>You are entitled to obtain the personal data you have provided to us in a structured, common and machine-readable form. You are entitled to transfer these data to another data controller.<strong>&nbsp;</strong></span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Right of withdrawal of consent</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Right of withdrawal of consent: if the processing of personal data is based on prior consent, you have the right to withdraw this consent. These personal data will then only be processed if we have another legal basis for doing so.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Right to restriction of processing</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>You may request that the processing of your personal data be restricted. If you believe that we are processing your personal data on an unlawful basis, you can ask for the processing of your personal data to be restricted. This means that we may only further process your personal data with your consent. We may continue to store your personal data, but may not use it for further processing without your consent, except in the context of legal proceedings, for the protection of natural or legal persons or for important grounds of public interest.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Automatic decisions and profiling&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Some data processing and processes are fully automated without any human intervention. However, we do not apply this to the processing of your personal data.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'>&nbsp;<strong><span style=''>Entitlement to exercise your rights&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;'><span style=''>If you wish to exercise one or more of these rights, please contact us at the above contact address and clearly specify which right you wish to exercise.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;'><span style=''>Please present proof of identity in order to be able to identify yourself if necessary.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Right to lodge a complaint</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>One2three NV does its utmost to process your personal data in a correct, legal and transparent manner.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>If you nevertheless believe that your rights have been infringed and your concerns have not been addressed within our company, you are at liberty to lodge a complaint with the Data Protection Authority: Drukpersstraat 35, 1000 Brussels, Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35, e-mail: contact&#64;apd-gba.be</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>7/ HOW IS YOUR DATA SECURED?</span></strong><span style=''><br>&nbsp;One2three NV has developed safety measures that are suitably adapted on a technical and organisational level in order to avoid the destruction, loss, falsification, alteration, unauthorised access or notification by mistake to third parties of any personal data collected, as well as any other unauthorised processing of these data.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>In the unlikely event that your personal data is involved in an information security breach, we will take immediate action to identify the breach and take appropriate action. If necessary, we will notify you of this incident as required by law.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'>&nbsp;</p>
  
</ng-container>

<ng-container *ngIf="translate.currentLang == 'fr'">
  

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>D&eacute;claration de one2three SA sur la protection de la vie priv&eacute;e</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>https://one2three.community</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous avons le plaisir de vous pr&eacute;senter notre politique de protection de la vie priv&eacute;e. Que contient-elle ?</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>1 / Introduction et explication de quelques notions cl&eacute;s</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>2/ Qui sommes-nous ?&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>3 / Quelles donn&eacute;es vous concernant pouvons-nous traiter et dans quel but&nbsp;?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>4/ Qui re&ccedil;oit les donn&eacute;es &agrave; caract&egrave;re personnel&nbsp;?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>5 / Combien de temps gardons-nous vos donn&eacute;es &agrave; caract&egrave;re personnel&nbsp;?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>6 / De quels droits disposez-vous pour garantir la protection de vos donn&eacute;es &agrave; caract&egrave;re personnel ?&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>7 / Comment vos donn&eacute;es sont-elles prot&eacute;g&eacute;es ?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>1 / INTRODUCTION ET EXPLICATION DE QUELQUES NOTIONS CL&Eacute;S&nbsp;:</span></strong></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Tout comme vous, nous sommes tr&egrave;s attach&eacute;s au respect de votre priv&eacute;e. One2three SA met tout en &oelig;uvre pour traiter vos donn&eacute;es &agrave; caract&egrave;re personnel d&rsquo;une mani&egrave;re correcte, transparente et conforme &agrave; la loi.<br>&nbsp;La pr&eacute;sente D&eacute;claration sur la protection de la vie priv&eacute;e s&rsquo;applique au site web de la communaut&eacute; one2three (https://one2three.community/), &agrave; l&rsquo;application web, &agrave; l&rsquo;app Android et &agrave; l&rsquo;app iOS mis &agrave; disposition par one2three SA et dont celle-ci est propri&eacute;taire.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous souhaitez en savoir davantage sur les notions g&eacute;n&eacute;rales relatives au traitement des donn&eacute;es &agrave; caract&egrave;re personnel, vous pouvez consulter le site de l&rsquo;Autorit&eacute; de protection des donn&eacute;es via ce lien :&nbsp;</span><a href="https://www.autoriteprotectiondonnees.be/themes-de-vie-privee"><span style=''>https://www.autoriteprotectiondonnees.be/themes-de-vie-privee</span></a><span style=''>.&nbsp;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous estimez que nous ne respectons pas vos droits tels qu&rsquo;&eacute;nonc&eacute;s ci-dessous, vous pouvez &agrave; tout moment introduire un signalement aupr&egrave;s de l&rsquo;Autorit&eacute; de protection des donn&eacute;es&nbsp;:<br>&nbsp;rue de la Presse 35, 1000 Bruxelles, t&eacute;l. +32 (0) 2 274 48 00, fax +32 (0) 2 274 48 35, e-mail : contact&#64;apd-gba.be.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Cette politique de protection de la vie priv&eacute;e peut &ecirc;tre modifi&eacute;e &agrave; la suite, par exemple, de nouvelles activit&eacute;s de traitement dans le chef de one2three SA. Nous vous conseillons de v&eacute;rifier r&eacute;guli&egrave;rement si notre politique a subi des modifications. Nous pla&ccedil;ons toujours sur notre site web la version la plus r&eacute;cente.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>2/ QUI SOMMES-NOUS ?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>One2three SA est une entreprise qui a d&eacute;velopp&eacute; une plate-forme de commande en ligne adapt&eacute;e aux snacks, friteries, sandwicheries, bars, restaurants (d&rsquo;entreprise), h&ocirc;tels, ..., &eacute;tablie Avenue de Tervuren 34, 1040 Etterbeek et inscrite aupr&egrave;s de la Banque-Carrefour des Entreprises sous le num&eacute;ro BE0535.701.405.<br>&nbsp;Si vous avez des questions ou si vous souhaitez un compl&eacute;ment d&rsquo;information, vous pouvez vous adresser au g&eacute;rant &agrave; l&rsquo;adresse e-mail info&#64;one2three.be.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>3 / QUELLES DONN&Eacute;ES VOUS CONCERNANT POUVONS-NOUS TRAITER ET DANS QUEL BUT&nbsp;?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous recueillons des donn&eacute;es dans le cadre de l&rsquo;utilisation de notre plate-forme de commande&nbsp;:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Afin de pouvoir offrir les services appropri&eacute;s, one2three SA a besoin de certaines informations de votre part. Les informations que one2three SA demande et recueille &agrave; travers l&rsquo;utilisation de la plate-forme, le site web ou les diff&eacute;rentes apps et qui nous permettent de vous identifier sont appel&eacute;es &lsquo;donn&eacute;es &agrave; caract&egrave;re personnel&rsquo;. Nous expliquons ci-dessous pourquoi one2three SA vous demande des donn&eacute;es &agrave; caract&egrave;re personnel et &agrave; quelles fins elles seront utilis&eacute;es.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es vous concernant que nous recueillons pour l&rsquo;inscription sur notre plate-forme de commande one2three et les diff&eacute;rentes apps&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Avec inscription&nbsp;:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Avec one2three, vous pouvez tr&egrave;s facilement et rapidement passer vos commandes aupr&egrave;s de votre snack, friterie, sandwicherie, bar, restaurant (d&rsquo;entreprise), h&ocirc;tel, ... favori. Pour cela, nous vous demandons de vous inscrire. En tant qu&rsquo;utilisateur de notre plate-forme ou de l&rsquo;une de nos apps, vos donn&eacute;es personnelles sont recueillies comme suit&nbsp;:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous demandons votre adresse e-mail pour pouvoir vous inscrire, vous identifier et traiter votre commande.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous demandons votre num&eacute;ro de t&eacute;l&eacute;phone pour pouvoir vous contacter si n&eacute;cessaire au sujet de votre commande.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous demandons la langue afin de nous adresser &agrave; vous dans la bonne langue.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous utilisons les donn&eacute;es de localisation, comme votre adresse, pour pouvoir vous livrer correctement la commande et vous recommander des commer&ccedil;ants dans votre quartier.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es de navigation peuvent &ecirc;tre enregistr&eacute;es pour pr&eacute;venir la fraude et les contestations ainsi que pour am&eacute;liorer et optimiser nos services. Pour plus d&rsquo;information &agrave; ce sujet, veuillez consulter notre politique relative aux cookies.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es techniques de l&rsquo;&eacute;quipement que vous utilisez peuvent &ecirc;tre enregistr&eacute;es &agrave; des fins statistiques.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous tenons &agrave; jour la liste de vos commandes en cas de contestation, &agrave; des fins statistiques, ou encore pour pouvoir vous servir mieux et plus rapidement la fois suivante.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Donn&eacute;es auxquelles nous avons acc&egrave;s via les apps pour consommateurs si vous vous inscrivez&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Pour un traitement optimal de votre commande et pour vous permettre de b&eacute;n&eacute;ficier des services appropri&eacute;s, nous avons acc&egrave;s, via les apps pour consommateurs sur votre PC, smartphone ou tablette, aux donn&eacute;es suivantes&nbsp;:</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>vos donn&eacute;es de localisation pour pouvoir vous indiquer le commer&ccedil;ant le plus proche&nbsp;;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>notifications pour vous envoyer des messages afin de confirmer vos commandes ;&nbsp;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>votre appareil photo pour pouvoir scanner les codes QR.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Sans inscription&nbsp;:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Avec one2three, vous pouvez tr&egrave;s facilement et rapidement passer vos commandes aupr&egrave;s de votre snack, friterie, sandwicherie, bar, restaurant (d&rsquo;entreprise), h&ocirc;tel, ... favori. Pour cela, nous vous demandons de nous communiquer les donn&eacute;es suivantes. En tant qu&rsquo;utilisateur de notre plate-forme ou de l&rsquo;une de nos apps, vos donn&eacute;es personnelles sont recueillies comme suit&nbsp;:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous demandons votre adresse e-mail pour pouvoir vous inscrire, vous identifier et traiter votre commande.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous demandons votre num&eacute;ro de t&eacute;l&eacute;phone pour pouvoir vous contacter si n&eacute;cessaire au sujet de votre commande.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous utilisons les donn&eacute;es de localisation, comme votre adresse, pour pouvoir vous livrer correctement la commande et vous recommander des commer&ccedil;ants dans votre quartier.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es de navigation peuvent &ecirc;tre enregistr&eacute;es pour pr&eacute;venir la fraude et les contestations ainsi que pour am&eacute;liorer et optimiser nos services. Pour plus d&rsquo;information &agrave; ce sujet, veuillez consulter notre politique relative aux cookies.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es techniques de l&rsquo;&eacute;quipement que vous utilisez peuvent &ecirc;tre enregistr&eacute;es &agrave; des fins statistiques.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Donn&eacute;es relatives &agrave; votre paiement&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Le mode de paiement chez un commer&ccedil;ant est enregistr&eacute; pour pr&eacute;venir la fraude et les contestations. Nous enregistrons aussi le statut du paiement pour pouvoir suivre correctement le paiement avec le commer&ccedil;ant.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Le num&eacute;ro de votre carte bancaire ou carte de cr&eacute;dit est trait&eacute; par diff&eacute;rents syst&egrave;mes de paiement s&eacute;curis&eacute;s qui r&eacute;pondent &agrave; des normes de s&eacute;curit&eacute; strictes. Les num&eacute;ros de cartes bancaires et cartes de cr&eacute;dit ne sont utilis&eacute;s que pour les paiements en ligne et ne sont pas conserv&eacute;s &agrave; d&rsquo;autres fins.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Donn&eacute;es que nous recueillons pour pr&eacute;venir la fraude, les d&eacute;fauts de paiement et les fausses commandes.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Pour pr&eacute;venir l&rsquo;utilisation frauduleuse et le risque d&rsquo;activit&eacute;s ind&eacute;sirables sur la plate-forme de one2three et de ses commer&ccedil;ants, nous recueillons vos donn&eacute;es transactionnelles, des informations sur l&rsquo;endroit o&ugrave; vous avez achet&eacute; vos produits et des informations sur votre autorisation num&eacute;rique pour l&rsquo;ex&eacute;cution de paiements.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>L&rsquo;am&eacute;lioration de nos services&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>One2three conserve et analyse vos donn&eacute;es transactionnelles afin d&rsquo;am&eacute;liorer les services de one2three. Ces donn&eacute;es sont utilis&eacute;es en vue d&rsquo;am&eacute;liorer la facilit&eacute; d&rsquo;emploi de la plate-forme et des applications.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous traitons ces donn&eacute;es de fa&ccedil;on anonyme, si bien qu&rsquo;il est impossible de vous identifier.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Informations destin&eacute;es &agrave; nos clients et contacts&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous avez d&eacute;j&agrave; utilis&eacute; notre plate-forme, il se peut que nous vous envoyions des informations ou des notifications. Nous nous basons, dans ce cadre, sur l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime. Pour vous recommander des services et des produits disponibles chez les commer&ccedil;ants one2three, nous nous basons sur l&rsquo;historique de vos commandes.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Cookies&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les cookies sont des petits fichiers de donn&eacute;es qui, lors de la visite sur un site web ou lors de l&rsquo;installation et de l&rsquo;utilisation d&rsquo;une app, sont automatiquement plac&eacute;s sur l&rsquo;appareil du visiteur (ordinateur, tablette ou smartphone) ou sont lus &agrave; partir de cet appareil. Ceci s&rsquo;effectue par le biais du navigateur ou de l&rsquo;app install&eacute;e sur l&rsquo;appareil. Les informations relatives &agrave; votre utilisation des sites web ou des apps qui sont recueillies par un cookie peuvent &ecirc;tre transf&eacute;r&eacute;es vers les serveurs s&eacute;curis&eacute;s de one2three ou d&rsquo;un tiers.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Pour en savoir plus sur notre politique en mati&egrave;re de cookies, veuillez vous r&eacute;f&eacute;rer &agrave; notre &laquo;&nbsp;Cookiebot&nbsp;&raquo;.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>SITES WEB DE TIERS</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>La pr&eacute;sente d&eacute;claration sur la protection de la vie priv&eacute;e et les cookies ne s&rsquo;applique pas aux sites web de tiers qui sont reli&eacute;s &agrave; notre site web par des liens. Cette d&eacute;claration ne s&rsquo;applique pas, par exemple, aux commer&ccedil;ants (en ligne) aupr&egrave;s desquels vous achetez des produits ou des services. Nous ne pouvons pas garantir que ces tiers traitent vos donn&eacute;es personnelles de mani&egrave;re fiable et s&eacute;curis&eacute;e. Nous vous conseillons d&egrave;s lors de lire la d&eacute;claration de vie priv&eacute;e de ces sites avant de recourir &agrave; ces services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><u><span style=''>Donn&eacute;es relatives aux fournisseurs, partenaires et prestataires de services&nbsp;:</span></u></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es &agrave; caract&egrave;re personnel qui sont requises pour l&rsquo;ex&eacute;cution d&rsquo;un contrat ou d&rsquo;une commande avec des fournisseurs, des partenaires ou des prestataires de services sont &eacute;galement recueillies. Il s&rsquo;agit principalement d&rsquo;informations de contact, comme les adresses e-mail et les num&eacute;ros de t&eacute;l&eacute;phone des personnes de contact pour rendre la communication possible.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><u><span style=''>Donn&eacute;es sensibles&nbsp;</span></u><span style=''>:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>En tant que responsable du traitement des donn&eacute;es, nous ne recueillons et ne traitons pas de donn&eacute;es dites sensibles, telles que&nbsp;:</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>donn&eacute;es &agrave; caract&egrave;re personnel r&eacute;v&eacute;lant l&rsquo;origine raciale ou ethnique, les opinions politiques, les convictions religieuses ou philosophiques ou l&rsquo;appartenance &agrave; un syndicat&nbsp;;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>donn&eacute;es g&eacute;n&eacute;tiques ou biom&eacute;triques (p. ex. images faciales et empreintes digitales)&nbsp;;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>donn&eacute;es concernant la sant&eacute;&nbsp;;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>donn&eacute;es relatives au comportement sexuel ou &agrave; l&rsquo;orientation sexuelle.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si ce genre d&rsquo;informations personnelles sensibles nous &eacute;taient communiqu&eacute;es, nous ne les utiliserions pas et nous les supprimerions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><u><span style=''>&Acirc;ge :</span></u></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous avez moins de 13 ans, vous ne pouvez utiliser notre plate-forme qu&rsquo;avec l&rsquo;autorisation de vos parents. Si vos enfants de moins de 13 ans souhaitent utiliser notre plate-forme, veuillez d&egrave;s lors nous transmettre l&rsquo;autorisation requise pour le traitement des donn&eacute;es &agrave; l&rsquo;adresse e-mail info&#64;one2three.be.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>En tant que sous-traitant des donn&eacute;es, nous ne recueillons et ne traitons pas de donn&eacute;es &agrave; caract&egrave;re personnel concernant des mineurs d&rsquo;&acirc;ge, sauf autorisation.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>4/ QUI RE&Ccedil;OIT LES DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL&nbsp;?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Vos donn&eacute;es &agrave; caract&egrave;re personnel ne sont, en principe, pas transmises &agrave; d&rsquo;autres parties.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Seuls les collaborateurs de one2three SA ou d&rsquo;entreprises li&eacute;es &agrave; one2three SA qui, pour l&rsquo;ex&eacute;cution de leur fonction, ont besoin de vos donn&eacute;es &agrave; caract&egrave;re personnel, peuvent consulter vos donn&eacute;es &agrave; caract&egrave;re personnel, except&eacute; dans les cas suivants&nbsp;:</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si cela est n&eacute;cessaire pour nos services&nbsp;:</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Nous travaillons avec un certain nombre de tiers qui peuvent &eacute;galement traiter vos donn&eacute;es &agrave; caract&egrave;re personnel. <br>&nbsp;Il s&rsquo;agit de destinataires qui op&egrave;rent exclusivement dans l&rsquo;Union europ&eacute;enne et de personnes ou d&rsquo;organisations en relation directe avec One2three SA. Ce sont par exemple des partenaires ou des installateurs pour l&rsquo;activation et l&rsquo;installation de l&rsquo;application.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>La transmission de vos donn&eacute;es est limit&eacute;e aux donn&eacute;es dont ils ont besoin pour exercer leurs t&acirc;ches &agrave; notre demande. Nous nous assurons qu&rsquo;ils traitent vos donn&eacute;es, tout comme nous, d&rsquo;une mani&egrave;re s&ucirc;re, respectueuse et en bon p&egrave;re de famille et nous pr&eacute;voyons &agrave; cet effet les garanties contractuelles.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous nous y autorisez&nbsp;: dans l&rsquo;&eacute;ventualit&eacute; o&ugrave; one2three SA fournit, dans d&rsquo;autres situations, des donn&eacute;es &agrave; caract&egrave;re personnel &agrave; des tiers, elle le communique explicitement, en donnant des explications sur le tiers en question, la finalit&eacute; de la transmission et le traitement.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>S&rsquo;il y a une obligation l&eacute;gale de partager vos donn&eacute;es avec des instances publiques.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>5 / COMBIEN DE TEMPS GARDONS-NOUS VOS DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL&nbsp;?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Il va de soi que nous conservons vos donn&eacute;es &agrave; caract&egrave;re personnel conform&eacute;ment aux d&eacute;lais prescrits par la loi et aux d&eacute;lais de prescription &eacute;ventuellement applicables. Par exemple 7 ans dans le cas de factures.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Les donn&eacute;es dans le cadre de la gestion de nos clients sont conserv&eacute;es le temps n&eacute;cessaire pour atteindre nos objectifs et pouvoir vous offrir un bon service en tant qu&rsquo;utilisateur.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>En tant qu&rsquo;utilisateur enregistr&eacute;, vos informations sont conserv&eacute;es aussi longtemps que n&eacute;cessaire pour atteindre les objectifs. Vous pouvez &agrave; tout moment demander que votre compte soit supprim&eacute;.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>6 / DE QUELS DROITS DISPOSEZ-VOUS POUR GARANTIR LA PROTECTION DE VOS DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL&nbsp;?</span></strong></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit d&rsquo;acc&egrave;s, de consultation et de rectification&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Droit d&rsquo;acc&egrave;s et de consultation : vous avez le droit de prendre connaissance gratuitement des donn&eacute;es vous concernant que nous d&eacute;tenons et de v&eacute;rifier &agrave; quelles fins elles sont utilis&eacute;es.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Droit de rectification : vous avez le droit d&rsquo;obtenir la rectification de vos donn&eacute;es &agrave; caract&egrave;re personnel incorrectes, ainsi que de compl&eacute;ter des donn&eacute;es &agrave; caract&egrave;re personnel incompl&egrave;tes.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit d&rsquo;opposition</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Vous avez le droit de faire opposition au traitement de vos donn&eacute;es &agrave; caract&egrave;re personnel pour des motifs graves et l&eacute;gitimes. Cependant, veuillez noter que vous ne pouvez pas vous opposer au traitement des donn&eacute;es &agrave; caract&egrave;re personnel n&eacute;cessaires &agrave; l&rsquo;ex&eacute;cution d&rsquo;une obligation l&eacute;gale, &agrave; l&rsquo;ex&eacute;cution du contrat ou &agrave; notre int&eacute;r&ecirc;t l&eacute;gitime, tant que ces donn&eacute;es sont n&eacute;cessaires aux fins pour lesquelles elles ont &eacute;t&eacute; collect&eacute;es.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>En outre, vous avez toujours le droit de vous opposer &agrave; l&rsquo;utilisation des donn&eacute;es personnelles &agrave; des fins de marketing direct ; dans ce cas, vous n&rsquo;avez m&ecirc;me pas &agrave; motiver votre opposition.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit &agrave; la libre transmission des donn&eacute;es&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Vous avez le droit d&rsquo;obtenir les donn&eacute;es &agrave; caract&egrave;re personnel que vous nous avez fournies sous une forme structur&eacute;e, courante et lisible par machine. Vous avez &eacute;galement le droit de transf&eacute;rer vos donn&eacute;es personnelles &agrave; un autre responsable du traitement.<strong>&nbsp;</strong></span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit au retrait du consentement&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Droit au retrait du consentement&nbsp;: dans la mesure o&ugrave; le traitement des donn&eacute;es &agrave; caract&egrave;re personnel est fond&eacute; sur le consentement pr&eacute;alable, vous avez le droit de retirer ce consentement. Ces donn&eacute;es personnelles ne seront alors trait&eacute;es que si nous disposons &agrave; cet effet d&rsquo;une autre base l&eacute;gale.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit &agrave; la limitation du traitement</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Vous pouvez demander que le traitement de vos donn&eacute;es &agrave; caract&egrave;re personnel soit limit&eacute;. Si vous estimez que nous traitons vos donn&eacute;es &agrave; caract&egrave;re personnel sur une base ill&eacute;gitime, vous pouvez demander d&rsquo;en limiter le traitement. Cela signifie que nous ne pouvons continuer &agrave; traiter vos donn&eacute;es &agrave; caract&egrave;re personnel qu&rsquo;avec votre consentement. Nous pouvons continuer &agrave; stocker vos donn&eacute;es &agrave; caract&egrave;re personnel, mais nous ne pouvons plus effectuer de traitement sur ces donn&eacute;es sans votre consentement, sauf dans le cadre d&rsquo;une proc&eacute;dure judiciaire, pour la protection des personnes physiques ou morales ou pour des motifs importants d&rsquo;int&eacute;r&ecirc;t g&eacute;n&eacute;ral.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>D&eacute;cisions automatiques et profilage&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Certains processus et traitements de donn&eacute;es se d&eacute;roulent de fa&ccedil;on enti&egrave;rement automatis&eacute;e, sans intervention humaine. Nous n&rsquo;y avons cependant pas recours en ce qui concerne le traitement de vos donn&eacute;es &agrave; caract&egrave;re personnel.</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit &agrave; l&rsquo;exercice de vos droits</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous souhaitez exercer un ou plusieurs de ces droits, veuillez nous contacter &agrave; l&rsquo;adresse de contact susmentionn&eacute;e et pr&eacute;ciser clairement quel droit vous souhaitez exercer. <br>&nbsp;Veuillez dans ce cas pr&eacute;senter une preuve d&rsquo;identit&eacute; afin que nous puissions vous identifier si n&eacute;cessaire.&nbsp;</span></p>
<ul style="margin-bottom:0cm;" type="disc">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>Droit d&rsquo;introduire une plainte&nbsp;</span></strong></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>One2three SA met tout en &oelig;uvre pour traiter vos donn&eacute;es &agrave; caract&egrave;re personnel d&rsquo;une mani&egrave;re correcte, transparente et conforme &agrave; la loi.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Si vous estimez n&eacute;anmoins que vos droits ont &eacute;t&eacute; enfreints et que vos pr&eacute;occupations ne sont pas entendues au sein de notre entreprise, vous &ecirc;tes en droit d&rsquo;introduire une plainte aupr&egrave;s de l&rsquo;Autorit&eacute; de protection des donn&eacute;es : rue de la Presse 35, 1000 Bruxelles, t&eacute;l. +32 (0) 2 274 48 00, fax +32 (0) 2 274 48 35, e-mail : contact&#64;apd-gba.be.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><strong><span style=''>7 / COMMENT VOS DONN&Eacute;ES SONT-ELLES PROT&Eacute;G&Eacute;ES ?&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>One2three SA a mis en place des mesures de s&eacute;curit&eacute; adapt&eacute;es sur le plan technique et organisationnel pour &eacute;viter la destruction, la perte, la falsification, l&rsquo;alt&eacute;ration, l&rsquo;acc&egrave;s non autoris&eacute; ou la notification erron&eacute;e &agrave; des tiers de donn&eacute;es &agrave; caract&egrave;re personnel, ainsi que tout autre traitement non autoris&eacute; de ces donn&eacute;es.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'><span style=''>Dans le cas peu probable o&ugrave; vos donn&eacute;es &agrave; caract&egrave;re personnel sont impliqu&eacute;es dans une infraction &agrave; la protection des informations, nous proc&eacute;derons imm&eacute;diatement &agrave; l&rsquo;identification de l&rsquo;infraction et &agrave; l&rsquo;adoption des mesures ad&eacute;quates. S&rsquo;il y a lieu, nous vous informerons de cet incident comme le pr&eacute;voit la loi.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;'>&nbsp;</p>
  
</ng-container>

<ng-container *ngIf="translate.currentLang == 'nl'">

<div style="padding-bottom:20px;">
    <h3>Privacy verklaring<br>'one2three NV'</h3>
    <h4>https://one2three.community</h4>
</div>

<p class="p1">Welkom op ons privacy beleid! Wat mag je verwachten in ons privacy beleid?</p>
<p class="p1"><b>1/ Inleiding en uitleg enkele privacy begrippen</b></p>
<p class="p1"><b>2/ Wie zijn wij<span class="Apple-converted-space"> </span></b></p>
<p class="p1"><b>3/ Welke gegevens kunnen wij van jou verwerken en waarom?</b></p>
<p class="p1"><b>4/ Wie ontvangt de persoonsgegevens?</b></p>
<p class="p1"><b>5/ Hoelang bewaren wij uw persoonsgegevens</b></p>
<p class="p1"><b>6/ Welke rechten heb je om de bescherming van je persoonsgegevens te garanderen<span class="Apple-converted-space"> </span></b></p>
<p class="p1"><b>7/ Hoe worden uw gegevens beveiligd?</b></p>
<p class="p2"><b></b><br></p>
<p class="p1"><b>1/ INLEIDING EN UITLEG ENKELE PRIVACY BEGRIPPEN:</b></p>
<ul class="ul1">
  <li class="li1">Uw privacy vinden wij, net als u belangrijk. One2three NV doet er alles aan om je persoonsgegevens op een correcte, wettelijke en transparante manier te verwerken.<br>
<span class="s1">Deze Privacy verklaring</span> is van toepassing op:<br>
<span class="s2">d</span>e one2three community website<span class="s1"> </span>https://one2three.community/<span class="s1"> , de web app, de Android app, de iOS App eigendom van en ter beschikking gesteld door one2three NV.</span></li>
</ul>
<ul class="ul1">
  <li class="li3">Indien u meer wenst te weten te komen over de algemene begrippen rond verwerking van persoonsgegevens kan u op deze link van de privacy commissie terecht:<br>
<a href='https://www.gegevensbeschermingsautoriteit.be/privacythemas'>Klik hier</a></li>
  <li class="li3">Indien u meent dat wij uw rechten zoals hieronder opgenomen niet naleven kan u te allen tijde een melding indienen bij de Gegevensbeschermingsautoriteit:<br>
Drukpersstraat 35, 1000 Brussel, Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35, <br>
e-mail: contact&#64;apd-gba.be</li>
  <li class="li3">Dit privacy beleid kan van tijd tot tijd gewijzigd worden, bijvoorbeeld naar aanleiding van bijvoorbeeld nieuwe verwerkingsactiviteiten van one2three NV, wij adviseren u om regelmatig dit privacy beleid na te lezen op eventuele wijzigingen. De meest actuele versie zullen wij steeds op onze website plaatsen.</li>
</ul>
<p class="p1"><b>2/ WIE ZIJN WIJ:</b></p>
<p class="p1">One2three NV is een bedrijf dat een online bestelplatform op maat van de snackbar, frituur, broodjeszaak, bar, (bedrijfs-) restaurant, hotel, … heeft ontwikkeld met adres te Kantersteen 47, 1000 Brussel en ingeschreven is in de Kruispuntbank van Ondernemingen onder het nummer <span class="s3">BE</span><span class="s1">0535.701.405.</span><span class="s3"><br>
</span>Indien u vragen heeft of meer informatie wenst kan u steeds terecht bij de zaakvoerder op het emailadres info&#64;one2three.be<span class="Apple-converted-space"> </span></p>
<p class="p2"><br></p>
<p class="p1"><b>3/ WELKE GEGEVENS KUNNEN WIJ VAN JOU VERWERKEN EN WAAROM?</b></p>
<p class="p1"><span class="s4">Wij verzamelen gegevens bij het gebruik van ons bestelplatform:</span></p>
<p class="p1"><br>
Om u de juiste diensten aan te kunnen bieden heeft one2three NV bepaalde informatie van u nodig. De informatie die one2three NV opvraagt en verzamelt via het gebruik van het platform , de website of de verschillende apps en waarmee wij u kunnen identificeren worden persoonsgegevens genoemd. Hieronder geven we mee waarom one2three NV van u persoonsgegevens opvraagt en voor welke doeleinden deze zullen worden gebruikt.<br>
</p>
<ul class="ul1">
  <li class="li1"><span class="s4">Gegevens die wij van u verzamelen voor het registreren op ons bestelplatform one2three en de verschillende apps:</span></li>
</ul>
<p class="p1"><span class="s4">Met registratie:</span></p>
<p class="p1">Met one2three is het heel eenvoudig om snel uw bestellingen te plaatsen bij uw favoriete snackbar, frituur, broodjeszaak, bar, (bedrijfs-) restaurant, hotel, … Hiervoor vragen wij u om te registreren. Als gebruiker van ons platform of van één van onze apps worden volgende persoonsgegevens verzameld:</p>
<p class="p1">Emailadres vragen wij om U te kunnen registreren, te identificeren en uw bestelling te verwerken.</p>
<p class="p1">Telefoonnummer vragen wij U om u te contacteren indien dit nodig zou zijn i.v.m. uw bestelling.</p>
<p class="p1">Taal vragen wij U om u in de juiste taal te kunnen aanspreken.</p>
<p class="p1">Locatiegegevens zoals uw adres gebruiken wij om uw bestelling juist af te kunnen leveren en om u de juiste handelaars in de buurt aan te kunnen bevelen.</p>
<p class="p1">Surf en navigatiegedrag kan bijgehouden worden om fraude en betwistingen te voorkomen alsook om onze diensten te verbeteren en te optimaliseren, meer info hierover kan u terugvinden in ons cookiebeleid.</p>
<p class="p1">Technische gegevens van de apparatuur die u gebruikt kan bijgehouden worden voor statistische doeleinden.</p>
<p class="p1">Het overzicht van uw bestellingen houden wij bij voor in geval van betwistingen, statistische doeleinden alsook om u een volgende keer sneller en beter te kunnen bedienen.<span class="s4"><span class="Apple-converted-space"> </span></span></p>
<ul class="ul1">
  <li class="li1"><span class="s4">Gegevens waar wij toegang tot hebben via de consumenten apps indien u zich registreert:</span></li>
</ul>
<p class="p4">Om uw bestellingen optimaal te kunnen verwerken en u te laten genieten van de juiste diensten hebben wij via de consumenten apps op uw pc, smartphone of tablet toegang tot volgende gegevens:</p>
<ul class="ul1">
  <li class="li1">Uw locatiegegevens om de dichtstbijzijnde handelaar te tonen.</li>
  <li class="li1">Notificaties om aan u berichten te sturen ter bevestiging van uw bestellingen<span class="Apple-converted-space"> </span></li>
  <li class="li1">Uw camera om QR codes te kunnen scannen</li>
</ul>
<p class="p1"><span class="s4">Zonder registratie:</span></p>
<p class="p1">Met one2three is het heel eenvoudig om snel uw bestellingen te plaatsen bij uw favoriete snackbar, frituur, broodjeszaak, bar, (bedrijfs-) restaurant, hotel, … Hiervoor vragen wij u om onderstaande gegevens aan te reiken. Als gebruiker van ons platform of van een van onze apps worden volgende persoonsgegevens verzameld:</p>
<p class="p1">Esmailadres vragen wij om U te kunnen registreren, te identificeren en uw bestelling te verwerken.</p>
<p class="p1">Telefoonnummer vragen wij U om u te contacteren indien dit nodig zou zijn i.v.m. uw bestelling.</p>
<p class="p1">Locatiegegevens zoals uw adres gebruiken wij om uw bestelling juist af te kunnen leveren en om u de juiste handelaars in de buurt aan te kunnen bevelen.</p>
<p class="p1">Surf en navigatiegedrag kan bijgehouden worden om fraude en betwistingen te voorkomen alsook om onze diensten te verbeteren en te optimaliseren, meer info hierover kan u terugvinden in ons cookiebeleid.</p>
<p class="p1">Technische gegevens van de apparatuur die u gebruikt kan bijgehouden worden voor statistische doeleinden.</p>
<ul class="ul1">
  <li class="li1"><span class="s4">Gegevens i.v.m. uw betaling:</span></li>
</ul>
<p class="p1">Betaalwijze bij een handelaar wordt bijgehouden om fraude en betwistingen te voorkomen. Betaalstatus houden wij ook bij om de betaling goed en juist op te kunnen volgen samen met de handelaar.</p>
<p class="p1">De nummer van uw bank- of kredietkaart wordt verwerkt via verschillende veilige betaalsystemen die voldoen aan strenge beveiligingsnormen. Nummers van bank- en kredietkaarten worden alleen gebruikt voor online betalingen en worden niet bewaard voor andere doeleinden.</p>
<p class="p2"><br></p>
<p class="p5"><br></p>
<ul class="ul1">
  <li class="li1"><span class="s4">Gegevens die wij verzamelen om fraude, wanbetalingen en valse bestellingen te voorkomen.</span></li>
</ul>
<p class="p1">Om frauduleus gebruik te voorkomen en het risico op ongewenste activiteiten op het platform van one2three en zijn handelaars te beperken verzamelen wij uw transactiegegevens, informatie waar u uw producten gekocht heeft en informatie m.b.t. uw digitale toestemming voor het uitvoeren van betalingen.</p>
<ul class="ul1">
  <li class="li1"><span class="s4">Verbeteren van onze diensten:</span></li>
</ul>
<p class="p1">One2three bewaart en analyseert uw transactiegegevens om de one2three diensten te verbeteren. Deze gegevens worden gebruikt voor het verbeteren van de gebruiksvriendelijkheid van het platform en de apps.</p>
<p class="p1">Deze gegevens verwerken wij anoniem en hiermee kunnen wij u niet identificeren.</p>
<ul class="ul1">
  <li class="li1"><span class="s4">Informatie naar onze gebruikers en contacten:</span></li>
</ul>
<p class="p1">Indien u reeds gebruik heeft gemaakt van ons platform kan het zijn dat wij u informatie of notificaties sturen. Wij beroepen ons hiervoor op het gerechtvaardigd belang. Het aanbevelen van diensten en producten beschikbaar bij one2three handelaars gebeurt op basis van uw bestelhistoriek.</p>
<ul class="ul1">
  <li class="li1"><span class="s4">Cookies:</span></li>
</ul>
<p class="p6">Cookies zijn kleine informatiebestanden die bij het bezoeken van een website of het installeren en het gebruik van een app automatisch worden opgeslagen op of worden uitgelezen van het toestel van de bezoeker (waaronder een computer, tablet of smartphone). Dat gebeurt via de webbrowser of de app op het toestel. De informatie die een cookie verkrijgt over uw gebruik van de websites of de apps kan worden overgebracht naar eigen beveiligde servers van one2three of een derde.</p>
<p class="p7"><br></p>
<p class="p6">Voor ons cookiebeleid verwijzen wij naar onze cookiebot.</p>
<p class="p6"> </p>
<p class="p6"> </p>
<p class="p6">WEBSITES VAN DERDEN</p>
<p class="p6">Deze privacy- en cookieverklaring is niet van toepassing op websites van derden die door middel van links met onze website zijn verbonden. Deze privacy- en cookieverklaring is bijvoorbeeld niet van toepassing op de (online)handelaars waar u producten of diensten koopt. Wij kunnen niet garanderen dat deze derden op een betrouwbare en veilige manier met uw persoonsgegevens omgaan. Wij raden u dan ook aan de privacyverklaring van deze websites te lezen alvorens van deze diensten gebruik te maken.</p>
<p class="p7"><br></p>
<p class="p1"><span class="s4">Gegevens leveranciers, partners en dienstverleners:</span></p>
<p class="p1">De persoonsgegevens die nodig zijn voor de uitvoering van een overeenkomst of opdracht met leveranciers, partners of dienstverleners worden ook verzameld. Het gaat dan voornamelijk om contactgegevens zoals vb. e-mailadressen en telefoonnummers van contactpersonen om de communicatie mogelijk te maken.</p>
<p class="p2"><br></p>
<p class="p1"><span class="s4">Gevoelige gegevens:</span></p>
<p class="p6">Wij verzamelen en verwerken als gegevensverwerkingsverantwoordelijke geen zogenaamde gevoelige gegevens, te weten:</p>
<ul class="ul1">
  <li class="li8">Persoonsgegevens waaruit ras of etnische afkomst, politieke opvattingen, religieuze of levensbeschouwelijke overtuigingen, of het lidmaatschap van een vakbond blijken;</li>
  <li class="li8">Genetische of biometrische gegevens (vb. gezichtsafbeeldingen en vingerafdrukken);</li>
  <li class="li8">Gegevens over gezondheid;</li>
  <li class="li8">Gegevens met betrekking tot seksueel gedrag of seksuele gerichtheid.</li>
</ul>
<p class="p6">Indien dergelijke gevoelige persoonsgegevens aan ons zouden worden verstrekt, zullen wij deze niet gebruiken en zullen wij deze verwijderen.</p>
<p class="p6"><span class="s4">Leeftijd:</span></p>
<p class="p7"><span class="s4"></span><br></p>
<p class="p1">Indien U geen 13 jaar bent mag u geen gebruik maken van ons platform tenzij u de toestemming heeft van uw ouders. Indien uw kinderen onder de leeftijd van 13 jaar gebruik zouden maken van ons platform, gelieve ons dan de juiste toestemming te bezorgen voor het verwerken van de gegevens via het emailadres info&#64;one2three.be</p>
<p class="p6">Wij verzamelen en verwerken als gegevensverwerker geen persoonsgegevens van minderjarigen tenzij er toestemming is.</p>
<p class="p7"><br></p>
<p class="p7"><br></p>
<p class="p1"><b>4/ WIE ONTVANGT DE PERSOONSGEGEVENS?</b></p>
<p class="p1">Uw persoonsgegevens worden in principe niet doorgegeven aan andere partijen.</p>
<p class="p1">Enkel medewerkers van one2three NV of gerelateerde bedrijven aan one2three NV, die in uitvoering van hun functie uw persoonsgegevens nodig hebben kunnen uw persoonsgegevens consulteren tenzij in de volgende gevallen:</p>
<ul class="ul1">
  <li class="li1">Indien dit nodig is voor onze dienstverlening:</li>
</ul>
<p class="p1">Wij werken samen met een aantal derde partijen die uw persoonsgegevens ook kunnen verwerken. <br>
Het gaat om ontvangers die uitsluitend in de Europese Unie opereren en <span class="s3">individuen of organisaties in directe relatie met one2three NV . Dit zijn bv. partners of installateurs voor het activeren en installeren van de applicatie.</span></p>
<p class="p4">De doorgifte van je gegevens is beperkt tot die gegevens die zij nodig hebben voor het uitoefenen van hun taak in onze opdracht. Wij zien erop toe dat zij, net zoals wij, je gegevens veilig, respectvol en als een goede huisvader beheren en wij voorzien daarvoor de contractuele waarborgen.</p>
<ul class="ul1">
  <li class="li1">Indien je ons daarvoor de toelating geeft; indien one2three NV in andere situaties persoonsgegevens aan derden zou geven dan gebeurt dit met een expliciete kennisgeving, waarin toelichting wordt gegeven over de derde, de doeleinden van de doorgifte en de verwerking.<br>
</li>
  <li class="li1">Indien er een wettelijke verplichting is om uw gegevens te delen met overheidsinstanties.</li>
</ul>
<p class="p2"><br></p>
<p class="p1"><b>5/ HOELANG BEWAREN WIJ UW PERSOONSGEGEGEVENS</b></p>
<p class="p1">Uiteraard bewaren wij uw persoonsgegevens in overeenstemming met de mogelijke wettelijke verplichte termijnen en eventuele verjaringstermijnen die gelden. Bijvoorbeeld voor facturen 7 jaar.</p>
<p class="p1">Gegevens in het kader van ons klantenbeheer houden wij zolang nodig is om onze doelen te bereiken en u als gebruiker goed te kunnen bedienen.</p>
<p class="p1">Als geregistreerde gebruiker worden uw gegevens bijgehouden zolang nodig is om de doelen te bereiken, u kan steeds vragen uw account te verwijderen.<br>
</p>
<p class="p2"><br></p>
<p class="p1"><b>6/ WELKE RECHTEN HEB JE OM DE BESCHERMING VAN JE PERSOONSGEGEVENS TE GARANDEREN</b></p>
<ul class="ul1">
  <li class="li1"><b>Recht van toegang, inzage en verbetering<span class="Apple-converted-space"> </span></b></li>
</ul>
<p class="p9">Recht op toegang en inzage: u heeft het recht om kosteloos kennis te nemen van de gegevens die wij over u hebben en na te gaan waarvoor deze worden aangewend.</p>
<p class="p9">Recht op verbetering: u heeft het recht om verbetering)van uw onjuiste persoonsgegevens te verkrijgen, evenals om onvolledige persoonsgegevens te vervolledigen.</p>
<ul class="ul1">
  <li class="li1"><b>Recht van bezwaar</b></li>
</ul>
<p class="p9">U heeft het recht bezwaar te maken tegen de verwerking van uw persoonsgegevens wegens ernstige en legitieme motieven. Gelieve er evenwel rekening mee te houden dat u zich niet kan verzetten tegen de verwerking van persoonsgegevens die voor ons noodzakelijk zijn voor het uitvoeren van een wettelijke verplichting, de uitvoering van de overeenkomst of ons gerechtvaardigd belang, en dit zolang deze gegevens noodzakelijk zijn voor de doeleinden waarvoor zij werden verzameld.<span class="s1"><span class="Apple-converted-space"> </span></span></p>
<p class="p1">Daarnaast hebt u steeds het recht om u te verzetten tegen het gebruik van persoonsgegevens voor doeleinden van direct marketing; in dergelijk geval hoeft u zelfs geen redenen op te geven. <span class="Apple-converted-space"> </span></p>
<ul class="ul1">
  <li class="li1"><b>Recht van vrije gegevensoverdracht<span class="Apple-converted-space"> </span></b></li>
</ul>
<p class="p9">U heeft het recht de persoonsgegevens die u ons heeft verstrekt, in een gestructureerde, gangbare en machine leesbare vorm te verkrijgen. U heeft het recht deze gegevens over te dragen aan een andere verantwoordelijke voor de verwerking.<span class="s1"><b><span class="Apple-converted-space"> </span></b></span></p>
<ul class="ul1">
  <li class="li1"><b>Recht van intrekking van de toestemming<span class="Apple-converted-space"> </span></b></li>
</ul>
<p class="p9">Recht van intrekking van de toestemming: indien de verwerking van de persoonsgegevens is gebaseerd op de voorafgaande toestemming, beschikt u over het recht deze toestemming in te trekken. Deze persoonsgegevens zullen dan enkel nog verwerkt worden indien wij hiervoor over een andere rechtsgrond beschikken.</p>
<ul class="ul1">
  <li class="li1"><b>Recht op beperking van de verwerking</b></li>
</ul>
<p class="p1">U kunt vragen dat de verwerking van uw persoonsgegevens wordt beperkt. Meent u dat wij uw persoonsgegevens verwerken op onrechtmatige basis, dan kunt u vragen om de verwerking ervan te beperken. Dit betekent dat wij uw persoonsgegevens enkel en alleen met uw toestemming verder mogen verwerken. Wij mogen uw persoonsgegevens wel blijven opslaan, maar mogen er geen verdere verwerkingen mee uitvoeren zonder uw toestemming, tenzij in het kader van een gerechtelijke procedure, voor de bescherming van natuurlijke personen of rechtspersonen of om gewichtige redenen van algemeen belang.<span class="Apple-converted-space"> </span></p>
<ul class="ul1">
  <li class="li1"><b>Automatische beslissingen en profiling<span class="Apple-converted-space">   </span></b></li>
</ul>
<p class="p1">Sommige gegevensverwerkingen en processen verlopen volledig geautomatiseerd zonder menselijke tussenkomst. Wij maken hier echter geen gebruik van met betrekking tot de verwerking van uw persoonsgegevens.</p>
<ul class="ul1">
  <li class="li1"><b>Recht op uitoefening van uw rechten</b></li>
</ul>
<p class="p1">Indien u een of meerdere van deze rechten wilt uitoefenen, gelieve dan contact op te nemen met ons op bovenstaand contactadres en duidelijk te specifiëren welk recht u wilt uitoefenen. <br>
Gelieve dan wel een identiteitsbewijs voor te leggen om u te kunnen identificeren indien nodig.<span class="Apple-converted-space"> </span></p>
<p class="p2"><br></p>
<ul class="ul1">
  <li class="li1"><b>Recht om klacht in te dienen<span class="Apple-converted-space"> </span></b></li>
</ul>
<p class="p1">One2three NV<span class="Apple-converted-space">  </span>doet er alles aan om je persoonsgegevens op een correcte, wettelijke en transparante manier te verwerken.</p>
<p class="p9">Indien u desalniettemin van oordeel bent dat uw rechten geschonden zouden zijn en u binnen onze onderneming voor uw bezorgdheden geen gehoor zou vinden, staat het u vrij een klacht in te dienen bij de Gegevensbeschermingsautoriteit: Drukpersstraat 35, 1000 Brussel, Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35, e-mail: contact&#64;apd-gba.be </p>
<p class="p2"><br></p>
<p class="p1"><b>7/ HOE WORDEN UW GEGEVENS BEVEILIGD?<span class="Apple-converted-space"> </span></b></p>
<p class="p1"><br>
One2three NV<span class="Apple-converted-space">  </span>heeft veiligheidsmaatregelen ontwikkeld die aangepast zijn op technisch en organisatorisch vlak, om de vernietiging, het verlies, de vervalsing, de wijziging, de niet-toegestane toegang of de kennisgeving per vergissing aan derden van persoonsgegevens verzameld te vermijden alsook elke andere niet toegestane verwerking van deze gegevens.<span class="Apple-converted-space"> </span></p>
<p class="p1"><br>
In het onwaarschijnlijke geval dat uw persoonsgegevens betrokken zijn bij een inbreuk op de informatiebeveiliging zullen wij onmiddellijk handelen om de inbreuk te identificeren en actie te ondernemen met gepaste maatregelen. Indien nodig zoals de wet dit voorziet zullen wij u in kennis stellen van dit incident.</p>

</ng-container>

</div>