<mat-card *ngIf="userService.user && userService.user.open_invited_groups.length > 0">
	<mat-card-title>
		{{ 'group_invitations.title' | translate }}
	</mat-card-title>
	<mat-card-content>
		<mat-list>
			<mat-list-item *ngFor="let group of userService.user.open_invited_groups">
					<h3 mat-line>{{ group.name }}</h3>
				<p mat-line>{{ group.description }}</p>

				<button mat-raised-button 
						class="me-2"
						color="primary" 
						(click)="acceptInvitation(group.id)"
						style="min-width:140px;">
					{{ 'group_invitations.accept' | translate }}
				</button>
				<button mat-raised-button 
						(click)="refuseInvitation(group.id)"
						style="min-width:140px;">
					{{ 'group_invitations.refuse' | translate }}
				</button>
			</mat-list-item>
		</mat-list>
	</mat-card-content>
</mat-card>
