import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';

@Injectable()
export class GroupService {

	public group: any;

	constructor(
		private apiService: ApiService,
		private userService: UserService
	) {}

	getGroup(group_id): Observable<any> {
		return this.apiService.get('api/v2/groups/' + group_id, this.userService.access_token);
	}

	getGroupByQrCode(qr_code): Observable<any> {
		return this.apiService.get(`api/v2/groups?qr_code=${qr_code}`, this.userService.access_token);
	}

	updateGroup(group): Observable<any> {
      return this.apiService.put('api/v2/groups/' + group.id, JSON.stringify({ group: group }), this.userService.access_token);
    }

	setOwner(group_id, owner_email) {
		let owner = { email: owner_email };
		return this.apiService.post('api/v2/groups/' + group_id + '/owner', owner, this.userService.access_token);
	}

	getMerchantGroups(merchantId): Observable<any> {
		return this.apiService.get('api/v2/merchants/' + merchantId + '/groups', this.userService.access_token);
	}

	createGroup(group): Observable<any> {
		return this.apiService.post('api/v2/groups/', JSON.stringify({ group: group }), this.userService.access_token);
	}

	createMerchantGroup(merchantGroup): Observable<any> {
		return this.apiService.post('api/v2/groups/create_with_merchant', merchantGroup, this.userService.access_token);
	}

	generateNewQrToken(group_id): Observable<any> {
		return this.apiService.post('api/v2/groups/' + group_id + '/qr_token', {}, this.userService.access_token);
	}
}
