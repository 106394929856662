import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserService } from '../../services/user.service';
import { MerchantService } from '../../services/merchant.service';

@Component({
  selector: 'order-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})

export class ConfirmationComponent {

  merchant;
  order;

  constructor(
    public userService: UserService,
    public merchantService: MerchantService,
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.merchant = this.merchantService.merchant;
    this.order = this.data.data.order;
  }

}
