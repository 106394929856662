import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';

import { Router, ActivatedRoute } from '@angular/router';
import { Location as AngularLocation } from '@angular/common';

import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ccv',
  templateUrl: './ccv.component.html',
  styleUrls: ['./ccv.component.css']
})
export class CCVComponent implements OnInit, OnDestroy {

  public ccv: any;
  public payment: any;
  public ticket: any;
  private timer: any;
  public deviceInfo = null;
  public isMobile;
  public order;
  public cancel_button_shown: boolean = false;

  constructor(	private userService: UserService,
  				private merchantService: MerchantService,
  				private orderService: OrderService,
  				private router: Router,
  				private route: ActivatedRoute,
  				private angularLocation: AngularLocation,
  				private deviceService: DeviceDetectorService,
          private cd: ChangeDetectorRef,
          private translate: TranslateService,
    ) {
  }

  ngOnInit() {

  	this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    if (!this.isMobile) { this.isMobile = this.deviceService.isTablet() };

  	if (!this.merchantService.merchant || !this.userService.basket) { 
  		this.goBack();
  	} else {
      this.userService.setPaymentOngoing(true);
  		this.ccv = this.userService.basket.order.ccv;
      this.order = this.userService.basket.order;
      this.verifyPaymentStatus();
  	}

  }

  ngOnDestroy() {

  	if (this.timer) clearTimeout(this.timer);
    this.userService.setPaymentOngoing(false);
  
  }

  verifyPaymentStatus() {
  	if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) this.getPaymentStatus(this.userService.basket.order.id, this.ccv.reference);
  	this.timer = setTimeout(() => {
      this.verifyPaymentStatus();
 	}, 2000);
  }

  getPaymentStatus(order_id, payment_id) {
  	this.orderService.getPaymentStatus(order_id, payment_id).subscribe(
      response => {
      	this.payment = response;

        if (this.payment.type == 'process_error') {
          //this.goBack();
        }

        if (this.payment.details && this.payment.details.customerReceipt) {
          this.ticket = this.payment.details.customerReceipt;
          this.ticket = this.ticket.replace("[","");
          this.ticket = this.ticket.replace("]","");
          this.ticket = this.ticket.replaceAll("\",\"","\n");
          this.ticket = this.ticket.replaceAll("\"","");
        }
        
        if (this.payment.status == 'failed') {
          this.goBack();
        } else if (this.payment.status == 'success') {
      		this.proceedToOrderStatus();
          //else if (this.payment.cancelled == '1' || this.payment.error == '1') this.goBack();
        }

        if (this.payment.status === 'manualintervention') { this.cancel_button_shown = true; }
        else { this.cancel_button_shown = false; }

        this.cd.detectChanges();
      },
      error => {
      	console.log("PAYMENT STATUS ERROR =>");
      	console.log(error);
      });
  }

  public toggleDevice() {
    this.isMobile = !this.isMobile;
  }

  goBack() {
    if (this.timer) clearTimeout(this.timer);
    setTimeout(() => {
      this.angularLocation.back();
    }, 10000);
  }

  proceedToOrderStatus() {
    if (this.timer) clearTimeout(this.timer);

    this.userService.destroyBasket();

    setTimeout(() => {
          this.router.navigate(['../menu'], { relativeTo: this.route });
    }, 20000);

  }

}
