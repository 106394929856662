import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';

import { AppHelper } from '../helpers/app-helper';
import { StylingHelper } from '../helpers/styling-helper';

import { UtilityService } from '../services/utility.service';

import { NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../../environments/environment';

declare var cordova: any;

import * as Sentry from '@sentry/browser';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {

  public template: string;
  public whitelabel = "guide";

  private merchant_slug;
  private timer: any;

  public sending = false;
  public address: any;
  public necessaryData = false;
  public basketHeight = 'calc(100vh - 350px)';
  public monizze_balance: any;
  public loyalty_points: number;
  public logo: any;

  public invoice_request: boolean;
  public is_reservation: boolean = false;

  private payment: any;
  public user_loyalty_point: any;

  public background_url = "assets/backgrounds/empty.png";

  public fol = false;

  constructor(
    public merchantService: MerchantService,
    public userService: UserService,
    private translate: TranslateService,
    private orderService: OrderService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilityService,
    private spinner: NgxSpinnerService,
    private appHelper: AppHelper,
    private stylingHelper: StylingHelper) {

    this.whitelabel = environment.whitelabel;
    this.fol = this.appHelper.isFol();

  }

  ngOnInit() {

    this.logo = './assets/logos/' + environment.whitelabel + '.png';
    this.invoice_request = environment.invoice_request;

    //does user have a basket?
    if (localStorage && localStorage.getItem('basket')) {
      this.userService.basket = JSON.parse(localStorage.getItem('basket'));
      if (this.userService.basket.order.reservation_id) this.is_reservation = true;
      this.userService.alterUpdateOngoing(false);
    }

     this.route.queryParams
    .subscribe(params => {
    });

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        if (this.merchant_slug.indexOf('one2three') == -1) { this.merchant_slug += '.one2three.be'; }
        //header_image as trigger to detect that not full merchant data is present
        if (!this.merchantService.merchant || !this.merchantService.merchant.header_image) {
          this.getMerchantByDomain(this.merchant_slug);
        } else {
          this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);
          if (this.merchantService.merchant.header_image) { this.background_url = this.merchantService.merchant.header_image.large_url; };
          this.initPayment();
        }
      });

    this.verifyPaymentStatus();
    if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) { this.getLoyaltyPoints(this.userService.basket.order.id) };
    this.getCredit();
  }

  ngOnDestroy() {
      if (this.timer) clearTimeout(this.timer);
  }

  verifyPaymentStatus() {
     if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) { 
       this.getPaymentStatus(this.userService.basket.order.id, null); 
     };
  }

  getPaymentStatus(order_id, payment_id) {

    this.orderService.getPaymentStatus(order_id, payment_id).subscribe(
      response => {
        this.payment = response;

        if (this.payment && this.payment.status && this.payment.status == 'paid') {
          if (this.payment.id.indexOf("tr_") != -1) {
            this.router.navigate(['../order-status'],{ queryParams: { PM: 'MOLLIE', STATUS: 'paid', ID: this.userService.basket.order.id }, relativeTo: this.route });
          } else {
             this.router.navigate(['../order-status'],{ queryParams: { PM: 'WORLDLINE', STATUS: 'paid', ID: this.userService.basket.order.id }, relativeTo: this.route });
          }
        }

        this.timer = setTimeout(() => {
          this.verifyPaymentStatus();
         }, 2000);


      },
      error => {
          this.timer = setTimeout(() => {
          this.verifyPaymentStatus();
         }, 2000);
     
      });
  }

  initPayment() {

    try {

      if (
        !this.userService.basket ||
        !this.userService.basket.checkoutFields ||
        !this.userService.basket.checkoutFields.payment_method ||
        this.userService.basket.order.order_lines.length == 0) { 
        Sentry.captureException(JSON.stringify("empty basket"));
        this.router.navigate(['../menu'], { relativeTo: this.route }); 
    } else {
        this.appHelper.verifyBasketOrderState(this.merchant_slug);
        this.basketHeight = this.appHelper.getBasketHeight();
        this.appHelper.preparePaymentMethod();
        this.necessaryData = true;
      } 

    } catch (err) {
      console.log("ERROR");
      console.log(err);
      Sentry.captureException(JSON.stringify(err));
      this.router.navigate(['../menu'], { relativeTo: this.route });
    }

  }

  getMerchantByDomain(domain) {

    this.merchantService.getMerchantByDomain(domain)
    .subscribe(
      response => {
        this.merchantService.merchant = this.merchantService.updateMerchant(response);
        this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);
        if (this.merchantService.merchant.header_image) { this.background_url = this.merchantService.merchant.header_image.large_url; };

        this.appHelper.loadMerchantData(this.merchantService.merchant.id).subscribe(
          responseList => {
          });

        this.initPayment();
        this.getCredit();
      },
      error => {
        console.log(error);
      }
      );
  }

  updateMonizzeBalance(received) {
    this.monizze_balance = received;
  }

  getUpdate(received: boolean) {
    this.userService.saveBasketInStorage();
    this.userService.alterUpdateOngoing(true);
    this.getCredit();
    this.appHelper.getCheckoutFieldsForOrder().subscribe(
      respone => {
        this.userService.alterUpdateOngoing(false);
      },
      error => {
        this.userService.alterUpdateOngoing(false);
      });
  }

  goToMenu(event) {
    this.router.navigate(['../menu'], { relativeTo: this.route });
  }

  getLoyaltyPoints(order_id) {
    this.orderService.getLoyaltyPoints(order_id).subscribe(
      response => {
        this.loyalty_points = +response.points;
        this.loyalty_points = Math.floor(this.loyalty_points);
      },
      error => {
        console.log(error);
      },
      () => {});
  }

  getCredit() {
     if (this.userService.hasUserLoyaltyPoints() && this.merchantService.merchant) { 
       this.user_loyalty_point = this.userService.getUserLoyaltyPoint(this.merchantService.merchant.id);
     };
  }

}
