/* src/app/profile/profile.component.css */
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  min-width: 100%;
}
.tile-text {
  position: absolute;
  left: 5px;
}
.buttons-container {
  min-width: 20%;
}
.main-content {
  width: 100%;
}
@media (min-width: 768px) {
  .main-content {
    width: 600px;
  }
}
/*# sourceMappingURL=profile.component.css.map */
