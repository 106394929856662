<div class="main p-3 d-flex flex-column justify-content-center align-items-center">

  <div class="d-flex justify-content-between align-items-center p-3 py-1 my-3 first-part" style="width:100vw;position:absolute;top:0;left:0">
    <div style="width:100px;">
      <mat-icon class="clickable" (click)="goBack()">arrow_back</mat-icon>
    </div>
    <div style="text-align: center; flex-grow:1;">
      <img class="logo" src="{{ logo }}">
    </div>
    <div class="d-flex align-items-center justify-content-end me-2"
        style="text-align:right;width:100px;">
      <app-language-selection *ngIf="domain && domain.indexOf('kuehnenagel') > -1"></app-language-selection>
    </div>
  </div>

  <mat-card class="main-card p-3">
  <form #f="ngForm" [formGroup]="user">
    <div class="form-container">
      <mat-form-field>
        <mat-label>{{ 'user.email' | translate }}</mat-label>
        <input  matInput 
                formControlName="email" 
                required
                name="email"
                placeholder="email">
        <mat-error>{{ errors['email'] }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-container" style="margin-top:20px;margin-bottom:20px">
          <div style="color:rgba(0, 0, 0, 0.87);font-size:0.8em;padding-bottom:10px;">{{ 'user.mobile' | translate }}*</div>
          <ngx-intl-tel-input 
          [cssClass]="'telInput'" 
          [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="false" 
          [enablePlaceholder]="true" 
          [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectFirstCountry]="false" 
          [selectedCountryISO]="CountryISO.Belgium"
          [maxLength]="15"
          [phoneValidation]="true" 
          [separateDialCode]="separateDialCode"
          name="phone" formControlName="phone"
          style="
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid;
">
        </ngx-intl-tel-input>
        <mat-error *ngIf="f.form.controls['phone'].errors?.mobileNumber && f.form.controls['phone'].touched">
          {{ 'user.incorrect_phone' | translate }}
        </mat-error>
        <!--
        <mat-error>{{ 'user.incorrect_phone' | translate }}</mat-error>
        -->
    </div>

    <div style="text-align:right;">
      <a class="link" [routerLink]="['/login']">{{ 'user.existing_account' | translate }}</a><br/>
      <a class="link" [routerLink]="['/register']">{{ 'user.register' | translate }}</a>
    </div>

    <div class="py-3" style="text-align: center;">

      <div class="py-3 privacy_statement">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <mat-checkbox formControlName="policyApproved"></mat-checkbox>
          </div>
          <div class="ps-2">
            {{ 'user.privacy_check_1' | translate }}
            <a class="link" [routerLink]="['/terms-and-conditions']">{{ 'user.privacy_check_general_terms' | translate }}</a>
            {{ 'user.privacy_check_2' | translate }}
            <a class="link" [routerLink]="['/privacy-policy']">{{ 'user.privacy_check_privacy_statement' | translate }}</a>
            {{ 'user.privacy_check_3' | translate }}
          </div>
        </div>
      </div>


      <div>
        <button mat-flat-button [disabled]="!user.valid" (click)="register()" color="primary">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div *ngIf="registration_ongoing" class="pe-2"><mat-spinner [color]="'accent'"
                       [diameter]="20"></mat-spinner>
                     </div>
                     <div>
          {{ 'user.guest_login' | translate }}
        </div>
      </div>
        </button>
      </div>
    </div>

  </form>
</mat-card>
</div>

<div class="overflow-hidden background"
   [style.background]="'url(' + background_image + ')'"
   [style.background-repeat]="background_repeat"
   [style.background-size]="background_size"
   [style.background-position]="'center'">
</div>
