<div class="invisible-scroll" style="overflow-y: scroll;">

	<basketbar [feature]="'menu'" [position]="'popup'" (completed)="goToVenue()"
		*ngIf="show_basketbar && userService.basket && userService.basket.order.scenario != 'waiter'"></basketbar>

	<div class="p-3"
		[ngStyle]="{'margin-bottom-px': userService.basket && userService.basket.order.scenario == 'waiter' ? 0 : 50 }">

		<div *ngIf="merchantService.merchant.settings.s_closed">
			<div class="content">


				<div class="p-2 closed_highlight mb-2" *ngIf="merchantService.merchant.settings.s_closed_message">
					{{ merchantService.merchant.settings.s_closed_message | translate }}
				</div>

				<ng-container *ngIf="userService.basket && userService.basket.orderingPossible == false">

					<div class="p-2 mb-2">
						<app-contact></app-contact>
					</div>

					<div class="mb-2">
						<merchant-promotion
							*ngIf="merchantService.merchant.promotion && merchantService.merchant.promotion.android_enabled">
						</merchant-promotion>
					</div>

				</ng-container>

			</div>

		</div>

		<div class="py-1" *ngIf="groupFunctionality">
			<order-type *ngIf="userService.basket && userService.basket.checkoutFields"></order-type>
		</div>

		<div
			*ngIf="userService.basket && userService.basket.orderingPossible == false && merchantService.merchant.settings.s_closed == false">

			<ng-container
				*ngIf="!merchantService.merchant.level_of_activity || merchantService.merchant.level_of_activity < 2">

				<div class="m-2 advisory advisory-info d-flex" *ngIf="!this.merchantService.merchant.settings.s_closed"
					[@fadeInOut]>

					<div style="min-width: 50px;flex-shrink:0">
						<mat-icon>info</mat-icon>
					</div>
					<div class="d-flex flex-column">
						<div *ngIf="!userService.basket.order.scenario">
							{{ 'venue_closed' | translate }}
						</div>
						<div *ngIf="userService.basket.order.scenario">
							{{ 'ordering_impossible' | translate: { scenario: userService.basket.order.scenario |
							translate } }}
						</div>
					</div>

				</div>

				<div class="m-2">
					<merchant-promotion
						*ngIf="merchantService.merchant.promotion && merchantService.merchant.promotion.android_enabled">
					</merchant-promotion>
				</div>

				<div class="m-2">
					<div class="card p-2">
						<app-contact></app-contact>
					</div>
				</div>

			</ng-container>

			<div
				*ngIf="merchantService.merchant.level_of_activity && 
								  (merchantService.merchant.level_of_activity == 2 && (!userService.basket || userService.basket.order.device_type != 'Kiosk'))">
				<level-of-activity>
				</level-of-activity>
			</div>

		</div>

		<div *ngIf="needsUserDetails">
			<div class="d-flex flex-column">
				<!--
				<div class="clickable"
					 (click)="goToVenue($event)"
					 style="text-align:right;">
					<mat-icon>close</mat-icon>
				</div>
				-->
				<div>
					<app-authentication (authenticated)="enableStep2($event)"></app-authentication>
				</div>
			</div>
		</div>


		<div *ngIf="needsOrderDetails && !userService.basket.checkoutFields">
			<mat-progress-bar mode="indeterminate" color="warn">
			</mat-progress-bar>

		</div>

		<div class="d-flex flex-column justify-content-between"
			*ngIf="!needsUserDetails && needsOrderDetails && userService.basket && userService.basket.orderingPossible">

			<div>

				<ng-container>

					<div class="py-1" *ngIf="userService.basket.checkoutFields && 
										 						 userService.basket.order.scenario != 'table' &&
										 						 !merchantService.kiosk" [@fadeInOut]>
						<scenario (emitUpdate)="getUpdate($event)">
						</scenario>
					</div>

					<div class="py-3" *ngIf="userService.basket.order.scenario == 'waiter'">

						<div class="subtitle mb-3">
							{{ 'checkout.no_of_persons' | translate }}
						</div>

						<div class="d-flex justify-content-between align-items-center" style="width: 100%;">

							<div>
								<button (click)="updateNoOfPersons($event, -1)"
									[disabled]="!userService.basket.order.no_of_persons || userService.basket.order.no_of_persons == 0"
									mat-icon-button>
									<mat-icon style="cursor: pointer;">remove_circle_outline</mat-icon>
								</button>
							</div>

							<div class="d-flex justify-content-center align-items-center"
								style="height: 50px; border:1px solid; border-radius: 5px; font-size: 1.6em; text-align:center;width:80px;display:inline">
								{{ userService.basket.order.no_of_persons }}
							</div>

							<div>
								<button (click)="updateNoOfPersons($event, +1)" mat-icon-button>
									<mat-icon>
										add_circle_outline
									</mat-icon>
								</button>
							</div>

						</div>

					</div>


					<div class="py-1"
						*ngIf="userService.basket.checkoutFields && userService.basket.order.scenario != 'waiter'"
						[@fadeInOut]>
						<timing (emitUpdate)="getUpdate($event)">
						</timing>
					</div>

				</ng-container>

				<!--
			<ng-container *ngIf="userService.basket.order.order_type == 'group' && !userService.basket.order.group.instant_checkout">
				<mat-icon>info</mat-icon> {{ 'order_details_for_groups' | translate }}
			</ng-container>
		-->

				<level-of-activity *ngIf="userService.basket.order.scenario != 'waiter'">
				</level-of-activity>

				<advisories *ngIf="userService.basket.order.scenario != 'waiter'">
				</advisories>

				<div class="mt-2">
					<merchant-promotion
						*ngIf="merchantService.merchant.promotion && merchantService.merchant.promotion.android_enabled && userService.basket.order.scenario != 'waiter'">
					</merchant-promotion>
				</div>

				<div class="mt-2">
					<notification></notification>
				</div>

			</div>

		</div>

	</div>

	<basketbar [feature]="'menu'" [position]="'main'" (completed)="goToVenue()"
		*ngIf="userService.basket && userService.basket.order.scenario == 'waiter'">
	</basketbar>

</div>