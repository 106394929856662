import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enabled',
    pure: false
})

export class EnabledFilterPipe implements PipeTransform {
        transform(items: any, arg: boolean): any {
            return items.filter(item => item.is_enabled == arg);
          }
}
