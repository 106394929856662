<!--
<div class="container-fluid" style="text-align:center;background-color:#f0f0f0;">

	<div class="container">

	<div class="row p-3">
		<div class="col-12 col-md-4" style="text-align:center;min-height:240px;max-height:350px;">
			<span style="display:inline-block;vertical-align:middle;height:100%;"></span>
				<img style="vertical-align:middle;max-height:100%;max-width:100%;" src="{{merchantService.merchant.image.medium_url}}">
		</div>
		<div class="col-12 col-md-8" style="min-height:240px; background-color:#f0f0f0;">

			<h2>{{ 'contact.opening_hours' | translate }}</h2>

			<div #myIdentifier style="position:relative">

				<ng-container *ngFor="let hour of hours; index as i">

					<div *ngIf="i > 0 && i < hours.length" style="position:absolute;left:200px;border-left:1px solid;height:5px;top:15px;"
						[ngStyle]="{ 'left.px': slots[0].total_width_px/hours.length*i+50 }"></div>

						<div *ngIf="i > 0 && i < hours.length" style="text-align:center;font-size:10px;position:absolute;left:200px;width:20px;"
							[ngStyle]="{ 'left.px': slots[0].total_width_px/hours.length*i+40 }">{{hour}}</div>
						</ng-container>

						<div 	style="position:absolute;background-color:black;height:1px;left:50px;top:20px;"
						[ngStyle]="{ 'width.px': slots[0].total_width_px }"></div>

						<ng-container *ngFor="let day of days; index as i">
							<div 	style="font-size:15px;text-align:right;width:50px;position:absolute;left:0px;padding-right:5px;margin:0px;"
							[ngStyle]="{ 'top.px': i*20+20 }">{{day}}</div>
						</ng-container>

						<ng-container *ngFor="let slot of slots">		
							<div style="position:absolute;background-color:black;height:8px;" [ngStyle]="{ 'left.px': slot.start_px+50, 'top.px': slot.day*20+30, 'width.px': slot.width_px }">
							</div>

							<div 	style="position:absolute;border:1px solid black;height:8px;left:50px;"
							[ngStyle]="{ 'width.px': slot.total_width_px, 'top.px': slot.day*20+30 }"></div>
						</ng-container>

					</div>
				</div>
			</div>

</div>

		</div>

<div class="container" *ngIf="!contactCreated">
	<h2>{{ 'contact.contact_us' | translate }}</h2>
  <p class="primary">* {{ 'contact.all_fields_mandatory' | translate }}</p>
	<form [formGroup]="contact" (ngSubmit)="createContact()">
    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'user.first_name' | translate }}" formControlName="first_name">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'user.last_name' | translate }}" formControlName="last_name">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'user.email' | translate }}" formControlName="email">
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <input matInput placeholder="{{ 'phone' | translate }}" formControlName="phone">
        <mat-error>{{ 'user.incorrect_phone' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <textarea matInput placeholder="{{ 'contact.comment' | translate }}" formControlName="comment"></textarea>
      </mat-form-field>
    </div>

    <button mat-raised-button type="submit" [disabled]="!contact.valid" color="primary">
			{{ 'send' | translate }}
			<mat-icon>send</mat-icon>
    </button>
	</form>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="text-center">
      <h3>
        <google-map [latitude]="lat" [longitude]="lng">
  			<map-marker [latitude]="lat" [longitude]="lng"></map-marker>
		</google-map>
      </h3>
    </div>
  </div>
</div>
-->

<ng-container *ngIf="slots.length > 0">

	<div class="row">

		<div class="col-12 col-xl-6 d-flex flex-column justify-content-center align-items-center mb-3">

					<div>
							<h4><span 	class="badge bg_secondary" 
    							style="color: white;"
    							[ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '' }">
                			{{ merchantService.merchant.status_formatted | capitalizeFirst }}
    					</span></h4>

					</div>


					<div style="text-align: left;">
						<div *ngFor="let holiday_period of holiday_periods; let i = index">
							<div *ngIf="i == 0">
								{{ 'contact.closing_days' | translate }}
							</div>
							<div style="font-weight: bold;">
								{{ holiday_period.dates['startDate'] | date:'mediumDate' }}
								<ng-container *ngIf="holiday_period.multiple_days"> - {{ 	holiday_period.dates['endDate'] | date:'mediumDate' }}
								</ng-container>
							</div>
							<div *ngIf="holiday_period.to_be_shown == true">
								{{ holiday_period.name }} 
							</div>
						</div>
					</div>


				</div>


		<div class="col-12 col-xl-6 mb-3">

<div #myIdentifier style="min-height:200px;position:relative; font-size:0.9em;">

				<ng-container *ngFor="let hour of hours; index as i">

					<div *ngIf="i > 0 && i < hours.length" style="position:absolute;left:200px;border-left:1px solid;height:5px;top:15px;"
						[ngStyle]="{ 'left.px': slots[0].total_width_px/hours.length*i+50 }"></div>

						<div *ngIf="i > 0 && i < hours.length" style="text-align:center;font-size:10px;position:absolute;left:200px;width:20px;"
							[ngStyle]="{ 'left.px': slots[0].total_width_px/hours.length*i+40 }">{{hour}}</div>
						</ng-container>

						<div 	style="position:absolute;background-color:#505050;height:1px;left:50px;top:20px;"
						[ngStyle]="{ 'width.px': slots[0].total_width_px }"></div>

						<ng-container *ngFor="let day of days; index as i">
							<div 	style="font-size:15px;text-align:right;width:50px;position:absolute;left:0px;padding-right:5px;margin:0px;"
							[ngStyle]="{ 'top.px': i*25+25 }">{{ 'weekdays.' + day | translate }}</div>
						</ng-container>

						<ng-container *ngFor="let closing_day of closing_days">	
							
							<div 	class="d-flex justify-content-center align-items-center"
									style="color: gray;border:1px solid gray;text-align:center;position:absolute; left: 50px; height:20px;"
									[ngStyle]="{ 'width.px': slots[0].total_width_px, 'top.px' : closing_day*25+30 }">
								{{ 'closed' | translate }}
							</div>
						</ng-container>

						<ng-container *ngFor="let slot of slots">		
							<div 	class="d-flex justify-content-center align-items-center"
									style="font-size: 0.8em;padding-left:5px;position:absolute;color:white;background-color:#505050;height:20px;" 
									[ngStyle]="{ 'left.px': slot.start_px+50, 'top.px': slot.day*25+30, 'width.px': slot.width_px }">
								<ng-container *ngIf="slot.width_px > 75">
									{{ slot.start_of_day |  date:'HH:mm' }} - {{ slot.end_of_day | date:'HH:mm' }}
								</ng-container>
							</div>

							<div 	style="position:absolute;border:1px solid black;height:20px;left:50px;"
							[ngStyle]="{ 'width.px': slot.total_width_px, 'top.px': slot.day*25+30 }"></div>
						</ng-container>

					</div>

				</div>

			</div>

		</ng-container>

				


				
