import { Component, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

import { AppHelper } from '../helpers/app-helper';

import { environment } from '../../environments/environment';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent {

  public whitelabel = environment.whitelabel;
  public breakpoint = "sm";
  public fol = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.breakpoint = this.appHelper.getBootstrapBreakpoint();
  }

  constructor(
    private dialogRef: MatDialogRef<CategoryDialogComponent>,
    public merchantService: MerchantService,
    public userService: UserService,
    private appHelper: AppHelper,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fol = this.appHelper.isFol();
   }

  ngOnInit() {
  }

  onDataRequestCompleted() {
    this.close();    
    //this.setBasketFeature();
  }

  onDataRequestUpdated() {
    this.cd.detectChanges();
  }

  clickTaxon(taxon) {
    this.dialogRef.close({ taxon: taxon });
  }

  close() {
    this.dialogRef.close(false);
  }
}
