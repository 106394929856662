import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-order-type-sheet',
  templateUrl: './order-type-sheet.component.html',
  styleUrls: ['./order-type-sheet.component.scss']
})
export class OrderTypeSheetComponent implements OnInit {

	public orderTypeSelected = new EventEmitter();


  constructor(public userService: UserService,
  			  @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
  }

  selectOrderType(order_type) {
  	this.orderTypeSelected.emit(order_type);
  }

}
