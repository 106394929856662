<header>
  <div class="header-container">
    <app-navbar [fol_bg]="true"></app-navbar>
  </div>
</header>

<div class="container">
  <div class="row">
    <div id="ucTitle_divTitle" class="col-12 col-md-9">
      <h1 id="ucTitle_pageTitle" class="title text-center">{{ 'fol.about_us' | translate }}</h1>	
    </div>
  </div>					
  
  <div id="ContentPlaceHolder1_ucPagemanager_ctl00_DIVtekst" class="dvPage flyup">
    <div class="row blok">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 column">
            <p><strong><span style="font-size: 20px;">Frietjesonline.be</span></strong></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_1' | translate }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="row blok">
      <div class="col-md-5 col-lg-4">
        <div class="row mx-1 my-5">
          <a [routerLink]="['/contact-us-demo']" class="btnPrimary">{{ 'fol.become_client' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="row blok">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 column">
            <p><strong><span style="font-size: 20px;">{{ 'fol.part_2' | translate }}</span></strong></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_3' | translate }}<br>
              {{ 'fol.part_4' | translate }}</span></p>
            <p><span style="font-size: 16px;"><br>
              {{ 'fol.part_5' | translate }}</span></p>
            <p><span style="font-size: 16px;"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="row blok">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 column">
            <p><span style="font-size: 20px;"><strong>{{ 'fol.part_6' | translate | uppercase }}</strong></span></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_7' | translate }}</span></p>
            <p><span style="font-size: 16px;"></span></p>
          </div>
          <div class="col-md-4 column"><p><span style="font-size: 20px;"><strong>{{ 'fol.part_8' | translate | uppercase}}</strong></span></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_9' | translate }}</span></p>
            <p><span style="font-size: 16px;"></span></p>
            <p><span style="font-size: 16px;"></span></p></div><div class="col-md-4 column"><p><span style="font-size: 20px;"><strong>{{ 'fol.part_10' | translate | uppercase }}</strong></span></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_11' | translate }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="row blok">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 column"><p><span style="font-size: 20px;"><strong>{{ 'fol.part_12' | translate | uppercase }}</strong></span></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_13' | translate }}</span></p>
            </div><div class="col-md-4 column"><p><span style="font-size: 20px;"><strong>{{ 'fol.part_14' | translate | uppercase}}</strong></span></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_15' | translate }}</span></p>
          </div>
          <div class="col-md-4 column"></div>
        </div>
      </div>
    </div>
    <div class="row blok">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 column"><p><span style="font-size: 16px;"><strong></strong></span></p>
            <p><span style="font-size: 16px;"><strong>{{ 'fol.part_16' | translate }}</strong><br>
              {{ 'fol.part_17' | translate }}<br>
            <br>
            <strong>{{ 'fol.part_18' | translate }}</strong><br>
            {{ 'fol.part_19' | translate }}<br>
            <br>
            <strong>{{ 'fol.part_20' | translate }}</strong><br>
            {{ 'fol.part_21' | translate }}<br>
            <br>
            <strong>{{ 'fol.part_22' | translate }}</strong><br>
            {{ 'fol.part_23' | translate }}<br>
            <br>
            <strong>{{ 'fol.part_24' | translate }}</strong><br>
            {{ 'fol.part_25' | translate }}<br>
            {{ 'fol.part_26' | translate }}
            <br>
            <strong>{{ 'fol.part_27' | translate }}</strong><br>
            {{ 'fol.part_28' | translate }}</span></p>
            <p><span style="font-size: 16px;">{{ 'fol.part_29' | translate }}<br>
            <br>
            <br>
            <strong>{{ 'fol.part_30' | translate }}</strong><br>
            {{ 'fol.part_31' | translate }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer>  
  <app-fol-footer></app-fol-footer>
</footer>