<mat-nav-list>
  <div class="scenario d-flex" *ngFor="let order_type of data.order_types; let i = index">
  	<div class="marker" *ngIf="order_type == data.compareWith">
  	</div>
  	<div style="width:100%;">
  		<a (click)="selectOrderType(order_type)" mat-list-item>
    	<span mat-line>
        <div class="d-flex">
          <div style="width:50px;text-align:center">
            <img class="order_type_img" src='./assets/order_types/gray/{{ order_type }}.png'>
          </div>
          <div>{{ 'order_type.' + order_type | translate | capitalizeFirst}}</div>
        </div>
      </span>
  		</a>
  	</div>
  </div>
  
</mat-nav-list>