<div class="d-flex align-items-center justify-content-end pe-3 pt-3"
    style="text-align:right;width:100%;"
    *ngIf="merchantService.kiosk">
    <app-language-selection></app-language-selection>
</div>

<div class="main p-2 p-sm-3 pt-3 d-flex flex-column justify-content-between align-items-center">

  <div class="d-flex flex-row justify-content-between align-items-center p-3 py-1 mb-sm-3 first-part" style="width:100vw;">

  <ng-container *ngIf="!merchantService.kiosk">
    <div style="width:100px">
      <mat-icon class="clickable" (click)="goBack()">arrow_back</mat-icon>
    </div>
    <div style="text-align: center; flex-grow:1;">
      <img class="logo" src="{{ logo }}">
    </div>
    
    <div class="d-flex align-items-center justify-content-end me-2"
        style="width:100px;text-align:right;">
      <app-language-selection *ngIf="domain && domain.indexOf('kuehnenagel') > -1"></app-language-selection>
    </div>
  </ng-container>
</div>

  <div>

  <mat-card class="main-card p-2 p-sm-3" color="primary">
  <form #f="ngForm" [formGroup]="user">
    <div class="form-container">
      <mat-form-field>
        <mat-label>{{ 'user.email' | translate }}</mat-label>
        <input  matInput 
                formControlName="email">
        <mat-error>{{ errors['email'] }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <mat-label>{{ 'user.password' | translate }}</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error *ngIf="f.form.controls['password'].errors?.minlength">Paswoord moet minimum 5 karakters zijn</mat-error>
      </mat-form-field>
    </div>

    <div class="form-container">
      <mat-form-field>
        <mat-label>{{ 'user.confirm_password' | translate }}</mat-label>
        <input matInput type="password" formControlName="password_confirmation">
        <mat-error>{{ 'user.password_match' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-container" style="margin-top:20px;margin-bottom:20px">
          <div style="color:rgba(0, 0, 0, 0.87);font-size:0.8em;padding-bottom:10px;">{{ 'user.mobile' | translate }}*</div>
          <ngx-intl-tel-input 
          [cssClass]="'telInput'" 
          [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="false" 
          [enablePlaceholder]="true" 
          [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectFirstCountry]="false" 
          [selectedCountryISO]="CountryISO.Belgium"
          [maxLength]="15"
          [phoneValidation]="true" 
          [separateDialCode]="separateDialCode"
          name="phone" formControlName="phone"
          style="
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid;">
        </ngx-intl-tel-input>
        <mat-error *ngIf="f.form.controls['phone'].errors?.mobileNumber && f.form.controls['phone'].touched">
          {{ 'user.incorrect_phone' | translate }}
        </mat-error>
    </div>

    <div class="form-container">
      <mat-form-field>
        <mat-label>{{ 'profile.choose_language' | translate }}</mat-label>
        <mat-select formControlName="locale" (selectionChange)="changeLanguage()">
          <mat-option *ngFor="let language of languages" [value]="language.value">
            {{ language.name | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="text-align:right;">
      <a class="link"
        [routerLink]="['/login']"
        [queryParams]="{domain: domain, scenario: scenario}">{{ 'user.existing_account' | translate }}</a>
    </div>

    <div class="py-2 py-sm-3" style="text-align: center;">
      <div class="pb-3 px-1 privacy_statement">
        <div class="d-flex justify-content-center align-self-center">
          <div>
            <mat-checkbox formControlName="policyApproved"></mat-checkbox>
          </div>
          <div class="align-self-center">
            {{ 'user.privacy_check_1' | translate }}
            <a class="link" [routerLink]="['/terms-and-conditions']">{{ 'user.privacy_check_general_terms' | translate }}</a>
            {{ 'user.privacy_check_2' | translate }}
            <a class="link" [routerLink]="['/privacy-policy']">{{ 'user.privacy_check_privacy_statement' | translate }}</a>
            {{ 'user.privacy_check_3' | translate }}
          </div>
        </div>
      </div>
      <div>
        <button mat-raised-button [disabled]="!user.valid" (click)="register()" color="primary">{{ 'user.register' | translate }}</button>
      </div>
    </div>

  </form>
</mat-card>

</div>

  <div>
  </div>


</div>

<div class="overflow-hidden background"
   [style.background]="'url(' + background_image + ')'"
   [style.background-repeat]="background_repeat"
   [style.background-size]="background_size"
   [style.background-position]="'center'">
</div>
