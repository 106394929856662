/* src/app/group-order/group-order.component.css */
.remove-suborder-btn {
  margin-top: -10px;
}
.shadowed {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
/*# sourceMappingURL=group-order.component.css.map */
