/* src/app/contact/contact.component.css */
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container > * {
  width: 100%;
}
.merchant-map {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}
google-map {
  height: 300px;
}
/*# sourceMappingURL=contact.component.css.map */
