import { Component, OnInit, Input, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { PubnubService } from '../../services/pubnub.service';

import { UtilityService } from '../../services/utility.service';
import { MerchantService } from '../../services/merchant.service';
import { TranslateService } from '@ngx-translate/core';

import { AppHelper } from '../../helpers/app-helper';
import { faIdBadge, faArrowAltLeft, faCheck } from '@fortawesome/pro-solid-svg-icons';

import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  public ongoing = false;
  public hide = true;
  public use_badge = false;
  public faIdBadge = faIdBadge;
  public faArrowLeft = faArrowAltLeft;
  public faCheck = faCheck;
  public use_code = false;
  public code_entered = '';
  public show_dots = '';
  public fol = false;

  @Output() authenticated = new EventEmitter();
  @Input() domain;
  @Input() scenario;
  @Input() module;

  auth = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.pattern(/.+@.+\..+/)]),
    password: new UntypedFormControl('', [Validators.required]),
    grant_type: new UntypedFormControl('password')
  });

  public badge_number = "";

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 

  if (this.use_badge) {
    if (event.keyCode == 13) {
      console.log(this.badge_number);
      let auth = { badge_number: this.badge_number, grant_type: 'password' };
       this.userService.onLoginFormSubmit(auth)
      .subscribe(
        auth => {
          console.log("SUCCES => ");
          console.log(auth);
          if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
          this.userService.access_token = auth.access_token;
          this.getProfile();
        },
        error => {
          console.log("ERROR =>");
          console.log(error);
        }
      );
      this.badge_number = "";
    } else {
      this.badge_number += event.key;
    }}
  }

  constructor(	public userService: UserService,
  				public pubnubService: PubnubService,
  				private appHelper: AppHelper,
          private util: UtilityService,
          private merchantService: MerchantService,
          private translate: TranslateService,
          private renderer: Renderer2
          ) { 
    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {
    this.renderPageBodyColor();
    if (this.domain && 
        this.domain.indexOf("kuehnenagel") > -1 && 
        this.module == 'consumer' &&
        this.merchantService.kiosk) { this.use_badge = true; }
        
    /*this.merchantService.getMerchantByDomain(this.domain).subscribe(
      merchant => {
        if (this.module == 'pos' && merchant.features.indexOf('f_fdm') != -1) {
          this.use_code = true;
        }
      }
    );*/  
  }

  clickNumber(number) {
    this.code_entered += number;
    this.show_dots += '•';
  }

  erase() {
    this.code_entered = this.code_entered.slice(0, -1);
    this.show_dots = this.show_dots.slice(0, -1);
  }


  private renderPageBodyColor() {
    this.renderer.removeClass(document.body, 'darkMode');
    this.renderer.removeClass(document.body, 'lightMode');
  }

  onLoginFormSubmit() {

    if (!this.ongoing) {
      this.ongoing = true;
      this.auth.value.username = this.auth.value.username.replace(/\s/g, '');
      this.userService.onLoginFormSubmit(this.auth.value)
      .subscribe(
        auth => {
          if (sessionStorage) sessionStorage.setItem('ngStorage-token', JSON.stringify(auth));
          if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
          this.userService.access_token = auth.access_token;
          this.getProfile();
        },
        error => {
          this.ongoing = false;
          if (error && error.status && error.status == 401) {
            this.util.openSnackBar(this.translate.instant('user.credentials_mismatch'), this.translate.instant('ok'));
          } else {
            if (error && error.statusText) { this.util.openSnackBar(error.statusText, this.translate.instant('ok')); };
            Sentry.captureException(JSON.stringify(error));
            console.log(error);
          }
        }
        );
    }
  }

  onSubmitCode() {
  let auth = { badge_number: this.code_entered, grant_type: 'password' };
    this.userService.onLoginFormSubmit(auth)
    .subscribe(
      auth => {
        console.log("SUCCES => ");
        console.log(auth);
        if (localStorage) localStorage.setItem('ngStorage-token', JSON.stringify(auth));
        this.userService.access_token = auth.access_token;
        this.getProfile();
      },
      error => {
        console.log("ERROR =>");
        console.log(error);
      }
    );
    this.code_entered = "";
  }

  getProfile() {

    this.userService.getProfile()
    .subscribe(
      user => {
        this.userService.setUser(user);
        this.appHelper.initSentryUser(user);
        this.ongoing = false;
        this.authenticated.emit();
        if (!this.merchantService.kiosk) this.translate.use(this.userService.user.locale);
      },
      error => {
        this.ongoing = false;
        console.log(error);
      }
      );

  }

  toggleHide(event) {
    console.log(event);
    console.log("TOGGLE HIDE!");
    this.hide = !this.hide;
    console.log(this.hide);
    event.stopPropagation();
  }

  useCredentialsToLogin() {
    this.use_badge = !this.use_badge;
  }
}
