import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';

import { Order } from '../models/order';
import { Product } from '../models/product';

import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ])
  ]
})
export class SuggestionsComponent implements OnInit {

  public order: Order;
  public suggestions = [];;

  @Input() showTitle = true;
   @Output() productSelected = new EventEmitter<Product>();

  constructor(	public orderService: OrderService,
  				      public userService: UserService,
                public merchantService: MerchantService) { }

  ngOnInit() {
  	this.order = this.userService.basket.order;
  	this.getSuggestions(this.order.id);
  }

  getSuggestions(order_id) {


  	this.orderService.suggestProducts(order_id)
		.subscribe(
			response => {
        for(let suggestion of response) {
          let product = this.merchantService.merchant.products.find(x => x.id == suggestion.id);
          if (product && product.is_available && product.product_taxons && product.product_taxons.length > 0) {
            product.category = this.merchantService.merchant.categories.find(x => x.id == product.product_taxons[0].taxon_id);
            this.suggestions.push(product);
          }
        }
			},
			error => {
				console.log(error);
			}

			);
  }

  openProduct(event, product) {

    if (this.merchantService.merchant.settings.s_closed == false) {
        this.productSelected.emit(product);
    } else {
        console.log('CLOSED!!!!!');
    }
 }

}
