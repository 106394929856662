/* src/app/contact-us/contact-us-support.component.scss */
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
h1.title {
  font-size: 45px;
  line-height: 1em;
  color: #f0513f;
  margin-bottom: 30px;
  font-family: "Permanent Marker";
}
.content {
  flex: 1;
}
.col-12 > mat-form-field {
  width: 100%;
}
.mat-raised-button {
  background-color: #FDD329;
  color: #f0513f;
  font-family: "Permanent Marker";
}
.fol-footer {
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(https://www.frietjesonline.be/uploads/backgrounds/background.jpg?h=500&mode=max);
  background-position: 0 0, 50% 100% !important;
  background-size: auto, cover !important;
  background-repeat: repeat, no-repeat !important;
  background-attachment: scroll, fixed !important;
  width: 100%;
}
.fol-footer .section {
  padding: 60px 0;
}
.fol-footer .link-home > img {
  max-height: 120px;
}
.fol-footer .link-footer,
.fol-footer h4 {
  font-family: "Permanent Marker";
}
.fol-footer .container-details,
.fol-footer .sitemap-list {
  font-family: "Dosis";
}
.fol-footer a.sitemap-link {
  color: black;
}
.fol-footer .sm-link {
  display: inline-block;
  padding: 5px;
}
.fol-footer fa-icon {
  color: black;
}
.fol-footer ul {
  list-style: none;
}
/*# sourceMappingURL=contact-us-support.component.css.map */
