import { Injectable } from '@angular/core';

import { ApiReservationService } from './api.reservation.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(
  	private apiReservationService: ApiReservationService
  ) { }

  getReservation(access_token: string, id: number) {
    return this.apiReservationService.get('/reservation/' + id, access_token);
  }

}
