import { Component, HostListener, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { MerchantService } from './services/merchant.service';
import { OrderService } from './services/order.service';
import { PubnubService } from './services/pubnub.service';
import { UserService } from './services/user.service';

import { slideInAnimation } from './animations/animations';

import { Meta, Title } from '@angular/platform-browser';

import { AppHelper } from './helpers/app-helper';
import { FeaturesHelper } from './helpers/features-helper';

import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';
import { KioskTimeoutDialogComponent } from './kiosk-timeout-dialog/kiosk-timeout-dialog.component';

import { Renderer2 } from '@angular/core';

import { environment } from '../environments/environment';

import { Subscription } from 'rxjs';

import { Merchant } from './models/merchant';

declare var window: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	animations: [slideInAnimation]
})

export class AppComponent implements OnInit, OnDestroy {

	private interval;

	public profileLoaded = false;
	public merchantsLoaded = false;

	private statusPing = 0;
	private KIOSK_RESET_AFTER = 30;

	private orderUpdateSubscription: any;
	private ccService: any;
	private routerSubscription: Subscription;

	public scenarios = environment.scenarios;

	public fol = false;

	@HostListener('document:keypress', ['$event'])
	@HostListener('document:mousemove', ['$event'])
	@HostListener('click', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    this.merchantService.kioskTimeLeft = this.KIOSK_RESET_AFTER;
  }
	
	constructor(private translate: TranslateService,
		private pubnubService: PubnubService,
		public merchantService: MerchantService,
		public orderService: OrderService,
		private userService: UserService,
		private ngZone: NgZone,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private appHelper: AppHelper,
		private featuresHelper: FeaturesHelper,
		private titleService: Title,
		private metaTagService: Meta,
		private injector: Injector,
		private renderer: Renderer2) {

		this.fol = this.appHelper.isFol();

		let userLang = navigator.language || window.navigator['userLanguage'];
		if (this.fol) { userLang = "nl" };

		if (userLang.startsWith('en')) { userLang = 'en'; } else if (userLang.startsWith('fr')) { userLang = 'fr'; } else if (userLang.startsWith('nl')) { userLang = 'nl'; }

		if (userLang === 'nl' || userLang === 'en' || userLang === 'fr') {
			translate.setDefaultLang(userLang);
			translate.use(userLang);
		} else if (environment.whitelabel === 'leswitch' || environment.whitelabel === 'fritapapa') {
			translate.setDefaultLang('fr');
			translate.use('fr');
		} else {
			translate.setDefaultLang('nl');
			translate.use('nl');
		}
		document.addEventListener('resume', this.resumeApp.bind(this), false);

		if (this.fol) this.renderer.addClass(document.body, 'fol');

		this.merchantService.kioskTimeLeft = this.KIOSK_RESET_AFTER;
	}

	timer() {
		this.merchantService.kioskTimeLeft--;
		this.statusPing--;

		if (this.statusPing < 0) {
			this.statusPing = 60;
			this.getNotifications();
		}

		if (this.merchantService.kioskTimeLeft < 0) {
			this.merchantService.kioskTimeLeft = this.KIOSK_RESET_AFTER;
			
			if (this.merchantService.kiosk) {

				if (this.userService.basket && 
					this.userService.basket.order && 
					this.userService.basket.order.id && 
					!this.userService.basket.paymentOngoing) {
					let dialogRef = this.dialog.open(KioskTimeoutDialogComponent, {
						width: '500px',
						height: '400px',
						disableClose: true
					});

					dialogRef.afterClosed().subscribe(result => {
						if (result == false) {
							if (!this.userService.user.virtual_user) {
								this.userService.logoutUser();
								this.router.navigate(['login'], { queryParams: { domain: this.merchantService.merchant.domain } });
							} else {
								this.userService.destroyBasket();
							}
						} else if (result == true) {
							this.merchantService.kioskTimeLeft = this.KIOSK_RESET_AFTER;
						}
					});
				}
			}
		}
		setTimeout(() => {
			this.timer();
		}, 1000);

	}

	getNotifications() {

		const query = 'q[status_eq]=open';

		this.userService
		.getNotifications(query)
		.subscribe(
			(res: any) => {
				this.userService.notifications = res;
			},
			err => {
				console.log(err);
			}
			);

	}

	isMobile() {
		return this.appHelper.isMobile();
	}

	ngOnInit() {

		this.timer();

		if ((sessionStorage && sessionStorage.getItem('ngStorage-token')) || (localStorage && localStorage.getItem('ngStorage-token'))) {

			let auth = null;
			if (sessionStorage && sessionStorage.getItem('ngStorage-token')) {
				auth = JSON.parse(sessionStorage.getItem('ngStorage-token'));
			} else if (localStorage && localStorage.getItem('ngStorage-token')) {
				auth = JSON.parse(localStorage.getItem('ngStorage-token'));
			}

			if (auth) {
				this.userService.access_token = auth.access_token;
				this.userService.userExpected = true;
				this.getProfile(true);
				this.getMerchants();
			} else {
				this.profileLoaded = true;
				this.merchantsLoaded = true;
			}

		} else {
			this.profileLoaded = true;
			this.getMerchants();
		}

		this.featuresHelper.setFeature();

		const bgGeo = window.BackgroundGeolocation;

		const that = this;

		if (bgGeo != undefined) {
			bgGeo.onLocation(function(location) {
				console.log('[location] -', location);
			});

			bgGeo.onMotionChange(function(event) {
				console.log('[motionchange] -', event.isMoving, event.location);

				if (event.isMoving == false) {
					bgGeo.changePace(true, function() {
						console.log('- plugin is now tracking');
					});
				}

			});

			bgGeo.onProviderChange(function(event) {
				console.log('[providerchange] -', event.status, event.enabled, event.gps, event.network);
			});

		}

		this.orderUpdateSubscription = this.userService.orderUpdate.subscribe((updateOngoing) => {
			console.log("THE APP COMPONENT DETECTED AN ORDER UPDATE!!!");
			this.updateOrder();
		});

		this.routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				let url = (<NavigationEnd>event).urlAfterRedirects;
				this.showCookiesIfAppropriate(url);
				this.routerSubscription.unsubscribe();
			}
		});
	}

	updateOrder() {

		if (this.userService.getBasket()) {

			this.userService.alterUpdateOngoing(true);
			this.orderService.updateOrder(this.userService.getBasket().order, false)
			.subscribe(
				(res: any) => {

					this.appHelper.setBasketOrder(res, this.merchantService.merchant);

					for (const order_line of res.order_lines) {

						const product_id = order_line.product_id;

						// we need to know how many of these products are currently in the basket already
						const in_basket = res.order_lines.filter(order_line => {
							return order_line.product_id === product_id;
						}).reduce((sum, current) => sum + current.amount, 0);

						if (this.merchantService.merchant.menu) {
							for (const category of this.merchantService.merchant.menu) {
								this.appHelper.updateStockInfoProduct(category.products, product_id, order_line, in_basket);
								for (const subcategory of category.subcategories) {
									this.appHelper.updateStockInfoProduct(subcategory.products, product_id, order_line, in_basket);
									for (const level3 of subcategory.subcategories) {
										this.appHelper.updateStockInfoProduct(level3.products, product_id, order_line, in_basket);
									}
								}
							}

						}

					}

					this.appHelper.updateCheckoutFields();
					this.userService.alterUpdateOngoing(false);
				},
				err => {
					console.log(err);
					this.userService.alterUpdateOngoing(false);
				}
				);

		}

	}

	resumeApp() {
		if (this.userService.basket && this.userService.basket.order) {
			this.merchantService.getMerchantById(this.userService.basket.order.merchant_id.toString())
			.subscribe(
				response => {
					const merchant = response;
					const slug = merchant.domain.substring(0, merchant.domain.indexOf('.'));
					this.appHelper.verifyBasketOrderState(slug);
				},
				error => {
					console.log(error);
				}
				);
		}
	}

	ngOnDestroy() {
		clearInterval(this.interval);
		if (this.orderUpdateSubscription) { this.orderUpdateSubscription.unsubscribe(); }
	}

	setScenarios(merchants: Array<any> | Merchant) {

		if (!environment.use_predefined_scenarios) {
			 // if merchants is a merchant, put it in an array
			 if (merchants instanceof Array) {
			} else { merchants = [merchants] };
	
			let new_scenarios = [];
			 for (let merchant of merchants) {
				 console.log("MERCHANT:");
				 console.log(merchant);
				for (let scenario of this.scenarios) {
					if (scenario == 'takeaway' && merchant.settings.s_scenario_takeaway_enabled) new_scenarios.push('takeaway');
					if (scenario == 'delivery' && merchant.settings.s_scenario_delivery_enabled) new_scenarios.push('delivery');
					if (scenario == 'eatin' && merchant.settings.s_scenario_eatin_enabled) new_scenarios.push('eatin');
					if (scenario == 'catering' && merchant.settings.s_scenario_catering_enabled) new_scenarios.push('catering');
				}
			}
	
			// collect only unique values
			//this.scenarios = [...new Set(new_scenarios)];
			environment.scenarios = [...new Set(new_scenarios)];
		} else {
			return;
		}
  }

	getMerchants() {
		this.merchantService.getMerchants(1)
		.subscribe(
			response => {
				if (response.merchants.length == 1) {
		          this.merchantService.singleMerchantWhitelabel = true;
		          this.merchantService.merchant = response.merchants[0];
		          this.setScenarios(this.merchantService.merchant);
		        } else if (response.pagination.total_entries <= response.pagination.per_page) {
		          this.setScenarios(response.merchants);
		        }

		        let favoritesFound = false;
		        for (let merchant of response.merchants) {
		          if (merchant.favorite) {
		            favoritesFound = true;
		            break;
		          }
		        }
		        this.userService.hasFavorites = favoritesFound;
				this.merchantsLoaded = true;
				this.getVenue();
			},
			error => {
				this.merchantsLoaded = true;
			});
	}

	getVenue() {
		if (this.userService.basket && this.userService.basket.order) {
			const merchant_id = this.userService.basket.order.merchant_id;
			const merchant = this.merchantService.getStoredMerchant(merchant_id);
			this.merchantService.merchant = merchant;
			//this.merchantService.merchant.primary_color = this.merchantService.getPrimaryMerchantColor(this.merchantService.merchant);
		}
	}


	getProfile(initPubnub) {

		if (this.userService.access_token) {

			this.userService.getProfile()
			.subscribe(
				user => {
					this.userService.setUser(user);
					this.appHelper.initSentryUser(user);
					this.translate.use(this.userService.user.locale);
					this.profileLoaded = true;
				},
				error => {
					if (sessionStorage) { sessionStorage.removeItem('ngStorage-token'); }
					if (localStorage) { localStorage.removeItem('ngStorage-token'); }
				}
				);

		} else {
			this.profileLoaded = true;
		}

	}

	logoutUser() {
		if (sessionStorage) { sessionStorage.removeItem('ngStorage-token'); }
		this.userService.user = null;
		this.userService.access_token = null;
		this.router.navigate(['/login']);
	}

	prepareRoute(outlet: RouterOutlet) {
		return this.isMobile() && outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
	}

	private showCookiesIfAppropriate(url) {
		
		/*
		let cookiesShouldBeShown = true; 

		if (environment.pos || environment.kitchen) cookiesShouldBeShown = false;
		if (window.hasOwnProperty('cordova')) cookiesShouldBeShown = false;
		if (this.merchantService.kiosk) cookiesShouldBeShown = false;
		if (url.indexOf('pos') > -1 || url.indexOf('kds') > -1 || url.indexOf('kitchen') > -1 || url.indexOf('kiosk') > -1) {
			cookiesShouldBeShown = false;
		}

		if (cookiesShouldBeShown == true) {
			this.ccService = this.injector.get<NgcCookieConsentService>(NgcCookieConsentService);
			this.translateCookies();
		}
		*/
		
	}

	private translateCookies() {
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate//
			.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
			.subscribe(data => {
				this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
				// Override default messages with the translated ones
				this.ccService.getConfig().content.header = data['cookie.header'];
				this.ccService.getConfig().content.message = data['cookie.message'];
				this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
				this.ccService.getConfig().content.allow = data['cookie.allow'];
				this.ccService.getConfig().content.deny = data['cookie.deny'];
				this.ccService.getConfig().content.link = data['cookie.link'];
				this.ccService.getConfig().content.policy = data['cookie.policy'];
	
				this.ccService.destroy();//remove previous cookie bar (with default messages)
				this.ccService.init(this.ccService.getConfig()); // update config with translated messages
			});
    });
	}
}
