/* src/app/app.component.css */
.app-container {
  min-height: 100vh;
}
mat-drawer {
  min-width: 300px;
  padding: 10px;
}
mat-drawer-content {
  min-height: 100vh;
}
.button-row button {
  margin-right: 4px;
}
/*# sourceMappingURL=app.component.css.map */
