import { Component, OnInit, Inject } from '@angular/core';

import { MerchantService} from '../services/merchant.service';
import { AppHelper } from '../helpers/app-helper';
import { UserService} from '../services/user.service';

import { Merchant } from '../models/merchant';

@Component({
  selector: 'merchant-promotion',
  templateUrl: './merchant-promotion.component.html',
  styleUrls: ['./merchant-promotion.component.scss']
})
export class MerchantPromotionComponent implements OnInit {

  public fol = false;

  constructor(	public merchantService: MerchantService,
                private appHelper: AppHelper) {
    this.fol = this.appHelper.isFol(); }

  ngOnInit() {
  }

}
