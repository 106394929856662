<div class="p-3" *ngIf="limitedConfirmation">

	<ng-container *ngIf="paramSTATUS == 'failed'">
		De betaling van je bestelling is mislukt.<br>
		Gelieve het opnieuw te proberen.
	</ng-container>

	<ng-container *ngIf="paramSTATUS == 'paid'">
		{{ 'order_status.feedback_short_begin' | translate }}<br>
		{{ 'order_status.feedback_short_end' | translate }}
	</ng-container>

</div>

<div 	*ngIf="merchantService.merchant && order && order.state != 'composing' && order.state != 'canceled' && !order.reservation_id"
		class="main">

	<venue-header [extra_info]="false" [back_goes_to]="'/'"></venue-header>

	<div class="titlebar px-3">{{ 'order_status.sent' | translate }}</div>

	<div 	class="d-flex flex-column align-items-center"
			style="margin-top: 100px;"
			*ngIf="merchantService.kiosk">


		<div 	class="joyn_kiosk p-3 m-3"
				style="border:1px solid;font-size:6em;font-weight:bolder;border-radius: 5px;text-align:center;">
			{{ order.kiosk_number }}<br>
		</div>

		<div  	class="p-3" *ngIf="order_is_number_only">
			Een moment aub. Je nummer wordt zodadelijk afgedrukt.<br>
			Wanneer het jou beurt is, wordt je bestelling opgenomen.<br>
		</div>

		<div  	class="joyn_kiosk p-3" *ngIf="!order_is_number_only"
						style="font-size:20px; white-space: pre-wrap;">
				{{ 'order.kiosk_confirmation' | translate }}
				<ng-container *ngIf="kiosk_has_printer">
					{{ 'order.kiosk_confirmation_with_printer' | translate }}
				</ng-container>
				<ng-container *ngIf="order.payment_method == 'CASH'">
					{{ 'order.kiosk_confirmation_with_cash' | translate }}
				</ng-container>
		</div>

	</div>

	<div 	*ngIf="order.scenario == 'waiter'"
			class="p-3" style="text-align: center;">

		{{ 'order_status.sent' | translate }}

		<div class="py-3">
			<a class="clickable" (click)="newOrder()" style="color: inherit !important">{{ 'order_status.new_order' | translate }}</a>
		</div>
	</div>

	<div 	*ngIf="!merchantService.kiosk && order.scenario != 'waiter'"
			class="p-3" style="text-align: center;">
		<div class="py-3">{{ 'order_status.response' | translate }}</div>

		<div class="py-3"
			 *ngIf="order.scenario == 'table'"
			 style="white-space: pre-wrap;">
			{{ 'order_status.table_order_received' | translate: { merchant_name: order.merchant_name } }}
			<ng-container *ngIf="!userService.user.virtual_user">{{ 'order_status.table_order_received_real_user' | translate }}</ng-container>.
		</div>

		<div 	*ngIf="order.scenario != 'table'"
				class="py-3">
			{{ 'order_status.feedback_begin' | translate : { merchant_name: order.merchant_name } }}
			<ng-container *ngIf="order.informed_when_prepared">{{ 'order_status.feedback_end' | translate }}</ng-container>
		</div>

		<div class="py-3" *ngIf="userService.user && !userService.user.is_anonymous_user">
			{{ 'order_status.profile_link' | translate }}
		</div>

		<div class="py-3">
			<div>{{ 'order_status.back_to_menu' | translate }}</div>
			<a class="clickable" (click)="newOrder()" style="color: inherit !important">{{ 'order_status.new_order' | translate }}</a>
		</div>

		<div class="py-3" *ngIf="userService.user && !userService.user.is_anonymous_user">
				<button (click)="goMyOrders()" mat-flat-button color="primary">{{ 'order_status.my_orders' | translate }}</button>
		</div>

	</div>

	<ng-template *ngIf="order.scenario != 'waiter'">
	<div class="p-3" *ngFor="let reward of order.coupon_rewards">
		<div class="reward">
			Beste klant,<br>
			<br>
			Proficiat, door jouw online bestelling heb je een waardebon gewonnen! Deze waardebon is enkel via het online bestelplatform te spenderen bij {{ order.merchant_name }}. Hieronder vind je alle nodige informatie terug:<br>
			<br>
			Waardeboncode: {{ reward.formatted_code }}<br>
			Waarde: €{{ reward.value_original }}<br>
			Vervaldatum: {{ reward.expiry_date | date }}<br>
			<br>
			Op het einde van het bestelproces kan deze code ingegeven worden, waarna de waarde van je coupon automatisch afgetrokken zal worden van het bestelbedrag. Is de waarde van je coupon hoger dan je bestelbedrag? Dan kan je restwaarde een andere keer gebruiken.<br>
			<br>
			Smakelijk!<br>
		</div>
	</div>
	</ng-template>

	<div class="p-3" *ngIf="order.scenario != 'waiter' && order.joyn_qr && !order.kiosk_id">

		<div class="joyn">
		<div class="d-flex p-0 m-0">
        	<div>
            	<img style='width:140px;' src="{{ order.joyn_qr }} ">
          	</div>
          	<div class="d-flex flex-column joyn_text justify-content-between">
              <div>{{ 'order_status.joyn_text' | translate }}</div>
              <div>
              	{{ 'order_status.joyn_points_begin' | translate }} {{ order.joyn_amount }} {{ 'order_status.joyn_points_end' | translate }}
              	<img class="joyn_logo" src='assets/logos/joyn.png'>
              </div>
            </div>
        </div>

        	<div class="joyn-button-div">
        		<button (click)="getJoynLink()" mat-flat-button class="joyn-button">
        			{{ 'order_status.joyn_collect' | translate }}
        		</button>
        	</div>

        </div>
	</div>

	<div class="p-3" *ngIf="order.scenario != 'waiter' && order.joyn_qr && order.kiosk_id">


		<div class="joyn_kiosk">
		<div class="d-flex p-0 m-0">
        	<div>
            	<img style='width:200px;' src="{{ order.joyn_qr }} ">
          	</div>
          	<div class="d-flex flex-column joyn_text_kiosk justify-content-between">
              <div>{{ 'order_status.joyn_text' | translate }}</div>
              <div>
              	{{ 'order_status.joyn_points_begin' | translate }}{{ order.joyn_amount }}{{ 'order_status.joyn_points_end' | translate }}
              	<img class="joyn_logo" src='assets/logos/joyn.png'>
              </div>
            </div>
        </div>

        </div>

	</div>

  <div 	class='row justify-content-center'
  		*ngIf="merchantService.merchant">
    <div style="width: 100%; max-width: 800px;">
      <app-advertisements [target_page]="'order-status'"></app-advertisements>
    </div>
  </div>

	<div 	*ngIf="!merchantService.kiosk && order.scenario != 'table' && order.scenario != 'waiter' && merchantService.merchant"
			class="p-3" style="text-align:center">
		<button mat-flat-button color="primary" (click)="showRoute()">
			{{ 'order_status.route' | translate }}
		</button>
	</div>



</div>

<div 	*ngIf="order && order.state != 'composing' && order.state != 'canceled' && order.reservation_id"
		class="main">

	<div class="container">
    <div>
       <img class="logo" src="{{ merchantService.merchant.image?.medium_url }}" style="border-radius: 5px;">
    </div>
    <div *ngIf="reservation">
      
      <h1>{{ merchantService.merchant.name }}</h1>
      
      Beste,<br/>
      Uw reservering in {{ merchantService.merchant.name }} werd bevestigd.<br/>
      Hierbij vindt u de details van uw reservering:<br/>
      <br/>
			Dag: {{ reservation.startDate | date:'longDate' }}<br/>
			Aankomstuur: {{ reservation.startDate | date:'HH:mm'}}<br/>

    </div>
  </div>

		<!--THIS ORDER IS A RESERVATION ORDER<br/>
		<br/>
		<div *ngIf="reservation">
			createdAt: {{ reservation.createdAt }}<br/>
			customerId: {{ reservation.customerId }}<br/>
			email: {{ reservation.email }}<br/>
			endDate: {{ reservation.endDate }}<br/>
			id: {{ reservation.id }}<br/>
			isApproved: {{ reservation.isApproved }}<br/>
			merchantId: {{ reservation.merchantId }}<br/>
			name: {{ reservation.name }}<br/>
			notes: {{ reservation.notes }}<br/>
			people: {{ reservation.people }}<br/>
			phone: {{ reservation.phone }}<br/>
			reservationPayable: {{ reservation.reservationPayable }}<br/>
			startDate: {{ reservation.startDate }}<br/>
			status: {{ reservation.status }}<br/>
			tableId: {{ reservation.tableId }}<br/>
			updatedAt: {{ reservation.updatedAt }}<br/>
		</div>
	-->
</div>
