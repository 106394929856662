	<div class="main p-3">

		<button mat-icon-button
		mat-dialog-close
		tabindex="-1"
		class="top-right">
		<mat-icon>close</mat-icon>
	
		</button>

	<h2 mat-dialog-title>{{ 'Groepsinformatie' | translate }}</h2>

	<ng-container>

		<form class="px-1" [formGroup]="group" (ngSubmit)="onSubmit()">
			
			<input formControlName="id" type="hidden">
			<input formControlName="approval_status" type="hidden">

			<div 
				*ngIf="!data.limited"
				class="form-container">
				<mat-form-field class="full-width">
					<mat-label>{{ 'apply.merchant' | translate }}</mat-label>
					<mat-select formControlName="merchant_id">
						<mat-option *ngFor="let merchant of merchants" [value]="merchant.id">
							{{ merchant.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="form-container">
				<mat-form-field class="full-width">
					<mat-label>{{ 'apply.group_name' | translate }}</mat-label>
					<input matInput formControlName="name">
				</mat-form-field>
			</div>

			<div class="form-container">
				<mat-form-field class="full-width">
					<mat-label>{{ 'apply.group_description' | translate }}</mat-label>
					<input matInput formControlName="description">
				</mat-form-field>
			</div>

			<ng-container *ngIf="!data.limited">

			<div class="row">
				<div class="col-6">

					<div class="form-container">
						<mat-form-field class="full-width">
							<mat-label>{{ 'apply.company_name' | translate }}</mat-label>
							<input matInput formControlName="company_name">
						</mat-form-field>
					</div>

				</div>
				<div class="col-6">

					<div class="form-container">
						<mat-form-field class="full-width">
							<mat-label>{{ 'apply.vat' | translate }}</mat-label>
							<input matInput formControlName="vat_number">
						</mat-form-field>
					</div>

				</div>
			</div>

			<div class="form-container">
				<mat-form-field class="full-width">
					<mat-label>{{ 'phone' | translate }}</mat-label>
					<input matInput formControlName="phone_number">
				</mat-form-field>
			</div>

			<div class="form-container">
				<mat-form-field class="full-width">
					<mat-label>{{ 'apply.who_pays' | translate }}</mat-label>
					<mat-select formControlName="who_pays">
						<mat-option *ngFor="let who_pays_option of who_pays_options" [value]="who_pays_option.value">
							{{ 'apply.' + who_pays_option.value | translate }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

				<div class="form-container">
					<mat-form-field class="full-width">
						<mat-label> {{ 'apply.instant_checkout' | translate }}</mat-label>
						<mat-select formControlName="instant_checkout">
							<mat-option [value]="true">
								{{ 'yes' | translate }}
							</mat-option>
							<mat-option [value]="false">
								{{ 'no' | translate }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-container">
					<mat-form-field class="full-width">
						<mat-label> {{ 'apply.checkout_at' | translate }}</mat-label>
						<mat-select formControlName="checkout_at"
									[disableControl]="group.value.instant_checkout">
							<mat-option [value]="null">
								{{ '--' }}
							</mat-option>
							<mat-option *ngFor="let timing of timings" [value]="timing.value">
								{{ timing.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-container">
					<mat-form-field class="full-width">
						<mat-label>{{ 'apply.payment_methods' | translate }}</mat-label>
						<mat-select formControlName="payment_methods" multiple>
							<mat-option *ngFor="let payment_method_option of payment_method_options" [value]="payment_method_option.value">
								{{ 'payment_method.' + payment_method_option.value | translate }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>


				<div formGroupName="address_attributes">

					<div class="row">
						<div class="col-6">

							<div class="form-container">
								<mat-form-field class="full-width">
									<mat-label>{{ 'apply.address_line_1' | translate }}</mat-label>
									<input 	required
											matInput 
											formControlName="line1">
								</mat-form-field>
							</div>

						</div>
						<div class="col-6">

							<div class="form-container">
								<mat-form-field class="full-width">
									<input matInput placeholder="{{ 'apply.address_line_2' | translate }}" formControlName="line2">
								</mat-form-field>
							</div>

						</div>
					</div>

					<div class="row">
						<div class="col-6">

							<div class="form-container">
								<mat-form-field class="full-width">
									<input 	required
											matInput placeholder="{{ 'apply.zip_code' | translate }}" formControlName="zipcode">
								</mat-form-field>
							</div>

						</div>
						<div class="col-6">

							<div class="form-container">
								<mat-form-field class="full-width">
									<input 	required
											matInput placeholder="{{ 'apply.city' | translate }}" formControlName="city">
								</mat-form-field>
							</div>

						</div>
					</div>

					<div class="form-container">
						<mat-form-field class="full-width">
							<mat-select 	required
											placeholder="{{ 'apply.country' | translate }}" formControlName="country_alpha2">
								<mat-option *ngFor="let country of countries" [value]="country.value">
									{{ 'country.' + country.name | translate}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

				</div>

				<div class="form-container"
				*ngIf="groupObject">
				<mat-form-field class="full-width">
					<mat-label>{{ 'apply.owner' | translate }}</mat-label>
					<mat-select formControlName="user_email">
						<mat-option *ngFor="let group_member of groupObject.group_members" [value]="group_member.email">
							{{ group_member.email }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="form-container">
				<div class="full-width">
					<h2 mat-dialog-title>{{ 'apply.qr_code' | translate}}</h2>
					<div class="d-flex flex-column">
						<div class="align-self-center">
							<ng-container *ngIf="qr_token">
								<a href="http://jb0.be/{{qr_token}}">
									<qrcode [qrdata]="'http://jb0.be/' + qr_token" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
								</a>
							</ng-container>
						</div>
						<div class="align-self-center mb-3 text-left"><button mat-stroked-button type="button" (click)="openConfirmDialog()">{{ 'apply.generate_qr_code' | translate }}</button></div>
					</div>
				</div>
			</div>

	</ng-container>


		<button mat-raised-button type="submit" [disabled]="!group.valid" color="primary">{{ 'update' | translate }}</button>

	</form>

</ng-container>

</div>

