import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard {

  constructor(	private userService: UserService,
  				private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userService.user) {
        return true;
    }

    // navigate to login page
    console.log('GUARD !!! USER');
    this.router.navigate(['/']);
    return false;
  }

}
