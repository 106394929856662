import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { AdvertisementService } from '../../services/advertisement.service';
import { MerchantService } from '../../services/merchant.service';
import { AppHelper } from '../../helpers/app-helper';

declare var cordova: any;

@Component({
  selector: 'app-advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.css'],
})
export class AdvertisementsComponent implements OnInit {

  @Input() public target_page = "general";
  @Input() public location = null;

  advertisements = [];

  constructor(
    public advertisementService: AdvertisementService,
    public merchantService: MerchantService,
     private cd: ChangeDetectorRef,
    public appHelper: AppHelper
  ) {}

  ngOnInit(): void {
    this.getAdvertisements();
  }

  getAdvertisements() {
    this.advertisementService
      .getAdvertisements(
        "q[imageable_id_eq]=" + this.merchantService.merchant.id +
        "&q[target_page_eq]=" + this.target_page
      )
      .subscribe(res => {
        for (const image of res.images) {
            this.advertisements.push(image);
        }
        this.cd.detectChanges();
      });
  }

  redirectToAdvertisement(url: string) {
    if (window.hasOwnProperty('cordova')) {
      cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(url);
    }
  }

  log(event) {
    console.log(this.location);
    const advertisement = this.advertisements[event];

     this.advertisementService.createAdvertisementLog(advertisement, this.location).subscribe( 
       res => {
       },
       error => {
       }
     );
  }
}
