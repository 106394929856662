<div *ngIf="!will_redirect">

	<ng-container *ngIf="!fol">

	<div style="position:fixed;top:0;left:0;width:100vw;z-index:999" *ngIf="!searchActive">

		<div class="first-part" style="background-color: white;">
			<app-navbar [background]="true" 
						[frontColor]="'darkGrey'" 
						(locationChanged)="refreshView();">
			</app-navbar>
		</div>

		<div class="mx-2 searchbox d-flex justify-content-between align-items-center pe-2">
			<div>
				<input  class="input-field merchantSearch" 
						style="width: calc(100vw/2)"
						type="search" 
						[(ngModel)]="searchText" 
						[ngbTypeahead]="searchAhead"
						placeholder="{{ 'search_merchant' | translate }}"
						#merchantSearch>
			</div>
			<div class="d-flex justify-content-end flex-grow-1 align-items-center">
				<div>
					<button mat-raised-button color="primary"
							(click)="openScenarioSheet()">
							
							<div class="d-flex justify-content-begin align-items-center">
								<img class="scenario_img me-1" src="./assets/scenarios/{{ whitelabel_for_scenarios }}/{{ userService.scenario }}.png">
								<div class="d-none d-md-block">{{ 'scenario.' + userService.scenario | translate | capitalizeFirst }}</div>
								<mat-icon>arrow_drop_down</mat-icon>
							</div>

						</button>

					<!--
					<div class="d-flex justify-content-begin align-items-center clickable" (click)="openScenarioSheet()">

						<img class="scenario_img me-1" src="./assets/scenarios/{{ whitelabel_for_scenarios }}/inverted/{{ userService.scenario }}.png">
						<div class="d-none d-md-block">{{ 'scenario.' + userService.scenario | translate | capitalizeFirst }}</div>
						<mat-icon>arrow_drop_down</mat-icon>

					</div>
					-->

				</div>
				<div class="ms-1">
					<div class="clickable" style="text-align: right;">
						
						<button mat-raised-button
								(click)="toggle()">
							
							<fa-icon 
								*ngIf="switch == 'list'"
								[icon]="faMap">
							</fa-icon>
							<fa-icon 
								*ngIf="switch == 'map'"
								[icon]="faList">
							</fa-icon>

						</button>

						<!--
						<button type="button" class="btn btn-outline-dark">
							<fa-icon 
								*ngIf="switch == 'list'"
								[icon]="faMap"
								(click)="toggle()">
							</fa-icon>
							<fa-icon 
								*ngIf="switch == 'map'"
								[icon]="faList"
								(click)="toggle()">
							</fa-icon>
						</button>
						-->
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="main d-flex justify-content-center">

		<div 	*ngIf="switch == 'list'"
				class="first-part mb-1">
			<div class="d-none d-md-flex flex-column" style="position:sticky;top:120px;min-width:300px;">
				<app-venue-filters (filtersChangedEvent)="setFilters($event)" 
								   [total_entries]="total_entries"
								   [valuesOfFiltersFromInput]="values_of_filters"
									 [user_logged_in]="user_logged_in"></app-venue-filters>
			</div>

		</div>

		
	<div class="d-flex justify-content-center">

	<ngx-spinner type="ball-pulse"></ngx-spinner>

	<div class="d-flex flex-column selected-merchant " *ngIf="selectedMerchant">
		<div class="container merchant-info p-3" style="max-width:800px">
			<div class="d-flex justify-content-between">
				<div>
					<img class="img-logo" *ngIf="selectedMerchant.logo" src='{{ selectedMerchant.logo }}' loading="lazy">
				</div>
				<div class="d-flex flex-column">
					<div class="merchant-name">{{ selectedMerchant.name }}</div>
					<div>{{ selectedMerchant.address.line1 }}</div>
					<div>{{ selectedMerchant.address.zipcode }}</div>
					<div>{{ selectedMerchant.address.city }}</div>
					<div>{{ selectedMerchant.address.country }}</div>
				</div>
				<div></div>
			</div>
		</div>
		<div (click)="setVenue(selectedMerchant.domain)" class="container clickable d-flex open-merchant bg_secondary align-items-center" style="border:1px solid;text-align:center; max-width:800px">
			<div class="open-merchant-div">{{ 'venues.open' | translate }}</div>
		</div>
		
	</div>

	<div *ngIf="switch == 'map'" class="map-wrapper">
		<google-map [center]="{lat: mapLatitude, lng: mapLongitude}"
								[zoom]="10"
								width="100%"
								height="100%">
			<ng-container *ngFor="let merchant of merchants">
				<ng-container *ngIf="merchant.address && merchant.address.latitude && merchant.address.longitude">
					<!--[iconUrl]="'./assets/launcher/map_logo.png'"-->
					<map-marker
					[position]="{lat:merchant.address.latitude, lng: merchant.address.longitude}"
					(mapClick)="markerClicked(merchant.domain)"
					[title]="merchant.name"></map-marker>
				</ng-container>
			</ng-container>
		</google-map>
	</div>
</div>


<div *ngIf="switch == 'list'" class="container p-0 m-0">

	<div class="row p-0 m-0">
		
		<div class="venues clickable col-6 col-lg-4 col-xl-3 p-0"
		*ngFor="let merchant of merchants; index as i"
		[ngClass]="{ 'disabled': merchant.settings.s_closed || merchant.tags.includes('setup') }"
		(click)="setVenue(merchant.domain)"
		[@fadeInOut]>

			<div class="favorite"
				 *ngIf="userService.user">
			<a (click)="toggleFav(merchant,$event)">
				<img *ngIf="!merchant.favorite" height="20" style="padding-right:10px;" src='./assets/icons/favorite_empty.png'>
				<img *ngIf="merchant.favorite" height="20" style="padding-right:10px;" src='./assets/icons/favorite.png'>
			</a>
			</div>

		<div class="flex flex-column p-2">
			
			<div class="logo p-1 text-center"> 
				<img 	*ngIf="merchant.logo"
						src="{{ merchant.logo }}" 
						style="max-width:100%; max-height:100%; border-radius: 5px;">
			</div>
			
			<!--
			<div *ngIf="merchant.venue_image" class="logo p-1" 
				[style.background]="'url(' + merchant.venue_image.medium_url + ')'">
			</div>
			-->

		<div class="tags mt-3 overflow-hidden">
			<div class="d-flex w-100 justify-content-between">
				<div style="line-height: 1.0; flex-grow: 1;">
					<div class="name">{{ merchant.name }}</div>
					<!--
					<ng-container *ngFor="let tag of merchant.tags | tagFilter; let i = index">
						<ng-container>
							<p *ngIf="i > 0" style="display:inline">, </p>
							<p style="display:inline">{{ tag | lowercase }}</p>
						</ng-container>
					</ng-container>
					-->
				</div>
				
				<div 	*ngIf="merchant.domain"
						class="align-right" style="line-height:0.9; flex-shrink: 0; min-width: 50px;">
					<p style="display:inline" *ngIf="merchant.settings.s_scenario_takeaway_enabled">
						<img 	class="scenario_img" 
								src='./assets/scenarios/{{ whitelabel_for_scenarios }}/inverted/takeaway.png'>
					</p>
					<p style="display:inline" *ngIf="merchant.settings.s_scenario_delivery_enabled">
						<img 	class="scenario_img" 
								src='./assets/scenarios/{{ whitelabel_for_scenarios }}/inverted/delivery.png'>
					</p>
					<p style="display:inline" *ngIf="merchant.settings.s_scenario_eatin_enabled || merchant.settings.s_scenario_table_enabled">
						<img 	class="scenario_img" 
								src='./assets/scenarios/{{ whitelabel_for_scenarios }}/inverted/eatin.png'>
					</p>
					<p style="display:inline" *ngIf="merchant.settings.s_scenario_catering_enabled">
						<img 	class="scenario_img" 
								src='./assets/scenarios/{{ whitelabel_for_scenarios }}/inverted/catering.png'>
					</p>
				</div>

			</div>
		</div>
		<div class="d-flex flex-row more_info font_secondary bg_tertiary justify-content-between rounded">
			<div *ngIf="merchant.status_formatted" class="ms-1 my-1 status">
				{{ merchant.status_formatted | capitalizeFirst }}
			</div>
			<div class="me-1 my-1"
				 style="text-align:right; min-width:60px;"
				 *ngIf="gps_requested">
				{{ merchant.distance_to_user | number:'1.1-1' }} KM
			</div>
		</div>
	</div>

		<div class="splitter" style="margin-left: 20px;" *ngIf="i % 2 == 0"></div>
		<div class="splitter" style="margin-right: 20px;" *ngIf="i % 2 == 1"></div>
	
	</div>

</div>

<ng-container *ngIf="merchants.length == 0 && filter_on_favourite_merchants == true">
	<div class="text-center mx-3" style="margin-top: 20%;">
		<div>{{ 'venues.no_favourites' | translate }}</div>
		<button mat-stroked-button 
						color='primary'
						(click)="resetFilterOnFavourites()"
						class="mt-3">{{ 'back' | translate | capitalizeFirst }}</button>
	</div>
</ng-container>

</div>
<div class="d-block d-md-none filter-button-container">
	<button mat-mini-fab 
			matBadge="{{ filter_count }}"
			matBadgeColor="accent"
			style="position: fixed; bottom: 20px; right: 20px; z-index: 1;"
			color="primary" 
			aria-label="Search button"
			(click)="openVenueFiltersDialog()">
		<fa-icon [icon]="faFilter"></fa-icon>
	</button>
</div>
</div>

<div #anchor
	 style="position: relative; top: -400px;">
</div>


</ng-container>

<ng-container *ngIf="fol">

	<div class="fol">
		<header>
			<div class="header-container">
				<app-navbar [background]="true" 
							[fol_bg]="true">
				</app-navbar>
			</div>
		</header>
		<section class="section section-master p-0">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-9">
						<h1 class="fol-title text-center">
							{{ 'fol.find_venue' | translate }}
						</h1>
					</div>
				</div>
				<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-2 order-md-1">
									<div class="row">
										<div class="fol-searchbox mx-2 mb-2">
											<input class="input-field merchantSearch"
											type="search"
											[(ngModel)]="searchText"
											[ngbTypeahead]="searchAhead"
											placeholder="{{ 'search_merchant' | translate }}"
											#merchantSearch>
		
											<button mat-flat-button>
												<img src="../../assets/frietjesonline/fol_svg.svg" style="width:25px;height:auto;margin-left:-10px;">
												<div style="font-family:'Dosis';line-height:normal;margin-left:-10px;">{{ 'venues.search' | translate }}</div>
											</button>
										</div>

										<div class="col-md-12" style="max-height: 55vh; overflow:scroll">
											<div *ngFor="let merchant of merchants">
												<div class="list-group-item" #divForOneMerchant>
													<div class="list-group-item-header d-flex align-items-baseline">
														<fa-icon [icon]="faFrenchFries" class="fa-2x far" style="margin-right:10px"></fa-icon>
														<div class="favorite"
															*ngIf="userService.user">
															<a (click)="toggleFav(merchant,$event)" style="margin-right:10px; color:#f0513f">
																<fa-icon *ngIf="!merchant.favorite" [icon]="faHeart" class="fa-2x"></fa-icon>
																<fa-icon *ngIf="merchant.favorite" [icon]="faSolidHeart" class="fa-2x"></fa-icon>
															</a>
														</div>
														<h3 class="item-title" *ngIf="merchant.distance_to_user != undefined else distanceUnknown">
															{{ merchant.name }} - {{merchant.distance_to_user | number:'1.1-1' }} KM
														</h3>
														<ng-template #distanceUnknown>
															<h3 class="item-title">{{ merchant.name }}</h3>
														</ng-template>
														<button class="fol-show-on-map btn__primary" type="button" (click)="showMerchantOnMap(merchant)">{{ 'fol.show_on_map'| translate }}</button>
													</div>
													<div class="list-group-item-body row specialties">
														<div *ngFor="let tag of merchant.tags | tagFilter" class="specialty"><span>{{ tag | capitalizeFirst }}</span></div>
													</div>
													<div class="list-group-item-body font-dosis">
														<address>
															<span>{{merchant.address.line1}}</span>
															<br>
															<span>{{merchant.address.zipcode}} {{merchant.address.city}}</span>
														</address>
													</div>
													<div class="list-group-item-footer my-2 font-dosis">
														<span class="frituur-status" *ngIf="merchant.status_formatted">{{merchant.status_formatted | capitalizeFirst }}</span>
													</div>
													<div class="list-group-item-link row ml-0">
														<a mat-flat-button class="btn__primary col-12"
															(click)="setVenue(merchant.domain)">
															{{ 'fol.place_order' | translate }}
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
	
							
								<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-1 order-md-1">
									<div class="col-md-12">
										<div class="d-md-none">
											<a class="btn btn__primary" (click)="toggleMap()" #mapButton>{{ 'fol.open_map' | translate }}</a>
										</div>
										<div id="map" #map class="d-none d-md-block mb-4"></div>
									</div>
								</div>
							</div>

							<div #anchor
								style="position: relative; top: -400px;">
							</div>
					</div>
				</div>
			</div>
		</section>
	
		<footer>  
			<app-fol-footer></app-fol-footer>
		</footer>
	</div>

</ng-container>

</div>



